import React, {useState, useEffect} from 'react';
import './CategoryFilter.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Category from '../../Atoms/Category/Category';
import { connect } from 'react-redux';
import { SAVE_SEARCH } from '../../../redux/actions';
import { withRouter } from 'react-router-dom';

let settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 880,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 559,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 410,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    }
  ]
}
const CategoryFilter = ({language, dispatch, match, history} ) => {
  const [categories, setCategories] = useState([]);


  const searchByCarrousel = id => {
    dispatch({
        type: SAVE_SEARCH,
        data: {radius: match.params.radius, cats: [id]}
    })
    history.push(`/tiendas/lista/categorias/${id}/radio/${match.params.radius}`);
    /*if (props.match.params.lat) {
      props.history.push(`/tiendas/lista/categorias/${id}/radio/${props.match.params.radius}/lat/${props.match.params.lat}/lng/${props.match.params.lng}`);
    } else {
      props.history.push(`/tiendas/lista/categorias/${id}/radio/${props.match.params.radius}`);
    }*/
  }

  const fetchOnMount = () => {
    fetch(`${process.env.REACT_APP_API_URL}/main-categories/${language === 'CA' || language === 'ca-ES' ? 0 : 1}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(resp => resp.json())
    .then(resp => {
        const mainCategories = [];
        resp.getMainCategories.forEach(cat=>{
          mainCategories.push(<Category searchByCarrousel={()=>searchByCarrousel(cat.id)} catIcon={cat.iconUrl} catName={cat.name} key={cat.name} id={cat.id}/>)
        })
        setCategories(mainCategories);
    })
  }

  useEffect(fetchOnMount, [language]);
    return (
        <div className='category-filter-container'>
            {/* <h3 className='category-filter-title'>Realiza una búsqueda por categoría</h3> */}
            <div className='categories-container'>
              <Slider {...settings}>
                {categories}
              </Slider>
            </div>
        </div>
        
    )
}

const mapStateToProps = (state) => ({
  userLocation: state.locationReducer.userLocation,
  search: state.searchReducer.search,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps)(CategoryFilter))
