import React from 'react';
import { Button } from 'antd'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import {useTranslation} from 'react-i18next';

const OrderCardDescription = ({orderSeller,history, language}) => {
  const { t } = useTranslation();
  const goToDescription = (id) => {
    history.push(`/dashboard/tienda/ventas-activas/ord-${id}`)
  }
  const languages = {
    'Reservado': 'Reservat',
    'En espera': 'En espera',
    'Entregado': 'Lliurat',
    'Cancelado': 'Cancel·lat'
  }
  return (
    <div key={orderSeller.id}>
    {console.log(orderSeller)}
      <div className="card-product">
        <div className="cart-seller-info">
          <h1 className="cart-seller-info--name">{`${t("buyer", {a: ' '})}: ${orderSeller.buyerName}`}</h1>
          <h6>{t("order-number", {x:orderSeller.id, a: ' '})} {moment(orderSeller.createdAt).format('L')}</h6>
          <h4 className="cart-seller-info--status">{`${t("order", {a: ' '})}: ${language !== 'CA' && language !== 'ca-ES' ? orderSeller.status : languages[orderSeller.status]}`}</h4>
          <Button className="round-btn btn-first"  onClick={() => goToDescription(orderSeller.id)} htmlType='submit' type="primary">{t("order-detail", {a: ' '})}</Button>
        </div>
      </div> 
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps,{})(OrderCardDescription))
