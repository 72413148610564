import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux';
import {Form, Input, Button, Alert } from 'antd';
import {LockOutlined, ArrowRightOutlined} from '@ant-design/icons';
//import {validatePassword, validateConfirmedPassword} from '../../validation/validations';
import './EditarLogin.scss';
import alerta from '../../../utilityFunctions/alerta';
import {withRouter} from 'react-router-dom';
import Swal from 'sweetalert2';
import {useTranslation} from 'react-i18next';

const EditarLogin = (props) => {
    const { t } = useTranslation();
    /*const [passwordError, setPasswordError] = useState('');
    const [confirmedPasswordError, setConfirmedPasswordError] = useState('');*/
    const [passwordChangeError, setPasswordChangeError] = useState('');
    const [loadings, setLoadings] = useState([]);

    const enterLoading = index => {
        const newLoadings = [...loadings];
        newLoadings[index] = true;
        setLoadings(newLoadings);
    };
    
    const handlePasswordSubmit = (ev) => {
        /*setPasswordError('');
        setConfirmedPasswordError('');*/
        setPasswordChangeError('');
        /*const validPassword=validatePassword(ev.password);
        const validConfirmedPassword=validateConfirmedPassword(ev.password,ev['confirmed-password']);*/
        //if (validPassword === '' && validConfirmedPassword === '') {
            enterLoading(0);
            fetch(`${process.env.REACT_APP_API_URL}/update-password/${props.userInfo.id}`, { 
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
               },
                body: JSON.stringify({ 
                  "email": props.userInfo.email,
                  "password": ev.password.trim() ,
                  "esp": localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
                })
            })
            .then(res => res.json())
            .then(res=> {
              console.log(res)
                if (res.message==="Password updated") {
                    const newLoadings = [...loadings];
                    newLoadings[0] = false;
                    setLoadings(newLoadings);
                    props.history.push('/dashboard/perfil')
                    alerta(t("pass-updated", {a: ' '}))
                } else {
                    const newLoadings = [...loadings];
                    newLoadings[0] = false;
                    setLoadings(newLoadings);
                    setPasswordChangeError(t('oops', {a: ' '}))
                }
            })
        /*} else {
            setPasswordError(validPassword);
            setConfirmedPasswordError(validConfirmedPassword);
        }*/
    }
    useEffect(()=>{
      document.body.scrollIntoView({block:'start'})
        if (props.userInfo.googleRegister) {
            Swal.fire({
                icon: 'error',
                title: t("invalid-action", {a: ' '}),
                text: t("google-validation", {a: ' '}),
            })
            props.history.push('/dashboard/perfil')
        }
    })
    return (
    <>
      <div className="profile-container">
      <div className="wrapped-profile-container">
        <div className="box form-edit-login-container">
          <Form name='password-form' onFinish={handlePasswordSubmit} className='form-edit-login'>
              <h2 className='center-text title-form'>{t("edit-pass", {a: ' '})}</h2>
              <Form.Item name='password' label={t("new-password", {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}, {validator: (_, value) => /\S{8,}/.test(value) ? Promise.resolve() : Promise.reject(t("error-password", {a: ' '}))}]}>
                  <Input.Password placeholder={t("new-password", {a: ' '})} prefix={<LockOutlined />} className='text-input' />
              </Form.Item>
              {/*passwordError && <Alert message={passwordError} type='error' className='error-msg' />*/}
              <Form.Item name='confirmed-password' label={t("confirm-contraseña", {a: ' '})} hasFeedback dependencies={['password']} rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}, ({getFieldValue}) => ({validator(_,value) {if (!value || !getFieldValue('password') || getFieldValue('password').trim() === value.trim()) {return Promise.resolve();} return Promise.reject(t("pass-not-match", {a: ' '}))}})/*, {validator: (_, value) => value === 'hola' ? Promise.resolve() : Promise.reject('formato malo, validar formato con regex aqui')}*/]}>
                  <Input.Password placeholder={t("confirm-contraseña", {a: ' '})} prefix={<LockOutlined />} className='text-input'/>
              </Form.Item>
              {/*confirmedPasswordError && <Alert message={confirmedPasswordError} type='error' className='error-msg' />*/}
              <Form.Item name='submit-btn'>
                  <Button type='primary' disabled={props.userInfo.googleRegister} htmlType='submit' className='btn btn-first' loading={loadings[0]}>
                  {t('save', {a: ' '})}
                      <ArrowRightOutlined/>
                  </Button>
              </Form.Item>
              {passwordChangeError && <Alert message={passwordChangeError} type='error' className='error-msg' />}
          </Form>
        </div>
      </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(withRouter(EditarLogin))
