import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import "./RegisterGoogle.scss";
import { SAVE_LOGIN_USER } from "../../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {useTranslation} from 'react-i18next';
// import alerta from '../../../utilityFunctions/alerta';
import Swal from "sweetalert2";
import { Spin } from "antd";

const RegisterGoogle = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const WEB_PUSH_PUBLIC_KEY =
    "BBUmYtq4HOOc-DCO7giM1WIVrvxa7elnqRr2kZsOVM7Lleenkjodqe35-ZtO6r5jL5-ceON0VvaRjcDGmix8Oxo";

  // Funcion para convertir la subcripcion del usuario
  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  const responseSuccessGoogle = (response) => {
    setIsLoading(true);
    setErrorPopup(false);
    // console.log(response);
    fetch(`${process.env.REACT_APP_API_URL}/google-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tokenId: response.tokenId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Auth Successful") {
          setIsLoading(false);
          props.dispatch({
            type: SAVE_LOGIN_USER,
            data: res.findUser,
          });
          // Funcion que registra al usario para las notificaciones
          function registerPush() {
            navigator.serviceWorker.register(
              `${process.env.PUBLIC_URL}/serviceworker.js`
            );
            navigator.serviceWorker.ready.then(function (registration) {
              return registration.pushManager
                .getSubscription()
                .then(function (subscription) {
                  if (subscription) {
                    return subscription;
                  }
                  return registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(
                      WEB_PUSH_PUBLIC_KEY
                    ),
                  });
                })
                .then(function (subscription) {
                  // console.log(subscription);
                  // console.log(res.findUser.person.userId);
                  return fetch(
                    `${process.env.REACT_APP_API_URL}/subscription`,
                    {
                      method: "post",
                      headers: {
                        "Content-type": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                      },
                      body: JSON.stringify({
                        subscription,
                        userId: res.findUser.person.userId,
                      }),
                    }
                  )
                    .then((resp) => resp.json())
                    .then((resp) => {
                      // console.log(resp);
                      if (resp.message === "process done") {
                        // console.log("Subscrito correctamente");
                      } else {
                        // console.log("No se pudo hacer la subscripcion");
                      }
                    });
                });
            });
          }
          registerPush();
          // Guardo el token en el local Storage
          if (res.token) {
            localStorage.setItem("token", res.token);
            localStorage.setItem("remember", "true");
          } else {
            localStorage.setItem("remember", "false");
          }
          props.history.push("/tiendas/mapa");
        } else if (res.message === "User created through google") {
          if (res.token) {
            localStorage.setItem("token", res.token);
            localStorage.setItem("remember", "true");
          } else {
            localStorage.setItem("remember", "false");
          }
          setIsLoading(false);
          const config = {
            headers: {
              'Authorization': `Bearer ${res.token}`
            }
          }
          fetch(`${process.env.REACT_APP_API_URL}/find-user/${res.userId}`, config)
            .then((response) => response.json())
            .then((data) => {
              // console.log(data);
              if (data.message === "user found") {
                // Guardo la data en redux
                props.dispatch({
                  type: SAVE_LOGIN_USER,
                  data: data.findUser,
                });

                // Funcion que registra al usario para las notificaciones
                function registerPush() {
                  navigator.serviceWorker.register(
                    `${process.env.PUBLIC_URL}/serviceworker.js`
                  );
                  navigator.serviceWorker.ready.then(function (registration) {
                    return registration.pushManager
                      .getSubscription()
                      .then(function (subscription) {
                        if (subscription) {
                          return subscription;
                        }
                        return registration.pushManager.subscribe({
                          userVisibleOnly: true,
                          applicationServerKey: urlBase64ToUint8Array(
                            WEB_PUSH_PUBLIC_KEY
                          ),
                        });
                      })
                      .then(function (subscription) {
                        // console.log(subscription);
                        return fetch(
                          `${process.env.REACT_APP_API_URL}/subscription`,
                          {
                            method: "post",
                            headers: {
                              "Content-type": "application/json",
                              'Authorization': `Bearer ${localStorage.getItem('token')}`
                            },

                            body: JSON.stringify({
                              subscription,
                              userId: data.findUser.person.userId,
                            }),
                          }
                        )
                          .then((resp) => resp.json())
                          .then((resp) => {
                            // console.log(resp);
                            if (resp.message === "process done") {
                              // console.log("Subscrito correctamente");
                            } else {
                              // console.log("No se pudo hacer la subscripcion");
                            }
                          });
                      });
                  });
                }
                registerPush();
                // Guardo el token el el LocalStorege
                if (res.token) {
                  localStorage.setItem("token", res.token);
                  localStorage.setItem("remember", "true");
                } else {
                  localStorage.setItem("remember", "false");
                }
                // Me voy al mapa para ver tiendas
                props.history.push("/tiendas/mapa");
                // console.log("Final");
              }
            });
        } else if (res.message === "not allowed by sessions limit - google") {
          setErrorPopup(false);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: t('oops', {a: ' '}),
            text:
              "Ha excedido el número máximo de sesiones activas. Por favor cierre sesión en alguno de sus otros dispositivos.",
          });
        } else if (res.message === "algo salió mal") {
          setErrorPopup(false);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: t('oops', {a: ' '}),
            text: "",
          });
        } else if(res.message === "Something went wrong - password or user") {
          // console.log("el usuario se registro por el formulario", res);
          setIsLoading(false);
            setErrorPopup(false);
          Swal.fire({
            icon: "error",
            title: t('error-login', {a: ' '}),
            text: t('error-login-msg', {a: ' '}),
          });
          } else {
            // console.log("error dentro del else", res);
            setIsLoading(false);
            setErrorPopup(false);
            Swal.fire({
              icon: "error",
              title: res.customMessage ? res.message : t('oops', {a: ' '}),
              text: "",
            });
          }
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log("error del catch", err);
      });
  };

  const responseErrorGoogle = (response) => {
    setIsLoading(false);
    // console.log(response);
    if (response.error === "popup_closed_by_user") {
      setErrorPopup(true);
      // console.log("Se cerro la ventana");
    }
  };

  return (
    <div className="google-container">
      {isLoading ? (
        <div className="container-spiner-google">
          {props.home ? (
            <div className="item-spiner-google">
              <Spin tip={t('wait', {a: ' '})} size="small"></Spin>
            </div>
          ) : (
            <div className="item-spiner-google-blue">
              <Spin tip={t('wait', {a: ' '})} size="small"></Spin>
            </div>
          )}
        </div>
      ) : (
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText={t('login-google', {a: ' '})}
          onSuccess={responseSuccessGoogle}
          onFailure={responseErrorGoogle}
          cookiePolicy={"single_host_origin"}
          className="btn-google-login"
        />
      )}
      {errorPopup && props.home && (
        <p className="error_popup">
          {t('closed-google', {a: ' '})}
        </p>
      )}
      {errorPopup && !props.home && (
        <p className="error_popup-blue">
          {t('closed-google', {a: ' '})}
        </p>
      )}
    </div>
  );
};

export default withRouter(connect()(RegisterGoogle));
