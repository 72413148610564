import React, {useState, useEffect} from 'react'
import OrderItemReserved from '../OrderItemReserved/OrderItemReserved'
import { Button , Divider } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import 'sweetalert2/src/sweetalert2.scss'
import moment from 'moment';
import 'moment/locale/es';
import QualifiedSeller from '../QualifiedSeller/QualifiedSeller';
import { connect } from 'react-redux';
import {useTranslation, Trans} from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import CancelOrderBuyer from '../CancelOrderBuyer/CancelOrderBuyer';
const MySwal = withReactContent(Swal)


const OrderCardReserved = ({order, userInfo, setReload, confirmed, history, language}) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [goOrder, setGoOrder] = useState(false)

  useEffect(() => {
    setShowModal(false)
    setGoOrder(false)
    showModal && 
    confirmOrderDelivered()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])
  
  const goToOrderActives = () => {
    return history.push('/dashboard/perfil/compras-activas')
  }

  goOrder && goToOrderActives()

  // Eliminar orden
  // const handleDeleteOrder = (id, name, sellerId, sellerEmail) => {
  //   enterLoading(0);
  //   Swal.fire({
  //     title: `${t("confirm-cancel-order", {a: ' '})} ${name}?`,
  //     text: t("cancel-warning", {a: ' '}),
  //     icon: "warning",
  //     showCancelButton :true,
  //     confirmButtonText: t("confirm-cancel", {a: ' '}),
  //     cancelButtonText: t('keep', {a: ' '})
  //   })
  //   .then( async (result) => {
  //     if (result.isConfirmed) {
  //       let url = `${process.env.REACT_APP_API_URL}/cancel-order/${id}/${sellerId}`;
  //       const response = await fetch(url, {
  //         method: 'PATCH',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${localStorage.getItem('token')}`
  //         },
  //         body: JSON.stringify({ 
  //             "status": "Cancelado",
  //             "personId": userInfo.person.id,
  //             "buyerName": userInfo.person.firstName,
  //             "sellerEmail": sellerEmail,
  //             "buyerPhoneNumber": userInfo.person.phoneNumber !== null ? userInfo.person.phoneNumber : '',
  //             "sellerId": sellerId,
  //             "mySellerId": userInfo.person.userId,
  //             "esp": localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
  //         })
  //       })
  //       if(response.status === 200) {
  //         setReload(true)
  //         history.push('/dashboard/perfil/compras-activas')
  //         Swal.fire(t("cancelled-order", {a: ' '}), {
  //           icon: "success",
  //         });
  //       }
  //     } else {
  //       enterLoading(1);
  //       Swal.fire(t("cancelled-action", {a: ' '}));
  //     }
  //   });
  // }

  const handleDeleteOrder = (orderId, name, sellerId, sellerEmail) => {
    enterLoading(0);
    MySwal.fire({
      title: `${t("confirm-cancel-order", {a: ' '})} ${name}?`,
      title: t("reason-2", {a: ' '}),
      icon: "info",
      html: <CancelOrderBuyer setReload={setReload} orderId={orderId} sellerId={sellerId} sellerEmail={sellerEmail} personId={userInfo.person.id} buyerName={ `${userInfo.person.firstName} ${userInfo.person.lastName !== null && userInfo.person.lastName}`} buyerEmail={userInfo.email} buyerPhoneNumber={userInfo.person.phoneNumber} mySellerId={userInfo.person.userId} />,
      showCancelButton :true,
      showConfirmButton :false,
      // confirmButtonText: 'Valorar tienda',
      cancelButtonText: t("no-cancel", {a: ' '})
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        Swal.fire(t("cancelled-order-2", {a: ' '}))
      } else {
      
      } //Fin de else
    });
  }

  // Loader del boton 
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  // Sumar el total 
  const getTotal = orderArr => {
    let total=0;
    orderArr.forEach(product=>{
      total += product.quantity * product.product.price
    })
    return total;
  }
  

  // Confirmar la orden y calificar
  const confirmOrderDelivered = (orderId, nameStore, personId, sellerId, storeId) => {
    MySwal.fire({
      title: `${t("qualify", {a: ' '})} ${nameStore}`,
      html: <QualifiedSeller setGoOrder={setGoOrder} setReload={setReload} orderId={orderId} userId={userInfo.person.userId} personId={userInfo.person.id} sellerId={sellerId} storeId={storeId}/>,
      icon: "info",
      showCancelButton :true,
      showConfirmButton :false,
      // confirmButtonText: 'Valorar tienda',
      cancelButtonText: t("rate-later", {a: ' '})
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        history.push('/dashboard/perfil/compras-activas')
        Swal.fire(t("thanks-order", {a: ' '}))
        setShowModal(false)
      } else {
        Swal.fire(t("come-again-soon", {a: ' '}))
      } //Fin de else
    });
  }

  const languages = {
    'Reservado': 'Reservat',
    'En espera': 'En espera',
    'Entregado': 'Lliurat',
    'Cancelado': 'Cancel·lat'
  }

  return (
    // order.status === 'Reservado' && //Me muestra solo las ordenes en reservadas
    <>
    <div className="card-product-reserved" key={order.id}>
    {console.log('order', order)}
      <div className="cart-seller-info">
      {/* <Link to={`/vendedor/${order.storeId}/${order.sellerName.trim().toLowerCase().replace(/\s+/g, '-')}`}><h1 className="cart-seller-info--name">{`${t("seller", {a: ' '})} ${order.sellerName}`}</h1></Link> */}
      <Link to={`/vendedor/${order.storeId}`}><h1 className="cart-seller-info--name">{`${t("seller", {a: ' '})} ${order.sellerName}`}</h1></Link>
        <h6>{t("order-number2", {a: ' '})} #{order.id}</h6>
        <h6 className="mt-5">{t("ordered-on", {a: ' '})}:  {moment(order.createdAt).format('L')}</h6>
        {/* <p className="cart-seller-info--location">Tarragona, Cataluña</p> */}
        <h4 className="cart-seller-info--status">{`${t("order", {a: ' '})}: ${language !== 'CA' && language !== 'ca-ES' ? order.status : languages[order.status]}`}</h4>
        {/* <div><h6 className="cart-seller-info--totalPrice">Total de tu orden: <span>{`${getTotal(order.orderItem)}`}</span></h6></div> */}
        <div><h6 className="cart-seller-info--totalPrice">{t("total", {a: ' '})}: <span>{order.totalPrice}</span></h6></div>
        <Divider />
        {
          order.withdrawalTime !== null && order.deliveryAddress === null && !confirmed &&
          <>
            <div><h6 className="cart-seller-info--withDrawelTime">{t("withdrawal-time", {a: ' '})}: <span>{order.withdrawalTime}</span></h6></div>
            {order.additionalInformation !== null && <div><h6 className="cart-seller-info--withDrawelTime">{t("seller-message", {a: ' '})}: <span>{order.additionalInformation}</span></h6></div>}
            <p className="cart-seller-info-data">{`${t("store-phone", {a: ' '})}: ${order.storePhoneNumber}`}</p>
            {order.status !== 'En espera' && <a className="enlace-whatsapp" href={`https://api.whatsapp.com/send?phone=${order.storePhoneNumber}&text=Hola%20${order.sellerName},%20`} target="_blank" rel="noopener noreferrer">{t("write-store", {a: ' '})} <span className="whatsapp-name-user">{` ${order.sellerName}`}</span> via <span className="whatsapp-name"> WhatsApp <WhatsAppOutlined /></span></a>}
          </>
        }
        {
          order.withdrawalTime === null && order.deliveryAddress === null &&
          <div className="mb-10"><h6>{t("store", {a: ' '})} <span className="cart-seller-info--withDrawelTime-undefined">{`${order.sellerName}`}</span> {t("no-withdrawal-yet", {a: ' '})}</h6></div>
        }
        
        {
          order.withdrawalTime === null && order.deliveryAddress !== null && !confirmed &&
          <div>
            <h6 className="cart-seller-info--withDrawelTime">{t("withdrawl-time-time", {a: ' '})}: <span>{order.deliveryAddress}</span></h6>
            {order.additionalInformation !== null ? <div><h6 className="cart-seller-info--withDrawelTime">{t("seller-message", {a: ' '})}: <span>{order.additionalInformation}</span></h6></div>: null }
            <p className="cart-seller-info-data">{`${t("store-phone", {a: ' '})}: ${order.storePhoneNumber}`}</p>
            {order.status !== 'En espera' && <a className="enlace-whatsapp" href={`https://api.whatsapp.com/send?phone=${order.storePhoneNumber}&text=Hola%20${order.sellerName},%20`} target="_blank" rel="noopener noreferrer">{t("write-store", {a: ' '})} <span className="whatsapp-name-user">{` ${order.sellerName}`}</span> via <span className="whatsapp-name"> WhatsApp <WhatsAppOutlined /></span></a>}
          </div>
        }
        {
          confirmed &&
          <>
          <div className="mb-5"><h6>{t("delivered-order2", {a: ' '})} </h6></div>
          <div className="mb-5"><h6>{t("no-rate-store", {a: ' '})} <span className="cart-seller-info--withDrawelTime-undefined">{`${order.sellerName}`}</span></h6></div>
          </>
        }
      </div>
      <Divider />
      <div className="cart-seller-product-container">
      {
        order.orderItem.map(orderItem => (
          <OrderItemReserved key={orderItem.id}
            orderItem={orderItem}
          />
        ))
      }
      </div>
      <div className="container-btn-cart">
        {
          order.status ==='Entregado'&& order.deliveryConfirmed && //Me muestra el boton solo si ya la tienda dio a entregar y paso el deliveryConfirmed a true
          <Button className="round-btn btn-cart-reserved" type="primary" onClick={() => confirmOrderDelivered(order.id, order.sellerName, order.personId, order.sellerId, order.storeId)}>
            {t("confirm-delivery", {a: ' '})}
          </Button>
        }
        {
          /* order.status ==='Reservado' && // Si ya la tienda confirmo no puedo eliminar
          <Button className="round-btn btn-cart-reserved" loading={loadings[0]} type="primary" danger onClick={() => handleDeleteOrder(order.id, order.orderItem[0].product.store.storeName, order.orderItem[0].product.store.userId)}>
            Cancelar pedido
          </Button> */
          order.status === 'En espera' && // Solo mostrar en espera, luego de la confirmacion ya no puede cancelar
          <p className="btn-delete-order">
            <Trans i18nKey='cancel-order2' values={{a: ' '}}>
                ¿Deseas cancelar el pedido? Haz <span onClick={() => handleDeleteOrder(order.id, order.sellerName, order.sellerId, order.sellerEmail)} className="cta-delete-order">click aquí</span> 
            </Trans>
          </p>
        }
      </div>
    </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps,{})(OrderCardReserved))
// export default OrderCardReserved

