import React from 'react'
// import Profile from '../Profile/Profile'
// import './Dashboad.scss'
import { withRouter } from 'react-router-dom'
import EmptyCart from '../../Molecules/EmptyCart/EmptyCart'

const InConstruction = () => {
  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
        <EmptyCart message="En proceso..."/>
        {/* <Profile /> */}
      </div>
    </div>
  )
}

export default withRouter(InConstruction)
