import React, {useState} from 'react'
import {Form, Button, Upload, Alert} from 'antd';
import { validateFileSize, getBase64} from '../../validation/validations';
import {UploadOutlined, ArrowRightOutlined} from '@ant-design/icons';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import alerta from '../../../utilityFunctions/alerta';
import ImgCrop from 'antd-img-crop';
import {useTranslation} from 'react-i18next';
import imageCompression from 'browser-image-compression';

const FormProfilePictureStore = (props) => {
  const { t } = useTranslation();
  const [uploadedPhoto, setUploadedPhoto] = useState(false)
  const [profilePictureError, setProfilePictureError] = useState('')
  const [loadings, setLoadings] = useState([])
  const [profilePicture, setProfilePicture] = useState(undefined);

  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }
  
  // Editar foto de perfil
  const handleFormSubmit = (ev) => {
    if (profilePicture) {
      setProfilePictureError('')
      const compressImg = async img => {
        const options = {
          maxSizeMB: 0.5,
          useWebWorker: true,
          maxWidthOrHeight: 1080,
          fileType: "image/jpg",
          initialQuality: 0.5,
          maxIteration: 15
        }
          try {
              // console.log(img)
              return imageCompression(img, options);
          } catch (error) {
              // console.log(error)
          }
        }
        enterLoading(0);
        compressImg(profilePicture)
        .then(file=>{
            const formData = new FormData();
            formData.append('storeId', props.userInfo.store.id);
            formData.append('profilePicture', file);
            fetch(`${process.env.REACT_APP_API_URL}/store-profile-picture/${props.userInfo.store.id}/${props.userInfo.person.userId}`, { 
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              },
              body: formData
            })
            .then(res => res.json())
            .then(res => {
              if(res.message === 'Profile picture uploaded and updated') {
                props.dispatch({
                  type: SAVE_LOGIN_USER,
                  data: res.findUser
                })
                alerta(t('pic-updated', {a: ' '}))
                props.history.push('/dashboard/tienda')  
                const newLoadings = [...loadings]
                newLoadings[0] = false;
                setLoadings(newLoadings);
              } else {
                const newLoadings = [...loadings]
                newLoadings[0] = false;
                setLoadings(newLoadings);
                setProfilePictureError(t('oops', {a: ' '}))
              }
            })
        })
    } else {
      setProfilePictureError(t('heavy-photo', {a: ' '}))
    }
           
  }

  const fakeRequest = ({file, onSuccess}) => {
    if (validateFileSize(file) === '') {
      setUploadedPhoto(true)
      setProfilePicture(file);
      setTimeout(()=> {
        onSuccess('ok');
      }, 0);
    } else {
        setProfilePicture(undefined);
        setTimeout(()=> {
          onSuccess(false);
        }, 0);
        setProfilePictureError(t('heavy-photo', {a: ' '}))
    }
    
      /*if (validateFileSize(file) === '') {
        setValidProfilePicture(true);
        setProfilePictureError('')
        new Promise((resolve, reject)=>{
          resolve(compressImg(file))
        })
        .then(res => {
            setProfilePicture(res)
            setUploadedPhoto(true)
        })
        setTimeout(()=> {
          onSuccess('ok');
        }, 0);
      } else {
        setValidProfilePicture(false);
        setProfilePictureError(`La foto no puede exceder ${process.env.REACT_APP_MAX_FILE_SIZE}mb de tamaño. Por favor intente con otra imagen.`)
      }*/
  };

  return (
    <Form name='user-info-form' onFinish={handleFormSubmit} className='form-editar-foto-store center-text center-block'>
      <Form.Item name="profilePicture">
        <ImgCrop aspect={1 / 1} modalTitle={t('fix-image', {a: ' '})} modalCancel={t('cancel', {a: ' '})} modalOk={t('confirm', {a: ' '})}>
          <Upload onRemove={()=>{setProfilePicture(undefined); setProfilePictureError(''); setUploadedPhoto(false)}} customRequest={fakeRequest} listType="picture">
            {
              !uploadedPhoto && !profilePictureError
              ? 
              <>
                <Button>
                  <UploadOutlined /> {t("edit-profile-pic", {a: ' '})}
                </Button>
                <p>{t("max-size", {a: ' '}) +' ' +process.env.REACT_APP_MAX_FILE} mb.</p> 
              </>
              : <p>{t("store-pic-success", {a: ' '})}</p> 
            }
          </Upload>
        </ImgCrop>
      </Form.Item>
      {profilePictureError && <Alert message={profilePictureError} type='error' className='error-msg' />}
      {props.userInfo.store.blocked ||
        !uploadedPhoto
        ?
        <>
          <Button htmlType='submit' disabled className='btn btn-first mb-5'>
          {t('save', {a: ' '})}
            <ArrowRightOutlined />
          </Button>
          <div className="center mt-5"><Button danger className='btn round-button ' onClick={props.history.goBack}>{t('cancel', {a: ' '})}</Button></div>
        </>
        :
        <>
          <Button htmlType='submit' className='btn btn-first  mb-5' loading={loadings[0]}>
          {t('save', {a: ' '})}
            <ArrowRightOutlined />
          </Button>
          <div className="center mt-5"><Button danger className='btn round-button ' onClick={props.history.goBack}>{t('cancel', {a: ' '})}</Button></div>
        </>
      } 
    </Form> 
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(FormProfilePictureStore))
