import React from 'react'
import { connect } from 'react-redux'
import './Profile.scss';
import { Button } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import portraitPicture from '../../../assets/P1-Banner perfil.jpg';
import {useTranslation} from 'react-i18next';
import { CheckCircleOutlined, CameraFilled, PhoneOutlined, EnvironmentOutlined, SmileOutlined } from '@ant-design/icons';

const Profile = (props) => {
  const { t } = useTranslation();
  const direction = props.userInfo.person.location.length > 0 ?
  props.userInfo.person.location[0].additionalInformation ?
  props.userInfo.person.location[0].address.split('+++').join(', ')+' / '+ props.userInfo.person.location[0].additionalInformation:
  props.userInfo.person.location[0].address.split('+++').join(', '):
  t("no-location-user", {a: ' '})

  return (
    <div className="container_card ">
      <div className="card-profile user box">
        <div className="main-container-profile-user">

          <div className="container-store-profile-picture picture-photo-user">
            <Link to="/dashboard/perfil/apariencia">
              <div className="camera-image-profile"><CameraFilled twoToneColor="#eee" /></div>
              <img src={props.userInfo.profilePicture.url} alt="dsd"/>
            </Link>
          </div>

          <div className="main-container-profile-user-data user"> 
            <div className="main-container-profile-user-name">
              <div className="mt-10">
               <h1 className="blue-text user-name-profile user">{props.userInfo.person.firstName} {props.userInfo.person.lastName}</h1>
               <p className="gray-text user-email">{props.userInfo.email}</p>
              </div>
             
              <Link to="/dashboard/perfil/editar-perfil">
                <Button type='primary' className='center-block btn-small round-btn btn-second mt-10'>{t('edit-name', {a: ' '})}</Button>
              </Link>
            </div>
            <div className="main-container-profile-user-direction">
              <div>
                <p className='container-store-description-category user-direccion'><EnvironmentOutlined />{t('delivery-address', {a: ' '})}: <span>{direction}</span></p>
                <p className='container-store-description-category user-contact'><PhoneOutlined /> {t('contact', {a: ' '})}: <span>{props.userInfo.person.location.length>0?props.userInfo.person.location[0].phoneNumber:t('no-contact', {a: ' '})}</span></p>
              </div>
              <Link to="/dashboard/perfil/direccion-de-envio">
                <Button type='primary' className='center-block btn-small round-btn btn-second'>{t('edit-contact', {a: ' '})}</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
    userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(Profile))
