import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const OrderItemReserved = ({orderItem}) => {
  const { t } = useTranslation();
  return (
    <div key={orderItem.id}>
      <div className="cart-seller-product">
        <img className="cart-seller-img" src={orderItem.productImageUrl} alt={orderItem.productName}/>
        <div className="cart-seller-description">
        <div className="cart-seller-description-others">
          <div className="title-container">
            <Link to={`/producto/${orderItem.productId}/${orderItem.productName.trim().toLowerCase().replace(/\s+/g, '-')}/1`}><h4 className="cart-seller-description-title">{orderItem.productName}</h4></Link>
          </div>
          <p>{`${t('price', {a: ' '})}: ${orderItem.productPrice}€`}</p>
          <p>{`${t('quantity', {a: ' '})}: ${orderItem.quantity}`}</p>
          <p>{`${t('subtotal', {a: ' '})}: ${orderItem.quantity *orderItem.productPrice}€`}</p>
        </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps,{})(OrderItemReserved)
