import React, {useEffect} from 'react'
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { getOrders } from '../../../redux/actionCreators';
import store from '../../../redux/store';
import { connect } from 'react-redux';
import {useTranslation} from 'react-i18next';

const IconCart = ({userInfo, userOrders}) => {
  const { t } = useTranslation();
  useEffect(() => {
    store.dispatch(getOrders(userInfo.person.userId))
  }, [userInfo.person.userId])

  return (
    <div className="main-footer--item">
      <Badge count={userOrders.length > 9 ? `${9}+` : userOrders.length} gap={1}>
        <ShoppingCartOutlined twoToneColor="#161B4B" />
      </Badge>
      <div className="icon-title">{t("go-to-cart", {a: ' '})}</div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  userOrders: state.ordersReducer.userOrders
})
export default connect(mapStateToProps, {})(IconCart)
