import React, {useState,useEffect} from 'react'
import './ViewOrderDetail.scss'
import { withRouter, Link, /*Link*/} from 'react-router-dom'
import Loader from '../../Atoms/Loader/Loader'
// import EmptyCart from '../../Molecules/EmptyCart/EmptyCart'
import moment from 'moment';
import 'moment/locale/es';
import {useTranslation} from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';
import logoVentas from '../../../assets/P1-perfil-compras-no-compras-activas-imagen.png';
import { connect } from 'react-redux';
import {  Divider } from 'antd';

const ViewOrderDetail = (props) => {
  const { t } = useTranslation();
  const [orderDetail, setOrderDetail] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [orderNotFound, setOrderNotFound] = useState(false)

  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/history-order/${props.match.params.id}/${props.userInfo.person.userId}`, {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
  })
    .then(resp => resp.json())
    .then(resp => {
      // console.log(resp)
      // console.log(`respuesta de la orden `, resp)
      // console.log(resp.findBuyerOrderHistory)
      if(resp.message === 'order found') {
        setOrderDetail(resp.findBuyerOrderHistory)
        setIsLoading(false);
        setOrderNotFound(false)
        // console.log('Orden encontrada')
      } 
      if (resp.message === 'order not found'){
        // console.log('Orden no encontrada')
        setOrderNotFound(true)
        setIsLoading(false);
      } 
      if(resp.message === 'not allowed') {
        if(props.location.pathname.includes('/perfil/detalle')) {
          // console.log('debe ir a historial de compras')
          props.history.push('/dashboard/perfil/historial-de-compras')
        }
        if(props.location.pathname.includes('/tienda/detalle')){
          // console.log('debe ir a historial de ventas')
          props.history.push('/dashboard/tienda/historial-de-ventas')
        }
      }
    })
  }, [props.match.params.id])

  // useEffect(() => {
  //   document.body.scrollIntoView({block:'start'})
  //   setIsLoading(true);
  //   fetch(`${process.env.REACT_APP_API_URL}/order/${props.match.params.id}/${props.userInfo.person.userId}/${null}`)
  //   .then(resp => resp.json())
  //   .then(resp => {
  //     console.log(resp)
  //     if(resp.message === 'order found') {
  //       setOrderDetail(resp.findOrder)
  //       setIsLoading(false);
  //       setOrderNotFound(false)
  //     } 
  //     if (resp.message === 'order not found'){
  //       console.log('Orden no encontrada')
  //       setOrderNotFound(true)
  //       setIsLoading(false);
  //     } 
  //     if(resp.message === 'not allowed') {
  //       if(props.location.pathname.includes('/perfil/detalle')) {
  //         console.log('debe ir a historial de compras')
  //         props.history.push('/dashboard/perfil/historial-de-compras')
  //       }
  //       if(props.location.pathname.includes('/tienda/detalle')){
  //         console.log('debe ir a historial de ventas')
  //         props.history.push('/dashboard/tienda/historial-de-ventas')
  //       }
  //       console.log(props.location.pathname)
  //     }
  //   })
  // }, [props.match.params.id])

  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
          {
            isLoading ? <Loader /> :
            <div className="order-details-container box">
              {orderNotFound ? 
              <div className="main-empty">
                <h2>{t('order-not-found', {a: ' '})}</h2>
                <img src={logoVentas} alt={t('order-not-found', {a: ' '})}/>
              </div> 
              :
              <>
    
                <div className='container-orders-reserved-arrow'>
                  <Link to="/dashboard/perfil/historial-de-compras"><ArrowLeftOutlined /></Link>
                </div>
                <div className="order-details-header">
                  <h2>{t('purchase', {a: ' '})} #{orderDetail.id} - {moment(orderDetail.createdAt).format('L')}</h2>
                </div>
                <div className="order-details-summary">
                  <div className="order-details-summary-header">
                    {/* <p>{t('purchased-from', {a: ' '})} <span><Link to={`/vendedor/${orderDetail.storeId}/${orderDetail.sellerName.trim().toLowerCase().replace(/\s+/g, '-')}`}>{orderDetail.sellerName}</Link></span></p> */}
                    <p>{t('purchased-from', {a: ' '})} <span><Link to={`/vendedor/${orderDetail.storeId}`}>{orderDetail.sellerName}</Link></span></p>
                    <p className="order-details-summary-price">{t('total', {a: ' '})}: <span>{`${orderDetail.totalPrice}€`}</span></p>
                    {/* <p className="order-details-summary-status" >{orderDetail.status}</p> */}
                    <p>{t('delivered', {a: ' '})}</p>
                  </div>
                  <Divider />
                  <div className="order-details-summary-body">
                    {
                      orderDetail.buyerOrderItemHistory.map(od => (
                        <div className="order-details-summary-order-item" key={od.id}>
                          <div>
                            <img className="order-details-summary-img" src={od.productImageUrl} alt={od.productName}/>
                          </div>
                          <div className="order-details-summary-content">
                            <p>{t('product-name', {a: ' '})}: <Link to={`/producto/${od.productId}/${od.productName.trim().toLowerCase().replace(/\s+/g, '-')}/1`}><span className="order-details-summary-name">{od.productName}</span></Link> </p>
                            {/* <p>Nombre del producto:<span className="order-details-summary-name">{od.productName}</span></p>  */}
                            <p>{t('product-description', {a: ' '})}: <span className="order-details-summary-description">{od.productDescription !== '' ? od.productDescription : 'Sin descripción'}</span> </p>
                            <p>{t('quantity', {a: ' '})}: <span className="order-details-summary-description">{od.quantity}</span> </p>
                            <p>{t('price', {a: ' '})}: <span className="order-details-summary-description">{od.productPrice}</span> </p>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </>
              }
            </div>
          }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(ViewOrderDetail))

