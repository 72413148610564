import { 
  SAVE_LOGIN_USER,
  GET_ORDERS_IN_TO_CART, 
  SAVE_LOCATION,
  GET_NOTIFICATIONS,
  SAVE_SEARCH, 
  LOGOUT_USER,
  GET_LANGUAGE} from "./actions"


const initialUserState = {
  userInfo: {}
}
export const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case SAVE_LOGIN_USER:
      return {
      ...state,
      userInfo: Object.assign(state.userInfo, action.data)
    }
    case LOGOUT_USER:
      return {
      ...state,
      userInfo: action.data
    }
    default:
      return state
  }
}

const initialUserOrders = {
  userOrders: []
}

export const ordersReducer = (state = initialUserOrders, action) => {
  switch (action.type) {
    case GET_ORDERS_IN_TO_CART:
      return {
      ...state,
      userOrders: Object.assign([], action.data)
    }
    default:
      return state
  }
}


export const languageReducer = (state = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'es-ES', action) => {
  switch (action.type) {
    case  GET_LANGUAGE:
      return {
      ...state,
      language: action.data
    }
    default:
      return state
  }
}

const initialNotifications = {
  userNotifications: []
}

export const notificationsReducer = (state = initialNotifications, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
      ...state,
      userNotifications: Object.assign([], action.data)
    }
    default:
      return state
  }
}

const initialUserLocation = {
  userLocation: {}
}

export const locationReducer = (state = initialUserLocation, action) => {
  switch (action.type) {
    case SAVE_LOCATION:
      return {
      ...state,
      userLocation: Object.assign(state.userLocation, action.data)
    }
    default:
      return state
  }
}

const initialSearch = {
  search: {}
}

export const searchReducer = (state = initialSearch, action) => {
  switch (action.type) {
    case SAVE_SEARCH:
      return {
      ...state,
      search: Object.assign(state.search, action.data)
    }
    default:
      return state
  }
}

