import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./CheckoutApp.scss";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

export function CheckoutApp({match, userInfo, location}) {
  console.log('--- CheckoutApp', match, userInfo, location?.state);
  const { order } = location?.state ?? {};
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const totalAmount = order?.orderItem?.reduce((total, item) => total + (item.quantity * item.productPrice), 0)

    fetch(`${process.env.REACT_APP_API_URL}/create-payment-intent/${order.id}/${order.sellerId}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App chekout-app checkout-app-container">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm order={order}/>
        </Elements>
      )}
    </div>
  );
}