import React, {useState} from 'react'
import {Form, Button, Upload, Alert} from 'antd';
import { validateFileSize, getBase64} from '../../validation/validations';
import {UploadOutlined, ArrowRightOutlined} from '@ant-design/icons';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import alerta from '../../../utilityFunctions/alerta';
import ImgCrop from 'antd-img-crop';
import {useTranslation} from 'react-i18next';
import imageCompression from "browser-image-compression";

const FormPortraitPictureStore = (props) => {
  const { t } = useTranslation();
  const [portraitPictureError, setPortraitPictureError] = useState('')
  const [loadings, setLoadings] = useState([]);
  const [portraitPicture, setPortraitPicture] = useState(undefined);

  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }
  
  // Editar foto de portada
  const handleFormSubmit2 = () => {
    if (portraitPicture) {
        setPortraitPictureError('')
        const compressImg = async img => {
          const options = {
            maxSizeMB: 0.5,
            useWebWorker: true,
            maxWidthOrHeight: 1080,
            fileType: "image/jpg",
            initialQuality: 0.5,
            maxIteration: 15
          }
          try {
              // console.log(img)
              return imageCompression(img, options);
          } catch (error) {
              // console.log(error)
          }
        }
        enterLoading(0);
        compressImg(portraitPicture)
        .then(file=>{
            // console.log(file);
            const formData = new FormData();
            formData.append('storeId', props.userInfo.store.id);
            formData.append("portraitPicture", file);
            fetch(`${process.env.REACT_APP_API_URL}/portrait-picture/${props.userInfo.store.id}/${props.userInfo.person.userId}`, { 
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              },
              body: formData
            })
            .then(res => res.json())
            .then(res => {
              if(res.message === 'Portrait picture uploaded and updated') {
                props.dispatch({
                  type: SAVE_LOGIN_USER,
                  data: res.findUser
                })
                alerta(t('pic-updated', {a: ' '}))
                props.history.push('/dashboard/tienda')  
                const newLoadings = [...loadings]
                newLoadings[0] = false;
                setLoadings(newLoadings);
              } else {
                const newLoadings = [...loadings]
                newLoadings[0] = false;
                setLoadings(newLoadings);
                setPortraitPictureError(t('oops', {a: ' '}))
              }
            })
        })
    } else {
        setPortraitPictureError(t('heavy-photo', {a: ' '}))
    }  
  }

  const fakeRequest = ({file, onSuccess}) => {
    /*if (validateFileSize(file) === '') {
      setValidSize(true);
      setPortraitPictureError('');
    } else {
      setValidSize(false);
      setPortraitPictureError(t("photo-size-error", {x: process.env.REACT_APP_MAX_FILE_SIZE, a: ' '}))
    }
    new Promise((resolve, reject)=>{
      resolve(getBase64(file))
    })
    .then(res => {
      setPortraitPicture(res)
    })
    setTimeout(()=> {
      onSuccess('ok');
    }, 0);*/
    if (validateFileSize(file) === '') {
      setPortraitPictureError('');
      setPortraitPicture(file);
      setTimeout(()=> {
        onSuccess('ok');
      }, 0);
    } else {
      setPortraitPicture(undefined);
      setPortraitPictureError(t('heavy-photo', {a: ' '}))
    }
  };

  return (
    <Form name='user-info-form' onFinish={handleFormSubmit2} className='form-editar-foto-store center-text center-block'>
      <Form.Item name="portraitPicture">
      <ImgCrop aspect={10 / 3} modalTitle={t('fix-image', {a: ' '})} modalCancel={t('cancel', {a: ' '})} modalOk={t('confirm', {a: ' '})}>
        <Upload onRemove={()=>{setPortraitPicture(''); setPortraitPictureError('')}} customRequest={fakeRequest} listType="picture">
          {
            !portraitPicture && !portraitPictureError
            ? 
            <>
              <Button>
                <UploadOutlined /> {t("edit-portrait-pic", {a: ' '})}
              </Button>
              <p>{t("max-size", {a: ' '}) +' ' +process.env.REACT_APP_MAX_FILE} mb.</p>    
            </>
            : <p>{t("store-pic-success", {a: ' '})}</p> 
          }
        </Upload>
        </ImgCrop>
      </Form.Item>
      {portraitPictureError && <Alert message={portraitPictureError} type='error' className='error-msg' />}
      {props.userInfo.store.blocked || 
        !portraitPicture
        ? 
        <>
          <Button htmlType='submit' disabled className='btn btn-first mb-5'>
            {t('save', {a: ' '})}
            <ArrowRightOutlined />
          </Button>
          <div className="center mt-5"><Button danger className='btn round-button ' onClick={props.history.goBack}>{t('cancel', {a: ' '})}</Button></div>
        </>
        :
        <>
          <Button htmlType='submit' type='primary' className='btn btn-first mb-5' loading={loadings[0]}>
            {t('save', {a: ' '})}
            <ArrowRightOutlined />
          </Button>
          <div className="center mt-5"><Button danger className='btn round-button ' onClick={props.history.goBack}>{t('cancel', {a: ' '})}</Button></div>
        </>
      }
    </Form>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(FormPortraitPictureStore))
