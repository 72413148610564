import React, {useState, useEffect} from 'react'
import './EditSchedules.scss'
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import {Form, Select, Checkbox, Input, Space, Button, Modal, Row} from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import Logo from '../../Atoms/Logo/Logo';
import Loader from '../../Atoms/Loader/Loader';
import errorStore from '../../../utilityFunctions/noStore';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import {useTranslation} from 'react-i18next';

const EditSchedules = (props) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])
  const [scheduleId, setScheduleId] = useState()
  const [scheduleOpeningDay, setScheduleOpeningDay] = useState('')
  const [scheduleClosingDay, setScheduleClosingDay] = useState('')
  const [scheduleOpeningTime, setScheduleOpeningTime] = useState('')
  const [scheduleClosingTime, setScheduleClosingTime] = useState('')
  const [scheduleExtended, setScheduleExtended] = useState('')
  const [schedulesError, setSchedulesError] = useState(false)
  const [special, setSpecial] = useState('')
  const [currentSchedule, setCurrentSchedule] = useState({
      "openingDay": scheduleClosingDay,
      "closingDay": scheduleOpeningTime,
      "openingTime": scheduleOpeningTime,
      "closingTime": scheduleClosingTime
  })

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/schedules/${props.userInfo.store.id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.findSchedules.length < 1) {
          setSchedulesError(true)
        }else {
        
          const schedule = res.findSchedules[0]
          setScheduleId(schedule.id)
          setScheduleOpeningDay(schedule.openingDay)
          setScheduleClosingDay(schedule.closingDay)
          setScheduleOpeningTime(schedule.openingTime)
          setScheduleClosingTime(schedule.closingTime)
          setScheduleExtended(schedule.scheduleExtended)
          if(schedule.scheduleExtended !== null) {
            setSpecial(true)
          } else {
            setSpecial(false)
          }
          setCurrentSchedule({
            "openingDay": schedule.openingDay,
            "closingDay": schedule.closingDay,
            "openingTime": schedule.openingTime,
            "closingTime":schedule.closingTime,
          })
        }
    })
  }, [props.userInfo])
  
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  // const finish = () => {
  //   let secondsToGo = 5;
  //   const modal = Modal.success({
  //     title: 'Cambios guardados',
  //     content: 'Su información se actualizo con éxito',
  //   });
  //   const timer = setInterval(() => {
  //     secondsToGo -= 1;
  //   }, 1000);
  //   setTimeout(() => {
  //     clearInterval(timer);
  //     modal.destroy();
  //   }, secondsToGo * 1000);
  // }

  const handleFormSubmit = (e) => {
    enterLoading(0);

    fetch(`${process.env.REACT_APP_API_URL}/schedule/${scheduleId}`, { 
      method: 'PATCH',
      headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
      body: JSON.stringify({
        "storeId": props.userInfo.store.id,
        "userId": props.userInfo.person.userId,
        "name": "Horario",
        "openingDay": e.openingDay,
        "closingDay": e.closingDay,
        "openingTime": e.openingTime,
        "closingTime":e.closingTime,
        "scheduleExtended": !special ? null : e.specialSchedule,
        "serviceSchedule": false
      })
  })
  .then(res => res.json())
  .then(res => {
 
    if(res.message === 'process done') {
      props.dispatch({
        type: SAVE_LOGIN_USER,
        data: res.findUser
      })
      Swal.fire({
        icon: 'success',
        title: t('edited-schedule', {a: ' '}),
        // text: '',
      })
      props.history.push('/dashboard/tienda') 
    } else {
      const newLoadings = [...loadings]
      newLoadings[0] = false;
      setLoadings(newLoadings);
      Swal.fire({
        icon: 'error',
        title: t('oops', {a: ' '}),
      })
    }
  })
  }

  // const handleChangeOpeningDay = (e) => {
  //   setCurrentSchedule({
  //     ...currentSchedule,
  //     openingDay:e
  //   })
  // }
  // const handleChangeClosingDay = (e) => {
  //   setCurrentSchedule({
  //     ...currentSchedule,
  //     closingDay: e
  //   })
  // }
  // const handleChangeOpeningTime = (e) => {
  //   let dateO = e._d
  //   const openingTime = moment(dateO).format("hh:mm a")
  //   setCurrentSchedule({
  //     ...currentSchedule,
  //     openingTime: openingTime
  //   })
  // }
  // const handleChangeClosingTime = (e) => {
  //   let dateC = e._d
  //   const closingTime = moment(dateC).format("hh:mm a")
  //   setCurrentSchedule({
  //     ...currentSchedule,
  //     closingTime: closingTime
  //   })
  // }
  useEffect(()=>{
    if (props.userInfo.store.blocked) {
        errorStore()
        props.history.push('/dashboard/tienda');
    }
  }, [props.language])

  return (
    <>
    {
      schedulesError 
      ? 
      <>
      <div className="profile-container">
        <div className="wrapped-profile-container">
            <div className="box container-not-schedules">
              <h3>{t('no-current-schedule', {a: ' '})}</h3>
              {!props.userInfo.store.blocked && <>
                <p>{t('want-create-schedule', {a: ' '})}</p>
                <Link to="/dashboard/tienda/crear-horario">
                  <Button type='primary' className="btn mb-1 btn-first">
                    {t('create-schedule', {a: ' '})}
                  </Button>
                </Link>
                </>
              }
              <Button danger className="btn" onClick={props.history.goBack}>{t('cancel', {a: ' '})}</Button>
            </div>
          </div>
      </div>
      </>
      :
    <div className="template">
    
    <div className="profile-container">
      <div className="wrapped-profile-container">
      {scheduleOpeningDay !== ''  && scheduleClosingDay !== '' && scheduleOpeningTime !== '' && scheduleClosingTime !== '' && scheduleExtended !== '' && special !== '' ?
    <Form name='user-info-form' onFinish={handleFormSubmit} className='form'
        initialValues={{
            "openingDay": scheduleOpeningDay,
            "closingDay" :scheduleClosingDay,
            "openingTime": scheduleOpeningTime,
            "closingTime": scheduleClosingTime,
            "specialSchedule": scheduleExtended,
            "specialScheduleCheck": special 
          }}>
        <Logo />
        <h1 className='center-text title-form'>{t('edit-schedule-title', {a: ' '})}</h1>
      <Row className='fix-width-row'>
        <Form.Item name='openingDay' className="inline-field-form" label={t('opening-day', {a: ' '})}>
            <Select placeholder={t('opening-day', {a: ' '})} name='openingDay' size='large'>
                <Select.Option value={t('monday', {a: ' '})}>{t('monday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('tuesday', {a: ' '})}>{t('tuesday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('wednesday', {a: ' '})}>{t('wednesday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('thursday', {a: ' '})}>{t('thursday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('friday', {a: ' '})}>{t('friday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('saturday', {a: ' '})}>{t('saturday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('sunday', {a: ' '})}>{t('sunday', {a: ' '})}</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item name='closingDay' className="inline-field-form" label={t('closing-day', {a: ' '})}>
            <Select placeholder={t('closing-day', {a: ' '})} name='closingDay' size='large' >
                <Select.Option value={t('monday', {a: ' '})}>{t('monday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('tuesday', {a: ' '})}>{t('tuesday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('wednesday', {a: ' '})}>{t('wednesday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('thursday', {a: ' '})}>{t('thursday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('friday', {a: ' '})}>{t('friday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('saturday', {a: ' '})}>{t('saturday', {a: ' '})}</Select.Option>
							  <Select.Option value={t('sunday', {a: ' '})}>{t('sunday', {a: ' '})}</Select.Option>
            </Select>
        </Form.Item>
      </Row>
      <Row className='fix-width-row'>
        <Form.Item name='openingTime' className="inline-field-form" label={t('opening-time', {a: ' '})}>
        <Select placeholder={t('opening-time', {a: ' '})} size='large' className="select-input ml-time" >
                    <Select.Option value="0:00">0:00</Select.Option>
                    <Select.Option value="1:00">1:00</Select.Option>
                    <Select.Option value="2:00">2:00</Select.Option>
                    <Select.Option value="3:00">3:00</Select.Option>
                    <Select.Option value="4:00">4:00</Select.Option>
                    <Select.Option value="5:00">5:00</Select.Option>
                    <Select.Option value="6:00">6:00</Select.Option>
                    <Select.Option value="7:00">7:00</Select.Option>
                    <Select.Option value="8:00">8:00</Select.Option>
                    <Select.Option value="9:00">9:00</Select.Option>
                    <Select.Option value="10:00">10:00</Select.Option>
                    <Select.Option value="11:00">11:00</Select.Option>
                    <Select.Option value="12:00">12:00</Select.Option>
                    <Select.Option value="13:00">13:00</Select.Option>
                    <Select.Option value="14:00">14:00</Select.Option>
                    <Select.Option value="15:00">15:00</Select.Option>
                    <Select.Option value="16:00">16:00</Select.Option>
                    <Select.Option value="17:00">17:00</Select.Option>
                    <Select.Option value="18:00">18:00</Select.Option>
                    <Select.Option value="19:00">19:00</Select.Option>
                    <Select.Option value="20:00">20:00</Select.Option>
                    <Select.Option value="21:00">21:00</Select.Option>
                    <Select.Option value="22:00">22:00</Select.Option>
                    <Select.Option value="23:00">23:00</Select.Option>
                    <Select.Option value="24:00">24:00</Select.Option>
                  </Select>
        </Form.Item>
        <Form.Item name='closingTime' className="inline-field-form" label={t('closing-time', {a: ' '})}>
        <Select placeholder={t('closing-time', {a: ' '})} size='large' className="select-input ml-time" >
                    <Select.Option value="0:00">0:00</Select.Option>
                    <Select.Option value="1:00">1:00</Select.Option>
                    <Select.Option value="2:00">2:00</Select.Option>
                    <Select.Option value="3:00">3:00</Select.Option>
                    <Select.Option value="4:00">4:00</Select.Option>
                    <Select.Option value="5:00">5:00</Select.Option>
                    <Select.Option value="6:00">6:00</Select.Option>
                    <Select.Option value="7:00">7:00</Select.Option>
                    <Select.Option value="8:00">8:00</Select.Option>
                    <Select.Option value="9:00">9:00</Select.Option>
                    <Select.Option value="10:00">10:00</Select.Option>
                    <Select.Option value="11:00">11:00</Select.Option>
                    <Select.Option value="12:00">12:00</Select.Option>
                    <Select.Option value="13:00">13:00</Select.Option>
                    <Select.Option value="14:00">14:00</Select.Option>
                    <Select.Option value="15:00">15:00</Select.Option>
                    <Select.Option value="16:00">16:00</Select.Option>
                    <Select.Option value="17:00">17:00</Select.Option>
                    <Select.Option value="18:00">18:00</Select.Option>
                    <Select.Option value="19:00">19:00</Select.Option>
                    <Select.Option value="20:00">20:00</Select.Option>
                    <Select.Option value="21:00">21:00</Select.Option>
                    <Select.Option value="22:00">22:00</Select.Option>
                    <Select.Option value="23:00">23:00</Select.Option>
                    <Select.Option value="24:00">24:00</Select.Option>
                  </Select>
        </Form.Item>
        <Form.Item  defaultChecked={special} name='specialScheduleCheck' onChange={()=>setSpecial(!special)} className="specialScheduleCheck">
          <Checkbox defaultChecked={special} name='specialScheduleCheck'>{t('have-special-schedule', {a: ' '})}</Checkbox>
        </Form.Item>
        {special && 
        <Form.Item name='specialSchedule' label={t('special-schedule', {a: ' '})} className="special-schedule" hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
          <Input.TextArea maxLength={200} name='specialSchedule' placeholder={t('special-schedule-description', {a: ' '})} className='text-input'/>
        </Form.Item>}
      </Row>
          <Button
            type="primary"
            htmlType="submit"
            className="btn mb-1"
            loading={loadings[0]}
            disabled={props.userInfo.store.blocked}
          >
            {t('save', {a: ' '})}
            <ArrowRightOutlined />
          </Button>
          <Button danger onClick={props.history.goBack} className="btn mb-1">{t('cancel', {a: ' '})}</Button>
    </Form>
    : <Loader />}
    </div>
    </div>
   
    </div>
    }
    </>
    
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps)(EditSchedules))
