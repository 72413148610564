import React from 'react'
// import logoMovil from '../../../assets/logo.png'
import './Banner.scss'

const Banner = ({title}) => {
  return (
    <div className="banner-cart">
      <div className="banner-cart-content">
        <h1 className="banner-cart--title">{title}</h1>
        {/* <div className="banner-cart--logoContainer">
          <img className="banner-cart--logo" src={logoMovil} alt="logo"/> 
        </div> */}
      </div>
    </div>
  )
}

export default Banner
