import { useState, useEffect } from 'react'
import { getProducts } from '../services/getProducts'

const INITIAL_PAGE = 0

export const useProducts = ({ storeId, status, switchBloqued } = {}) => {
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [products, setProducts] = useState([]) 
  const [productsCount, setProductsCount] = useState([]) 
  const [page, setPage] = useState(INITIAL_PAGE) 
  
  useEffect(() => {
    setLoadingProducts(true)
    getProducts({page: page, storeId, status, switchBloqued}).then(data => {
      if(data) {
        setProducts(prevProducts => prevProducts.concat(data.rows)) 
        setProductsCount(data.count) 
        setLoadingProducts(false) 
      }else {
        setProducts([]) 
        setProductsCount(0) 
        setLoadingProducts(false) 
      }
    })
  }, [page, storeId, status])

  return { loadingProducts, products, productsCount, setPage }
}
