import React, {useEffect} from 'react';
import './PageNotFound.scss';
import {withRouter} from 'react-router-dom';
import {Button} from 'antd';
import img404 from '../../../assets/P1-error-404.png';
import {useTranslation} from 'react-i18next';

const PageNotFound = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    // console.log('404 not found')
    const container = document.querySelector('.father-container-menu-vertical');
    // console.log(container)
    if(container) {
      if(window.innerWidth > 829) {
        container.style.display = 'none';
        // console.log('Se oculto')
        // console.log(window.innerWidth)
        // console.log(container);
        document.body.scrollIntoView({block:'start'})
        return () => {
          container.style.display = 'block';
          // console.log('Todo normal')
        }
      }
    }
  }, [])

  return (
    <div className="main-notFound">
      <div className="main-notFound-img mb-15">
        <img src={img404} alt="Página no encontrada" />
      </div>
        <Button type='primary' className='btn-first' onClick={props.history.goBack}>
        {t("back", {a: ' '})}
        </Button>
      
    </div>
  )
}

export default withRouter(PageNotFound);
