import React, {useEffect} from 'react'
import NewProductForm from '../NewProductForm/NewProductForm';
import { connect } from 'react-redux';
import {Redirect} from 'react-router-dom';

const NewProductContainer = (props) => {
  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
  }, [])
  return (
    <>
      {props.userInfo.store && props.userInfo.store.location.length === 0 ? 
        <Redirect to='/registro/vender/ubicar-tienda' />
        :
        <div className="profile-container">
          <div className="wrapped-profile-container">
          <div className="new-product-form box">
            <NewProductForm />
          </div>
          </div>
        </div>
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})

export default connect(mapStateToProps)(NewProductContainer)
