export const getProducts = async ({ limit = 24, page = 0, storeId, status, switchBloqued} = {}) => {
  const url =`${process.env.REACT_APP_API_URL}/products/${storeId}/${status}/${switchBloqued}/${limit}/${page * limit}`
  try {
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if(response.status === 200) {
      const data = await response.json()
      return data.products
    } else {
      return null
    }
  } catch (error) {
    return error
  }
}
