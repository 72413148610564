import React, {useState, useEffect} from 'react';
import Logo from '../../Atoms/Logo/Logo';
import {Button, Alert} from 'antd';
import { connect } from 'react-redux';
import './VerifyEmailAgain.css'
import logoEmailVerification from '../../../assets/P1-correo-veririficacion-imagen.png';
import {useTranslation} from 'react-i18next';

function VerifyEmailAgain(props) {
    const { t } = useTranslation();
    const [verifyError, setVerifyError] = useState('')
    const [verifySuccess, setVerifySuccess] = useState('')
    const [loadings, setLoadings] = useState([])

    useEffect(() => {
      document.body.scrollIntoView({block:'start'})
    }, [])
    const enterLoading = index => {
        const newLoadings = [...loadings];
        newLoadings[index] = true;
        setLoadings(newLoadings);
    };
    const handleVerifyClick = () => {
        setVerifyError('');
        enterLoading(0);
        fetch(`${process.env.REACT_APP_API_URL}/reconfirm-account`, { 
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "email": props.userInfo.email,
                "id": props.userInfo.id
            })
        })
        .then(res=>res.json())
        .then(res=>{
            // console.log(res);
            const newLoadings = [...loadings];
            newLoadings[0] = false;
            setLoadings(newLoadings);
            if (res.message === 'reconfirm account email sent') {
                setVerifySuccess(t("email-sent", {a: ' '}))
            } else {
                setVerifyError(t('oops', {a: ' '}))
            }
        })
        .catch(err=> {
            const newLoadings = [...loadings];
            newLoadings[0] = false;
            setLoadings(newLoadings);
            setVerifyError(t('oops', {a: ' '}))
        })
    }
    return (
        <> 
            {
                props.userInfo.verified ? 
                <div className='box verify-again'>
                    <Logo />
                    <h1 className='center-text blue-text'>{t("email-verified", {a: ' '})}</h1>
                </div>: 
                <>
                    <div className='box verify-again'>
                        <Logo />
                        <h1 className='center-text blue-text'>{t("important-verify", {a: ' '})}</h1>
                        <div className='container-logo-verify'><img src={logoEmailVerification} alt="Verificar Identidad"/></div>
                        <p className='center-text'>{t("get-verification-email", {a: ' '})}:</p>
                        <Button type='primary' onClick={handleVerifyClick} className='btn-first center-block round-btn mb-10' loading={loadings[0]}>{t("email-verify", {a: ' '})}</Button>
                        {verifyError && <Alert message={verifyError} type='error' className='center-text'/>}
                        {verifySuccess && <Alert message={verifySuccess} type='success' className='center-text'/>}
                    </div>
                </>
            }  
        </>
    )
}

const mapStateToProps = (state) => ({
    userInfo: state.userReducer.userInfo
})

export default connect(mapStateToProps)(VerifyEmailAgain);
