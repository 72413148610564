import React, {useEffect} from 'react'
import moment from 'moment';
import 'moment/locale/es';

const NotificationHoursES = ({hours}) => { 
  moment.locale('es');
  useEffect(() => {
    // console.log('hours ES', hours);
  }, [])

  return (
    <p className="msg-notification-time">{moment(hours).startOf('second').fromNow()}</p>
  )
}

export default NotificationHoursES;
