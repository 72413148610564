import React, {useEffect} from 'react'
import { connect } from 'react-redux';
import './EditarPerfil.scss'
import EditProfileComsumer from '../EditProfileComsumer/EditProfileComsumer';

// import MenuProfile from '../../Organisms/MenuProfile/MenuProfile';

const EditarPerfil = (props) => {
  useEffect(()=>{
    document.body.scrollIntoView({block:'start'})
  }, [])
  
  return (
    <EditProfileComsumer userInfo={props.userInfo}/>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(EditarPerfil)
