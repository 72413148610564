import React, {useState, useEffect} from 'react'
import {Form, Input, Button, Alert } from 'antd';
import {ArrowRightOutlined, MailOutlined} from '@ant-design/icons';
//import {validateEmail} from '../../validation/validations';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import './EditarEmail.scss';
import alerta from '../../../utilityFunctions/alerta';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import {useTranslation} from 'react-i18next';

const EditarEmail = (props) => {
  const { t } = useTranslation();
  //const [emailError, setEmailError] = useState('');
  const [emailChangeError, setEmailChangeError] = useState('');
  const [loadings, setLoadings] = useState([]);

    const enterLoading = index => {
        const newLoadings = [...loadings];
        newLoadings[index] = true;
        setLoadings(newLoadings);
    };

  const handleEmailSubmit = (ev) => {
    //setEmailError('');
    setEmailChangeError('');
    //const validEmail=validateEmail(ev.email);
    //if (validEmail === '') {
        if (ev.email !== props.userInfo.email) {
            enterLoading(0);
            fetch(`${process.env.REACT_APP_API_URL}/update-email/${props.userInfo.id}`, { 
                method: 'PATCH',
                headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
                body: JSON.stringify({ 
                    "newEmail": ev.email.trim().toLowerCase()
                })
            })
            .then(res => res.json())
            .then(res=> {
                const newLoadings = [...loadings];
                newLoadings[0] = false;
                setLoadings(newLoadings);
                if (res.message==="user email updated") {
                    props.dispatch({
                      type: SAVE_LOGIN_USER,
                      data: res.findUser
                    })
                    props.history.push('/verificar-email')
                    alerta(t("edited-email", {a: ' '}))
                } else if (res.message === "Email already exist") {
                    setEmailChangeError(t("reg-email-exist", {a: ' '}))
                }
                else {
                    setEmailChangeError(t('oops', {a: ' '}))
                }
            })
        } else {
            setEmailChangeError(t("email-validation", {a: ' '}))
        }  
    /*} else {
        setEmailError(validEmail);
    } */   
  }
  useEffect(()=>{
    document.body.scrollIntoView({block:'start'})
    if (props.userInfo.googleRegister) {
      Swal.fire({
        icon: 'error',
        title: t("invalid-action", {a: ' '}),
        text: t("google-validation", {a: ' '}),
      })
      props.history.push('/dashboard/perfil')
    }
  })
  return (
    <>
    <div className="profile-container">
      <div className="wrapped-profile-container">
      <div className="box form-edit-email-container">
    <Form name='email-form' onFinish={handleEmailSubmit} className='form-edit-email'>
        <h2 className='center-text title-form'>{t("edit-email", {a: ' '})}</h2>
        <Form.Item name='email' hasFeedback label={t("new-email", {a: ' '})} rules={[{required: true, whitespace: true, message: t('required', {a: ' '})}, {type: 'email', message: t("invalid-email", {a: ' '})}]}>
            <Input placeholder={t("new-email", {a: ' '})} prefix={<MailOutlined />} className='text-input' />
        </Form.Item>
        {/*emailError && <Alert message={emailError} type='error' className='error-msg' />*/}
        <Form.Item name='submit-btn'>
            <Button type='primary' disabled={props.userInfo.googleRegister} htmlType='submit' className='btn btn-first' loading={loadings[0]}>
                {t('save', {a: ' '})}
                <ArrowRightOutlined/>
            </Button>
        </Form.Item>
        {emailChangeError && <Alert message={emailChangeError} type='error' className='error-msg' />}
      </Form>
      </div>
      </div>
    </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(EditarEmail)
