import React, {useState, useEffect} from 'react';
import '../styles/styles.scss';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Welcome from './Pages/Welcome/Welcome';
import Login from './Pages/Login/Login';
import RecoverPassword from './Pages/RecoverPassword/RecoverPassword';
import NewPassword from './Pages/NewPassword/NewPassword';
// import NewProductForm from './Pages/NewProductForm/NewProductForm';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import EditarPerfil from './Pages/EditarPerfil/EditarPerfil';
import EditarLogin from './Pages/EditarLogin/EditarLogin';
import ConditionsTerms from './Pages/ConditionsTerms/ConditionsTerms';
import EditSchedules from './Pages/EditSchedules/EditSchedules';
import VerifyEmail from './Pages/VerifyEmail/VerifyEmail';
import VerifyEmailAgain from './Pages/VerifyEmailAgain/VerifyEmailAgain';
import Home from './Pages/Home/Home';
import Favorites from './Pages/Favorites/Favorites';
import Dashboad from './Pages/Dashboard/Dashborard';
import SelectStoreMap from './Pages/Mapa/SelectStoreMap';
import EditStoreMap from './Pages/Mapa/EditStoreMap';
import ViewStoreMap from './Pages/Mapa/ViewStoreMap';
import ViewAllStoresMap from './Pages/Mapa/ViewAllStoresMap';
import Cart from './Pages/Cart/Cart';
import SellerStore from './Pages/SellerStore/SellerStore';
// import ProductDescription from './Pages/ProductDescription/ProductDescription';
import ProductDescriptionII from './Pages/ProductDescription/ProductDescriptionII';
import Register from './Pages/Register/Register';
import EmailVerification from './Pages/EmailVerification/EmailVerification';
import SellerPersonForm from './Pages/SellerPersonForm/SellerPersonForm';
import EditStore from './Pages/EditStore/EditStore';
import ConfirmOrder from './Pages/ConfirmOrder/ConfirmOrder';
// import Orders from './Pages/Orders/Orders';
import PageHeader  from './Organisms/PageHeader/PageHeader';
import PageFooter  from './Organisms/PageFooter/PageFooter';
import ProfileStore from './Pages/ProfileStore/ProfileStore';
// import MySales from './Pages/MySales/MySales';
// import MyProducts from './Pages/MyProducts/MyProducts';
import EditProduct from './Pages/EditProduct/EditProduct';
import PricingTable from './Pages/PricingTable/PricingTable';
import NewProductContainer from './Pages/NewProductContainer/NewProductContainer';
import MenuVertical from './Organisms/MenuVertical/MenuVertical';
import FormProfilePicturePerson from './Organisms/FormProfilePicturePerson/FormProfilePicturePerson';
import EditarEmail from './Pages/EditarEmail/EditarEmail';
import AddressForm from './Pages/AddressForm/AddressForm';
import MyOrdersActives from './Pages/MyOrdersActives/MyOrdersActives';
import MyOrdersHistory from './Pages/MyOrdersHistory/MyOrdersHistory';
import EditPhotosStore from './Pages/EditPhotosStore/EditPhotosStore';
import MySalesActives from './Pages/MySalesActives/MySalesActives';
import MySalesHistory from './Pages/MySalesHistory/MySalesHistory';
import MySalesCanceled from './Pages/MySalesCanceled/MySalesCanceled';
// import NewProductForm from './Pages/NewProductForm/NewProductForm';
import MyProductsActives from './Pages/MyProductsActives/MyProductsActives';
import MyProductsInactives from './Pages/MyProductsInactives/MyProductsInactives';
import InConstruction from './Atoms/InConstruction/InConstruction';
import CreateAddress from './Pages/CreateAddress/CreateAddress';
import OrderDetail from './Pages/OrderDetail/OrderDetail';
import { connect } from 'react-redux';
import { SAVE_LOGIN_USER } from '../redux/actions';
import Loader from './Atoms/Loader/Loader';
import ViewOrderDetail from './Pages/ViewOrderDetail/ViewOrderDetail';
import ViewOrderDetailStore from './Pages/ViewOrderDetail/ViewOrderDetailStore';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeConsumerForm from './Pages/Stripe/StripeConsumerForm';
import StripeAccount from './Pages/Stripe/StripeAccount';
import { CheckoutApp } from './Pages/CheckoutApp/CheckoutApp';
import StoreReputation from './Pages/StoreReputation/StoreReputation';
import SearchProducts from './Pages/SearchProducts/SearchProducts';
import OrderDescription from './Pages/OrderDescription/OrderDescription';
import OrderDescriptionPurchase from './Pages/OrderDescription/OrderDescriptionPurchase';
// import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import CloseAccount from './Pages/CloseAccount/CloseAccount';
import CloseStore from './Pages/CloseAccount/CloseStore';
import ContactForm from './Pages/ContactForm/ContactForm';
import CreateSchedules from './Pages/CreateSchedules/CreateSchedules';
import ReportPasswordChange from './Pages/ReportPasswordChange/ReportPasswordChange';
import { CheckoutConfirmation } from './Pages/CheckoutApp/CheckoutConfirmation';
// import FAQ from './Pages/FAQ/FAQ';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const App = (props) => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  //eslint-disable-next-line
  const [reload, setReload] = useState(true);
  
  useEffect(()=>{
    if (
      props.location.pathname !== '/' &&
      props.location.pathname !== '/registro' &&
      props.location.pathname !== '/login' &&
      props.location.pathname !== '/recuperar-contraseña' &&
      // props.location.pathname !== '/terminos-y-condiciones' &&
      // props.location.pathname !== '/faq' &&
      !/\/password\/\S+/.test(props.location.pathname) &&
      !/\/verify\/\S+/.test(props.location.pathname) 
    ) {
      if (!props.userInfo.id) {
        if (localStorage.getItem('token') && localStorage.getItem('remember') === 'true') {
          fetch(`${process.env.REACT_APP_API_URL}/check-login-token`, {
            headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
          })
          .then(res=>res.json())
          .then(res=>{
            if (res.findUser) {
              props.dispatch({
                type: SAVE_LOGIN_USER,
                data: res.findUser
              });
              if (!res.findUser.verified) {
                props.history.push('/verificar-email')
              };
              setReload(false);
            } else {
              setRedirectToLogin(true);
            }
          })
        } else {
          setRedirectToLogin(true);
        }
      }
    }
    //eslint-disable-next-line
  }, [])
  return (
      <Elements stripe={stripePromise}>
        <div className="app">
          {props.userInfo.id && <><PageHeader />
          <Route path='/dashboard/' component={MenuVertical} /></>}
          <Switch>
            <Route path='/' exact component={Welcome} />
            <Route path='/registro' exact component={Register} />
            <Route path='/login' exact component={Login} />
            <Route path='/recuperar-contraseña' exact component={RecoverPassword} />
            <Route path='/terminos-y-condiciones' exact component={ConditionsTerms} />
            {/* <Route path='/faq' exact component={FAQ} /> */}
            <Route path='/verify/:token' exact component={VerifyEmail} />
            <Route path='/password/:token' exact component={NewPassword} />
            <Route path='/reportar/:token' exact component={ReportPasswordChange}/>
            {redirectToLogin && !props.userInfo.id && <Redirect to='/login'/>}
            {props.userInfo.id ?
              <Switch>
                <Route path='/dashboard/adquirir-membresia/:planId' exact component={StripeConsumerForm} />
                <Route path='/dashboard/membresía/exito' exact component={StripeAccount}/>
                <Route path='/registro/verificar-email' exact component={EmailVerification} />
                {/* <Route path='/dashboard/perfil/politica-de-privacidad' exact component={PrivacyPolicy} /> */}
                <Route path='/dashboard/perfil/contacta' exact component={ContactForm} />
                <Route path='/verificar-email' exact component={VerifyEmailAgain} />
                <Route path='/dashboard/pedido/:id' exact component={OrderDetail} />
                <Route path='/dashboard/perfil' exact component={Dashboad} />
                <Route path='/dashboard/perfil/apariencia' exact component={FormProfilePicturePerson} />
                <Route path='/dashboard/perfil/cambiar-contraseña' exact component={EditarLogin} />
                <Route path='/dashboard/perfil/cambiar-correo' exact component={EditarEmail} />
                <Route path='/dashboard/perfil/direccion-de-envio' exact component={AddressForm} />
                <Route path='/dashboard/perfil/compras-activas' exact component={MyOrdersActives} />
                <Route path='/dashboard/perfil/compras-activas/ord-:id' exact component={OrderDescriptionPurchase} />
                <Route path='/dashboard/perfil/historial-de-compras' exact component={MyOrdersHistory} />
                <Route path='/dashboard/perfil/favoritos' exact component={Favorites} />
                <Route path='/dashboard/perfil/editar-perfil' exact component={EditarPerfil} />
                <Route path='/dashboard/perfil/detalle/ord-:id' exact component={ViewOrderDetail} />
                <Route path='/dashboard/tienda' exact component={ProfileStore} />
                <Route path='/dashboard/tienda/nuevo-producto' exact component={NewProductContainer} />
                <Route path='/dashboard/tienda/apariencia' exact component={EditPhotosStore} />
                <Route path='/dashboard/tienda/crear-direccion' exact component={CreateAddress} />
                <Route path='/dashboard/tienda/ventas-activas' exact component={MySalesActives} />
                <Route path='/dashboard/tienda/ventas-activas/ord-:id' exact component={OrderDescription} />
                <Route path='/dashboard/tienda/historial-de-ventas' exact component={MySalesHistory} />
                <Route path='/dashboard/tienda/ordenes-canceladas' exact component={MySalesCanceled} />
                <Route path='/dashboard/tienda/mis-calificaciones' exact component={StoreReputation} />
                <Route path='/dashboard/tienda/crear-producto' exact component={NewProductContainer} />
                <Route path='/dashboard/tienda/productos-activos' exact component={MyProductsActives} />
                <Route path='/dashboard/tienda/productos-inactivos' exact component={MyProductsInactives} />
                <Route path='/dashboard/tienda/editar-tienda' exact component={EditStore} />
                <Route path='/dashboard/tienda/editar-horario' exact component={EditSchedules} />
                <Route path='/dashboard/tienda/crear-horario' exact component={CreateSchedules} />
                <Route path='/dashboard/tienda/editar-producto/:idProduct' exact component={EditProduct} />
                <Route path='/dashboard/tienda/detalle/ord-:id' exact component={ViewOrderDetailStore} />
                <Route path='/dashboard/tienda/crear-tienda' exact component={SellerPersonForm} />
                <Route path='/dashboard/construccion' exact component={InConstruction} />
                <Route path={['/tiendas/lista/categorias/:categories/radio/:radius', '/tiendas/lista/categorias/:categories/radio/:radius/lat/:lat/lng/:lng']} exact component={Home} />
                {/* <Route path='/vendedor/:sellerId/:sellerSlug' exact component={SellerStore} /> */}
                <Route path='/vendedor/:sellerId' exact component={SellerStore} />
                <Route path='/producto/:productId/:productSlug/:mine' exact component={ProductDescriptionII} />
                <Route path='/productos/busqueda/:query' exact component={SearchProducts} />
                <Route path='/dashboard/planes' exact component={PricingTable} />
                <Route path='/carrito' exact component={Cart} />
                <Route path='/confirmar-orden/:orderId' exact component={ConfirmOrder} />
                <Route path='/pago-orden' exact component={CheckoutApp} />
                <Route path='/pago-orden/confirmation' exact component={CheckoutConfirmation} />
                <Route path='/editar-tienda/mapa' exact component={EditStoreMap} />
                <Route path={['/tiendas/mapa/categorias/:categories/radio/:radius','/tiendas/mapa/categorias/:categories/radio/:radius/lat/:lat/lng/:lng', '/tiendas/mapa']} exact component={ViewAllStoresMap} />
                <Route path='/tienda/mapa/:userId' exact component={ViewStoreMap} />
                <Route path='/registro/vender/ubicar-tienda' exact component={SelectStoreMap} />
                <Route path='/dashboard/tienda/darse-baja' exact component={CloseStore} />
                <Route path='/dashboard/perfil/darse-baja' exact component={CloseAccount} />
                <Route component={PageNotFound} />
              </Switch>
              : <Loader />
            }
          </Switch> 
          {props.userInfo.id && <PageFooter />}
        </div>
      </Elements>  
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  userLocation: state.locationReducer.userLocation
})

export default connect(mapStateToProps)(withRouter(App))

