import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import {Button} from 'antd';
import { connect } from 'react-redux';
import './Mapa.scss';
import Swal from 'sweetalert2';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import {AimOutlined} from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content'
import {useTranslation} from 'react-i18next';

const SelectStoreMap = props => {
    const { t } = useTranslation();
    const { center = {lat: 41.2369789, lng: 1.8027169}, zoom = 17} = props;
    //eslint-disable-next-line
    const [markerArr, setMarkerArr] = useState([]);
    const [location, setLocation] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false)
    const [currentMap, setCurrentMap] = useState('');
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    const submitAddress = () => {
      setButtonLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/create-location/${props.userInfo.id}`, { 
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
        body: JSON.stringify(location)
      })
      .then(res => res.json())
      .then(res => {
        console.timeLog(res)
        setButtonLoading(false);
        if (res.message === 'location created') {
          swalWithBootstrapButtons.fire({
            icon: 'success',
            title: t("store-created-title", {a: ' '}),
            text: t("store-created-msg", {a: ' '}),
            showCancelButton :true,
            showConfirmButton :true,
            confirmButtonText: t("create-new-product", {a: ' '}),
            cancelButtonText: t("or-edit", {a: ' '}),
            // html:  
            // <div>
            //   <p>Su tienda ha sido creada con éxito. Si desea, puede agregar información adicional acerca de la ubicación de su tienda en su perfil.</p>
            //   <button onClick={() =>  goToCreateProduct} className="btn-second center-block btn-drawer mt-10 mb-10">Crea un producto nuevo</button>
            //   <p onClick={() =>} className="create-store-link"> O edita tu tienda</p>
            // </div>
            // html:  <button onClick={() => props.history.push('/dashboard/tienda/crear-producto')} className="btn-second center-block btn-drawer mt-10 mb-10">Crea un producto nuevo</button>,
            // html: <p onClick={() => props.history.push('/dashboard/tienda/editar-tienda')}> O edita tu tienda</p>,    
          })
          .then( async (result) => {

            if (result.isConfirmed) {
              props.history.push('/dashboard/tienda/crear-producto'); 
            } else {
              props.history.push('/dashboard/tienda/editar-tienda')
            } //Fin de else
          });
          props.dispatch({
            type: SAVE_LOGIN_USER,
            data: res.findUser
          });
          props.history.push('/dashboard/tienda');
        } else {
            //error
        }
      })
      .catch(err => {
        setButtonLoading(false);

      })
    }


    const deleteMarker = () => {
        setMarkerArr(prevState => {
            if (prevState.length > 0) {
                prevState[0].setMap(null);
            }
            return []
        });
        setLocation([]);
    }


    const handleApiLoaded = (map, maps) => {
      //const infoWindow = new maps.InfoWindow();
      map.addListener('click', e => {
        const clickedLocation = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        };
        deleteMarker();
        const marker = new maps.Marker({
          position: clickedLocation,
          map: map,
          title: t('delete-location', {a: ' '})
        });
        marker.addListener('click', e => {
          deleteMarker();
        })
        setMarkerArr([marker])
        const geocoder = new maps.Geocoder();
        geocoder.geocode({'location': clickedLocation}, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              const infoWindow = new maps.InfoWindow({
                content: results[0].formatted_address
              });
              infoWindow.open(map, marker);
              setLocation([{latitude: clickedLocation.lat, longitude: clickedLocation.lng, address: results[0]?.formatted_address, storeId: props.userInfo.store.id}])
            } else {
              window.alert(t("map-geoloc-error", {a: ' '}))
            }
          } else {
   
          }
        })
      })
      if (navigator.geolocation) {
        let pos;
        navigator.geolocation.getCurrentPosition(position=>{
          pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          map.setCenter(pos);
          map.setZoom(17);
          /*infoWindow.setPosition(pos);
          infoWindow.setContent('Ubicación detectada.');
          infoWindow.open(map);*/ 
        }, () => {
          window.alert(t("map-geoloc-error", {a: ' '}))
        })
      } else {
        window.alert(t("map-no-geoloc", {a: ' '}))
      }
      setCurrentMap(map);
    }
    const showModal = () => {
      Swal.fire({
        icon: 'info',
        title: t("important-info-title", {a: ' '}),
        text:  t("important-info-msg", {a: ' '})
      })
    }
    const centerMap = () => {
      if (navigator.geolocation) {
          let pos;
          navigator.geolocation.getCurrentPosition(position=>{
          pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
          };
          currentMap.setCenter(pos);
          }, () => {
        
              currentMap.setCenter(center);
              //window.alert('No fue posible detectar su localización.')
          })
      } else {
          window.alert(t("map-no-geoloc", {a: ' '}))
      }
    }

    useEffect(showModal, [])

    return (
      <div className='map-container'>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_API_KEY}`}}
          defaultCenter={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
        />
        {location.length>0 && 
          <div className='map-buttons-container'>
            <Button loading={buttonLoading} onClick={submitAddress} type='primary' className='round-button btn-first'>{t('save-location', {a: ' '})}</Button>
            <Button onClick={deleteMarker} danger className='round-button background-red'>{t('delete-location', {a: ' '})}</Button>
          </div>
        }
        {currentMap &&
        <div className="map-main-footer--item map-center-button" onClick={centerMap}>
            <AimOutlined twoToneColor="#161B4B"/>
        </div>}
      </div>
    );
}
const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})

 export default withRouter(connect(mapStateToProps)(SelectStoreMap));
