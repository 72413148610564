import { 
  SAVE_LOGIN_USER, 
  GET_ORDERS_IN_TO_CART, 
  GET_LANGUAGE,
  GET_NOTIFICATIONS,
  SAVE_LOCATION, SAVE_SEARCH, LOGOUT_USER
} from "./actions";

export const saveLoginUser = (userLogin) => ({
  type: SAVE_LOGIN_USER,
  data: {userLogin}
})

export const logoutUser = (data) => ({
  type: LOGOUT_USER,
  data: data
})

export const getLanguage  = (language) => dispatch => {
  language = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'es-ES'
  return dispatch({
    type: GET_LANGUAGE,
    data: language 
  })
}

export const getOrders = userId => dispatch => {
  fetch(`${process.env.REACT_APP_API_URL}/orders-by-buyer/${userId}`, {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(resp => resp.json())
  .then(resp => {
    // console.log(resp)
    let arrays = []
    if(resp.message === 'Orders by buyer') {
      resp.findOrders.forEach(o=> {
        if(o.status === 'En carrito') {
          arrays.push(o)
        }
      })
      return dispatch({
        type: GET_ORDERS_IN_TO_CART,
        data: arrays
      })
    } else {
      return {
        
      }
    }  
  })
}

export const getNotifications  = userId => dispatch => {
  if(localStorage.getItem('token')) {
    //  console.log('El userId:', userId);
    //  console.log('localStorage.getItem(token):', localStorage.getItem('token'));
    fetch(`${process.env.REACT_APP_API_URL}/notifications/${userId}/10/0`, {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(resp => resp.json())
    .then(resp => {
      let arrays = []
      // console.log(resp)
      if(resp.message === 'notifications by user') {
        resp.findNotifications.rows.forEach(n=> {
          if(n.read === false) {
            arrays.push(n)
          }
        })
        return dispatch({
          type: GET_NOTIFICATIONS,
          data: arrays
        })
      }else {
        return
      }
    })
  }
}
export const saveUserLocation = (userLocation) => ({
  type: SAVE_LOCATION,
  data: {userLocation}
})
export const saveSearch = (search) => ({
  type: SAVE_SEARCH,
  data: {search}
})
