import React, {useState, useEffect} from 'react'
import './StoreForm.scss'
import {Form, Input, Button, Select, Alert, InputNumber} from 'antd';
import { ArrowRightOutlined} from '@ant-design/icons';
import Logo from '../../Atoms/Logo/Logo'
// import MenuProfile from '../../Organisms/MenuProfile/MenuProfile';
// import MenuVertical from '../../Organisms/MenuVertical/MenuVertical';
import { connect } from 'react-redux';
//import {validateNotEmpty} from '../../validation/validations';
import {withRouter} from 'react-router-dom';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import Loader from '../../Atoms/Loader/Loader';
import Swal from 'sweetalert2';
// import alerta from '../../../utilityFunctions/alerta';
import {useTranslation} from 'react-i18next';
import errorStore from '../../../utilityFunctions/noStore';

const EditStore = (props) => {
  const [loadings, setLoadings] = useState([])
  const { t } = useTranslation();
  const [getMainPayment, setGetMainPayment] = useState([])
  const [paymentId, setPaymentId] = useState([])
  const [getMainCategories, setGetMainCategories] = useState([])
  const [categoryId, setCategoryId] = useState([])
  const [reload, setReload] = useState(false)
  const [loaderHandleDeSelectCategory, setLoaderHandleDeSelectCategory] = useState(false);
  const [showMinimumDelivery, setShowMinimumDelivery] = useState(props.userInfo.store.deliveryTypeId[0] === 1 ? true : false)
  const [categoriasAMostrar, setCategoriasAMostrar] = useState(props.userInfo.store.mainCategoryId)
  const [currentUserData, setCurrentUserData] = useState({
    storeName: props.userInfo.store.storeName,
    description:props.userInfo.store.description,
    mainPhoneNumber: props.userInfo.store.mainPhoneNumber.slice(3),
    secondaryPhoneNumber:  props.userInfo.store.secondaryPhoneNumber ? props.userInfo.store.secondaryPhoneNumber.slice(3) : props.userInfo.store.secondaryPhoneNumber,
    minimumDelivery: props.userInfo.store.minimumDelivery,
    paymentMethodId: props.userInfo.store.paymentMethodId,
    deliveryTypeId:props.userInfo.store.deliveryTypeId[0],
    mainCategoryId: props.userInfo.store.mainCategoryId,
  })
  const [errors, setErrors] = useState({
    /*storeNameError: '',
    descriptionError: '',
    phoneNumberError: '',
    paymentMethodIdError: '',
    deliveryTypeIdError: '',
    mainCategoryIdError: '',*/
    formError: ''
  })

  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
    setReload(false)
    if(props.userInfo.userRoleId === 2){
      const getData = async () => {
        fetch(`${process.env.REACT_APP_API_URL}/payment-methods/${props.language === 'CA' || props.language === 'ca-ES' ? 0 : 1}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res=>res.json())
        .then(res => {
          let allPayments = [] // Para guardar las metodos de pago 
          let currentPaymentsId = [] // Para guardar los id de los metodos de pagoe
          res.getPaymentMethods.forEach(pay => {
            props.userInfo.store.paymentMethodId.forEach(catCurrent => {
              if(pay.id === catCurrent) {
                currentPaymentsId.push(pay.id) // Aqui obtengo los id de los metodos de pago actuales de usuario
              }
            })
            allPayments.push( // Aqui obtengo todos los metodos de pago
              <Select.Option value={pay.id} key={pay.name}>
                {pay.name}
              </Select.Option>
            )
          })
          setGetMainPayment(allPayments)
          setPaymentId(currentPaymentsId)
        })
      

        fetch(`${process.env.REACT_APP_API_URL}/main-categories/${props.language === 'CA' || props.language === 'ca-ES' ? 0 : 1}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res=>res.json())
        .then(res => {
          let allCategories = [] // Para guardar las categorias 
          let currentCategoriesId = [] // Para guardar los id de las categorias 
          res.getMainCategories.forEach(cat => {
            props.userInfo.store.mainCategoryId.forEach(catCurrent => {
              if(cat.id === catCurrent) {
                currentCategoriesId.push(cat.id) // Aqui obtengo los id de las categorias actuales de usuario
              }
            })
            allCategories.push( // Aqui obtengo todos las categorias
              <Select.Option value={cat.id} key={cat.name}>
                {cat.name}
              </Select.Option>
            )
          })
          setGetMainCategories(allCategories)
          setCategoryId(currentCategoriesId)
        })
      }
      getData()
    }
  }, [props.userInfo, reload, props.language]);

  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }
    // Alerta
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })


  // Datos de tienda
  const handleChange = (e) => {
    setCurrentUserData({
      ...currentUserData,
      [e.target.name] : e.target.value
    })
  }

  // Delivery
  const handleChangeDeliveryType = (e) => {
    if(e === 1) {
      setShowMinimumDelivery(true)
    }else {
      setShowMinimumDelivery(false)
    }
    setCurrentUserData({
      ...currentUserData,
      deliveryTypeId: e
    })
  }

  // Metodos de pago 
  const handleChangePaymentMethodId = (e) => {
    setCurrentUserData({
      ...currentUserData,
      paymentMethodId: e
    })
  }

   //Delivery Minimo
  const handleChangeMinimumDelivery = (e) => {
    setCurrentUserData({
      ...currentUserData,
      minimumDelivery: e
    })
  }

  // Categorias
  const handleChangeCategory = (e) => {

  }

  const handleSelectCategory = (e) => {
    setCategoriasAMostrar([...categoriasAMostrar, e])
    setCurrentUserData({
      ...currentUserData,
      mainCategoryId: [...categoriasAMostrar, e]
    })
    // removeDuplicate(categoriasAMostrar)
  }


  const goToBack = () => {
    if(currentUserData.mainCategoryId.length < 1) {
      Swal.fire({
        icon: 'error',
        title: t("error", {a: ' '}),
        text: t("add-category", {a: ' '})
      })
    }else {
      props.history.goBack()
    }
  }
  const handleDeSelectCategory = (e) => {
    setReload(true);
    // console.log(props.userInfo.store.mainCategoryId)
    // console.log('currentUserData.mainCategoryId', props.userInfo.store.mainCategoryId.length);
    if(props.userInfo.store.mainCategoryId.length <= 1) {
      Swal.fire(t("message-delete-category", {a: ' '}), {
        icon: "info",
      });
      return;
    } else {
      categoriasAMostrar.forEach(categoryIdItem => {
        if(e === categoryIdItem) {
          Swal.fire({
            title: t("delete-cat-warning", {a: ' '}),
            text: t("delete-cat-warning-2", {a: ' '}),
            icon: "warning",
            showCancelButton :true,
            confirmButtonText: t("confirm-delete", {a: ' '}),
            cancelButtonText: t("keep", {a: ' '})
          })
          .then( async (result) => {
          
            if (result.isConfirmed) {
              setLoaderHandleDeSelectCategory(true);
              setCategoriasAMostrar(categoriasAMostrar.filter(el => el !== e))
              setCurrentUserData({
                ...currentUserData,
                mainCategoryId: categoriasAMostrar.filter(el => el !== e)
              })
              let url = `${process.env.REACT_APP_API_URL}/delete-products-by-main-category/${e}/${props.userInfo.store.id}`;
              const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
              })
              
              const responseII = await fetch(`${process.env.REACT_APP_API_URL}/find-user/${props.userInfo.person.userId}`, {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              })
              const datII = await responseII.json()
            
              if(response.status === 200) {
                props.dispatch({
                  type: SAVE_LOGIN_USER,
                  data: datII.findUser
                });
                setLoaderHandleDeSelectCategory(false);
                Swal.fire(t("deleted-category", {a: ' '}), {
                  icon: "success",
                });
              }
            } else {
              setLoaderHandleDeSelectCategory(false);
            }
          });
        }
      })
    }
  }


  // Envio en form
  const handleFormSubmit = (e) => {
    setErrors({
      
      formError: ''
    })

    /*const validStoreName = validateNotEmpty(currentUserData.storeName);
    const validDescription = validateNotEmpty(currentUserData.description);
    const validContact = validateNotEmpty(currentUserData.mainPhoneNumber);
    const validPaymentMethodId =validateNotEmpty(currentUserData.paymentMethodId);
    const validDeliveryTypeId=validateNotEmpty(currentUserData.deliveryTypeId);
    const validMainCategoryId=validateNotEmpty(currentUserData.mainCategoryId);
    if (validStoreName === '' && validDescription === '' && validPaymentMethodId === '' && validDeliveryTypeId === '' && validMainCategoryId === '' && validContact === '') {*/
   
      enterLoading(0);
      fetch(`${process.env.REACT_APP_API_URL}/store/${props.userInfo.store.id}`, { 
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
        body: JSON.stringify({
            "userId": props.userInfo.person.userId, 
            "storeName": currentUserData.storeName,
            "mainPhoneNumber": '+34'+currentUserData.mainPhoneNumber,
            "secondaryPhoneNumber": currentUserData.secondaryPhoneNumber === undefined || currentUserData.secondaryPhoneNumber === null || currentUserData.secondaryPhoneNumber === '' ? undefined : '+34'+currentUserData.secondaryPhoneNumber,
            "minimumDelivery": currentUserData.minimumDelivery,
            "description": currentUserData.description,
            "paymentMethodId": currentUserData.paymentMethodId,
            "deliveryTypeId": [currentUserData.deliveryTypeId],
            "mainCategoryId": currentUserData.mainCategoryId,
            esp: localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
        })
    })
    .then(res => res.json())
    .then(res => {
 
      if(res.message === 'Store updated') {
        props.dispatch({
          type: SAVE_LOGIN_USER,
          data: res.findUser
        })
        Toast.fire({
            icon: 'success',
            title: t("edited-store", {a: ' '})
        })
        props.history.push('/dashboard/tienda') 
      } else {
          const newLoadings = [...loadings]
          newLoadings[0] = false;
          setLoadings(newLoadings);
          setErrors(prevState => ({
            ...prevState,
            formError: t('oops', {a: ' '})
          }))
      }
    })
    .catch(err => {
      const newLoadings = [...loadings]
      newLoadings[0] = false;
      setLoadings(newLoadings)
      setErrors(prevState => ({
        ...prevState,
        formError: t('oops', {a: ' '})
      }))
    })
  }

  // const [buttonLoading, setButtonLoading] = useState(false);
  // const [formError, setFormError] = useState('');
  // const submitAddress = ev => {
  //   setButtonLoading(true);
  //   fetch(`${process.env.REACT_APP_API_URL}/location/${props.userInfo.store.location[0].id}/${props.userInfo.id}`, { 
  //       method: 'PATCH',
  //       headers: {
           
  //       body: JSON.stringify({
  //         additionalInformation: ev.additionalInformation,
  //         address: ev.geoAddress
  //       })  
  //     })
  //     .then(res => res.json())
  //     .then(res => {
  //       console.log(res);
  //         setButtonLoading(false);
  //         if (res.message === 'store location updated') {
  //           props.dispatch({
  //               type: SAVE_LOGIN_USER,
  //               data: res.findUser
  //           });
  //           alerta('Dirección editada exitosamente')
  //           props.history.push('/dashboard/tienda')
  //         } else {
  //             setFormError('Oops. Algo salió mal.')
  //         }
  //     })
  //     .catch(err => {
  //         setButtonLoading(false);
  //         setFormError('Oops. Algo salió mal. Intente nuevamente')
  //     })
  // }
  useEffect(()=>{
    if (props.userInfo.store.blocked) {
        errorStore()
        props.history.push('/dashboard/tienda');
    }
}, [])
  return (
    <>
    <div className="profile-container">
    <div className="wrapped-profile-container">
    {/* <MenuVertical/> */}
    {paymentId.length > 0 && categoryId.length > 0 ?// Cuando me carguen las categorias y metodos de pago lo muestro
          <Form 
            name='user-info-form' 
            onFinish={handleFormSubmit} 
            className='form form-editStore'
            initialValues={{
              "storeName": props.userInfo.store.storeName,
              "description": props.userInfo.store.description,
              "mainPhoneNumber":currentUserData.mainPhoneNumber,
              "secondaryPhoneNumber": currentUserData.secondaryPhoneNumber,
              // "category": product.description,
              "deliveryTypeId" : props.userInfo.store.deliveryTypeId[0] ===1 ? 'Sí':'No',
              "minimumDelivery": props.userInfo.store.minimumDelivery,
              "paymentMethodId": props.userInfo.store.paymentMethodId,
            }}
          >
          <Logo/>
          <h1 className='center-text title-form'>{t("edit-store", {a: ' '})}</h1>
          <Form.Item name='storeName' label={t("store-name", {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
              <Input name="storeName" placeholder={t("store-name", {a: ' '})} onChange={handleChange.bind(this)} className='text-input' /*defaultValue={props.userInfo.store.storeName}*/ />
          </Form.Item>
          {/*errors.storeNameError && <Alert message={errors.storeNameError} type='error' className='error-msg' />*/}
          <Form.Item name='description' label={t('description', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
              <Input.TextArea placeholder={t('description', {a: ' '})} name="description" className="text-input" onChange={handleChange.bind(this)} /*defaultValue={props.userInfo.store.description}*/ />
          </Form.Item>
          {/*errors.descriptionError && <Alert message={errors.descriptionError} type='error'  className='error-msg' />*/}
          <Form.Item name="mainPhoneNumber" label={t("first-phone", {a: ' '})} hasFeedback rules={[{validator:(_, value)=> value?value.length === 9 ? !isNaN(value)?Promise.resolve():Promise.reject(t("numeric", {a: ' '})):Promise.reject(t("phone-valid", {a: ' '})):Promise.reject(t("required", {a: ' '}))},{required: true, message: ' '}]}>
            <Input addonBefore="+34" maxLength={9} placeholder={t("first-phone", {a: ' '})} name="mainPhoneNumber" className="text-input" onChange={handleChange.bind(this)} /*defaultValue={props.userInfo.store.mainPhoneNumber}*/ />
          </Form.Item>
          {/*errors.contactError && <Alert message={errors.contactError} type='error' className='error-msg' />*/}
          <Form.Item name="secondaryPhoneNumber" label={t("second-phone", {a: ' '})} hasFeedback rules={[{validator:(_, value)=> value?value.length === 9 ? !isNaN(value)?Promise.resolve():Promise.reject(t("numeric", {a: ' '})):Promise.reject(t("phone-valid", {a: ' '})):Promise.resolve()},{required: false, message: ' '}]}>
            <Input addonBefore="+34" maxLength={9} placeholder={t("second-phone", {a: ' '})} name="secondaryPhoneNumber" className="text-input" onChange={handleChange.bind(this)} /*defaultValue={props.userInfo.store.secondaryPhoneNumber}*/ />
          </Form.Item>
          <Form.Item name='paymentMethodId' label={t("payment-method", {a: ' '})} hasFeedback rules={[{required: true, message: t('required', {a: ' '})}]}>
              <Select placeholder={t("many-methods", {a: ' '})} mode='multiple' size='large' className="select-input" onChange={handleChangePaymentMethodId.bind(this)}  /*defaultValue={paymentId}*/ >  
                {getMainPayment}
              </Select>
          </Form.Item>
          {/*errors.paymentMethodIdError && <Alert message={errors.paymentMethodIdError} type='error' className='error-msg' />*/}
          
          {/* <Form.Item name='category' label="Categorias" hasFeedback rules={[{required: true, message: 'Este campo es obligatorio'}]}> */}
          <div className="form-item-category">
            <label htmlFor="Categorias">{t("store-categories", {a: ' '})}: </label>
            <Select placeholder={t("many-sectors", {a: ' '})} mode='multiple' size='large' 
              onSelect={handleSelectCategory.bind(this)} 
              onDeselect={handleDeSelectCategory.bind(this)} 
              onChange={handleChangeCategory.bind(this)} 
              value={categoriasAMostrar}
              disabled={loaderHandleDeSelectCategory ? true : false}
              // defaultValue={categoriasAMostrar} 
              className="select-input s-95">

                {getMainCategories}
            </Select>
          </div>
          {/* </Form.Item> */}
          {/*errors.mainCategoryIdError && <Alert message={errors.mainCategoryIdError} type='error' className='error-msg' />*/}

          <Form.Item name="deliveryTypeId" label={t("delivery", {a: ' '})+'?'} hasFeedback rules={[{required: true, message: t('required', {a: ' '})}]}>
            <Select placeholder={t("delivery", {a: ' '})+'?'} className="select-input" onChange={handleChangeDeliveryType.bind(this)} /*defaultValue={props.userInfo.store.deliveryTypeId[0] ===1 ? 'Sí':'No'}*/ >
              <Select.Option name="deliveryTypeId" value={1}>{t('yes', {a: ' '})}</Select.Option>
              <Select.Option name="deliveryTypeId" value={2}>{t('no', {a: ' '})}</Select.Option>
            </Select>
          </Form.Item>
          {/*errors.deliveryTypeIdError && <Alert message={errors.deliveryTypeIdError} type='error' className='error-msg' />*/}
          {showMinimumDelivery &&
          <Form.Item name='minimumDelivery' hasFeedback label={t("minimum-delivery", {a: ' '})} rules={[{validator: (_,value)=>value?typeof value === 'string'?Promise.reject(t('numeric', {a: ' '})):value<0?Promise.reject(t("minimum-price", {a: ' '})):Promise.resolve():Promise.resolve()}]}>
            <InputNumber placeholder={t("minimum-delivery", {a: ' '})} formatter={value=>value !== '' ? `${value} €`:''} name='minimumDelivery' className='text-input' onChange={handleChangeMinimumDelivery.bind(this)} /* defaultValue={props.userInfo.store.minimumDelivery}*/ />
          </Form.Item>}
          <Form.Item>
              {!props.userInfo.store.blocked && <Button
                type="primary"
                htmlType="submit"
                className="btn mb-5 btn-first btn-block"
                loading={loadings[0]}
              >
               { t('save', {a: ' '})}
                <ArrowRightOutlined />
              </Button>}
                <Button danger onClick={goToBack} className="btn mb-5 btn-block">{t('cancel', {a: ' '})}</Button>
                {!props.userInfo.store.blocked && <Button danger onClick={()=>props.history.push('/dashboard/tienda/darse-baja')} className="btn btn-block">{t("deactivate-store", {a: ' '})}</Button>}
          </Form.Item>
          {errors.formError && <Alert message={errors.formError} type='error' className='error-msg' />}
        </Form> 
      : <Loader /> }
    </div>
    </div>
  </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps)(EditStore))
