import React from 'react'
import './EmptyCart.scss'

const EmptyCart = ({message}) => {
  return (
    <div className="main-emptyCart">
      <h2>{message}</h2>
    </div>
  )
}

export default EmptyCart
