import React, {useState, useEffect} from 'react'
import { Input, Form, Button,Rate } from 'antd';
import Swal from 'sweetalert2';
// import { withRouter } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import './QualifiedSeller.scss'

const QualifiedSeller = (props) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])
  const [valueRate, setValueRate] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    props.setGoOrder(false)
  }, [props])

  // Loader del boton 
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  let response = []
  const objetoOrder = {
    status: "Entregado",
    deliveryConfirmed : false,
    personId: props.personId,
    storeId: props.storeId,
  }
  const [objetoRate, setObjetoRate] = useState({
    userId: props.userId,
    toUserId: null,
    storeId: props.storeId,
    givenReputation: 0,
    comment: ''
  })


  // Actualizo las estrellas y el comentario
  const sendRate = (e) => {
    if(e === 0) {
      setValueRate('')
      setObjetoRate({
        ...objetoRate,
        givenReputation: e,
        comment: ''
      })
    }
    else if(e === 1) {
      setValueRate('Malo')
      setError(false)
      setObjetoRate({
        ...objetoRate,
        givenReputation: e,
        comment: 'Malo'
      }) 
    }
    else if(e === 2) {
      setValueRate(localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? 'Podrien millorar': 'Podrían mejorar' )
      setError(false)
      setObjetoRate({
        ...objetoRate,
        givenReputation: e,
        comment: 'Podrían mejorar'
      }) 
    }
    else if(e === 3) {
      setValueRate(localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? 'Correcte': 'Correcto' )
      setError(false)
      setObjetoRate({
        ...objetoRate,
        givenReputation: e,
        comment: 'Correcto'
      }) 
    }
    else if(e === 4) {
      setValueRate(localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? 'Bé': 'Bueno' )
      setError(false)
      setObjetoRate({
        ...objetoRate,
        givenReputation: e,
        comment: 'Bueno'
      })
    }
    else if(e === 5) {
      setValueRate(localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? 'Molt bo': 'Muy bueno' )
      setError(false)
      setObjetoRate({
        ...objetoRate,
        givenReputation: e,
        comment: 'Muy bueno'
      })
    }
  }

  response.push(objetoOrder)
  response.push(objetoRate)
  

  // Calificar 
  const handleQuialifed = (e) => {
    if(objetoRate.givenReputation < 1) {
      setError(true);
      return;
    } else {
      setError(false)
      enterLoading(0)
      fetch(`${process.env.REACT_APP_API_URL}/confirm-order-delivered/${props.orderId}`, {
        method: 'PATCH',
        headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
        body: JSON.stringify({
          response
        })
      })
      .then(resp => resp.json())
      .then(resp=> {
        // console.log(resp)
        if(resp.message === 'order updated') {
          props.setReload(true)
          props.setGoOrder(true)
          Swal.fire({
            icon: 'success',
            title: t("thanks", {a: ' '}),
            text: t("come-again-soon", {a: ' '}),
          })
        }else {
          enterLoading(1)
          Swal.fire({
            icon: 'error',
            title: t('oops', {a: ' '}),
            text: '',
          })
        }
      })
    }
  }
  

  return (
    <>
    <div className="quialifed-container">
        <Rate onChange={(e) => sendRate(e)} autoFocus />
        {
          error &&
          <p className="error-quialifed">{t('qualify-valid', {a: ' '})}</p>
        }
        <Form name='new-product-withDrawelTime' onFinish={(e) => handleQuialifed(e)} >
          {/* <Form.Item name='comment'  hasFeedback rules={[{whitespace: true, required: true, message: 'Este campo es obligatorio'}]}> */}
          <Input value={valueRate} className="text-input input-quialifed"  placeholder={t('rate-store', {a: ' '})} />
          {/* </Form.Item> */}
        <Button className="round-btn btn-first btn-quialifed-modal" loading={loadings[0]} htmlType='submit' type="primary">{t("rate", {a: ' '})}</Button>
      </Form>
    </div>
    </>
  )
}

export default QualifiedSeller


