import React from 'react';
import './SelllerPersonForm.scss'
import {Form, Input, Button, Select, Alert, Upload, /*TimePicker*/ Modal, InputNumber, Row, Checkbox} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import Logo from '../../Atoms/Logo/Logo';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import { connect } from 'react-redux';
import {/*validateNotEmpty, */validateFileSize, getBase64} from '../../validation/validations';
import {withRouter, Link} from 'react-router-dom';
// import moment from 'moment';
import PricingTableModal from '../PricingTable/PricingTableModal';
import ImgCrop from 'antd-img-crop';
import { withTranslation } from 'react-i18next';
class SellerPersonForm extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            paymentMethods: [],
            allCategory: [],
            /*fNameError: '',
            contactError: '',
            descriptionError: '',
            paymentMethodIdError: '',
            deliveryTypeIdError: '',
            //juridicalIdError: '',
            mainCategoryIdError: '',*/
            formError: '',
            profilePictureError: '',
            profilePicture: undefined,
            validProfilePicture: true,
            portraitPictureError: '',
            portraitPicture: undefined,
            validPortraitPicture: true,
            uploadedPhoto: false,
            showminimumDelivery: false,
            loadings: [],
            modalVisible: false,
            openingTime: '',
            closingTime: '',
            special: false
        }
        this.handleFormSubmit=this.handleFormSubmit.bind(this);
        this.handleChangeUpload=this.handleChangeUpload.bind(this);
        this.handleFetchs=this.handleFetchs.bind(this);
        this.changeDeliveryTypeId=this.changeDeliveryTypeId.bind(this);
        this.handleChangeTime=this.handleChangeTime.bind(this);
        this.formRef= React.createRef();
        this.fakeRequest=this.fakeRequest.bind(this);
        this.fakeRequest2=this.fakeRequest2.bind(this);
    }
    
    enterLoading = index => {
        const newLoadings = [...this.state.loadings];
        newLoadings[index] = true;
        this.setState({
          loadings: newLoadings,
        });
    };

    // mostrar el minimumDelivery dinamicamente
    changeDeliveryTypeId(e) {
      if(e === 1) {
        this.setState({
          showminimumDelivery: true,
        })
      }else {
        this.setState({
          showminimumDelivery: false,
        });
      }  
    }

    handleChangeUpload(info) {
      if (info.fileList.length > 0) {
        this.setState({ 
          uploadedPhoto: true 
        });
    } else {
        this.setState({ 
          uploadedPhoto: false,
          profilePictureError: '',
        });
      }
    }
    async handleFormSubmit(ev){
        const { t } = this.props;
        this.setState({      
            /*fNameError: '',
            contactError: '',
            descriptionError: '',
            paymentMethodIdError: '',
            deliveryTypeIdError: '',
            //juridicalIdError: '',
            mainCategoryIdError: '',*/
            formError: ''
        });

        // console.log(ev)
        // let dateO = ''
        // let dateC = ''
        let openingDay = null
        let closingDay = null
        let openingTime = null
        let closingTime = null


        if (ev.openingDay !== undefined && ev.closingDay !== undefined && ev.openingTime !== undefined && ev.closingTime !== undefined) {
          // dateO = ev.openingTime._d
          // dateC = ev.closingTime._d
          openingDay = ev.openingDay
          closingDay = ev.closingDay
          openingTime = ev.openingTime
          closingTime = ev.closingTime
          // openingTime = moment(dateO).format("HH:mm")
          // closingTime = moment(dateC).format("HH:mm")
        }

        /*const validFirstName = validateNotEmpty(ev.fName);
        const validContact = validateNotEmpty(ev.mainPhoneNumber);
        const validDescription =validateNotEmpty(ev.description);
        //const validJuridicalId=validateNotEmpty(ev.juridicalId);
        const validDeliveryTypeId=validateNotEmpty(ev.deliveryTypeId);
        const validPaymentMethodId=validateNotEmpty(ev.paymentMethodId);
        const validCategoryId=validateNotEmpty(ev.mainCategoryId);*/
        // Creando arreglos y objetos
        let response = []
        let objStore = {
          "id": this.props.userInfo.id, 
          "userId": this.props.userInfo.id, 
          "blockedByAdmin": false,
          "storeName": ev.fName,
          "blocked" : false,
          "mainPhoneNumber": '+34'+ev.mainPhoneNumber,
          "secondaryPhoneNumber" : ev.secondaryPhoneNumber === undefined || ev.secondaryPhoneNumber === null || ev.secondaryPhoneNumber === '' ? undefined : '+34'+ev.secondaryPhoneNumber,
          "minimumDelivery": ev.minimumDelivery,
          "description": ev.description,
          "paymentMethodId": ev.paymentMethodId,
          "deliveryTypeId": [ev.deliveryTypeId],
          //"juridicalId": ev.juridicalId,
          "mainCategoryId": ev.mainCategoryId,
          "totalReputation": 0,
          esp: localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
          // "blocked": false
        }
        let objSchedule = {
            "storeId": null,
            "name": "Horario",
            "openingDay": openingDay,
            "closingDay": closingDay,
            "openingTime": openingTime,
            "closingTime": closingTime,
            "serviceSchedule": false,
            "scheduleExtended": ev.scheduleExtended
        }

        let objImageProfile = {
          "userId": null,
          "storeId": null,
          "file": this.state.profilePicture
        };
        
        let objImagePortrait = {
          "storeId": null,
          "file": this.state.portraitPicture 
        }

        response.push(objStore)
        response.push(objSchedule)
        response.push(objImageProfile)
        response.push(objImagePortrait)
        // console.log(response)

          if (this.state.validProfilePicture && this.state.validPortraitPicture ) {
            this.enterLoading(0);
            // console.log(response)
            fetch(`${process.env.REACT_APP_API_URL}/store`, { 
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              },
              body: JSON.stringify(response)
              })
              .then(res => res.json())
              .then(res => {
                // console.log(res)
                if(res.message === 'Store created') {
                  const getFindUser = async () => {
                    const responFindUser = await fetch(`${process.env.REACT_APP_API_URL}/find-user/${this.props.userInfo.id}`, {
                      headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                      }
                    })
                    const data = await responFindUser.json()
                    if(data.message === 'user found') {
                      this.props.dispatch({
                        type: SAVE_LOGIN_USER,
                        data: data.findUser
                      })
                      await this.props.history.push('/registro/vender/ubicar-tienda')
                    }
                  }
                  getFindUser()
                }else {
                  const newLoadings = [...this.state.loadings];
                  newLoadings[0] = false;
                  this.setState({
                      formError: t('oops', {a: ' '}),
                      loadings: newLoadings
                  })
                }
              })
              .catch(err => {
                  const newLoadings = [...this.state.loadings];
                  newLoadings[0] = false;
                  this.setState({
                    formError: t('oops', {a: ' '}),
                    loadings: newLoadings
                  })
                }) 
          } else {
            this.setState({
              formError: t('photo-problem', {a: ' '}),
            })
          }
    }


    handleFetchs() {
      const { t } = this.props;
      document.body.scrollIntoView({block:'start'})
        fetch(`${process.env.REACT_APP_API_URL}/payment-methods/${this.props.language === 'CA' || this.props.language === 'ca-ES' ? 0 : 1}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res=>res.json())
        .then(res=>{
          console.log('res',res)
            let paymentMethods=[];
            for (let i=0;i<res.getPaymentMethods.length;i++) {
                paymentMethods.push(
                    <Select.Option value={res.getPaymentMethods[i].id} key={res.getPaymentMethods[i].name}>
                        {res.getPaymentMethods[i].name}
                    </Select.Option>
                )
            }
            this.setState({
                paymentMethods: paymentMethods
            })
        })
        .catch(err=>this.setState({
            formError: t('oops', {a: ' '})
        }))

        console.log(this.props.language)
        fetch(`${process.env.REACT_APP_API_URL}/main-categories/${this.props.language === 'CA' || this.props.language === 'ca-ES' ? 0 : 1}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(resp => resp.json())
        .then(resp => {
          let allCategory = [];
          resp.getMainCategories.map( cat => (
            allCategory.push(	
              <Select.Option value={cat.id} key={cat.id}>
                {cat.name}
              </Select.Option>)
            )
          );
          this.setState({
            allCategory
          })
        })  
    }

    componentDidMount() { 
      this.handleFetchs();
    }

    handleChangeTime(e, timeId) {
      if (timeId === 1) {
        this.formRef.current.setFieldsValue({
          openingTime: e
        })
      } else {
        this.formRef.current.setFieldsValue({
          closingTime: e
        })
      }
    }
    fakeRequest ({file, onSuccess}) {
      const { t } = this.props;
      if (validateFileSize(file) === '') {
        this.setState({
          validPortraitPicture: true,
          portraitPictureError: ''
        })
        new Promise((resolve, reject)=>{
          resolve(getBase64(file))
        })
        .then(res => {
          this.setState({
            portraitPicture: res
          })
        })
        setTimeout(()=> {
          onSuccess('ok');
        }, 0);
        
      } else {
        this.setState({
          validPortraitPicture: false,
          portraitPictureError: t('portrait-error', {a: ' '})
        })
      }
      
    };
    fakeRequest2 ({file, onSuccess}) {
      const { t } = this.props;
      if (validateFileSize(file) === '') {
        this.setState({
          validProfilePicture: true,
          profilePictureError: ''
        })
        new Promise((resolve, reject)=>{
          resolve(getBase64(file))
        })
        .then(res => {
          this.setState({
            profilePicture: res
          })
        })
        setTimeout(()=> {
          onSuccess('ok');
        }, 0);
        
      } else {
        this.setState({
          validProfilePicture: false,
          profilePictureError: t('profile-error', {a: ' '})
        })
      }
    };
    checkSize(file) {
        if (file.size / 1048576 > Number(process.env.REACT_APP_MAX_FILE)) {
            return false;
        } else {
          return true
        }
    }
    
    componentDidUpdate(prevProps) {
      if(prevProps.language !== this.props.language) this.handleFetchs();
    }

    render() {
      const { t } = this.props;
        return (
            <>
            <div className="profile-container">
              <div className="wrapped-profile-container">
                <Form name='user-info-form' onFinish={this.handleFormSubmit} className='form' ref={this.formRef}>
                    <div className="container-info-store">
                      {/* <h4 className="info-store-title">{t("store-question", {a: ' '})}</h4>
                      <h4 className="info-store-subtitle">{t("store-trial", {a: ' '})}</h4> */}
                      {/* <Link to='/tiendas/lista/categorias/1-2-3-4-5-6/radio/30'><p className="form-info info-store-link">{t("store-back", {a: ' '})}</p></Link> */}
                    </div>
                    <Logo />
                    <h1 className='center-text title-form'>{t('create-store', {a: ' '})}</h1>
                    <Form.Item name='fName' label={t("store-name", {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
                        <Input placeholder={t("store-name", {a: ' '})} className='text-input' />
                    </Form.Item>
                    {''/*this.state.fNameError && <Alert message={this.state.fNameError} type='error' className='error-msg' />*/}
                    <Form.Item name='mainPhoneNumber' label={t("first-phone", {a: ' '})} hasFeedback rules={[{validator:(_, value)=> value?value.length === 9 ? !isNaN(value)?Promise.resolve():Promise.reject(t('numeric', {a: ' '})):Promise.reject(t('phone-valid', {a: ' '})):Promise.reject(t('required', {a: ' '}))},{required: true, message: ' '}]}>
                        <Input addonBefore="+34" maxLength={9} placeholder={t("first-phone", {a: ' '})} className='text-input' size='large'/>
                    </Form.Item>
                    {''/*this.state.contactError && <Alert message={this.state.contactError} type='error' className='error-msg' />*/}
                    <Form.Item name='secondaryPhoneNumber' label={t("second-phone", {a: ' '})} rules={[{validator:(_, value)=> value?value.length === 9 ? !isNaN(value)?Promise.resolve():Promise.reject(t('numeric', {a: ' '})):Promise.reject(t('phone-valid')):Promise.resolve()},{required: false, message: ' '}]}>
                        <Input addonBefore="+34" maxLength={9} placeholder={t("second-phone", {a: ' '})} className='text-input' size='large'/>
                    </Form.Item>
                    <Form.Item name='description' label={t('description', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
                        <Input.TextArea maxLength={200} placeholder={t('description', {a: ' '})} className='text-input' />
                    </Form.Item>
                    {''/*this.state.descriptionError && <Alert message={this.state.descriptionError} type='error' className='error-msg' />*/}
                    <Form.Item name='paymentMethodId'  label={t("payment-method", {a: ' '})} hasFeedback rules={[{required: true, message: t('required', {a: ' '})}]}>
                        <Select placeholder={t("many-methods", {a: ' '})} mode='multiple' size='large' className="select-input">  
                            {this.state.paymentMethods}
                        </Select>
                    </Form.Item>
                    {''/*this.state.paymentMethodIdError && <Alert message={this.state.paymentMethodIdError} type='error' className='error-msg' />*/}
                    <Form.Item name='mainCategoryId' label={t("store-categories", {a: ' '})} hasFeedback rules={[{required: true, message: t('required', {a: ' '})}]}>
                        <Select placeholder={t("many-sectors", {a: ' '})} mode='multiple' size='large' className="select-input">
                            {this.state.allCategory}
                        </Select>
                    </Form.Item>
                    {''/*this.state.mainCategoryIdError && <Alert message={this.state.mainCategoryIdError} type='error' className='error-msg' />*/}
                    <Form.Item name="profilePicture">
                      <ImgCrop aspect={1 / 1} modalTitle={t('fix-image', {a: ' '})} modalCancel={t('cancel', {a: ' '})} modalOk={t('confirm', {a: ' '})}>
                        <Upload onRemove={()=>this.setState({profilePicture: undefined, profilePictureError: '', validProfilePicture: true})} customRequest={this.fakeRequest2} listType="picture">
                          {
                            !this.state.profilePicture && !this.state.profilePictureError
                            ? 
                            <>
                              <Button>
                                <UploadOutlined /> {t("store-profile-pic", {a: ' '})}
                              </Button>
                              <p>{t("store-profile-pic-msg", {a: ' '}) + ' '+process.env.REACT_APP_MAX_FILE}mb</p> 
                            </>
                            : <p>{t("store-pic-success", {a: ' '})}</p> 
                          }
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                    {this.state.profilePictureError && <Alert message={this.state.profilePictureError} type='error' className='error-msg' />}
                    <Form.Item name="portraitPicture">
                      <ImgCrop aspect={10 / 3} modalTitle={t('fix-image', {a: ' '})} modalCancel={t('cancel', {a: ' '})} modalOk={t('confirm', {a: ' '})}>
                        <Upload onRemove={()=>this.setState({portraitPicture: undefined, portraitPictureError: '', validPortraitPicture: true})} customRequest={this.fakeRequest} listType="picture">
                          {
                            !this.state.portraitPicture && !this.state.portraitPictureError
                            ? 
                            <>
                              <Button>
                                <UploadOutlined /> {t("store-portrait-pic", {a: ' '})}
                              </Button>
                              <p>{t("store-profile-pic-msg", {a: ' '}) + ' '+process.env.REACT_APP_MAX_FILE}mb</p>  
                            </>
                            : <p>{t("store-pic-success", {a: ' '})}</p> 
                          }
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                    {this.state.portraitPictureError && <Alert message={this.state.portraitPictureError} type='error' className='error-msg' />}
                    <Form.Item name='deliveryTypeId' label={t("delivery", {a: ' '})+'?'} hasFeedback rules={[{required: true, message: t('required', {a: ' '})}]}>
                        <Select placeholder={t("delivery", {a: ' '})+'?'} size='large' onChange={e => this.changeDeliveryTypeId(e)} className="select-input">
                            <Select.Option value={1}>{t('yes', {a: ' '})}</Select.Option>
                            <Select.Option value={2}>{t('no', {a: ' '})}</Select.Option>
                        </Select>
                    </Form.Item>
                    {''/*this.state.deliveryTypeIdError && <Alert message={this.state.deliveryTypeIdError} type='error' className='error-msg' />*/}
                    {this.state.showminimumDelivery &&
                    <Form.Item name='minimumDelivery' hasFeedback label={t("minimum-delivery", {a: ' '})} rules={[{validator: (_,value)=>value?typeof value === 'string'?Promise.reject(t('numeric', {a: ' '})):value<0?Promise.reject(t('minimum-price', {a: ' '})):Promise.resolve():Promise.resolve()}]}>
                        <InputNumber formatter={value=>value !== '' ? `${value} €`:''} placeholder={t("minimum-delivery", {a: ' '})} className='text-input' />
                    </Form.Item>}
              <Row className='fix-width-row'>
                <Form.Item name='openingDay' className="inline-field-form" label={t('opening-day', {a: ' '})}>
                  <Select placeholder={t('opening-day', {a: ' '})} size='large' className="select-input mr-time">
                      <Select.Option value={t('monday', {a: ' '})}>{t('monday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('tuesday', {a: ' '})}>{t('tuesday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('wednesday', {a: ' '})}>{t('wednesday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('thursday', {a: ' '})}>{t('thursday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('friday', {a: ' '})}>{t('friday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('saturday', {a: ' '})}>{t('saturday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('sunday', {a: ' '})}>{t('sunday', {a: ' '})}</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name='closingDay' className="inline-field-form" label={t('closing-day', {a: ' '})}>
                    <Select placeholder={t('closing-day', {a: ' '})} size='large' className="select-input ml-time">
                      <Select.Option value={t('monday', {a: ' '})}>{t('monday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('tuesday', {a: ' '})}>{t('tuesday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('wednesday', {a: ' '})}>{t('wednesday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('thursday', {a: ' '})}>{t('thursday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('friday', {a: ' '})}>{t('friday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('saturday', {a: ' '})}>{t('saturday', {a: ' '})}</Select.Option>
                      <Select.Option value={t('sunday', {a: ' '})}>{t('sunday', {a: ' '})}</Select.Option>
                    </Select>
                </Form.Item>
              </Row>
					<Row className='fix-width-row'>
              <Form.Item name='openingTime' className="inline-field-form" label={t('opening-time', {a: ' '})}>
                  <Select placeholder={t('opening-time', {a: ' '})} size='large' className="select-input ml-time">
                    <Select.Option value="0:00">0:00</Select.Option>
                    <Select.Option value="1:00">1:00</Select.Option>
                    <Select.Option value="2:00">2:00</Select.Option>
                    <Select.Option value="3:00">3:00</Select.Option>
                    <Select.Option value="4:00">4:00</Select.Option>
                    <Select.Option value="5:00">5:00</Select.Option>
                    <Select.Option value="6:00">6:00</Select.Option>
                    <Select.Option value="7:00">7:00</Select.Option>
                    <Select.Option value="8:00">8:00</Select.Option>
                    <Select.Option value="9:00">9:00</Select.Option>
                    <Select.Option value="10:00">10:00</Select.Option>
                    <Select.Option value="11:00">11:00</Select.Option>
                    <Select.Option value="12:00">12:00</Select.Option>
                    <Select.Option value="13:00">13:00</Select.Option>
                    <Select.Option value="14:00">14:00</Select.Option>
                    <Select.Option value="15:00">15:00</Select.Option>
                    <Select.Option value="16:00">16:00</Select.Option>
                    <Select.Option value="17:00">17:00</Select.Option>
                    <Select.Option value="18:00">18:00</Select.Option>
                    <Select.Option value="19:00">19:00</Select.Option>
                    <Select.Option value="20:00">20:00</Select.Option>
                    <Select.Option value="21:00">21:00</Select.Option>
                    <Select.Option value="22:00">22:00</Select.Option>
                    <Select.Option value="23:00">23:00</Select.Option>
                    <Select.Option value="24:00">24:00</Select.Option>
                  </Select>
              </Form.Item>
            <Form.Item name='closingTime' className="inline-field-form" label={t('closing-time', {a: ' '})}>
              <Select placeholder={t('closing-time', {a: ' '})} size='large' className="select-input ml-time">
                    <Select.Option value="0:00">0:00</Select.Option>
                    <Select.Option value="1:00">1:00</Select.Option>
                    <Select.Option value="2:00">2:00</Select.Option>
                    <Select.Option value="3:00">3:00</Select.Option>
                    <Select.Option value="4:00">4:00</Select.Option>
                    <Select.Option value="5:00">5:00</Select.Option>
                    <Select.Option value="6:00">6:00</Select.Option>
                    <Select.Option value="7:00">7:00</Select.Option>
                    <Select.Option value="8:00">8:00</Select.Option>
                    <Select.Option value="9:00">9:00</Select.Option>
                    <Select.Option value="10:00">10:00</Select.Option>
                    <Select.Option value="11:00">11:00</Select.Option>
                    <Select.Option value="12:00">12:00</Select.Option>
                    <Select.Option value="13:00">13:00</Select.Option>
                    <Select.Option value="14:00">14:00</Select.Option>
                    <Select.Option value="15:00">15:00</Select.Option>
                    <Select.Option value="16:00">16:00</Select.Option>
                    <Select.Option value="17:00">17:00</Select.Option>
                    <Select.Option value="18:00">18:00</Select.Option>
                    <Select.Option value="19:00">19:00</Select.Option>
                    <Select.Option value="20:00">20:00</Select.Option>
                    <Select.Option value="21:00">21:00</Select.Option>
                    <Select.Option value="22:00">22:00</Select.Option>
                    <Select.Option value="23:00">23:00</Select.Option>
                    <Select.Option value="24:00">24:00</Select.Option>
                  </Select>
              </Form.Item>

              <Form.Item valuePropName='checked' name='special-schedule-check' className="specialScheduleCheck" onChange={()=>this.setState({special: !this.state.special})}>
                  <Checkbox>{t('have-special-schedule', {a: ' '})}</Checkbox>
              </Form.Item>
              {this.state.special && <Form.Item name='scheduleExtended' className="special-schedule" label={t('special-schedule', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: 'Este campo es obligatorio'}]}>
                  <Input.TextArea maxLength={200} placeholder={t('special-schedule-description', {a: ' '})} className='text-input'/>
              </Form.Item>}
					  </Row>
                    {/*<Form.Item name='juridicalId'>
                        <Input placeholder='Identificador jurídico' className='text-input' />
                      </Form.Item>
                    {this.state.juridicalIdError && <Alert message={this.state.juridicalIdError} type='error' className='error-msg' />}*/}
                    <Form.Item>  
                        <Button htmlType='submit' type='primary' className='btn btn-first mb-5' loading={this.state.loadings[0]}>
                        {t('create-store', {a: ' '})}
                            {/*<ArrowRightOutlined />*/}
                        </Button>
                        <Button type='secondary' className='btn btn-back-store' onClick={this.props.history.goBack}>
                          {/*<LeftOutlined />*/}
                          {t('cancel', {a: ' '})}
                        </Button>
                    </Form.Item>
                    {this.state.formError && <Alert message={this.state.formError} type='error' className='error-msg' />}
                    {/* <div className="footer-info-store">
                      <p className="footer-store-title">
                        {t("store-reminder", {a: ' '})}
                      </p>
                      <p className='blue-text link' onClick={()=>this.setState({modalVisible: true})}>{t("know-plans", {a: ' '})}</p>
                    </div>  */}
                </Form>
                <Modal width='90%' closable={false} visible={this.state.modalVisible} footer={null} className="modal-pricing">
                  <PricingTableModal closeModal={()=>this.setState({modalVisible: false})} hideButtons/>
                </Modal> 
                </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default connect(mapStateToProps)(withRouter(withTranslation()(SellerPersonForm)));
