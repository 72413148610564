import React, {useEffect} from 'react'
// import Profile from '../Profile/Profile'
import './EditPhotosStore.scss'
import FormProfilePictureStore from '../../Organisms/FormProfilePictureStore/FormProfilePictureStore'
import FormPortraitPictureStore from '../../Organisms/FormPortraitPictureStore/FormPortraitPictureStore'
import {Button} from 'antd';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import errorStore from '../../../utilityFunctions/noStore';
import {withRouter} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const EditPhotosStore = (props) => {
  const { t } = useTranslation();
  useEffect(()=>{

    document.body.scrollIntoView({block:'start'})
    if (props.userInfo.store.blocked) {
      errorStore()
      props.history.push('/dashboard/tienda');
    }
  })
  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
        <div className="box edit-photos-store-container">
          <h2 className='center-text  title-profilePicture'>{t("edit-store-photos", {a: ' '})}</h2>
          <div className="edit-photos-store">
              {props.location.state === 'portrait' && <FormPortraitPictureStore />}
              {props.location.state === 'profile' && <FormProfilePictureStore/>}
              {props.location.state ===  undefined && props.history.push('/dashboard/tienda')}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(EditPhotosStore))
