import React from 'react';
import Logo from '../../Atoms/Logo/Logo';
import './ConditionsTerms.css';

function ConditionsTerms() {
  return (
    <div>
      <Logo />
      <div className='white-bg round-box'>
                <h1 className='center-text'>Términos y condiciones de uso</h1>
                <p>Estos términos y condiciones se aplican a todo el contenido del sitio web con nombre de dominio XXXXXXXXX (“Sitio web”). Lea atentamente estas condiciones antes de usar el sitio web. Las referencias a “nosotros”, “nuestro” y derivaciones similares se refieren a la empresa, propietario y operador del Sitio web.</p>
                <p>Preste especial atención a los párrafos 7 y 8 de estos términos y condiciones.</p>
                <p>Al hacer uso de este sitio web usted acepta estos términos y condiciones junto con nuestra Política de privacidad y Política de cookies, así como todas las leyes y regulaciones aplicables, independientemente de que se registre o no con nosotros. Si no acepta estos términos y condiciones, no use este sitio web.</p>
                <p>Le aconsejamos que imprima una copia de estos términos y condiciones para poder consultarlas cuando sea necesario.</p>
                <h5>Su uso de este sitio web</h5>
                <ol className='allow-list-style'>
                    <li>Por el presente le concedemos una licencia limitada para acceder y hacer uso personal de este Sitio web, pero no para modificarlo, en parte o en su totalidad, a excepción de que cuente con nuestro permiso expreso por escrito.</li>
                    <li>Podrá acceder a la mayoría de las secciones de este sitio web sin registrar sus datos con nosotros. Sin embargo, otras solo están disponibles para aquellos que están registrados.</li>
                    <li>Al acceder a nuestro sitio web, consideraremos que ha aceptado plenamente los presentes términos y condiciones. Si no acepta estos términos y condiciones plenamente, deberá abandonar el sitio web de inmediato.</li>
                    <li>Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. En este caso, publicaremos la versión actualizada en el sitio web y entenderemos que si sigue haciendo uso de este en sus siguientes visitas es porque acepta dicha versión actualizada. Algunas disposiciones de estos términos y condiciones pueden ser sustituidas por avisos legales designados expresamente o términos y condiciones localizados en ciertas páginas del sitio web.</li>
                    <li>Todo el contenido disponible en este Sitio web está dirigido exclusivamente a individuos de 18 o más años de edad. Cualquier uso de este Sitio web está prohibido cuando no lo permita la ley.</li>
                </ol>
                <h5>Licencia</h5>
                <ol className='allow-list-style'>
                    <li>A menos que se estipule lo contrario, los derechos de propiedad intelectual y derechos de autor de todo el material de este sitio web es de nuestra propiedad o de nuestros licenciadores, anunciantes o proveedores de contenido. Salvo disposición expresa, ninguna parte del presente contenido podrá ser interpretada como que se otorga licencia o derecho sobre alguno de los derechos de autor, marca registrada u otros derechos de propiedad intelectual. Podrá recopilar, manipular, analizar, cambiar el formato, imprimir y mostrar el contenido ofrecido únicamente para uso personal y nunca para uso comercial. Bajo ningún concepto podrá publicar, distribuir o reproducir en ningún formato el contenido o copias del contenido que le hayamos enviado o que aparezca en el sitio web. Está prohibido el uso del contenido de este sitio web con otros propósitos que no sean los permitidos anteriormente. Si incumple alguna de las disposiciones de estos términos y condiciones, se pondrá fin a su permiso de uso de este sitio web. XXXXXXX, es una marca registrada. No le otorgamos ninguna licencia o consentimiento a usar estas marcas, a excepción de indicación expresa en caso contrario, y nos reservamos todos los derechos de estas y de las marcas relacionadas. Acepta no usar estas marcas o ninguna de las marcas que sean parecidas sin el permiso por escrito de XXXXXXXX.</li>
                    <li>En base a la cláusula, ninguna parte de este sitio web podrá reproducirse o recopilarse en ningún otro sitio web o incluirse en ningún servicio o sistema de recuperación electrónica privados o públicos sin nuestro consentimiento previo por escrito.</li>
                    <li>Nos reservamos todos los derechos no otorgados expresamente en estas condiciones.</li>
                </ol>
                <h5>Acceso al servicio</h5>
                <ol className='allow-list-style'>
                    <li>Aunque hacemos todo lo posible por que el sitio web esté disponible las 24 horas del día, no nos hacemos responsables si, por algún motivo, este no estuviese disponible en un momento o período determinado.</li>
                    <li>No ofrecemos garantías de disponibilidad, rendimiento o accesibilidad del sitio web.</li>
                    <li>El acceso a este sitio web puede verse interrumpido temporalmente y sin notificación previa, en caso de que haya un fallo en el sistema, por mantenimiento o reparación o por razones que estén fuera de nuestro control.</li>
                </ol>
                <h5>Enlaces y publicidad</h5>
                <p>Los enlaces a otros sitios web patrocinados en este sitio web se presentan solo para su comodidad. Si utiliza estos enlaces, saldrá de nuestro sitio web. No hemos revisado estos sitios web externos ni tampoco tenemos ningún control sobre ellos, por lo que no nos hacemos responsables de ellos, de su contenido o disponibilidad. De esta forma, nosotros no aceptamos ni representamos dichos sitios web ni su material o resultados que se puedan obtener de hacer uso de ellos. Si decide acceder a alguno de estos sitios web, usted asumirá las consecuencias.</p>
                <p>Nos reservamos el derecho a mostrar anuncios en el sitio web y en la página de inicio de sesión de productos y/o servicios de otras empresas que puedan ser de su interés. Tenga en cuenta que nosotros no ofrecemos estos servicios y productos anunciados, sino empresas externas que tendrán el control sobre estos. En caso de que quiera comprar estos servicios o productos, deberá aceptar los términos y condiciones de estas empresas antes de firmar un contrato con ellas. No nos hacemos responsables de dichos productos o servicios o de la información que ofrecen estos proveedores externos.</p>
                <p>Si desea crear un enlace a nuestro sitio web, tendrá que tener primero nuestro consentimiento por escrito.</p>
                <h5>Registro</h5>
                <p>Cada inscripción es para un solo usuario. No podrá compartir su nombre de usuario o contraseña con ninguna otra persona ni con varios usuarios de una red.</p>
                <p>Si se le ha proporcionado (o ha elegido) una contraseña para acceder a determinadas partes del sitio, usted es responsable de mantener dicha contraseña confidencial. Le pedimos que no comparta su contraseña con nadie. Usted se hace responsable de la seguridad de sus contraseñas.</p>
                <h5>Cláusula de renuncia</h5>
                <p>Aunque nos esforzamos para que la información que aparece en el sitio web sea correcta, no garantizamos la exactitud e integridad del material de nuestro sitio web. Podemos modificar el material o los servicios del sitio web siempre que lo creamos oportuno sin notificación previa. El material de este sitio web puede no estar actualizado, por lo que no nos hacemos responsables de su actualización.</p>
                <p>El material de este sitio web se ofrece «tal cual», es decir, sin condiciones de ningún tipo o garantías. De esta forma, dentro de lo permitido por la ley, le ofrecemos este sitio web excluyendo representación, garantías, condiciones y otros términos (incluyendo entre otras, las condiciones que dicta la ley en cuanto a cantidad satisfactoria, finalidad del propósito y uso razonable y adecuado) que puedan surgir de estos términos y condiciones con relación a este sitio web.</p>
                <h5>Responsabilidad</h5>
                <p>El material de nuestro sitio web se ofrece sin condiciones ni garantías de su exactitud. En la medida en que la ley lo permita, nosotros, otros miembros de nuestro grupo de empresas y sociedades externas relacionadas con nosotros excluimos expresamente:</p>
                <ol className='allow-list-style'>
                    <li>Todas las condiciones, garantías y otros términos que puedan implicarse por estatutos, ley común o leyes en un marco de igualdad.</li>
                    <li>
                        <p>Responsabilidad por pérdidas o daños directos o indirectos incurridos por un usuario en relación con nuestro sitio web o en relación con el uso, incapacidad de uso o resultados del uso de nuestro sitio web, sitios web con enlaces en este y material publicado en él, sin limitar la responsabilidad por:</p>
                        <ul className='allow-list-style'>
                            <li>Pérdida de ingresos o ganancias;</li>
                            <li>Pérdida de negocio;</li>
                            <li>Pérdida de beneficios o contratos;</li>
                            <li>Pérdida de ahorros anticipados;</li>
                            <li>Pérdida de datos;</li>
                            <li>Pérdida de imagen;</li>
                            <li>Gestión o tiempo laboral desperdiciados, así como por cualquier otra pérdida o daño de cualquier tipo, causado por agravio (incluyendo negligencia) o incumplimiento del contrato, incluso si fuese predecible.</li> 
                        </ul>
                    </li>
                </ol>
                <p>Si su uso del material de nuestro sitio web resulta en necesidad de servicios o reparación del equipo, software o datos, usted asumirá los costes.</p>
                <p>Cualquier transmisión de datos al Sitio web se realiza bajo su propia responsabilidad. Usted acepta los riesgos de seguridad inherentes a la transmisión de información en línea por Internet. Una vez hayamos recibido su información, usaremos estrictos procedimientos y funcionalidades de seguridad para prevenir cualquier acceso no autorizado. No nos hacemos responsables de brechas en la seguridad, a menos que estas se produzcan por negligencia o acción deliberada por nuestra parte.</p>
            </div>
    </div>
  )
}

export default ConditionsTerms;
