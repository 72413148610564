import React, {useState} from 'react';
import Logo from '../../Atoms/Logo/Logo';
import {Button, Alert} from 'antd';
import {withRouter} from 'react-router-dom';
import alerta from '../../../utilityFunctions/alerta';
import logoEmailVerification from '../../../assets/P1-correo-veririfacion-exitosa-imagen.png';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import { connect } from 'react-redux';

function VerifyEmail(props) {
    const { t } = useTranslation();
    const [verifyError, setVerifyError] = useState('')
    const [loadings, setLoadings] = useState([])
    const enterLoading = index => {
        const newLoadings = [...loadings];
        newLoadings[index] = true;
        setLoadings(newLoadings);
    };
    const handleVerifyClick = () => {
        setVerifyError('');
        enterLoading(0)
        fetch(`${process.env.REACT_APP_API_URL}/verify`, { 
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.match.params.token}`
            },
            body: JSON.stringify({
                "currentDate": moment.utc().format()
            })
        })
        .then(res=>res.json())
        .then(res=>{
            const newLoadings = [...loadings];
            newLoadings[0] = false;
            setLoadings(newLoadings);
            if (res.message === "process done") {
                alerta(t('email-verify-success', {a: ' '}))
                props.history.push('/login');
            } else if (res.message === "Auth failed") {
                setVerifyError(t('expired-link', {a: ' '}))
            } else {
                setVerifyError(t('oops', {a: ' '}))
            }
        })
        .catch(err=> {
            const newLoadings = [...loadings];
            newLoadings[0] = false;
            setLoadings(newLoadings);
            setVerifyError(t('oops', {a: ' '}))
        })
    }
    return (
        <div className='box abs-center'>
            <Logo />
            <h1 className='center-text title-form'>{t("email-welcome-title", {a: ' '})}</h1>
            <p className='center-text'>{t("email-welcome-msg", {a: ' '})}</p>
            <div className='container-logo-verify'><img src={logoEmailVerification} alt="Verificar Identidad"/></div>
            <p className='center-text'>{t("email-msg", {a: ' '})}:</p>
            <Button type='primary' onClick={handleVerifyClick} className='btn-first round-button center-block' loading={loadings[0]}>{t("email-verify", {a: ' '})}</Button>
            {verifyError && <Alert message={verifyError} type='error' className='error-msg' />}
        </div>
    )
}

export default connect()(withRouter(VerifyEmail));
