import React, {useState, useEffect} from 'react'
import './MyProductsInactives.scss'
// import Swal from 'sweetalert2'
// import 'sweetalert2/src/sweetalert2.scss'
// import MenuVertical from '../../Organisms/MenuVertical/MenuVertical'
import Loader from '../../Atoms/Loader/Loader';
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import MyProductCard2 from '../../Molecules/MyProductCard/MyProductCard2'
import {useTranslation} from 'react-i18next';
import notProduct from '../../../assets/P1-Tienda-sin-articulos-imagen.png';
import { useProducts } from '../../../customHooks/useProducts';
import { Button } from 'antd';

const MyProducts = (props) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(false)
  const { loadingProducts, products, productsCount, setPage } = useProducts({storeId: props.userInfo.store.id, status: 0, switchBloqued: 1})

  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
    setReload(false)
  }, [reload])

  // Validar producto
  const validProduct = (array, message) => {
    let result = false
    array &&
    array.forEach(p=> {
      if(p.status === message) {
        result = true
      }
    })
    return result
  }

  let productNotAvailable = validProduct(products, "No disponible")

  return (
    <>
    <div className="profile-container">
    <div className="wrapped-profile-container">
      {
      loadingProducts ? <Loader /> : 
      products.count < 1 ? 
      <div className="box container-products-inactives">
        <div className="main-empty">
          <h2 className="title-main-empty-product">{t("no-loaded-products", {a: ' '})}</h2>
          <img src={notProduct} alt={t("no-loaded-products", {a: ' '})}/>
        </div> 
      </div> :
        <>
        {productNotAvailable &&
        <div className="box container-products-inactives">
          <h2 className='center-text title-form title-profilePicture'>{t("inactive-products", {a: ' '})}</h2>
          <div className=" products-container">
            {
              products.map(product => (
                product.status === 'No disponible' &&
                <MyProductCard2 key={product.id}
                  product={product}
                  setReload={setReload}
                  inactive
                />
              ))
            }
            {products.length < productsCount && <Button type='primary' onClick={() => setPage(prevPage => prevPage + 1)} className='round-button btn-first pagination-button'>Cargar más</Button>}
        </div>
      </div>
        }
      <div>
        {
          !productNotAvailable &&
          <div className="box container-products-actives">
              <div className="main-empty">
                <h2 className="title-main-empty-product">{t("no-inactive-products", {a: ' '})}</h2>
                <img src={notProduct} alt={t("no-inactive-products", {a: ' '})}/>
              </div> 
            </div>
        }
      </div>
      </>
    }
    </div>
    </div>
  </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(MyProducts)) 

