import React,{useState, useEffect} from 'react'
import './CreateSchedules.scss';
import {Form, Button, Select, Row, Checkbox, Input} from 'antd';
import Logo from '../../Atoms/Logo/Logo';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import {withRouter, } from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const CreateSchedules = (props) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])
  const [special, setSpecial] = useState(false)

  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
    fetch(`${process.env.REACT_APP_API_URL}/schedules/${props.userInfo.store.id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(res => {

        if(res.findSchedules.length > 0) {
          props.history.push('/dashboard/tienda') 
        }
    })
  }, [props.history, props.userInfo])

  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  const handleFormSubmit = (e) => {
    enterLoading(0);
      fetch(`${process.env.REACT_APP_API_URL}/create-schedule`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          "storeId": props.userInfo.store.id,
          "userId": props.userInfo.person.userId,
          "name": "Horario",
          "openingDay": e.openingDay,
          "closingDay": e.closingDay,
          "openingTime": e.openingTime,
          "closingTime": e.closingTime,
          "serviceSchedule": false,
          "scheduleExtended": e.specialSchedule
        })
      })
      .then(res => res.json())
      .then(res => {

        if(res.message === 'Schedule created') {
          props.dispatch({
            type: SAVE_LOGIN_USER,
            data: res.findUser
          })
          Swal.fire({
            icon: 'success',
            title: t('schedule-created', {a: ' '}),
            // text: '',
          })
          props.history.push('/dashboard/tienda') 
        } else {
            const newLoadings = [...loadings]
            newLoadings[0] = false;
            setLoadings(newLoadings);
            Swal.fire({
              icon: 'error',
              title: t('oops', {a: ' '}),
              
            })
        }
      })
  }
  
  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
        <div className="container-create-schedules">
        <Form name='user-info-form' onFinish={e => handleFormSubmit(e)} className='form'>
          <Logo />
          <h1 className='center-text title-form'>{t('create-schedule', {a: ' '})}</h1>
					<Row className='fix-width-row'>
						<Form.Item name='openingDay' className="inline-field-form" label={t('opening-day', {a: ' '})}>
						  <Select placeholder={t('opening-day', {a: ' '})} size='large' className="select-input mr-time">
							  <Select.Option value="Lunes">{t('monday', {a: ' '})}</Select.Option>
							  <Select.Option value="Martes">{t('tuesday', {a: ' '})}</Select.Option>
							  <Select.Option value="Miercoles">{t('wednesday', {a: ' '})}</Select.Option>
							  <Select.Option value="Jueves">{t('thursday', {a: ' '})}</Select.Option>
							  <Select.Option value="Viernes">{t('friday', {a: ' '})}</Select.Option>
							  <Select.Option value="Sabado">{t('saturday', {a: ' '})}</Select.Option>
							  <Select.Option value="Domingo">{t('sunday', {a: ' '})}</Select.Option>
						  </Select>
						</Form.Item>
						<Form.Item name='closingDay' className="inline-field-form" label={t('closing-day', {a: ' '})}>
							  <Select placeholder={t('closing-day', {a: ' '})} size='large' className="select-input ml-time">
                  <Select.Option value="Lunes">{t('monday', {a: ' '})}</Select.Option>
                  <Select.Option value="Martes">{t('tuesday', {a: ' '})}</Select.Option>
                  <Select.Option value="Miercoles">{t('wednesday', {a: ' '})}</Select.Option>
                  <Select.Option value="Jueves">{t('thursday', {a: ' '})}</Select.Option>
                  <Select.Option value="Viernes">{t('friday', {a: ' '})}</Select.Option>
                  <Select.Option value="Sabado">{t('saturday', {a: ' '})}</Select.Option>
                  <Select.Option value="Domingo">{t('sunday', {a: ' '})}</Select.Option>
							  </Select>
						</Form.Item>
					</Row>
					<Row className='fix-width-row'>
            <Form.Item name='openingTime' className="inline-field-form" label={t('opening-time', {a: ' '})}>
            <Select placeholder={t('opening-time', {a: ' '})} size='large' className="select-input ml-time">
                    <Select.Option value="0:00">0:00</Select.Option>
                    <Select.Option value="1:00">1:00</Select.Option>
                    <Select.Option value="2:00">2:00</Select.Option>
                    <Select.Option value="3:00">3:00</Select.Option>
                    <Select.Option value="4:00">4:00</Select.Option>
                    <Select.Option value="5:00">5:00</Select.Option>
                    <Select.Option value="6:00">6:00</Select.Option>
                    <Select.Option value="7:00">7:00</Select.Option>
                    <Select.Option value="8:00">8:00</Select.Option>
                    <Select.Option value="9:00">9:00</Select.Option>
                    <Select.Option value="10:00">10:00</Select.Option>
                    <Select.Option value="11:00">11:00</Select.Option>
                    <Select.Option value="12:00">12:00</Select.Option>
                    <Select.Option value="13:00">13:00</Select.Option>
                    <Select.Option value="14:00">14:00</Select.Option>
                    <Select.Option value="15:00">15:00</Select.Option>
                    <Select.Option value="16:00">16:00</Select.Option>
                    <Select.Option value="17:00">17:00</Select.Option>
                    <Select.Option value="18:00">18:00</Select.Option>
                    <Select.Option value="19:00">19:00</Select.Option>
                    <Select.Option value="20:00">20:00</Select.Option>
                    <Select.Option value="21:00">21:00</Select.Option>
                    <Select.Option value="22:00">22:00</Select.Option>
                    <Select.Option value="23:00">23:00</Select.Option>
                    <Select.Option value="24:00">24:00</Select.Option>
                  </Select>
              </Form.Item>
            <Form.Item name='closingTime' className="inline-field-form" label={t('closing-time', {a: ' '})}>
            <Select placeholder={t('closing-time', {a: ' '})} size='large' className="select-input ml-time">
                    <Select.Option value="0:00">0:00</Select.Option>
                    <Select.Option value="1:00">1:00</Select.Option>
                    <Select.Option value="2:00">2:00</Select.Option>
                    <Select.Option value="3:00">3:00</Select.Option>
                    <Select.Option value="4:00">4:00</Select.Option>
                    <Select.Option value="5:00">5:00</Select.Option>
                    <Select.Option value="6:00">6:00</Select.Option>
                    <Select.Option value="7:00">7:00</Select.Option>
                    <Select.Option value="8:00">8:00</Select.Option>
                    <Select.Option value="9:00">9:00</Select.Option>
                    <Select.Option value="10:00">10:00</Select.Option>
                    <Select.Option value="11:00">11:00</Select.Option>
                    <Select.Option value="12:00">12:00</Select.Option>
                    <Select.Option value="13:00">13:00</Select.Option>
                    <Select.Option value="14:00">14:00</Select.Option>
                    <Select.Option value="15:00">15:00</Select.Option>
                    <Select.Option value="16:00">16:00</Select.Option>
                    <Select.Option value="17:00">17:00</Select.Option>
                    <Select.Option value="18:00">18:00</Select.Option>
                    <Select.Option value="19:00">19:00</Select.Option>
                    <Select.Option value="20:00">20:00</Select.Option>
                    <Select.Option value="21:00">21:00</Select.Option>
                    <Select.Option value="22:00">22:00</Select.Option>
                    <Select.Option value="23:00">23:00</Select.Option>
                    <Select.Option value="24:00">24:00</Select.Option>
                  </Select>
              </Form.Item>
					</Row>
          <Form.Item valuePropName='checked' name='special-schedule-check' className="specialScheduleCheck" onChange={()=>setSpecial(!special)}>
            <Checkbox>{t('have-special-schedule', {a: ' '})}</Checkbox>
          </Form.Item>
          {special && 
          <Form.Item name='specialSchedule' label={t('special-schedule', {a: ' '})} className="special-schedule" hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
            <Input.TextArea maxLength={200} placeholder={t('special-schedule-description', {a: ' '})} className='text-input'/>
          </Form.Item>}
          <Form.Item>  
            <Button htmlType='submit' type='primary' loading={loadings[0]} className='btn btn-first mb-5' >
              {t('create-schedule', {a: ' '})}
            </Button>
            <Button danger className='btn' onClick={props.history.goBack}>
              {t('cancel', {a: ' '})}
            </Button>
          </Form.Item> 
        </Form>
      </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(withRouter(CreateSchedules));
