import React, {useEffect} from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LOGOUT_USER } from '../../../redux/actions';
import moment from 'moment';
import 'moment/locale/es';
import Swal from 'sweetalert2';
import Logo from '../../Atoms/Logo/Logo';
import './ReportPasswordChange.scss'
const ReportPasswordChange = (props) => {

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('remember');
    props.dispatch({
      type: LOGOUT_USER,
      data: {}
    })
    props.history.push('/');
  }

  useEffect(() => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/report-password`
      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${props.match.params.token}`
        },
        body: JSON.stringify({
          // "email": props.userInfo.email,
          "currentDate": moment.utc().format(),
          "esp": true
        }),
      };
      
      const reportPass = async () => {
        const response = await fetch(url, config);
        const json = await response.json()
        if (response.ok) {
          console.log(response);
          Swal.fire({
            icon: 'success',
            title: "Contraseña reportada",
            text:  "Por favor, revisa tu bandeja de entrada y sigue los pasos que ahí aparecen."
          })
          .then( async (result) => {
            if (result.isConfirmed) {
              logout();
            } else {
              logout();
            }
          });
        } else {
          if(response.status === 401) {
            Swal.fire({
              icon: 'info',
              title: "¡Atención!",
              text:  "Ocurrió un error inesperado o el enlace expiró. Por favor, cambia tu contraseña presionando la opción Olvidé mi contraseña"
            })
            .then( async (result) => {
              if (result.isConfirmed) {
                logout();
              } else {
                logout();
              }
            });
          }
          if(response.status === 500) {
            Swal.fire({
              icon: 'error',
              title: 'Oops.',
              text: 'Algo salió mal. intento nuvamente',
            })
            console.log('error de backend');
          }
        }
      }

      reportPass();
    } 
    catch (error) {
      console.log(error)
    }
  }, []);

  return (
   <div className="box report-password">
     <h2 className="mb-10">Reporte de contraseña</h2>
     <h2 className="mb-10"><Logo /></h2>
   </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(ReportPasswordChange))

