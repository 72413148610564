import React, { useEffect, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import {Button, Modal, Checkbox, Form, Select, Space, Spin} from 'antd';
import Loader from '../../Atoms/Loader/Loader';
import {Link} from 'react-router-dom';
import {AimOutlined, ControlFilled} from '@ant-design/icons';
import Swal from 'sweetalert2';
import MarkerClusterer from '@google/markerclustererplus';
import {useTranslation} from 'react-i18next';
import { SAVE_LOCATION, SAVE_SEARCH } from '../../../redux/actions';

const Loader2 = () => {
    return (
        <Space size="middle" className="loader-container2">
          <Spin size="large" />
        </Space>
    )
}

const ViewAllStoresMap = props => {
    const { t } = useTranslation();
    const { center = {lat: 41.2318958, lng: 1.8016172}, zoom = 16} = props;
    const centerCatalunya = {lat: 41.77572305845443, lng: 1.6001299734144823};
    const [checkboxOptions, setCheckboxOptions] = useState([]);
    const [checkboxDefaultValues, setCheckboxDefaultValues] = useState([]);
    const [radiusDefaultValue, setRadiusDefaultValue] = useState('30');
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [currentMap, setCurrentMap] = useState('');
    //const [directionURL, setDirectionURL] = useState('');
    const [storeURL, setStoreURL] = useState('');
	const [storeName, setStoreName] = useState('');
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    //eslint-disable-next-line
    const [markers, setMarkers] = useState([])
    const [showReminder, setShowReminder] = useState(true);
    const [searchOtherLoc, setSearchOtherLoc] = useState(false);
    const searchOtherLocRef = useRef();
    const formRef = useRef();
    const catAndRadiusRef = useRef();

    searchOtherLocRef.current = searchOtherLoc;
    catAndRadiusRef.current = {categories: props.match.params.categories, radius: props.match.params.radius}

    const getCategories = () => {
        setShowPermissionModal(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position=>{
                setShowPermissionModal(false);
                !props.match.params.categories && setShowModal(true);
            }, error => { 
                setShowPermissionModal(false);
                !props.match.params.categories && setShowModal(true);
            }, {timeout: 0})
        } else {
            setShowPermissionModal(false);
            !props.match.params.categories && setShowModal(true);
            alert(t("map-no-geoloc", {a: ' '}))
        }
        fetch(`${process.env.REACT_APP_API_URL}/main-categories/${props.language === 'CA' || props.language === 'ca-ES' ? 0 : 1}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
        .then(resp => resp.json())
        .then(resp => {
            const checkboxDefaultOptions = [];
            let checkboxDefaultValues = [];
            resp.getMainCategories.forEach(cat=>{
                checkboxDefaultOptions.push({label: cat.name, value: cat.id});
                checkboxDefaultValues.push(cat.id);
            })
            setCheckboxOptions(checkboxDefaultOptions);
            if (props.match.params.categories) {
                checkboxDefaultValues=props.match.params.categories.split('-').map(idStr=>Number(idStr));
            }
            if (props.match.params.radius) {
                setRadiusDefaultValue(props.match.params.radius)
            }
            setCheckboxDefaultValues(checkboxDefaultValues); 
            setLoading(false);
            setLoading2(false); 
        }) 
    }
    let markerClusterer = null;
    const getLocations = (map, maps, userLocation) => {
      console.log(userLocation)
      setLoading2(true);
      markerClusterer && markerClusterer.clearMarkers();
      setMarkers(prevState => {
          prevState.forEach(marker=>marker.setMap(null))
          return []
      })
      fetch(`${process.env.REACT_APP_API_URL}/stores-by-categories/1000/0`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
        body: JSON.stringify({
            "categories": catAndRadiusRef.current.categories.split('-').map(catStr=>Number(catStr)),
            "radius": catAndRadiusRef.current.radius,
            "userLocation": userLocation,
            "pagination": false
        })
      })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        if (res.message === 'stores found') {
            const overlay = new maps.OverlayView();
            overlay.draw = function () {
                this.getPanes().markerLayer.id='markerLayer';
            };
            overlay.setMap(map);
            if (res.filteredStoresByRadius.length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: t("map-no-stores", {a: ' '}),
                    text: t("map-no-stores-msg", {a: ' '}),
                })
            }
            const newMarkers = [];
            res.filteredStoresByRadius.forEach(user => {
                const marker = new maps.Marker({
                    position: { lat: Number(user.storeData.store.location[0].latitude), lng: Number(user.storeData.store.location[0].longitude) },
                    map: map,
                    title: 'Ver tienda',
                    icon: {
                        url: user.storeData.store.profilePicture.url,
                        scaledSize: { width: 45, height: 45 },
                        labelOrigin: new maps.Point(22.5,55)
                    },
                    label: user.storeData.store.storeName
                });
                newMarkers.push(marker);
                marker.addListener('click', () => {
                    //const directionURL = `https://www.google.com/maps/dir/?api=1&origin=My+Location&destination=${Number(user.storeData.store.location[0].latitude)},${Number(user.storeData.store.location[0].longitude)}`
                    // const storeURL = `/vendedor/${user.storeData.store.userId}/${user.storeData.store.storeName.replace(/ /g, '-').toLowerCase()}`;
                    const storeURL = `/vendedor/${user.storeData.store.userId}`;
                    //setDirectionURL(directionURL);
                    setStoreName(user.storeData.store.storeName);
					setStoreURL(storeURL);
                    let address = user.storeData.store.location[0].address;
                    if (user.storeData.store.location[0].additionalInformation) {
                        address = address.concat(` / ${user.storeData.store.location[0].additionalInformation}`)
                    }
                    const infoWindow = new maps.InfoWindow({
                        content: address
                    });
                    infoWindow.open(map, marker);
                })  
            })
            setMarkers(newMarkers);
            markerClusterer = new MarkerClusterer(map, newMarkers, {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
            markerClusterer.fitMapToMarkers(0)
            setTimeout(setLoading2(false)); 
        } else {
            setLoading2(false);
            Swal.fire({
                icon: 'error',
                title: t('oops', {a: ' '}),
            })
            map.setCenter(userLocation);
        }
      })
      .catch(err => {
        setLoading2(false);
        console.log('error obteniendo stores es: ',err);
      })
    }
    const handleApiLoaded = (map, maps) => {
        setCurrentMap(map);
        map.addListener('click', e => {
            const clickedLoc = {
              lat: e.latLng.lat(),
              lng: e.latLng.lng()
            };
            if (searchOtherLocRef.current) {
                props.dispatch({
                    type: SAVE_LOCATION,
                    data: clickedLoc
                })
                props.history.push(`/tiendas/mapa/categorias/${catAndRadiusRef.current.categories}/radio/${catAndRadiusRef.current.radius}`)
                ///lat/${clickedLoc.lat}/lng/${clickedLoc.lng}
                getLocations(map, maps, clickedLoc);
            }
        })
        if (navigator.geolocation) {
            let pos;
            navigator.geolocation.getCurrentPosition(position=>{
            pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            if (props.match.params.categories) {
                props.userLocation.lat ? getLocations(map, maps, {lat: props.userLocation.lat, lng: props.userLocation.lng}) : getLocations(map, maps, pos);
            }
            map.setCenter(pos);
            map.setZoom(16);
            }, () => {
                if (props.match.params.categories) {
                    props.userLocation.lat ? getLocations(map, maps, {lat: props.userLocation.lat, lng: props.userLocation.lng}) : getLocations(map, maps, center);
                }
                showReminder && Swal.fire({
                    icon: 'warning',
                    title: t("map-recordatorio", {a: ' '}),
                    text: t("map-recordatorio-msg", {a: ' '})
                })
                setShowReminder(false);
                console.log('No fue posible detectar su localización.')
            })
        } else {
            props.match.params.categories && props.userLocation.lat ? getLocations(map, maps, {lat: props.userLocation.lat, lng: props.userLocation.lng}) : getLocations(map, maps, center);
            window.alert(t("map-no-geoloc", {a: ' '}))
        }
        
    }
    const searchCategories = e => {
        /*if (props.match.params.lat) {
            props.history.push(`/tiendas/mapa/categorias/${e.categories.join('-')}/radio/${e.radius}/lat/${props.match.params.lat}/lng/${props.match.params.lng}`);
        } else {
            props.history.push(`/tiendas/mapa/categorias/${e.categories.join('-')}/radio/${e.radius}`);
        }*/
        props.dispatch({
            type: SAVE_SEARCH,
            data: {radius: e.radius, cats: e.categories}
        })
        props.history.push(`/tiendas/mapa/categorias/${e.categories.join('-')}/radio/${e.radius}`);
        setShowModal(false);
        setCheckboxDefaultValues(e.categories);
        setRadiusDefaultValue(e.radius);
        setLoading2(true);
        setLoading(true);  //con esto hago que el mapa se desmonte y se monte de nuevo, necesario para hacer la peticion de las tiendas con los resultados del form
        setLoading(false);
    }
    const centerMap = () => {
        if (navigator.geolocation) {
            let pos;
            navigator.geolocation.getCurrentPosition(position=>{
            pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            currentMap.setCenter(pos);
            currentMap.setZoom(16);
            props.history.push(`/tiendas/mapa/categorias/${props.match.params.categories}/radio/${props.match.params.radius}`)
            }, () => {
                currentMap.setCenter(center);
                currentMap.setZoom(16);
                props.history.push(`/tiendas/mapa/categorias/${props.match.params.categories}/radio/${props.match.params.radius}`)
                console.log('no se detecto geolocalizacion')
                //window.alert('No fue posible detectar su localización.')
            })
        } else {
            props.history.push(`/tiendas/mapa/categorias/${props.match.params.categories}/radio/${props.match.params.radius}`)
            window.alert(t("map-no-geoloc"))
        }
    }
    const viewListResults = () => {
        props.history.push(`/tiendas/lista/categorias/${props.match.params.categories}/radio/${props.match.params.radius}`)
        /*if (props.match.params.lat) {
            props.history.push(`/tiendas/lista/categorias/${props.match.params.categories}/radio/${props.match.params.radius}/lat/${props.match.params.lat}/lng/${props.match.params.lng}`)
        } else {
            props.history.push(`/tiendas/lista/categorias/${props.match.params.categories}/radio/${props.match.params.radius}`)
        }*/
    }
    const searchOtherLocations = () => {
        currentMap.setCenter(centerCatalunya);
        currentMap.setZoom(9);
        const formValues = formRef.current.getFieldsValue();
        props.dispatch({
            type: SAVE_SEARCH,
            data: {radius: formValues.radius, cats: formValues.categories}
        })
        props.history.push(`/tiendas/mapa/categorias/${formValues.categories.join('-')}/radio/${formValues.radius}`);
        setShowModal(false);
        setCheckboxDefaultValues(formValues.categories);
        setRadiusDefaultValue(formValues.radius);
        setSearchOtherLoc(true);
        setLoading(true);  //con esto hago que el mapa se desmonte y se monte de nuevo, necesario para hacer la peticion de las tiendas con los resultados del form
        setLoading(false);
    }
    const searchByGeoloc = () => {
        centerMap();
        const formValues = formRef.current.getFieldsValue();
        props.dispatch({
            type: SAVE_LOCATION,
            data: {lat: null, lng: null}
        })
        props.dispatch({
            type: SAVE_SEARCH,
            data: {radius: formValues.radius, cats: formValues.categories}
        })
        props.history.push(`/tiendas/mapa/categorias/${formValues.categories.join('-')}/radio/${formValues.radius}`);
        setShowModal(false);
        setCheckboxDefaultValues(formValues.categories);
        setRadiusDefaultValue(formValues.radius);
        setSearchOtherLoc(false);
        setLoading(true);  //con esto hago que el mapa se desmonte y se monte de nuevo, necesario para hacer la peticion de las tiendas con los resultados del form
        setLoading(false);
    }

    useEffect(getCategories, [props.language])

    return (
    <>
      {!loading? <div className='map-container'>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_API_KEY}`}}
          defaultCenter={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
        />
        {loading2 && <Loader2 />}
        <Modal visible={showModal} centered footer={null} closable={false}>
            <Form ref={formRef} id='map-form' className="map-select-categories-form" name='map-select-categories-form' onFinish={searchCategories} initialValues={{radius: radiusDefaultValue, categories: checkboxDefaultValues}}>
                {/*<Logo />*/}
                <h4 className='center-text title-form'>{t("map-search-title", {a: ' '})}</h4>
                <h6 className='center-text'>
                    {t("map-search-subtitle", {a: ' '})}
                </h6>
                <Form.Item className='checkbox-block' name='categories' rules={[{required: true, message: t("map-cat-valid", {a: ' '})}]}>
                    <Checkbox.Group options={checkboxOptions} />
                </Form.Item>
                <Form.Item name='radius' label={t('radius', {a: ' '})}>
                    <Select>
                        <Select.Option value='1'>1km</Select.Option>
                        <Select.Option value='5'>5kms</Select.Option>
                        <Select.Option value='10'>10kms</Select.Option>
                        <Select.Option value='15'>15kms</Select.Option>
                        <Select.Option value='20'>20kms</Select.Option>
                        <Select.Option value='30'>30kms</Select.Option>
                        <Select.Option value='40'>40kms</Select.Option>
                        <Select.Option value='50'>50kms</Select.Option>
                    </Select>
                </Form.Item>
                <div className='map-form-center-buttons'>
                    <Button htmlType='submit' type='primary' className='round-button btn-first'>{t('search', {a: ' '})}</Button>
                    {props.match.params.categories ? <Button danger className='round-button' onClick={()=>setShowModal(false)}>{t('close', {a: ' '})}</Button> 
                    :
                    <Link to='/tiendas/lista/categorias/1-2-3-4-5-6/radio/30'><Button className='round-button btn-second hover-blue'>{t("map-home", {a: ' '})}</Button></Link>}
                </div>
                {props.userLocation.lat ? <p className='center-text map-info-text'>{t("map-geoloc-msg", {a: ' '})}:</p> : <p className='center-text map-info-text'>{t("map-manual-msg", {a: ' '})}</p>}  
                {props.userLocation.lat ? <Button onClick={searchByGeoloc} className='center-block round-button'>{t("map-geoloc-btn", {a: ' '})}</Button>:<Button className='center-block round-button' onClick={searchOtherLocations}>{t("map-manual-btn", {a: ' '})}</Button>}
            </Form>   
        </Modal>
        <Modal width={340} centered visible={showPermissionModal} closable={false} footer={null}>
            <AimOutlined className='modal-icon'/>
            <h6 className='center-text modal-text'>{t("map-first-msg-title", {a: ' '})}</h6>
            <p className='center-text modal-text'>{t("map-first-msg-content", {a: ' '})}</p>
            <Button type='primary' className='btn-first round-button center-block' onClick={()=>setShowPermissionModal(false)}>{t("map-entiendo", {a: ' '})}</Button>
        </Modal>
        {currentMap && !loading2 && 
        <div className="map-main-footer--item map-center-button" onClick={centerMap}>
            <AimOutlined twoToneColor="#161B4B"/>
        </div>}
        {currentMap && !loading2 &&
        <div className="map-main-footer--item map-settings-button" onClick={()=>setShowModal(true)}>
            <ControlFilled twoToneColor="#161B4B"/>
        </div>}
        {props.match.params.categories && <Button onClick={viewListResults} type='primary' className='map-view-list-button round-button btn-first btn-small'>{t("view-list-results", {a: ' '})}</Button>}
        {storeURL && <Link to={storeURL}><Button className='round-button btn-first view-store-button'>{t("view-store", {a: ' '})}: {storeName}</Button></Link>}
        {/*<div className='map-buttons-container'>
            {storeURL && <Link to={storeURL}><Button className='round-button btn-first center-block'>Ver tienda</Button></Link>}
            {directionURL && <a href={directionURL} target='_blank' rel='noopener noreferrer'><Button type='primary' className='round-button btn-second'>¿Cómo llegar?</Button></a>}
        </div>*/}
      </div> : <Loader />}
    </>
    );
}
const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  userLocation: state.locationReducer.userLocation,
  search: state.searchReducer.search,
  language: state.languageReducer.language
})
export default connect(mapStateToProps)(ViewAllStoresMap);
