import React, {useState, useEffect} from 'react'
import './ProfileStore.scss'
import {Button, Rate} from 'antd';
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ShopOutlined, CameraFilled, FileProtectOutlined, CheckCircleOutlined, EnvironmentOutlined,FieldTimeOutlined,PhoneOutlined, EuroCircleOutlined,CarOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import alerta from '../../../utilityFunctions/alerta'
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import {useTranslation} from 'react-i18next';

const ProfileStore = (props) => {
  const { t } = useTranslation();
  const [stripeUrl, setStripeUrl] = useState(null);
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [reactivateButtonLoading, setReactivateButtonLoading] = useState(false);
  const [reload, setReload] = useState(false);
  
  const direction = props.userInfo.store.location.length > 0 ?
  props.userInfo.store.location[0].additionalInformation ?
  props.userInfo.store.location[0].address+' / '+ props.userInfo.store.location[0].additionalInformation:
  props.userInfo.store.location[0].address:
  t("no-location-store", {a: ' '}); 

  useEffect(()=>{
    setReload(false)
    document.body.scrollIntoView({block:'start'})
    if (props.userInfo.stCustomerId) {
      fetch(`${process.env.REACT_APP_API_URL}/visit-dashboard`, {
          method: 'POST',
          headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            accountId: props.userInfo.stCustomerId
          })
      })
      .then(res => res.json())
      .then(res=>{
        if (res.link) {
          setStripeUrl(res.link);
        }
      })
    }
  }, [])
  const deleteStore = () => {
    const btnsStore = Array.from(document.querySelectorAll(".btn-editStore"));
    btnsStore.map(btn => {
      btn.setAttribute("disabled", "");
    })
    // console.log(btnsStore);
    Swal.fire({
      title: t('confirm-delete-store', {a: ' '}),
      text: t('confirm-delete-account2', {a: ' '}),
      icon: "warning",
      showCancelButton :true,
      confirmButtonText: t('yes', {a: ' '}),
      cancelButtonText: t('keep', {a: ' '})
    })
    .then(result => {
      // console.log(result)
      if (result.isConfirmed) {
        btnsStore.map(btn => {
          btn.setAttribute("disabled", "");
        })
        setDeleteButtonLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/delete-store/${props.userInfo.store.id}`, { 
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => res.json())
        .then(res => {
            if (res.message === 'process done') {
                props.dispatch({
                  type: SAVE_LOGIN_USER,
                  data: res.findUser
                })
                props.history.push('/dashboard/perfil');
                alerta(t('deleted-store', {a: ' '}));
            } else {
                btnsStore.map(btn => {
                  btn.removeAttribute("disabled");
                })
                setDeleteButtonLoading(false);
                Swal.fire({
                    title: t('error', {a: ' '}),
                    text: t('oops', {a: ' '})
                })
            }
        })
        .catch(err=>{
            btnsStore.map(btn => {
              btn.removeAttribute("disabled");
            })
            setDeleteButtonLoading(false);
            // console.log(err);
            Swal.fire({
              title: t('error', {a: ' '}),
              text: t('oops', {a: ' '})
            })
        })
      }else {
        btnsStore.map(btn => {
          btn.removeAttribute("disabled");
        })
      }
    });
  }
  const reactivateStore = () => {
    fetch(`${process.env.REACT_APP_API_URL}/open-store/${props.userInfo.store.id}`, { 
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(res => res.json())
    .then(res => {
      // console.log(res)
        if (res.message === 'process done') {
            props.dispatch({
              type: SAVE_LOGIN_USER,
              data: res.findUser
            })
            setReload(true);
            alerta(t("reactivated-store", {a: ' '}));
        } else {
            setReactivateButtonLoading(false);
            Swal.fire({
                title: t("error", {a: ' '}),
                text: t("error-reactivating", {a: ' '})
            })
        }
    })
    .catch(err=>{
        setReactivateButtonLoading(false);
        // console.log(err);
        Swal.fire({
          title: t("error", {a: ' '}),
          text: t("error-reactivating", {a: ' '})
      })
    })
  }

  return (
    <div className="profile-container">
    <div className="wrapped-profile-container">
    <div className="container_card ">
      <div className="card-profile box">
        <div className="container-store-info">

          <Link to={{pathname: '/dashboard/tienda/apariencia', state: `portrait`}}>
            <div className="container-store-portrait-picture portrait-photo-user">
              <img src={props.userInfo.store.portraitPicture.url} alt="Foto de portada"/>
              <div className="camera-image-portrait"><CameraFilled twoToneColor="#eee" /></div>
            </div>
          </Link>

          <Link to={{pathname: '/dashboard/tienda/apariencia', state: `profile`}}>
            <div className="container-store-profile-picture">
              <div className="camera-image-profile"><CameraFilled twoToneColor="#eee" /></div>
              <img src={props.userInfo.store.profilePicture.url} alt="Foto de perfil"/>
            </div>
          </Link>
        </div>

        <div className="main-container-profile-user store">
          <div className="main-container-profile-user-data store"> 

            <div className="main-container-profile-user-direction store-name">
              <div>
                <h1 className="blue-text user-name-profile">{props.userInfo.store.storeName}</h1>
                <Rate clasName="rate-store" allowHalf disabled defaultValue={props.userInfo.store.totalReputation} />
              </div>
            </div>

            <div className="main-container-profile-user-direction store">
              <div>
                <p className='container-store-description-category user-direccion'><FileProtectOutlined />{t('description', {a: ' '})}: <span>{props.userInfo.store.description}</span></p>
                <p className='container-store-description-category user-contact'><PhoneOutlined />{t('phone', {a: ' '})}: <span>{props.userInfo.store.mainPhoneNumber} {props.userInfo.store.secondaryPhoneNumber !== null && '/ '+props.userInfo.store.secondaryPhoneNumber}</span></p>
                <p className='container-store-description-category user-contact'><CheckCircleOutlined />{t('categories', {a: ' '})}: <span>{props.userInfo.store.mainCategory.map(c => props.language === 'CA' || props.language === 'ca-ES' ? c.cat : c.esp).join(' / ')}</span></p>
                <p className='container-store-description-category user-contact'><EuroCircleOutlined />{t('payment-method', {a: ' '})}: <span>{props.userInfo.store.paymentMethods.map(m => props.language === 'CA' || props.language === 'ca-ES' ? m.cat : m.esp).join(' / ')}</span></p>
                <p className='container-store-description-category user-contact'><CarOutlined />{t('delivery', {a: ' '})}: <span>{ props.userInfo.store.deliveryTypeId[0] ===1 ? t('yes', {a: ' '}):t('no', {a: ' '})}</span></p>
                {
                  props.userInfo.store.deliveryTypeId[0] ===1 &&
                  <p className='container-store-description-category user-contact'><EuroCircleOutlined />{t('minimum-delivery', {a: ' '})}: <span>{props.userInfo.store.minimumDelivery ===null ? 'No definido' : `${props.userInfo.store.minimumDelivery} €`}</span></p>
                }
              </div>
              <Link to="/dashboard/tienda/editar-tienda">
                <Button disabled={props.userInfo.store.blockedByAdmin} type='primary' className='center-block round-btn btn-second btn-editStore'>{t('edit-store', {a: ' '})}</Button>
              </Link> 
            </div>

            <div className="main-container-profile-user-direction store">
              <div>
                <p className='container-store-description-category user-direccion'><FieldTimeOutlined />{t('schedule', {a: ' '})}: {props.userInfo.store.schedules.length > 0 
                && props.userInfo.store.schedules[0].openingDay !== null && props.userInfo.store.schedules[0].closingDay !== null && props.userInfo.store.schedules[0].openingTime !== null && props.userInfo.store.schedules[0].closingTime !== null ?
                <span>
                    {t("schedule-result", {
                        x: props.userInfo.store.schedules[0].openingDay,
                        y: props.userInfo.store.schedules[0].closingDay,
                        z: props.userInfo.store.schedules[0].openingTime,
                        v: props.userInfo.store.schedules[0].closingTime,
                        a: ' '
                    })}
                </span> 
                :<span>{t('no-schedule', {a: ' '})}</span>}  </p>
                {props.userInfo.store.schedules.length > 0 && <p className='container-store-description-category user-direccion'><FieldTimeOutlined />{t("special-schedule", {a: ' '})} : <span>{ props.userInfo.store.schedules[0].scheduleExtended === null ? 'No definido' : `${props.userInfo.store.schedules[0].scheduleExtended}` }</span></p>}
              </div>
              <Link to="/dashboard/tienda/editar-horario">
                <Button type='primary' disabled={props.userInfo.store.blockedByAdmin} className='center-block round-btn btn-second btn-editStore'>{t('edit-schedule', {a: ' '})}</Button>
              </Link>
            </div>

            <div className="main-container-profile-user-direction store">
              <div>
                <p className='container-store-description-category user-direccion'><EnvironmentOutlined />{t('address', {a: ' '})}: <span>{direction}</span></p>
              </div>
              <Link to="/dashboard/tienda/crear-direccion">
                <Button type='primary' disabled={props.userInfo.store.blockedByAdmin} className='center-block round-btn btn-second btn-editStore'>{t('edit-address', {a: ' '})}</Button>
              </Link>
            </div>
            {props.userInfo.store.blocked && 
            <div className="main-container-profile-user-direction store">
              <Button type='primary' disabled={props.userInfo.store.blockedByAdmin} danger className='center-block round-btn' onClick={deleteStore} loading={deleteButtonLoading}>{t('delete-store', {a: ' '})}</Button>
              <Button type='primary' disabled={props.userInfo.store.blockedByAdmin} className='center-block round-btn btn-first' onClick={reactivateStore} loading={reactivateButtonLoading}>{t("reactivate-store", {a: ' '})}</Button>
            </div>
            }
            {
              props.userInfo.store.blockedByAdmin &&
              <p className='message-bloqued'><span>No puedes realizar ninguna acción. Tu tienda ha sido bloqueada por la administración de propT1.</span></p>
            }
          {stripeUrl && 
              <div className="main-container-profile-user-direction store">
                <Link to={stripeUrl}>
                  <Button type='primary' className='center-block round-btn btn-second btn-editStore'>{t('go-stripe', {a: ' '})}</Button>
                </Link>
              </div>
              }
          </div>
        </div>

      </div>
    </div>
    </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps)(ProfileStore)) 
