import React from 'react'
import ProductBox from '../../Molecules/ProductBox/ProductBox';
import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import notProduct from '../../../assets/P1-Tienda-sin-articulos-imagen.png';
import notSearch from '../../../assets/P1-Busqueda-no-encontrada.png';
import { connect } from 'react-redux';
import logoMovil from '../../../assets/logo.png'

function ProductCatalog(props) {
  const { t } = useTranslation();
    // Validar que hayan productos DISPONIBLES
    const validProduct = (array, message) => {
      let result = false
      array &&
      array.forEach(p=> {
        if(p.status === message) {
          result = true
        }
      })
      return result
    }
  let verifyDisponible = validProduct(props.productList, "Disponible")
    return (
        <>
            {props.productList.length > 0 ? 
            <>
                <div className="product-container">
                {props.productList.map(product => {
                    return (
                      product.status === "Disponible" &&
                        <ProductBox 
                          key={product.id} 
                          id={product.id} 
                          name={product.name} 
                          slug={product.slug} 
                          description={product.description} 
                          price={product.price}
                          unit={props.language === 'CA' || props.language === 'ca-ES' ? product.unit.cat : product.unit.esp}
                          img={product.productImage.length > 0 ? product.productImage[0].url : 'https://res.cloudinary.com/propt1/image/upload/v1608131263/default/logo_x4qbw4_vx0ugc.png'}
                          unitValue={product.unitValue} 
                          storeId={product.storeId}
                          sellerId={product.store && product.store.userId}
                          productData={product}
                          storeData={product.store}
                          storeDataInfo={props.storeDataInfo}
                          search={props.search}
                        />
                      
                    )
                })}
                </div>
                {!verifyDisponible &&
                    <div className="box main-empty-store-product">
                      <div className="main-empty">
                        <h2 className="title-main-empty-product">{t('no-products', {a: ' '})}</h2>
                        <img src={notProduct} alt={t('no-products', {a: ' '})}/>
                      </div> 
                  </div> 
                }
                {props.productList.length < props.totalProduct && <Button onClick={props.getMoreProducts} type='primary' className='round-button btn-first pagination-button'>{t('load-more', {a: ' '})}</Button>}
            </>
            : 
            props.sellerStore ? 
            <div className="box main-empty-store-product">
              <div className="main-empty">
                <h2 className="title-main-empty-product">{t('no-products', {a: ' '})}</h2>
                <img src={notProduct} alt={t('no-products', {a: ' '})}/>
              </div> 
            </div> 
            :
            <div className="box main-empty-store-product">
              <div className="main-empty">
                <h2 className="title-main-empty-product">{t('not-found-products', {a: ' '})}</h2>
                <img src={notSearch} alt={t('not-found-products', {a: ' '})}/>
              </div>
            </div>}
        </>
    )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default connect(mapStateToProps)(ProductCatalog)
