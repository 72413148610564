import React, {useState,useEffect} from 'react'
import './OrderDetail.scss'
import {withRouter, /*Link*/} from 'react-router-dom'
import Loader from '../../Atoms/Loader/Loader'
import EmptyCart from '../../Molecules/EmptyCart/EmptyCart';
import moment from 'moment';
import 'moment/locale/es';
import { Button } from 'antd';
import { connect } from 'react-redux';
import {useTranslation} from 'react-i18next';
import logoMovil from '../../../assets/logo.png'

const OrderDetail = (props) => {
  const { t } = useTranslation();
  const [orderItem, setOrderItem] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [orderNotFound, setOrderNotFound] = useState(false)

  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/order/${props.match.params.id}`, {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    .then(resp => resp.json())
    .then(resp => {
      // console.log(resp)
      if(resp.message === 'order found') {
        setOrderItem(resp.findOrder)
        setIsLoading(false);
        setOrderNotFound(false)
      }else if (resp.message === 'order not found'){
        // console.log('Orden no encontrada')
        setOrderNotFound(true)
        setIsLoading(false);
      } 
      else {
        // console.log('Orden no encontrada')
        setIsLoading(false);
      }
    })
  }, [props.match.params.id])

  const goToDescriptionSales = (id) => {
    props.history.push(`/dashboard/tienda/ventas-activas/ord-${id}`)
  }
  const goToDescriptionMyOrder = (id) => {
    props.history.push(`/dashboard/perfil/compras-activas/ord-${id}`)
  }
  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
          {
            isLoading ? <Loader /> :
            <div className="order-detail-container box">
              <>
                {
                  orderNotFound ? <EmptyCart message="El pedido no existe o se eliminó"/> :
                  <>
                  <div className="order-details-container">
                    <>
                      <div className="order-details-header">
                        <h2>{t("purchase", {a: ' '})} #ord_{orderItem.id} - {moment(orderItem.createdAt).format('L')}</h2>
                      </div>
                      <div className="order-details-summary">
                        {/* <div className="order-details-summary-header">
                          <p>Compraste</p>
                          
                          <p>El vendedor dijo que ya lo tienes</p>
                        </div> */}
                        <div className="order-details-summary-body">
                          {
                            orderItem.orderItem.map(od => (
                              <div className="order-details-summary-order-item-noti" key={od.id}>
                                <div>
                                  <img className="order-details-summary-img" src={od.product.productImage.length > 0 ? od.product.productImage[0].url : 'https://res.cloudinary.com/propt1/image/upload/v1608131263/default/logo_x4qbw4_vx0ugc.png'} alt="Foto del pedido"/>
                                </div>
                                <div className="order-details-summary-content">
                                  <p className="order-details-summary-status" >{t('status', {a: ' '})}: {orderItem.status}</p>
                                  <p>{t('product-name', {a: ' '})}: <span className="order-details-summary-name">{od.product.name}</span> </p>
                                  <p>{t('product-description', {a: ' '})}: <span className="order-details-summary-description">{od.product.description}</span> </p>
                                  <p className="order-details-summary-price">{`${t('total2', {a: ' '})} ${orderItem.totalPrice}€`}</p>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className="order-details-summary-footer">
                        {
                          props.userInfo.person.userId === orderItem.sellerId &&
                          <Button className="round-btn btn-first" onClick={() => goToDescriptionSales(orderItem.id)} htmlType='submit' type="primary">{t("order-detail", {a: ' '})}</Button> 
                        }
                        {
                          props.userInfo.person.userId === orderItem.personId &&
                          <Button className="round-btn btn-first" onClick={() => goToDescriptionMyOrder(orderItem.id)} htmlType='submit' type="primary">{t("order-detail", {a: ' '})}</Button>
                        }
                      </div>
  
                    </>
                  </div>
                  </>
                }
              </>
            </div>
          }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps,{})(OrderDetail))
