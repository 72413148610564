import React, {useEffect} from 'react'
import './IconNotification.scss';
import { Badge } from 'antd';
import { getNotifications } from '../../../redux/actionCreators';
import store from '../../../redux/store';
import { connect } from 'react-redux';
import { 
  BellOutlined, 
  CaretDownOutlined, 
} from '@ant-design/icons';

const IconNotification = ({userInfo, userNotification, showDrawerNotification}) => {
  
  useEffect(() => {
    store.dispatch(getNotifications(userInfo.person.userId))
  }, [userInfo.person.userId])

  return (
    <Badge className="count-notification" count={userNotification.length > 9 ? `${9}+` : userNotification.length} gap={1}>
      <div className="main-header-p2-item" onClick={showDrawerNotification}>
        <BellOutlined className="main-header-bell" />
        <CaretDownOutlined className="main-header-down"/>
      </div>
    </Badge>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  userNotification: state.notificationsReducer.userNotifications
})
export default connect(mapStateToProps, {})(IconNotification)
