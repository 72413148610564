import React, {useState, useEffect, useRef} from 'react'
import SellerCatalog from '../../Organisms/SellerCatalog/SellerCatalog';
import {withRouter} from 'react-router-dom';
import {Button, Select, Modal, Form, Checkbox} from 'antd';
import './home.scss';
import CategoryFilter from '../../Organisms/CategoryFilter/CategoryFilter';
import Category from '../../Atoms/Category/Category';
import Loader from '../../Atoms/Loader/Loader';
import Logo from '../../Atoms/Logo/Logo';
import { connect } from 'react-redux';
import { EnvironmentFilled } from '@ant-design/icons';
import Swal from 'sweetalert2';
import {useTranslation} from 'react-i18next';
import { SAVE_SEARCH } from '../../../redux/actions';

const Home = props => { 
  const { t } = useTranslation(); 
  const [sellerList, setSellerList] = useState([])
  // const [categories, setCategories] = useState([]);
  const [mainCategories, setMainCategories] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);

  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  //const [userLoc, setUserLocation] = useState({lat: 10.495492, lng: -66.877549})
  const [userLoc, setUserLocation] = useState({lat: 41.2318958, lng: 1.8016172})
  const formRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [showReminder, setShowReminder] = useState(true);

  const getSellers = () => {
    setIsLoading2(true);
    setOffset(0);
    setLimit(10);
    if (!isLoading && formRef && formRef.current !== null) {
      formRef.current.setFieldsValue({
        radius: props.match.params.radius,
        categories: props.match.params.categories.split('-').map(idStr=>Number(idStr))
      });
    }

    if (props.userLocation.lat) {
        return getLocations({
          lat: props.userLocation.lat,
          lng: props.userLocation.lng
        });
    }
    if (navigator.geolocation) {
      let pos;
      navigator.geolocation.getCurrentPosition(position=>{
      pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
      };
      getLocations(pos);
      setUserLocation(pos);
      }, () => {
        getLocations(userLoc);
        showReminder && Swal.fire({
          icon: 'warning',
          title: t("map-recordatorio", {a: ' '}),
          text: t("map-recordatorio-msg", {a: ' '})
        })
        setShowReminder(false)
    
      })
    } else {
        getLocations(userLoc);
        window.alert(t("map-no-geoloc", {a: ' '}))
    }
  }
  const getLocations = userLocation => {

    fetch(`${process.env.REACT_APP_API_URL}/stores-by-categories/${limit}/${offset}`, {
      method: 'POST',
      headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
      body: JSON.stringify({
          "categories": props.match.params.categories.split('-').map(catStr=>Number(catStr)),
          "radius": props.match.params.radius,
          "userLocation": userLocation?userLocation:userLoc,
          "pagination": false
      })
    })
    .then(res => res.json())
    .then(res => {
    
      if (res.message === 'stores found') {
        setIsLoading(false);
        setIsLoading2(false);
        //setSellerList(sellerList.concat(res.filteredStoresByRadius));
        setSellerList(res.filteredStoresByRadius);
        // console.log('res.filteredStoresByRadius', res.filteredStoresByRadius)
        //setOffset(res.queryOffset);
        //setLimit(res.currentLimit);
      } else {
        //showerror
      }  
    })
  }
  const fetchOnMount = () => {
    fetch(`${process.env.REACT_APP_API_URL}/main-categories/${props.language === 'CA' || props.language === 'ca-ES' ? 0 : 1}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(resp => resp.json())
    .then(resp => {
        const mainCategories = [];
        const checkboxDefaultOptions = [];
        let checkboxDefaultValues = [];
        resp.getMainCategories.forEach(cat=>{
          mainCategories.push(<Category searchByCarrousel={()=>searchByCarrousel(cat.id)} catIcon={cat.iconUrl} catName={cat.name} key={cat.name} id={cat.id}/>)
          checkboxDefaultOptions.push({label: cat.name, value: cat.id});
          !props.match.params.categories && checkboxDefaultValues.push(cat.id);
        })
        setCheckboxOptions(checkboxDefaultOptions);
        setMainCategories(resp.getMainCategories);
        // setCategories(mainCategories);
    })
  }
  const searchCategories = e => {
    setShowModal(false);
    props.dispatch({
        type: SAVE_SEARCH,
        data: {radius: e.radius, cats: e.categories}
    })
    props.history.push(`/tiendas/lista/categorias/${e.categories.join('-')}/radio/${e.radius}`);
    /*if (props.match.params.lat) {
      props.history.push(`/tiendas/lista/categorias/${e.categories.join('-')}/radio/${e.radius}/lat/${props.match.params.lat}/lng/${props.match.params.lng}`);
    } else {
      props.history.push(`/tiendas/lista/categorias/${e.categories.join('-')}/radio/${e.radius}`);
    }*/
    
  }
  const searchByCarrousel = id => {
    props.dispatch({
        type: SAVE_SEARCH,
        data: {radius: props.match.params.radius, cats: [id]}
    })
    props.history.push(`/tiendas/lista/categorias/${id}/radio/${props.match.params.radius}`);
    /*if (props.match.params.lat) {
      props.history.push(`/tiendas/lista/categorias/${id}/radio/${props.match.params.radius}/lat/${props.match.params.lat}/lng/${props.match.params.lng}`);
    } else {
      props.history.push(`/tiendas/lista/categorias/${id}/radio/${props.match.params.radius}`);
    }*/
  }
  const viewMapResults = () => {
    props.history.push(`/tiendas/mapa/categorias/${props.match.params.categories}/radio/${props.match.params.radius}`)
    /*if (props.match.params.lat) {
      props.history.push(`/tiendas/mapa/categorias/${props.match.params.categories}/radio/${props.match.params.radius}/lat/${props.match.params.lat}/lng/${props.match.params.lng}`)
    } else {
        props.history.push(`/tiendas/mapa/categorias/${props.match.params.categories}/radio/${props.match.params.radius}`)
    }*/
  }

  
  useEffect(fetchOnMount, []);
  useEffect(getSellers, [props.match.params.categories, props.match.params.radius, mainCategories]);
  useEffect(()=>{
    document.body.scrollIntoView({block:'start'})
    if (props.search.radius) {
     
      props.history.push(`/tiendas/lista/categorias/${props.search.cats.join('-')}/radio/${props.search.radius}`)
    }
  }, [])

  return (
    <>
      {isLoading? <Loader /> : 
      <>
        {/* <CategoryFilter categories={categories} /> */}
        <CategoryFilter />
        <div className='home-map-settings-container'>
            <div className='home-map-settings'>
              <Modal visible={showModal} footer={null} closable={false}>
                  <Form ref={formRef} id='map-form' className="map-select-categories-form" name='map-select-categories-form' onFinish={searchCategories} initialValues={{radius: props.match.params.radius, categories: props.match.params.categories.split('-').map(idStr=>Number(idStr))}}>
                      <Logo />
                      <h4 className='center-text title-form'>{t("map-search-title", {a: ' '})}</h4>
                      <h6 className='center-text'>{t("map-search-subtitle", {a: ' '})}</h6>
                      <Form.Item className='checkbox-block' name='categories' rules={[{required: true, message: t("map-cat-valid", {a: ' '})}]}>
                          <Checkbox.Group options={checkboxOptions} />
                      </Form.Item>
                      <Form.Item name='radius' label={t('radius', {a: ' '})}>
                          <Select>
                              <Select.Option value='1'>1km</Select.Option>
                              <Select.Option value='5'>5kms</Select.Option>
                              <Select.Option value='10'>10kms</Select.Option>
                              <Select.Option value='15'>15kms</Select.Option>
                              <Select.Option value='20'>20kms</Select.Option>
                              <Select.Option value='30'>30kms</Select.Option>
                              <Select.Option value='40'>40kms</Select.Option>
                              <Select.Option value='50'>50kms</Select.Option> 
                          </Select>
                      </Form.Item>
                      <div className='map-form-center-buttons'>
                          <Button htmlType='submit' type='primary' className='round-button btn-first center-block'>{t('search', {a: ' '})}</Button>
                          <Button danger className='round-button' onClick={()=>setShowModal(false)}>{t('close', {a: ' '})}</Button>
                      </div>
                  </Form>   
              </Modal>
              <div className="container-btn-home">
                <Button onClick={()=>setShowModal(true)} type='primary' className='round-button btn-first btn-home-settings'>{t("ajustes", {a: ' '})}</Button>
                <Button onClick={viewMapResults} type='primary' icon={<EnvironmentFilled/>} className='round-button btn-first btn-map btn-home-go-to-map'>{t("view-map-results", {a: ' '})}</Button> 
              </div>
            </div>
        </div>
        {
          props.match.params.categoryName &&
          <>
            <h6 className='home-title'>{t("search-results", {a: ' '})}</h6>
            <h3 className='cat-title'>{props.match.params.categoryName.charAt(0).toUpperCase()+props.match.params.categoryName.slice(1)}</h3>
          </>
        }
        {isLoading2? <Loader /> : <SellerCatalog lat={props.match.params.lat} sellerList={sellerList} favorite={false} getMoreStores={getLocations}/>}
      </>}
    </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  userLocation: state.locationReducer.userLocation,
  search: state.searchReducer.search,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps)(Home))
