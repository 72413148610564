import React from 'react';
import {Button} from 'antd';
// import {MailOutlined} from '@ant-design/icons';
import Logo from '../../Atoms/Logo/Logo';
import './PricingTable.scss';
// import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import planImage from '../../../assets/plan-image.svg';

const PricingTable = props => {
  const { t } = useTranslation();
  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
    <div className="box container-PricingTable">
      <Logo />
      <div className="center-text mb-3">
        <h1 className="title-form">{t("plan-title", {a: ' '})}</h1>
        <h3 className='subtitle-plan'>{t("plan-subtitle", {a: ' '})}</h3>
      </div>
      <div className="row mb-5 justify-content-center">
        <div className="col-lg-4 main-card-body">
          <div className="card-PricingTable">
            <div className="card-body card-body-pricing-PricingTable ">
              <div className="pt-2 pb-2 pl-2 pr-2 text-center flex-grow-1 card-body-content">
                <img src={planImage} alt="plan" className='plan-image'/>
                {/* <MailOutlined className='plan-icon' /> */}
                {/* <div className="font-weight-bold plan-heading">{t("plan-month", {a: ' '})}</div>
                <div className="font-weight-bold mb-3 plan-price">
                  {t("plan-price", {a: ' '})}
                  <span> ({t("impostos-message", {a: ' '})})</span>
                </div> */}
                {/* {!props.hideButtons && false && <Link to='/dashboard/adquirir-membresia/1'><Button type='primary' className='round-button btn-first'>{t("select", {a: ' '})}</Button></Link>} */}
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div className='main-list-contribution'>
        <ul className='list-contribution'>
          <li>{t("plan-option-1", {a: ' '})}</li>
          {/* <li>{t("plan-option-2", {a: ' '})}</li> */}
        </ul>
        <h3 className='subtitle-plan list'>{t("plan-option-message", {a: ' '})}</h3>  
        <ul className='list-contribution'>
          <li>{t("plan-option-3", {a: ' '})}</li>
          <li>{t("plan-option-4", {a: ' '})}</li>
          <li>{t("plan-option-5", {a: ' '})}</li>
        </ul>
      </div>
      {props.hideButtons && <Button type='primary' size='large' className='round-btn back-btn btn-close-pricing' onClick={props.closeModal}>
         {t('close', {a: ' '})}
      </Button>}
    </div>
    </div>
    </div>
  )
}

export default PricingTable
