import Swal from 'sweetalert2';

let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })     
const alerta = (text) => (
      Toast.fire({
        icon: 'success',
        title: text
      })
)

export default alerta
