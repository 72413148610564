import React from 'react'
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import OrderItem from '../OrderItem/OrderItem';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { connect } from 'react-redux';
import { getOrders } from '../../../redux/actionCreators';
import store from '../../../redux/store';
import {useTranslation, Trans} from 'react-i18next';

const OrderCard = ({userInfo, setReload, order, setAllOrder}) => {
  const { t } = useTranslation();
  // Eliminar orden
  const handleDeleteOrder = (id, name, sellerId) => {
    Swal.fire({
      title: `${t("confirm-cancel-order", {a: ' '})} ${name}?`,
      text: t("cancel-warning", {a: ' '}),
      icon: "warning",
      showCancelButton :true,
      confirmButtonText: t("confirm-cancel", {a: ' '}),
      cancelButtonText: t('keep', {a: ' '})
    })
    .then( async (result) => {
      // console.log(result)
      if (result.isConfirmed) {
        let url = `${process.env.REACT_APP_API_URL}/cancel-order/${id}/${sellerId}`;
        const response = await fetch(url, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({ 
              "status": "Cancelado",
              "personId": userInfo.person.userId,
              "buyerName": userInfo.person.firstName,
              "esp": localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
          })
        })
        if(response.status === 200) {
          // console.log(response)
          setReload(true)
          store.dispatch(getOrders(userInfo.person.userId)) //Cuando agrego al Carrito me actualiza el store
          Swal.fire(t("cancelled-order", {a: ' '}), {
            icon: "success",
          });
        }
      } else {
        Swal.fire(t("cancelled-action", {a: ' '}));
      }
    });
  }

  // Sumar el total 
  const getTotal = orderArr => {
    let total=0;
    orderArr.forEach(product=>{
      total += product.quantity * product.product.price
    })
    return total;
  }

  return (
    order.status === 'En carrito' && //Me muestra solo las ordenes en carrito
    <div className="card-product card-product-in-cart" key={order.id}>
      <div className="cart-seller-info">
        {/* <Link to={`/vendedor/${order.orderItem[0].product.store.userId}/${order.orderItem[0].product.store.storeName.trim().toLowerCase().replace(/\s+/g, '-')}`}> */}
        <Link to={`/vendedor/${order.orderItem[0].product.store.userId}`}>
          <h1 className="cart-seller-info--name">{order.orderItem[0].product.store.storeName}</h1>
        </Link>
        {/* <p className="cart-seller-info--location">Tarragona, Cataluña</p> */}
      </div>
      {
        order.orderItem.map(orderItem => (
          <OrderItem key={orderItem.id}
            orderItem={orderItem}
            setReload={setReload}
            setAllOrder={setAllOrder}
          />
        ))
      }
      <div><h4>{`${t("total2", {a: ' '})}: ${getTotal(order.orderItem)} €`}</h4></div>
      <div className="container-btn-cart">
        <Button className="round-btn " type="primary" danger onClick={() => handleDeleteOrder(order.id, order.orderItem[0].product.store.storeName, order.orderItem[0].product.store.userId)}>
         {t("cancel-order-2", {a: ' '})}
        </Button>
        <Link to={`/confirmar-orden/${order.id}`}>
          <Button className="round-btn btn-first" type="primary">{t("make-order", {a: ' '})}</Button>
        </Link>
      </div>
    </div> 
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps,{})(OrderCard)
