import React from 'react';
import './PageFooter.scss'
import {ControlFilled, 
        StarFilled, 
        PlusCircleFilled, 
        // ShoppingCartOutlined, 
        // UserOutlined, 
        ShopOutlined,
        HomeFilled,
        EnvironmentFilled } from '@ant-design/icons';
// import { Badge } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IconCart from '../../Atoms/IconCart/IconCart';
// import InstallPWA from '../../Atoms/InstallPWA/InstallPWA';
import {useTranslation} from 'react-i18next';


const PageFooter = (props) => {
    const { t } = useTranslation();
    return (
      !/verify\/[\S]+\/[\S]+@[\S]+\.[\S]+/.test(props.location.pathname) &&
      !/password\/[\S]+\/[\S]+@[\S]+\.[\S]+/.test(props.location.pathname) &&
      !/tiendas\/mapa\/[\S]+\/[\S]+/.test(props.location.pathname) &&
      !/tienda\/mapa\/[\S]+/.test(props.location.pathname) &&
      //!/tiendas\/lista\/categorias\/[\S]+\/radio\/[\S]+/.test(props.location.pathname) &&
      props.location.pathname !== '/editar-tienda/mapa' &&
      props.location.pathname !== '/verificar-email' &&
      props.location.pathname !== '/tiendas/mapa' &&
      props.location.pathname !== '/registro/vender/ubicar-tienda' &&
      props.location.pathname !== '/' && 
      props.location.pathname !== '/login' && 
      props.location.pathname !== '/registro' &&
      props.location.pathname !== '/recuperar-contraseña' &&  
      props.location.pathname !== '/terminos-y-condiciones' && 
      props.location.pathname !== '/registro/verificar-email' &&
      props.location.pathname !== '/dashboard/tienda/crear-tienda' &&
      props.location.pathname !== '/dashboard/tienda/crear-direccion' &&
      props.location.pathname !== '/dashboard/perfil/editar-perfil' &&
      props.location.pathname !== '/dashboard/tienda/editar-tienda' &&
      props.location.pathname !== '/dashboard/perfil/direccion-de-envio' &&
      props.location.pathname !== '/dashboard/tienda/crear-producto' &&
      props.location.pathname !== '/dashboard/tienda/nuevo-producto' &&
      !props.location.pathname.includes('/dashboard/tienda/editar-producto/') &&
      !props.location.pathname.includes('/dashboard/tienda/ventas-activas/ord-') &&
      !props.location.pathname.includes('/confirmar-orden') &&
      !props.location.pathname.includes('/apariencia') &&
    
      <>
      <div className='margin-before'>
        <footer className="main-footer">
          {props.location.pathname === '/mapa'
            ?<div className="main-footer--item">
              <ControlFilled twoToneColor="#161B4B"/>
              <div className="icon-title">{t("settings", {a: ' '})}</div>
            </div>
            :<Link to="/tiendas/mapa">
              <div className="main-footer--item">
                <EnvironmentFilled twoToneColor="#161B4B"/>
                <div className="icon-title">{t("map", {a: ' '})}</div>
              </div>
            </Link>
          }
          {
            props.userInfo.userRoleId === 1 &&
            <Link to="/dashboard/tienda/crear-tienda">
              <div className="main-footer--item">
                <ShopOutlined twoToneColor="#161B4B"/>
                <div className="icon-title">{t('create-store', {a: ' '})}</div>
              </div>
            </Link>
            
          }
          {
            props.userInfo.userRoleId === 2 &&
            <Link to="/dashboard/tienda/crear-producto">
              <div className="main-footer--item">
                <PlusCircleFilled twoToneColor="#161B4B"/>
                <div className="icon-title">{t('create-product', {a: ' '})}</div> 
              </div>
            </Link>
            
          }
          <Link to='/tiendas/lista/categorias/1-2-3-4-5-6/radio/30'>
            <div className="main-footer--item">
              <HomeFilled twoToneColor="#161B4B" />
              <div className="icon-title">{t("home", {a: ' '})}</div>   
            </div>
        </Link>

        <Link to="/dashboard/perfil/favoritos">
          <div className="main-footer--item">
            <StarFilled twoToneColor="#161B4B" /> 
            <div className="icon-title">{t("favorites", {a: ' '})}</div>
          </div>
        </Link>
        <Link to="/carrito">
          <IconCart />
        </Link>
      </footer>
      </div>
      </>
    )
}
const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps, {})(PageFooter));
