import React from 'react';
import { Button } from 'antd'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import {useTranslation} from 'react-i18next';

const OrderCardDescriptionHistoryPurchase = ({order , storeName , originalOrderId, totalPrice, history}) => {
  const { t } = useTranslation();
  const goToDescription = (buyerOrderHistoryId) => {
    history.push(`/dashboard/perfil/detalle/ord-${buyerOrderHistoryId}`)
  }

  // Sumar el total 
  // const getTotal = orderArr => {
  //   let total=0;
  //   orderArr.forEach(product=>{
  //     total += product.quantity * product.product.price
  //   })
  //   return total;
  // }
  return (
    <div className="card-product" key={originalOrderId}>
      <div className="cart-seller-info">
        <h1 className="cart-seller-info--name">{`${t("seller", {a: ' '})} ${storeName}`}</h1>
        <h6>{t("order-number", {a: ' ', x: originalOrderId})} {moment(order[0].createdAt).format('L')}</h6>
        <h4 className="cart-seller-info--status">{t("delivered-order", {a: ' '})}</h4>
        {/*<div><h6 className="cart-seller-info--totalPrice">Total de tu orden: <span>{`${totalPrice}`}</span></h6></div>*/}
        <Button className="round-btn btn-first"  onClick={() => goToDescription(order[0].buyerOrderHistoryId)} htmlType='submit' type="primary">{t("order-detail", {a: ' '})}</Button>
      </div>
    </div> 
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps,{})(OrderCardDescriptionHistoryPurchase))
