import React from 'react'
import {useTranslation} from 'react-i18next';

const OrderItemBuyer = ({orderSellerItem, orderSeller}) => {
  const { t } = useTranslation();
  return (
    <div className="cart-seller-product" key={orderSellerItem.productId}>
      <img className="cart-seller-img" src={orderSellerItem.productImageUrl} alt={orderSellerItem.productName}/>
      <div className="cart-seller-description">
      <div className="cart-seller-description-others">
        <div className="title-container">
          <h4 className="cart-seller-description-title">{`${t('order', {a: ' '})}: ${orderSellerItem.productName}`}</h4>
        </div>
        <p className="cart-seller-description-price">{`${t('price', {a: ' '})}: ${orderSellerItem.productPrice}€`}</p>
        <p className="cart-seller-description-quantity">{`${t('quantity', {a: ' '})}: ${orderSellerItem.quantity}`}</p>
      </div>
      </div>
    </div>
  )
}
export default OrderItemBuyer
