import React from 'react';
import './NewProductForm.scss'
import {/*validateNotEmpty,*/ getBase64} from '../../validation/validations';
import {Form, Input, Button, Alert, Upload, InputNumber, Select, Carousel, TreeSelect} from 'antd';
import {UploadOutlined, LeftOutlined, RightOutlined,EuroCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Logo from '../../Atoms/Logo/Logo';
import alerta from '../../../utilityFunctions/alerta';
// import StarRating from '../../Atoms/StarRating/StarRating';
import { withTranslation } from 'react-i18next';
import ReactSlick from 'react-slick';
import errorStore from '../../../utilityFunctions/noStore';
import Swal from 'sweetalert2';
import imageCompression from 'browser-image-compression';
class NewProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.myRef1 = React.createRef();
        this.myRef2 = React.createRef();
        this.myRef3 = React.createRef();
        this.state={
            loadings: [],
            /*categoryError: '',
            nameError: '',*/
            slug: '',
            /*descriptionError: '',
            quantityError: '',
            priceError: '',
            unitError: '',*/
            allowMorePhotos: true,
            //photosError: '',
            formError: '',
            productCategories: [],
            unitOptions: [],
            subCategories: [],
            mainCatOfSelect: this.props.location.state && this.props.location.state.mainCategoryId,
            previsualize: false,
            productInfo: {},
            productImages: [],
            units: [],
            selectedUnit: '',
            fileProductImages: []
        } 
        this.handlePrevisualize=this.handlePrevisualize.bind(this);
        this.handleUploadChange=this.handleUploadChange.bind(this);
        this.handleFetchs=this.handleFetchs.bind(this);
        this.createSlug=this.createSlug.bind(this);
        this.getMainCatOfSelect=this.getMainCatOfSelect.bind(this);
        this.showForm=this.showForm.bind(this);
        this.carousel=React.createRef();
        this.nextImg=this.nextImg.bind(this);
        this.prevImg=this.prevImg.bind(this);
        this.enterLoading=this.enterLoading.bind(this);
        this.createProduct=this.createProduct.bind(this);
        this.checkSize = this.checkSize.bind(this);
    }
    
    enterLoading(index) {
        const newLoadings = [...this.state.loadings];
        newLoadings[index] = true;
        this.setState({
          loadings: newLoadings
        });
    };
    createSlug(ev) {
        const slug=ev.target.value.trim().toLowerCase().replace(/\s+/g, '-');
        this.setState({
            slug: slug
        })
    };
    handlePrevisualize(ev) {
        this.setState({
            formError: '',
        })
            this.state.units.forEach(unitObj=>{
                if (unitObj.id === ev.unit) {
                    this.setState({
                        selectedUnit: unitObj.name
                    })
                }
            })
            Promise.all(ev.photos.fileList.map(file=>getBase64(file.originFileObj)))
            .then(res=>{
                this.setState({
                    previsualize: true,
                    productInfo: {
                        "name": ev.name.trim(),
                        "storeId": this.props.userInfo.store.id, 
                        "slug": ev.name.trim().toLowerCase().replace(/\s+/g, '-'),
                        "price": ev.price,
                        "description": ev.description,
                        "quantity": null,
                        "status": 'Disponible',
                        "unitId": ev.unit,
                        "subCategoryId": ev.category,
                        "mainCategoryId": this.state.mainCatOfSelect,
                        "unitValue": ev.quantity,
                    },
                    productImages: res,
                    fileProductImages: ev.photos.fileList
                })
            })  
    }
    createProduct() {
    const { t } = this.props;
      this.myRef2.current.setAttribute("disabled", "")
      this.myRef3.current.setAttribute("disabled", "")
        this.enterLoading(0);
        const compressImg = async img => {
            const options = {
                maxSizeMB: 0.5,
                useWebWorker: true,
                maxWidthOrHeight: 1080,
                fileType: "image/jpg",
                initialQuality: 0.5,
                maxIteration: 15
            }
            try {
                const compressedFile = await imageCompression(img, options);
                return compressedFile;
            } catch (error) {
                // console.log(error)
            }
        }
        Promise.all(this.state.fileProductImages.map(file=>compressImg(file.originFileObj)))
        .then(allRes=>{
            // console.log('allres', allRes);
            const formData = new FormData();
            for (var key in this.state.productInfo) {
                formData.append(key, this.state.productInfo[key])
            }
            allRes.forEach(file=>{
                formData.append('productImages', file)
            })
            fetch(`${process.env.REACT_APP_API_URL}/product`, { 
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                 }
            })
            .then(res => res.json())
            .then(res => {
                if (res.message==="Product created") {
                  this.props.history.push(`/producto/${res.createProduct.id}/${res.createProduct.slug}/1`)
                  alerta('Producto creado con éxito')
                  this.myRef2.current.removeAttribute("disabled")
                  this.myRef3.current.removeAttribute("disabled")
                } else if (res.message === 'not allowed') {
                  this.myRef2.current.removeAttribute("disabled")
                  this.myRef3.current.removeAttribute("disabled")
                    Swal.fire({
                        title: this.props.t('error', {a: ' '}),
                        text: this.props.t('photo-error', {a: ' '})
                    })
                } else {
                  this.myRef2.current.removeAttribute("disabled")
                  this.myRef3.current.removeAttribute("disabled")
                  throw new Error('No se creó el producto')
                } 
            })
            .catch(err=>{
                const newLoadings = [...this.state.loadings];
                newLoadings[0] = false;
                // console.log(err);
                this.setState({
                    formError: this.props.t('oops', {a: ' '}),
                    loadings: newLoadings
                })
            })
        })
        .catch(err=>{
            const newLoadings = [...this.state.loadings];
            newLoadings[0] = false;
            // console.log(err);
            // this.myRef2.current.removeAttribute("disabled");
            this.setState({
                formError: this.props.t('oops', {a: ' '}),
                loadings: newLoadings
            })
        })
    }
    createAnotherProduct() {
      const { t } = this.props;
      this.myRef1.current.setAttribute("disabled", "")
      this.myRef3.current.setAttribute("disabled", "")
        this.enterLoading(1);
        const compressImg = async img => {
            const options = {
                maxSizeMB: 0.5,
                useWebWorker: true,
                maxWidthOrHeight: 1080,
                fileType: "image/jpg",
                initialQuality: 0.5,
                maxIteration: 15
            }
            try {
                const compressedFile = await imageCompression(img, options);
                return compressedFile;
            } catch (error) {
                // console.log(error)
            }
        }
        Promise.all(this.state.fileProductImages.map(file=>compressImg(file.originFileObj)))
        .then(allRes=>{
            // console.log('allres', allRes);
            const formData = new FormData();
            for (var key in this.state.productInfo) {
                formData.append(key, this.state.productInfo[key])
            }
            allRes.forEach(file=>{
                formData.append('productImages', file)
            })
            fetch(`${process.env.REACT_APP_API_URL}/product`, { 
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
            })
            .then(res => res.json())
            .then(res => {
                if (res.message==="Product created") {
                    this.props.history.push('dashboard/tienda');
                    this.props.history.push({pathname: `/dashboard/tienda/nuevo-producto`, state: this.state.productInfo})
                    alerta('Producto creado con éxito')
                    this.myRef1.current.removeAttribute("disabled")
                    this.myRef3.current.removeAttribute("disabled")
                    } else if (res.message === 'not allowed') {
                      this.myRef1.current.removeAttribute("disabled")
                      this.myRef3.current.removeAttribute("disabled")
                    Swal.fire({
                        title: this.props.t('error', {a: ' '}),
                        text: this.props.t('photo-error', {a: ' '})
                    })
                } else {
                  this.myRef1.current.removeAttribute("disabled")
                  this.myRef3.current.removeAttribute("disabled")
                    throw new Error('No se creó el producto')
                } 
            })
            .catch(err=>{
                const newLoadings = [...this.state.loadings];
                newLoadings[1] = false;
                // console.log(err);
                this.setState({
                    formError: this.props.t('oops', {a: ' '}),
                    loadings: newLoadings
                })
            })
        })
        .catch(err=>{
            // console.log(err);
            const newLoadings = [...this.state.loadings];
            newLoadings[1] = false;
            this.setState({
                formError: this.props.t('oops', {a: ' '}),
                loadings: newLoadings
            })
        })  
    }
    handleUploadChange(ev) {
        if (ev.fileList.length > 5) {
            this.setState({ 
                allowMorePhotos: false 
            });
        } else {
            this.setState({ 
                allowMorePhotos: true 
            });
        }
    }

    handleFetchs() {
      const { t } = this.props;
      document.body.scrollIntoView({block:'start'})
        if (this.props.userInfo.store.blocked) {
            errorStore()
            this.props.history.push('/dashboard/tienda');
        } else {
            let productCategories = [];
            this.props.userInfo.store.mainCategory.forEach(obj=> productCategories.push({
                title: this.props.language === 'CA' || this.props.language === 'ca-ES' ? obj.cat : obj.esp,
                children: [],
                disabled: true,
                mainCategoryId: obj.id,
                value: this.props.language === 'CA' || this.props.language === 'ca-ES' ? obj.cat : obj.esp
            }))
            fetch(`${process.env.REACT_APP_API_URL}/sub-categories/${this.props.language === 'CA' || this.props.language === 'ca-ES' ? 0 : 1}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
              })
            .then(res => res.json())
            .then(res => {
                this.setState({
                    subCategories: res.getSubCategories
                })
                res.getSubCategories.forEach(subCatObj=>{
                    productCategories.forEach((mainCatObj, i) => {
                      // console.log('subCatObj', subCatObj)
                      // console.log('mainCatObj', mainCatObj)
                        if (mainCatObj.mainCategoryId === subCatObj.mainCategoryId) {
                          // console.log('subCatObj.name', subCatObj.name)
                            productCategories[i].children.push({
                                title: subCatObj.name,
                                value: subCatObj.id
                            })
                        }
                        // console.log('productCategories', productCategories)
                    })
                })
                this.setState({
                    productCategories: productCategories
                })
            })
            .catch(err=>{
                this.setState({
                    formError: this.props.t('oops', {a: ' '})
                })
            })
            const units=[]
            fetch(`${process.env.REACT_APP_API_URL}/units/${this.props.language === 'CA' || this.props.language === 'ca-ES' ? 0 : 1}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
              })
            .then(res=>res.json())
            .then(res=>{
                res.getUnits.forEach(obj=>{
                    units.push(<Select.Option key={obj.name} value={obj.id}>{obj.name}</Select.Option>)
                })
                this.setState({
                    unitOptions: units,
                    units: res.getUnits
                })
            })
            .catch(err=>{
                this.setState({
                    formError: this.props.t('oops', {a: ' '})
                })
            })
        }
    }
    
    componentDidMount() {
        this.handleFetchs()
    }
    getMainCatOfSelect(e) {
        let mainCatOfSelect;
        this.state.subCategories.forEach(subCatObj=>{
            if (subCatObj.id === e) {
                mainCatOfSelect=subCatObj.mainCategoryId
            }
        })
        this.setState({
            mainCatOfSelect: mainCatOfSelect
        })
    }
    showForm() {
        this.setState({
            previsualize: false
        })
    }
    nextImg() {
        this.carousel.next();
    }
    prevImg() {
        this.carousel.prev();
    }
    checkSize(files) {
        let exceeds = false;
        files.fileList.forEach(file=>{
            if (file.size / 1048576 > Number(process.env.REACT_APP_MAX_FILE)) {
                exceeds = true;
            }
        })
        if (exceeds) {
            return false
        } else {
            return true
        }
    }

    componentDidUpdate(prevProps) {
      if(prevProps.language !== this.props.language) this.handleFetchs();
    }
    render() {
        const { t } = this.props;
        return (
            <> 
                <Form name='new-product-form' onFinish={this.handlePrevisualize} className={this.state.previsualize ? 'hideItem' : 'showItem'} initialValues={this.props.location.state?{...this.props.location.state, 'category': this.props.location.state.subCategoryId, 'unit': this.props.location.state.unitId, 'quantity': this.props.location.state.unitValue}:{"description": ''}}>
                    <Logo />
                    <h1 className='center-text title-form'>{t("create-product", {a: ' '})}</h1>
                    <Form.Item name='name' label={t("nombre", {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: this.props.t('required', {a: ' '})}]}>
                        <Input placeholder={t("nombre", {a: ' '})} className='text-input' />
                    </Form.Item>
                    {/*this.state.nameError && <Alert message={this.state.nameError} type='error' className='error-msg' />*/}
                    <Form.Item name='category' label={t("category", {a: ' '})} hasFeedback rules={[{required: true, message: this.props.t('required', {a: ' '})}]}>
                        <TreeSelect size='large' placeholder={t("category", {a: ' '})} treeData={this.state.productCategories} treeDefaultExpandAll onChange={e=>this.getMainCatOfSelect(e)}/>
                    </Form.Item>
                    {/*this.state.categoryError && <Alert message={this.state.categoryError} type='error' className='error-msg' />*/}
                    <Form.Item name='description' label={this.props.t('description', {a: ' '})} hasFeedback rules={[{whitespace: true, required: false, message: this.props.t('not-empty', {a: ' '})}]}>
                        <Input.TextArea maxLength={200} placeholder={this.props.t('description', {a: ' '})}  className='text-input'/>
                    </Form.Item>
                    {/*this.state.descriptionError && <Alert message={this.state.descriptionError} type='error' className='error-msg' />*/}
                    <Form.Item name='unit' label={t("unit", {a: ' '})} hasFeedback rules={[{required: true, message: this.props.t('required', {a: ' '})}]}>
                        <Select placeholder={t("unit", {a: ' '})} size='large'>
                            {this.state.unitOptions}
                        </Select>
                    </Form.Item>
                    {/*this.state.unitError && <Alert message={this.state.unitError} type='error' className='error-msg' />*/}
                    <Form.Item name='quantity' label={t("quantity-unit", {a: ' '})} hasFeedback rules={[{validator:(_, value)=> value ? typeof value === 'number'? value<1?Promise.reject(t("minimum-1", {a: ' '})):Promise.resolve():Promise.reject(this.props.t('numeric', {a: ' '})): value===0?Promise.reject(t("minimum-1", {a: ' '})):Promise.reject(this.props.t('required', {a: ' '}))}, {required: true, message: ' '}]}>
                        <InputNumber placeholder={t("quantity-unit", {a: ' '})} className='text-input'/> 
                    </Form.Item>
                    {/*this.state.quantityError && <Alert message={this.state.quantityError} type='error' className='error-msg' />*/}
                    <Form.Item name='price' label={this.props.t('price', {a: ' '})} hasFeedback rules={[{validator:(_, value)=> value ? typeof value === 'number'? value<=0?Promise.reject(this.props.t('minimum-price', {a: ' '})):Promise.resolve():Promise.reject(this.props.t('numeric', {a: ' '})): value===0?Promise.reject(this.props.t('minimum-price', {a: ' '})):Promise.reject(this.props.t('required', {a: ' '}))}, {required: true, message: ' '}]}>
                        <InputNumber max={9999} formatter={value=>value !== '' ? `${value} €`:''} placeholder={this.props.t('price', {a: ' '})} className='text-input padding-fix' step={0.01} decimalSeparator=',' /> 
                    </Form.Item>
                    {/*this.state.priceError && <Alert message={this.state.priceError} type='error' className='error-msg' />*/}
                    <label>{t("product-pics", {a: ' '})}</label>
                    <Form.Item name='photos' rules={[{validator: (_,value)=>value?value.fileList.length>0?value.fileList.length>5?Promise.reject(this.props.t('max-photos', {a: ' '})):this.checkSize(value) ? Promise.resolve() : Promise.reject(this.props.t('photo-exceed', {a: ' '})):Promise.reject(this.props.t('add-1-photo', {a: ' '})):Promise.reject(this.props.t('add-1-photo', {a: ' '}))}/*{required: true, message: 'Debe agregar al menos 1 foto'}*/]}>
                        <Upload accept=".png, .jpg, .jpeg, image/jpeg, image/jpg, image/png, image/x-png" beforeUpload={()=>false} listType='picture' onChange={this.handleUploadChange} multiple>    
                            {this.state.allowMorePhotos? 
                                <Button>
                                    <UploadOutlined />
                                    {t("add-pic", {a: ' '})}
                                </Button> :
                                <p>{t("max-pics", {a: ' '})}</p>  
                            }
                        </Upload>   
                    </Form.Item>
                    {/*this.state.photosError && <Alert message={this.state.photosError} type='error' className='error-msg' />*/}
                    <Form.Item name='submit-btn'>
                        {!this.props.userInfo.store.blocked && <Button type='primary' htmlType='submit' className='btn btn-first mb-5'>
                        {t("previsualize", {a: ' '})}
                            {/*<ArrowRightOutlined/>*/}
                        </Button>}
                        <Button danger className='btn' onClick={() => this.props.history.push('/dashboard/tienda')}>
                              {/*<ArrowLeftOutlined />*/}
                              {this.props.t('cancel', {a: ' '})}
                        </Button> 
                    </Form.Item>
                    {this.state.formError && <Alert message={this.state.formError} type='error' className='error-msg' />}
                </Form>

                {this.state.previsualize &&
                <div className='showItem container-product'>
                    <div className='background-white box-product'>
                        <div className='product-info-container'>
                            <h3 className='blue-text center-text box-product-name-form'>{this.state.productInfo.name}</h3>
                            <p className='gray-text center-text box-product-description-form'>{this.state.productInfo.description}</p>
                            <p className='blue-text center-text box-product-storeName-form'>{this.props.userInfo.store.storeName}</p>
                            <p className='product-info-product-price-prev'><EuroCircleOutlined /> {this.state.productInfo.price}{this.props.t('price-each', {a: ' '})} {this.state.productInfo.unitValue} {this.state.selectedUnit.toLowerCase()}</p>
                            {/* <StarRating rating={this.props.userInfo.totalReputation}/> */}
                        </div>
                        <div className='img-carousel-form'>
                        <div className='img-carousel-form-2'>
                            <ReactSlick
                                {...{
                                dots: false,
                                infinite: false,
                                speed: 500,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                touchMove: false 
                                }}>
                                {this.state.productImages.map(imgStr=><img src={imgStr} alt={this.state.productInfo.name} className='carousel-product-img-form' key={imgStr} />)}  
                            </ReactSlick>
                        </div> 
                        </div> 
                    </div> 
                    {!this.props.userInfo.store.blocked && 
                        <> <Button type='primary' htmlType='submit' ref={this.myRef1} className='btn-first btn-block round-button mb-5 btn-createProduct' onClick={this.createProduct} loading={this.state.loadings[0]}>
                            {t("save-and-view", {a: ' '})}
                            {/*<ArrowRightOutlined/>*/}
                        </Button>
                        <Button ref={this.myRef2} className='btn-second round-button btn-block btn-createProduct mb-5' onClick={this.createAnotherProduct.bind(this)} loading={this.state.loadings[1]}>
                        {t("save-and-create", {a: ' '})}
                            {/*<ArrowRightOutlined/>*/}
                        </Button>
                        </>
                    }
                    <Button danger  ref={this.myRef3} className='btn btn-block' onClick={this.showForm}>
                        {/*<ArrowLeftOutlined />*/}
                        {this.props.t('cancel', {a: ' '})}
                    </Button>
                </div>}
            </>   
        )      
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.userReducer.userInfo,
    language: state.languageReducer.language
})

export default withRouter(connect(mapStateToProps)(withTranslation()(NewProductForm)))
