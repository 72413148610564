import React, {useState,useEffect} from 'react'
import './EditProduct.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// import MenuVertical from '../../Organisms/MenuVertical/MenuVertical'
import Logo from '../../Atoms/Logo/Logo'
import { getBase64 } from '../../validation/validations';
import {Form, Input, Button, InputNumber,Upload, Select, TreeSelect, Alert} from 'antd';
import Loader from '../../Atoms/Loader/Loader';
import alerta from '../../../utilityFunctions/alerta';
import {UploadOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import errorStore from '../../../utilityFunctions/noStore';
import imageCompression from 'browser-image-compression';

const EditProduct = (props) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])
  const [product, setProduct] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isLoading2, setIsLoading2] = useState(true)
  const [isLoading3, setIsLoading3] = useState(true)
  const [mainCatOfSelect, setMainCatOfSelect] = useState(null)
  const [unitOptions, setUnitOptions] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [productCategories, setProductCategories] = useState([])
  const [currentProduct, setCurrentProduct] = useState({})
  const [photosToDelete, setPhotosToDelete] = useState([]);
  /*const [categoryError, setCategoryError] = useState('');
  const [nameError, setNameError] = useState('');
  const [quantityError, setQuantityError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [unitError, setUnitError] = useState('');*/
  //const [photosError, setPhotosError] = useState('')
  const [formError, setFormError] = useState('');
  const [allowMorePhotos, setAllowMorePhotos] = useState(true);
  const [fileList, setFileList] = useState([])
  // const [allowMorePhotos, setAllowMorePhotos] = useState(true)
  let newPhotos = []

    // Funcion para remover duplicados
  // let removeDuplicate = (arr) => [...new Set(arr)]

  const handleUploadChange = ev => {
    // eslint-disable-next-line array-callback-return
    // ev.fileList.map(currentPhoto => {
    //   if(currentPhoto.url) { //valido si los objetos tiene la propiedad url, si no la tiene la foto es nueva y la meto en el arreglo
    //     return null
    //   } else {
    //     newTemporaryArray.push(currentPhoto)
    //     newPhotos = removeDuplicate(newTemporaryArray)
    //   }
    // })
    // newPhotos=newTemporaryArray
    // Validacion para no agregar mas de 5 fotos
    if (ev.fileList.length > 5) {
        setAllowMorePhotos(false)
    } else {
      setAllowMorePhotos(true)
    }
    // Arreglo que se envia con las nuevas fotos elegidas
    // newPhotos = removeDuplicate(newTemporaryArray)
    // console.log(newPhotos.length)
  }
  const props2 = {
    listType: 'picture',
    defaultFileList: [...fileList],
    className: 'upload-list-inline',
    onChange: handleUploadChange,
    multiple: true,
    beforeUpload: ()=>false
  }
  
  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
    let allProductCategories = [];
    props.userInfo.store.mainCategory.forEach(obj => allProductCategories.push({
      title: props.language === 'CA' || props.language === 'ca-ES' ? obj.cat : obj.esp,
      children: [],
      disabled: true,
      mainCategoryId: obj.id,
      value: props.language === 'CA' || props.language === 'ca-ES' ? obj.cat : obj.esp
    }))
    fetch(`${process.env.REACT_APP_API_URL}/sub-categories/${props.language === 'CA' || props.language === 'ca-ES' ? 0 : 1}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(res => res.json())
    .then(res => {
      setSubCategories(res.getSubCategories)
      res.getSubCategories.forEach(subCatObj => {
        allProductCategories.forEach((mainCatObj, i) => {
          if (mainCatObj.mainCategoryId === subCatObj.mainCategoryId) {
            allProductCategories[i].children.push({
              title: subCatObj.name,
              value: subCatObj.id
            })
          }
        })
      })
      setProductCategories(allProductCategories)
      setIsLoading2(false)
    })

    let pictureArray = []
    fetch(`${process.env.REACT_APP_API_URL}/product/${props.match.params.idProduct}/1`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(resp => resp.json())
    .then(resp => {
      setProduct(resp.product)
      console.log('resp.product', resp.product)
      resp.product.productImage.map(image => (
        pictureArray.push(
          {
            uid: image.id,
            url: image.url,
            thumbUrl: image.url,
          },
        )
      ))
      setFileList(pictureArray)


      setCurrentProduct({
        name: resp.product.name,
        description: resp.product.description,
        status: resp.product.status,
        price: resp.product.price,
        quantity: null,
        unitId: resp.product.unit.id,
        subCategoryId: resp.product.subCategoryId,
        mainCategoryId: resp.product.mainCategoryId,
        unitValue: resp.product.unitValue
      })
      setMainCatOfSelect(resp.product.mainCategoryId)
      setIsLoading(false)
    })


    const units=[]
    fetch(`${process.env.REACT_APP_API_URL}/units/${props.language === 'CA' || props.language === 'ca-ES' ? 0 : 1}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(res=>res.json())
    .then(res=>{
      res.getUnits.forEach(obj=>{
        units.push(<Select.Option key={obj.name} value={obj.id}>{obj.name}</Select.Option>)
      })
      setUnitOptions(units)
      setIsLoading3(false)
    })

  }, [props.match.params.idProduct, props.userInfo.store.mainCategory, setProduct, props.language])

  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  // Datos del producto
  const handleChange = (e) => {
    setCurrentProduct({
      ...currentProduct,
      [e.target.name] : e.target.value
    })
  }

  //Unit
  const handleChangeUnit = (e) => {
    setCurrentProduct({
      ...currentProduct,
      unitId : e
    })
  }

  // Cambiar precio
  const handleChangePrice = (e) => {
    setCurrentProduct({
      ...currentProduct,
      price : e
    })
  }

  // Cambiar UnitValue
  const handleChangeUnitValue = (e) => {
    setCurrentProduct({
      ...currentProduct,
      unitValue : e
    })
  }

  // Cambiar Status
  // const handleChangeStatus = (e) => {
  //   setCurrentProduct({
  //     ...currentProduct,
  //     status : e
  //   })
  // }

  //Cambiar Subcategoria
  const handleChangeSubCategory = (e) => {
    let MainCatOfSelect;
    subCategories.forEach(subCatObj => {
        if (subCatObj.id === e) {
          MainCatOfSelect=subCatObj.mainCategoryId
        }
    })
    setMainCatOfSelect(MainCatOfSelect)
    setCurrentProduct({
      ...currentProduct,
      subCategoryId : e
    })
  }

  // Enviar formulario
  const handleFormSubmit = (e) => {
    let photoError = false;
    // eslint-disable-next-line array-callback-return
    e.photos.fileList.map(currentPhoto => {
      if(currentPhoto.url) { //valido si los objetos tiene la propiedad url, si no la tiene la foto es nueva y la meto en el arreglo
        return null
      } else {
        newPhotos.push(currentPhoto)
        // newPhotos = removeDuplicate(newTemporaryArray)
      }
    })
    if(newPhotos.length > 0) {
      enterLoading(0);
      const compressImg = async img => {
        const options = {
          maxSizeMB: 0.5,
          useWebWorker: true,
          maxWidthOrHeight: 1080,
          fileType: "image/jpg",
          initialQuality: 0.5,
          maxIteration: 15
        }
        try {
            const compressedFile = await imageCompression(img, options);
            return compressedFile;
        } catch (error) {
          
        }
      }
      Promise.all(newPhotos.map(file=>compressImg(file.originFileObj)))
      .then(allRes => {
          const formData = new FormData();
          allRes.forEach(file=>{
            formData.append('productImages', file)
          })
          fetch(`${process.env.REACT_APP_API_URL}/product-image-files/${props.match.params.idProduct}/${currentProduct.name.trim().toLowerCase().replace(/\s+/g, '-')}`, { 
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: formData
          })
          .then(res => res.json())
          .then(res=> {
            if (res.message === 'not allowed') {
                photoError = true;
                Swal.fire({
                  title: t('error', {a: ' '}),
                  text: t('oops', {a: ' '})
                })
            } else if (res.message === "Image files uploaded") {
              photosToDelete.forEach(e=>{
                deletePicture(e)
              })
              editProduct(e)
            }
          }) 
      })
    } else {
      photosToDelete.forEach(e=>{
        deletePicture(e)
      })
      editProduct(e)
     
    }
  }
  const editProduct = (e) => {
    setFormError('')
    enterLoading(0);
    fetch(`${process.env.REACT_APP_API_URL}/product/${props.match.params.idProduct}`, { 
      method: 'PATCH',
      headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
      body: JSON.stringify({
        "name": currentProduct.name.trim(),
        "storeId": props.userInfo.store.id, 
        "slug": currentProduct.name.trim().toLowerCase().replace(/\s+/g, '-'),
        "price": currentProduct.price,
        "description": currentProduct.description.trim(),
        // "status": currentProduct.status,
        "quantity": null,
        "unitId": currentProduct.unitId,
        "subCategoryId": currentProduct.subCategoryId,
        "mainCategoryId": mainCatOfSelect,
        "unitValue": currentProduct.unitValue
      })  
    })
    .then(res => res.json())
    .then(res => {
        if(res.message === 'process done') {
          props.history.push('/dashboard/tienda/productos-activos')
          alerta(t('edited-product', {a: ' '}))
        } else{
          const newLoadings = [...loadings];
          newLoadings[0] = false; 
          setFormError(t('oops', {a: ' '}))
          setLoadings(newLoadings)
        }
    })
    .catch(err=>{
      const newLoadings = [...loadings];
      newLoadings[0] = false; 
      setFormError(t('oops', {a: ' '}))
      setLoadings(newLoadings)
    })
  }
  // Eliminar foto
  const deletePicture = (e) => {
    fetch(`${process.env.REACT_APP_API_URL}/product-image-file/${e.uid}`, { 
      method: 'DELETE',
      headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         }
    })
  }
  const checkSize = (files) => {
        let exceeds = false;
        files.fileList.forEach(file=>{
            if (file.size / 1048576 > Number(process.env.REACT_APP_MAX_FILE)) {
                exceeds = true;
            }
        })
        if (exceeds) {
            return false
        } else {
            return true
        }
    }
  useEffect(()=>{
    if (props.userInfo.store.blocked) {
        errorStore()
        props.history.push('/dashboard/tienda');
    }
  }, [])
  return (
    <div className="profile-container">
    <div className="wrapped-profile-container">
      {/* <MenuVertical/> */}
      {isLoading || isLoading2 || isLoading3? <Loader /> : 
      <Form name='new-product-form' onFinish={handleFormSubmit.bind(this)} className='form' initialValues={{
        "name": product.name,
        "category": product.subCategoryId,
        "price": product.price,
        "unitValue": product.unitValue,
        "unitId": product.unit.id,
        "description": product.description,
        "status" : product.status,
        "photos": {file: {}, fileList: [...fileList]}
      }}>
          <Logo />
          <h1 className='center-text title-form'>{t('edit-product', {a: ' '})}</h1>
          <Form.Item name='name' label={t('nombre', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
              <Input placeholder={t('name', {a: ' '})} name='name' className='text-input'  onChange={handleChange.bind(this)} /*defaultValue={product.name}*//>
          </Form.Item>
          <Form.Item name='category' label={t('categories', {a: ' '})} hasFeedback rules={[{required: true, message: t('required', {a: ' '})}]}>
            <TreeSelect name='subCategoryId' className='text-input input-edit-product-category' /*defaultValue={product.subCategoryId}*/ size='large' placeholder={t('categories', {a: ' '})} onChange={handleChangeSubCategory.bind(this)} treeData={productCategories} treeDefaultExpandAll />
          </Form.Item>
          <Form.Item name='description' label={t('description', {a: ' '})} hasFeedback rules={[{whitespace: true, required: false, message: t('not-empty', {a: ' '})}]}>
            <Input.TextArea name='description' placeholder={t('description', {a: ' '})} className='text-input input-edit-product' onChange={handleChange.bind(this)} /*defaultValue={product.description}*//>
          </Form.Item>
          <Form.Item name='unitId' label={t('unit', {a: ' '})} hasFeedback rules={[{required: true, message: t('required', {a: ' '})}]}>
            <Select name='unitId' placeholder={t('unit', {a: ' '})} size='large'  onChange={handleChangeUnit.bind(this)} /*defaultValue={product.unit.id}*/>
              {unitOptions}
            </Select>
          </Form.Item>
          <Form.Item name='unitValue' label={t('quantity-unit', {a: ' '})} hasFeedback rules={[{validator:(_, value)=> value ? typeof value === 'number'? value<1?Promise.reject(t('numeric', {a: ' '})):Promise.resolve():Promise.reject(t('numeric', {a: ' '})): value===0?Promise.reject(t('minimum-1', {a: ' '})):Promise.reject(t('required', {a: ' '}))}, {required: true, message: ' '}]}>
            <InputNumber name='unitValue' onChange={handleChangeUnitValue.bind(this)} placeholder={t('quantity-unit', {a: ' '})} /*defaultValue={product.unitValue}*/ className='text-input input-edit-product' /> 
          </Form.Item>
          <Form.Item name='price' label={t('price', {a: ' '})} hasFeedback rules={[{validator:(_, value)=> value ? typeof value === 'number'? value<=0?Promise.reject(t('minimum-price', {a: ' '})):Promise.resolve():Promise.reject(t('minimum-price', {a: ' '})): value===0?Promise.reject(t('minimum-price', {a: ' '})):Promise.reject(t('required', {a: ' '}))}, {required: true, message: ' '}]}>
            <InputNumber max={9999} name='price' formatter={value=>value !== '' ? `${value} €`:''} onChange={handleChangePrice.bind(this)} placeholder={t('quantity-unit', {a: ' '})} className='text-input padding-fix input-edit-product' step={0.01} decimalSeparator=',' /*defaultValue={product.price}*//>
          </Form.Item> 
            <Form.Item name='photos' rules={[{validator: (_,value)=>value?value.fileList.length>0?value.fileList.length>5?Promise.reject(t('max-photos', {a: ' '})):checkSize(value) ? Promise.resolve() : Promise.reject(t('photo-exceed', {a: ' '})):Promise.reject(t('add-1-photo', {a: ' '})):Promise.reject(t('add-1-photo', {a: ' '}))}/*{required: true, message: 'Debe agregar al menos 1 foto'}*/]}>
                <Upload accept=".png, .jpg, .jpeg, image/jpeg, image/jpg, image/png, image/x-png" {...props2} onRemove={e=>setPhotosToDelete(prev=>{prev.push(e); return prev})}>    
                    {allowMorePhotos? 
                        <Button>
                            <UploadOutlined />
                            {t("add-pic", {a: ' '})}
                        </Button> :
                        <p>{t("max-pics", {a: ' '})}</p>  
                    }
                </Upload>   
            </Form.Item>
          <Form.Item name='submit-btn'>
              {!props.userInfo.store.blocked && <Button type='primary' htmlType='submit' className='btn mb-5 btn-first' loading={loadings[0]}>
                {t('save', {a: ' '})}
              </Button>}
              <Button danger className='btn' onClick={props.history.goBack} >
                {t('cancel', {a: ' '})}
              </Button> 
          </Form.Item>
          {formError && <Alert message={formError} type='error' className='error-msg' />}
      </Form> 
      }
    </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps)(EditProduct)) 
