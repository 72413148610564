import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import {Button} from 'antd';
import {AimOutlined} from '@ant-design/icons';
import Loader from '../../Atoms/Loader/Loader';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const ViewStoreMap = props => {
    const { t } = useTranslation();
    const { center = {lat: 41.2369789, lng: 1.8027169}, zoom = 17} = props;
    const [currentMap, setCurrentMap] = useState('');
    const [store, setStore] = useState('');
    const [loading, setLoading] = useState(true);

    const getStore = () => {
        fetch(`${process.env.REACT_APP_API_URL}/store/${props.match.params.userId}/0/2/0`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
        .then(res=>res.json())
        .then(res=>{
            if (res.message==='Store found') {
                setStore(res.findStore);
                setLoading(false);
            } else {
                // console.log('error es: ', res)
            }  
        })
        .catch(err=>console.log('error es: ', err))
    }
    
    const handleApiLoaded = (map, maps) => {
        if (store.location.length > 0) {
            const storePosition={
                lat: Number(store.location[0].latitude),
                lng: Number(store.location[0].longitude)
              }
            const marker = new maps.Marker({
                position: storePosition,
                map: map
            });
            let address = store.location[0].address;
            if (store.location[0].additionalInformation) {
                address = address.concat(` / ${store.location[0].additionalInformation}`)
            }
            const infoWindow = new maps.InfoWindow({
                content: address
            });
            infoWindow.open(map, marker);
            map.setCenter(storePosition);
        }
      setCurrentMap(map);
    }
    
    const centerMap = () => {
      if (navigator.geolocation) {
          let pos;
          navigator.geolocation.getCurrentPosition(position=>{
          pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
          };
          currentMap.setCenter(pos);
          }, () => {
              currentMap.setCenter(center);
              // console.log('no se detecto geolocalizacion')
              //window.alert('No fue posible detectar su localización.')
          })
      } else {
          window.alert(t("map-no-geoloc", {a: ' '}))
      }
    }

    useEffect(getStore, [])

    return (
        <>
            {!loading? <div className='map-container'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_API_KEY}`}}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
                />
                <div className='map-buttons-container'>
                    <a target='_blank' rel='noopener noreferrer' href={`https://www.google.com/maps/dir/?api=1&destination=${Number(store.location[0].latitude)},${Number(store.location[0].longitude)}`}><Button type='primary' className='round-button btn-first'>{t("how-arrive", {a: ' '})}</Button></a>
                    {/* <Link to={`/vendedor/${store.userId}/${store.storeName.replace(/ /g, '-').toLowerCase()}`}><Button className='round-button btn-second'>{t("view-store", {a: ' '})}</Button></Link> */}
                    <Link to={`/vendedor/${store.userId}`}><Button className='round-button btn-second'>{t("view-store", {a: ' '})}</Button></Link>
                </div>
                {currentMap &&
                <div className="map-main-footer--item map-center-button" onClick={centerMap}>
                    <AimOutlined twoToneColor="#161B4B"/>
                </div>}
            </div> : <Loader />}
      </>
    );
}

export default ViewStoreMap;
