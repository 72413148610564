import React, {useEffect, useState, useRef} from 'react';
import Logo from '../../Atoms/Logo/Logo';
import { connect } from 'react-redux';
import {Form, Button, Input, Alert, Select, Spin, Modal} from 'antd';
import alerta from '../../../utilityFunctions/alerta';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert2';
import './CloseAccount.scss'
import { LOGOUT_USER } from '../../../redux/actions';

function CloseAccount(props) {
    const { t } = useTranslation();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [button2Loading, setButton2Loading] = useState(false);
    const [formError, setFormError] = useState('');
    const [sentCode, setSentCode] = useState(false);
    const formRef = useRef();

    useEffect(() => {
      document.body.scrollIntoView({block:'start'})
    }, [])

    const submitForm = e => {
        setButton2Loading(true);
        const reasons = formRef.current.getFieldsValue();
        fetch(`${process.env.REACT_APP_API_URL}/close-user-account/${props.userInfo.id}`, { 
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
            body: JSON.stringify({
                reason: reasons.reason,
                fullReason: reasons.comment,
                code: e.code,
                esp: localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
            })
        })
        .then(res => res.json())
        .then(res => {
            if (res.message === 'process done') {
                setSentCode(false);
                localStorage.removeItem('token');
                localStorage.removeItem('remember');
                props.dispatch({
                  type: LOGOUT_USER,
                  data: {}
                })
                props.history.push('/');
                alerta(t('deleted-account', {a: ' '}));
            } else if (res.message === 'not found' || res.message === 'not allowed') {
                Swal.fire({
                    title: t('error', {a: ' '}),
                    text: t("invalid-code", {a: ' '})
                })
            } else if (res.message === 'not allowed - membership') {
                Swal.fire({
                    title: t('error', {a: ' '}),
                    text: t("active-member-error", {a: ' '})
                })
            } else if (res.message === 'not allowed - store') {
                Swal.fire({
                    title: t('error', {a: ' '}),
                    text: t("store-active-error", {a: ' '})
                })
            } else {
                Swal.fire({
                    title: t('error', {a: ' '}),
                    text: t('oops', {a: ' '})
                })
            }
            setButton2Loading(false);
        })
        .catch(err=>{
            setButton2Loading(false);
            setFormError(t('oops', {a: ' '}))
        })

    }
    const sendCode = () => {
        setFormError('');
        setButtonLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/generate-code`, { 
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
            body: JSON.stringify({
                userId: props.userInfo.id,
                esp: localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
            })
        })
        .then(res => res.json())
        .then(res=> {
            if (res.message === 'process done') {
                setSentCode(true);
                setButtonLoading(false)
            } else if (res.message === 'not allowed') {
                Swal.fire({
                    title: t('error', {a: ' '}),
                    text: t("order-active-error", {a: ' '})
                })
                setButtonLoading(false)
            }
        })
        .catch(err=>{
            setButtonLoading(false);
            setFormError(t('oops', {a: ' '}))
            setFormError('Oops. Algo salió mal. Por favor actualice la página')
        })
    }
    return (
        <div className="profile-container"> 
            <div className="wrapped-profile-container">
                <Form ref={formRef} name='generate-code-form' className='form' onFinish={sendCode}>
                    <Logo />
                    <h1 className='center-text title-form'>{t('delete-account', {a: ' '})}</h1>
                    <Form.Item name='reason' label={t('reason', {a: ' '})} hasFeedback rules={[{required: true, message: t('required', {a: ' '})}]}>
                        <Select placeholder={t('reason', {a: ' '})}>
                            <Select.Option value={t('not-interested', {a: ' '})}>{t('not-interested', {a: ' '})}</Select.Option>
                            <Select.Option value={t('not-agree', {a: ' '})}>{t('not-agree', {a: ' '})}</Select.Option>
                            <Select.Option value={t('other', {a: ' '})}>{t('other', {a: ' '})}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name='comment' label={t('other-comments', {a: ' '})}>
                        <Input.TextArea placeholder={t('other-comments', {a: ' '})} className='text-input' />
                    </Form.Item>
                    <Button htmlType='submit' type='primary' className='btn btn-first' loading={buttonLoading}>{t('baja', {a: ' '})}</Button>
                    {formError && <Alert message={formError} type='error' className='error-msg' />}
                </Form>
                <Modal footer={null} className="code-modal" visible={sentCode} onCancel={()=>setSentCode(false)}>
                    <Form name='close-account-form' onFinish={submitForm}>
                        <h5 className='center-text'>{t('baja-email-sent', {a: ' '})}</h5>
                        <Form.Item name='code' label={t('code', {a: ' '})} rules={[{required: true, message: t('required', {a: ' '})}]}>
                            <Input placeholder={t('code', {a: ' '})} className='text-input' />
                        </Form.Item>
                        <Button htmlType='submit' type='primary' className='btn btn-first' loading={button2Loading}>{t('confirm', {a: ' '})}</Button>
                    </Form>
                </Modal>
            </div>
        </div>
    )  
}

const mapStateToProps = (state) => ({
    userInfo: state.userReducer.userInfo
})

export default connect(mapStateToProps)(CloseAccount);
