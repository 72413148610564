import React, {useState} from 'react'
import './OrderCard2.scss'
import { Button } from 'antd';
import { Link, withRouter } from 'react-router-dom';
// import OrderItem from '../OrderItem/OrderItem';
import OrderItem2 from '../OrderItem/OrderItem2';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { connect } from 'react-redux';
import { getOrders } from '../../../redux/actionCreators';
import store from '../../../redux/store';
import {useTranslation} from 'react-i18next';

const OrderCard2 = ({userInfo, setReload, order, setAllOrder, history}) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])

  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  // Eliminar orden
  // const handleDeleteOrder = (id, name, sellerId) => {
  //   enterLoading(0);
  //   Swal.fire({
  //     title: `¿Seguro que desea cancelar la orden de ${name}?`,
  //     text: "Luego de cancelar no podrá revertir esta acción ",
  //     icon: "warning",
  //     showCancelButton :true,
  //     confirmButtonText: 'Si, Cancelar!',
  //     cancelButtonText: 'Quiero conservarla'
  //   })
  //   .then( async (result) => {
  //     console.log(result)
  //     if (result.isConfirmed) {
  //       let url = `${process.env.REACT_APP_API_URL}/cancel-order/${id}/${sellerId}`;
  //       const response = await fetch(url, {
  //         method: 'PATCH',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${localStorage.getItem('token')}`
  //         },
  //         body: JSON.stringify({ 
  //             "status": "Cancelado",
  //             "personId": userInfo.person.userId,
  //             "buyerName": userInfo.person.firstName
  //         })
  //       })
  //       if(response.status === 200) {
  //         console.log(response)
  //         setReload(true)
  //         store.dispatch(getOrders(userInfo.person.userId)) //Cuando agrego al Carrito me actualiza el store
  //         Swal.fire("Su orden se cancelo con exito!", {
  //           icon: "success",
  //         });
  //       }
  //     } else {
  //       const newLoadings = [...loadings]
  //       newLoadings[0] = false;
  //       setLoadings(newLoadings);
  //       // Swal.fire("Acción cancelada");
  //     }
  //   });
  // }

  const handleDeleteOrder = (id, name) => {
    const btnOrder = Array.from(document.querySelectorAll('.makeOrder'))
    btnOrder.map(btn => btn.setAttribute("disabled", ""))

    // console.log(btnOrder);
    enterLoading(0);
    Swal.fire({
      title: `${t("confirm-cancel-order", {a: ' '})} ${name}?`,
      text: t("cancel-warning", {a: ' '}),
      icon: "warning",
      showCancelButton :true,
      confirmButtonText: t("confirm-cancel", {a: ' '}),
      cancelButtonText: t('keep', {a: ' '})
    })
    .then( async (result) => {
      // console.log(result)
      if (result.isConfirmed) {
        btnOrder.map(btn => btn.setAttribute("disabled", ""))
        let url = `${process.env.REACT_APP_API_URL}/delete-order-cart/${id}`;
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        })
        if(response.status === 200) {
          btnOrder.map(btn => btn.removeAttribute("disabled"))
          // console.log(response)
          setReload(true)
          store.dispatch(getOrders(userInfo.person.userId)) //Cuando agrego al Carrito me actualiza el store
          Swal.fire(t("cancelled-order", {a: ' '}), {
            icon: "success",
          });
        }
      } else {
        btnOrder.map(btn => btn.removeAttribute("disabled"))
        const newLoadings = [...loadings]
        newLoadings[0] = false;
        setLoadings(newLoadings);
        // Swal.fire("Acción cancelada");
      }
    });
  }

   // Sumar el total 
   const getTotal = orderArr => {
    let total=0;
    orderArr.forEach(product=>{
      total += product.quantity * product.productPrice
    })
    return total;
  }


  // Ir a confirmar la order
  const goToConfirmOrder = (orderId, order) => {
    // console.log(orderId)
    // console.log(order)
    if(order.orderItem[0].product && order.orderItem[0].product.store.blocked ) {
      Swal.fire({
        icon: 'error',
        title: t("sorry", {a: ' '}),
        text: t("cant-buy", {a: ' '})
      })
    } else if(order.orderItem[0].product === null) {
      Swal.fire({
        icon: 'error',
        title: t("sorry", {a: ' '}),
        text: t("cant-buy2", {a: ' '})
      })
    }else {
      history.push(`confirmar-orden/${orderId}`)
    }
  }
  return (
    order.status === 'En carrito' && //Me muestra solo las ordenes en carrito
    <div className="main-container-card-grid" >
      <div className="container-card-grid-store">
        {/* <Link to={`/vendedor/${order.storeId}/${order.sellerName.trim().toLowerCase().replace(/\s+/g, '-')}`}> */}
        <Link to={`/vendedor/${order.storeId}`}>
          <h2 className="main-container-card-grid--store-name">{order.sellerName}</h2>
        </Link>
      </div>
      <div>
        {
          order.orderItem.map(orderItem => (
            <OrderItem2 key={orderItem.id}
              orderItem={orderItem}
              setReload={setReload}
              setAllOrder={setAllOrder}
            />
          ))
        }
      </div>
      <div className="main-container-card-grid-total"><h4>{`${t("total2", {a: ' '})}: ${getTotal(order.orderItem)} €`}</h4></div>
      <div className="main-container-card-grid-btns">
        <Button className="round-btn" loading={loadings[0]} type="primary" danger onClick={() => handleDeleteOrder(order.id, order.sellerName, order.sellerId)}>
          {t("cancel-order-2", {a: ' '})}
        </Button>
        <Button onClick={() => goToConfirmOrder(order.id, order)} className="round-btn btn-first makeOrder" type="primary">{t("make-order", {a: ' '})}</Button>
      </div>
    </div> 
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps,{})(OrderCard2))
