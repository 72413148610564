import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined, LoadingOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import { getOrders } from '../../../redux/actionCreators';
import store from '../../../redux/store';
import { 
  DeleteOutlined
} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

const OrderItem2 = ({userInfo, orderItem, setReload, setAllOrder}) => {
  const [loaderBtn, setLoaderBtn] = useState(false)
  const [loaderBtnQuantity, setloaderBtnQuantity] = useState(false)
  const { t } = useTranslation();
  // useEffect(() => {
  //   setLoaderBtn(false)
  //   setloaderBtnQuantity(false)
  // }, [])

  // EDITAR CANTIDAD DEL PRODUCTO
  const editQuantity = (previousQuantity, addition, orderItemId, maxQuantity) => {
    if (previousQuantity === 1 && !addition) {
      //ver si mostrar mensaje o algo de eliminar producto
    } 
    // else if (previousQuantity===maxQuantity) {
    //     //ver si mostrar mensaje o algo de que no hay mas disponibilidad
    // } 
    else {
      setloaderBtnQuantity(true)
      fetch(`${process.env.REACT_APP_API_URL}/edit-order-item/${orderItemId}/${userInfo.person.userId}`, { 
        method: 'PATCH',
        headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem('token')}`
       },
        body: JSON.stringify({
            "quantity": addition ? previousQuantity + 1 : previousQuantity - 1,
            "addition": addition
        })
      })
      .then(res => res.json())
      .then(res=> {
        if (res.message==='item modified') {
          setAllOrder(res.findOrders)
          setloaderBtnQuantity(false)
        } else {
          // console.log('Error al editar el item')
          setloaderBtnQuantity(false)
        }
      })
    }  
  }

  // Eliminar Item de una orden
  const handleDeleteItem = (id, name) => {
    const btnOrder = Array.from(document.querySelectorAll('.makeOrder'))
    btnOrder.map(btn => {
      btn.setAttribute("disabled", "");
    })
    Swal.fire({
      title: t("confirm-delete-cart", {x: name, a: ' '}),
      text: t("delete-cat-warning-3", {a: ' '}),
      icon: "warning",
      showCancelButton :true,
      confirmButtonText: t("confirm-delete", {a: ' '}),
      cancelButtonText: t('keep', {a: ' '})
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        btnOrder.map(btn => {
          btn.setAttribute("disabled", "");
        })
        setLoaderBtn(true)
        let url = `${process.env.REACT_APP_API_URL}/delete-order-item/${id}/${userInfo.person.userId}`;
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        if(response.status === 200) {
          btnOrder.map(btn => {
            btn.removeAttribute("disabled");
          })
          setReload(true)
          store.dispatch(getOrders(userInfo.person.userId)) //Cuando agrego al Carrito me actualiza el store
          Swal.fire(t("deleted-product", {a: ' '}), {
            icon: "success",
          });
          setLoaderBtn(false)
        }
      } else {
        btnOrder.map(btn => {
          btn.removeAttribute("disabled");
        })
        setLoaderBtn(false)
      }
    });
  }

  return (
    <div className="main-container-card-item box">
      <div className="main-container-card-item-p1">
        <div className="main-container-cart-img">
          <img className="main-container-cart-image" src={orderItem.productImageUrl} alt={orderItem.productName}/>
        </div>
        <div className="main-container-card-description-title-container">
          <Link to={`/producto/${orderItem.productId}/${orderItem.productName.trim().toLowerCase().replace(/\s+/g, '-')}/1`}><p className="main-container-card-description-title">{orderItem.productName}</p></Link>
          {orderItem && <p className="main-container-card-description-data">{orderItem.productPrice}{t("price-each", {a: ' '})} {orderItem.productUnitName}</p>}
        </div>
      </div>

      <div className="main-container-card-item-p2">
        <div className="main-container-card-item-p2-item">
          {
            <div className="container-editQuiantity">
              
              <div className="btn-container-editQuiantity MinusOutlined" onClick={()=>editQuantity(orderItem.quantity, false, orderItem.id, orderItem.quantity)}>
                {
                  loaderBtnQuantity ?
                  <LoadingOutlined />:<MinusOutlined />
                }
              </div>
              <div className="container-editQuiantity-quantity ">{`${orderItem.quantity}`}</div>
              <div className="btn-container-editQuiantity PlusOutlined" onClick={()=>editQuantity(orderItem.quantity, true, orderItem.id, orderItem.quantity)}>
                {
                  loaderBtnQuantity ?
                  <LoadingOutlined />:<PlusOutlined />
                }
              </div>
              
            </div>
          }
        </div>
        <div className="main-container-card-item-p2-item">
          <p className="main-container-card-item-subtotal"><span>{`${orderItem.quantity * orderItem.productPrice}€`}</span></p>
        </div>
        
        <div className="main-container-card-item-p2-item">
          {
          loaderBtn ?
          <LoadingOutlined />
          :
          <div className="main-container-card-item-delete"  onClick={() => handleDeleteItem(orderItem.id, orderItem.productName)}>
              <DeleteOutlined />
          </div> 
          }
        </div>
        
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps,{})(OrderItem2)
