import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import {Button} from 'antd';
import { connect } from 'react-redux';
import './Mapa.scss';
import alerta from '../../../utilityFunctions/alerta';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import {AimOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

const EditStoreMap = props => {
    const { t } = useTranslation();
    const { center = {lat: 41.2369789, lng: 1.8027169}, zoom = 17} = props;
    //eslint-disable-next-line
    const [markerArr, setMarkerArr] = useState([]);
    const [location, setLocation] = useState({});
    const [buttonLoading, setButtonLoading] = useState(false)
    const [currentMap, setCurrentMap] = useState('');

    const submitAddress = () => {
      setButtonLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/location/${props.userInfo.store.location[0].id}/${props.userInfo.id}`, { 
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
        body: JSON.stringify(location)
       })
      .then(res => res.json())
      .then(res => {
          // console.log(res);
        setButtonLoading(false);
        if (res.message === 'store location updated') {
          alerta(t("location-edited", {a: ' '}))
          props.dispatch({
            type: SAVE_LOGIN_USER,
            data: res.findUser
          });
          props.history.push('/dashboard/tienda')
        } else {
            //error
        }
      })
      .catch(err => {
        setButtonLoading(false);
        // console.log(err);
      })
    }
    const deleteMarker = () => {
        setMarkerArr(prevState => {
            if (prevState.length > 0) {
                prevState[0].setMap(null);
            }
            return []
        });
        setLocation({});
    }
    const handleApiLoaded = (map, maps) => {
      const oldPosition={
        lat: Number(props.userInfo.store.location[0].latitude),
        lng: Number(props.userInfo.store.location[0].longitude)
      }
      const oldMarker = new maps.Marker({
        position: oldPosition,
        map: map,
        title: t("delete-location", {a: ' '})
      });
      oldMarker.addListener('click', () => {
        deleteMarker();
      })
      setMarkerArr([oldMarker])
      const infoWindow = new maps.InfoWindow({
        content: props.userInfo.store.location[0].address
      });
      infoWindow.open(map, oldMarker);
      map.setCenter(oldPosition);
      setCurrentMap(map);

      map.addListener('click', e => {
        const clickedLocation = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        };
        deleteMarker();
        const newMarker = new maps.Marker({
          position: clickedLocation,
          map: map,
          title: t("delete-location", {a: ' '})
        });
        newMarker.addListener('click', () => {
          deleteMarker();
        })
        setMarkerArr([newMarker])
        const geocoder = new maps.Geocoder();
        geocoder.geocode({'location': clickedLocation}, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              const infoWindow = new maps.InfoWindow({
                content: results[0].formatted_address
              });
              infoWindow.open(map, newMarker);
              setLocation({latitude: clickedLocation.lat, longitude: clickedLocation.lng, address: results[0].formatted_address, storeId: props.userInfo.store.id, additionalInformation: null})
            } else {
              window.alert(t("invalid-location", {a: ' '}))
            }
          } else {
            // console.log('Geocoder fallo por ' + status)
          }
        })
      })
    }
    
    const centerMap = () => {
      if (navigator.geolocation) {
          let pos;
          navigator.geolocation.getCurrentPosition(position=>{
          pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
          };
          currentMap.setCenter(pos);
          }, () => {
              currentMap.setCenter(center);
              // console.log('no se detecto geolocalizacion')
              //window.alert('No fue posible detectar su localización.')
          })
      } else {
          window.alert(t("map-no-geoloc", {a: ' '}))
      }
    }
    return (
      <div className='map-container'>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_API_KEY}`}}
          defaultCenter={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
        />
        {markerArr.length>0 && 
          <div className='map-buttons-container'>
            <Button loading={buttonLoading} onClick={submitAddress} type='primary' className='round-button btn-first'>{t("save-location", {a: ' '})}</Button>
            <Button onClick={deleteMarker} danger className='round-button background-red'>{t("delete-location", {a: ' '})}</Button>
          </div>
        }
        {currentMap &&
        <div className="map-main-footer--item map-center-button" onClick={centerMap}>
            <AimOutlined twoToneColor="#161B4B"/>
        </div>}
      </div>
    );
}
const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(EditStoreMap);
