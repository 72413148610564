import React, {useState} from 'react'
import { Button } from 'antd';
import OrderItemCanceled from '../OrderItemCanceled/OrderItemCanceled'
import { connect } from 'react-redux';
import alerta from '../../../utilityFunctions/alerta';
import {useTranslation} from 'react-i18next';

const OrderCardCanceled = ({orderSeller, setReload, userInfo}) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])

  // Loader del boton 
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }
  
  // Confirmar cencelacion
  const confirmCancelOrden = (orderId) => {
    enterLoading(0);
    fetch(`${process.env.REACT_APP_API_URL}/confirm-cancelation/${orderId}/${userInfo.person.userId}`, { 
      method: 'DELETE',
      headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then(res => res.json())
    .then(res=> {
      setReload(true)
      // console.log(res)
      if(res.message === 'order deleted') {
        alerta(t("cancelled-order-2", {a: ' '}))
      }
    })
  }

  return (
    <div key={orderSeller.id}>
      <div className="card-product card-product-delivered">
        <div className="cart-seller-info">
          <h1 className="cart-seller-info--name">{`${t("cancel-person", {a: ' '})}: ${orderSeller.buyerName}`}</h1>
          <h4 className="cart-seller-info--status">{`${t('status', {a: ' '})}: ${orderSeller.status}`}</h4>
          <h6 className="cart-seller-info-data mb-10">{t('total', {a: ' '})}: <span>{`${orderSeller.totalPrice}`}</span></h6>
        </div> 
        {
          orderSeller.orderItem.map(orderSellerItem => (
          <OrderItemCanceled orderSellerItem={orderSellerItem} />
        ))
        }
        <Button className="round-btn btn-first canceled" loading={loadings[0]} type="primary" onClick={()=> confirmCancelOrden(orderSeller.id)}>{t("confirm-cancel-2", {a: ' '})}</Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(OrderCardCanceled)
