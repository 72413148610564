import React, {useState, useRef, useEffect} from 'react';
import {Card, Button} from 'antd';
import './ProductBox.scss';
import { connect } from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import { ShoppingCartOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import Swal from 'sweetalert2'
import alerta from '../../../utilityFunctions/alerta';
import { getOrders } from '../../../redux/actionCreators';
import store from '../../../redux/store';
import {useTranslation} from 'react-i18next';
import logoMovil from '../../../assets/logo.png'

function ProductBox(props) {
  const { t } = useTranslation();

  const productCurrent = useRef();
  const [loadings, setLoadings] = useState([])
  // const [reload, setReload] = useState(false)
  let orderItemId = []

    // Loader del boton 
    const enterLoading = index => {
      const newLoadings = [...loadings]
      newLoadings[index] = true
      setLoadings(newLoadings)
    }
    
    // Añadir al carrito 
    const addToCart = (e) => {
      // console.log('search={}', props.search);
      // console.log('storeDataInfo', props.storeDataInfo);
        const buttonParents = productCurrent.current.parentNode.querySelectorAll('.btn-add-to-cart')
        const resultBtns = Array.from(buttonParents)
        resultBtns.map(btn => {
          btn.setAttribute("disabled", "");
        })
        enterLoading(0);
        fetch(`${process.env.REACT_APP_API_URL}/filling-shopping-cart`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
          body: JSON.stringify({
            "storeId": props.storeData.id,
            "productName": props.productData.name,
            "sellerEmail": props.search ? props.productData.store.user.email : props.storeDataInfo.user.email,
            "productUnitName": props.language === 'CA' || props.language === 'ca-ES' ? props.productData.unit.cat : props.productData.unit.esp,
            "productDescription": props.productData.description,
            "productPrice": props.productData.price,
            "productImageUrl": props.productData.productImage.length > 0 ? props.productData.productImage[0].url : 'https://res.cloudinary.com/propt1/image/upload/v1608131263/default/logo_x4qbw4_vx0ugc.png',
            "sellerName": props.storeData.storeName, 
            "personId": props.userInfo.person.id, // id del comprador
            "storePhoneNumber": props.storeData.mainPhoneNumber, // telelefono de la tienda
            // "sellerId": props.match.params.sellerId || props.sellerId, // id del vendedor --> productInfo.store.userId 
            "sellerId": props.sellerId, // id del vendedor --> productInfo.store.userId 
            "productId": props.id, // id del producto que se metio al carrito -->  productInfo.id
            "quantity": 1, // cantidad del producto agregado, por defecto es 1, se podrá modifica en el carrito  --> quantityValue
            "deliveryConfirmed": false,
          })
        })
        .then(res => res.json())
        .then(res => {
          if(res.message === 'Order and/or item created') {
            resultBtns.map(btn => {
              btn.removeAttribute("disabled");
            })
            store.dispatch(getOrders(props.userInfo.person.userId)) //Cuando agrego al Carrito me actualiza el store
            alerta(t("added-cart", {a: ' '}))
            // setReload(true)
            const newLoadings = [...loadings]
            newLoadings[0] = false
            setLoadings(newLoadings)
          }
          else if(res.message === 'not allowed - blocked') {
            resultBtns.map(btn => {
              btn.removeAttribute("disabled");
            })
            enterLoading(1);
            Swal.fire({
              title: t("error", {a: ' '}),
              text: t("cant-buy", {a: ' '})
            })
          }
          else {
            resultBtns.map(btn => {
              btn.removeAttribute("disabled");
            })
            enterLoading(1);
            // console.log(res)
            Swal.fire({
              icon: 'error',
              title: res.customMessage ? res.message :  t('oops', {a: ' '}),
              text: '',
            })
          }
        })
    }


    useEffect(() => {}, [props.language])

    return (
      <div ref={productCurrent}>
      <Card className='box-product'  >
        <Link to={`/producto/${props.id}/${props.slug}/0`} > 
            <img src={props.img} alt={props.name} className='seller-img'/>
          <div className='box-product-description-container' >
            <h5 className='blue-text name-product'>{props.name}</h5>
            {/* {
              props.description !== '' ?
              <p className='gray-text description-product'>{props.description}</p>
              : <p className='gray-text description-product'>Sin descripción</p>
            } */}
            <p className='main-text product-price'>{props.price}€ </p>
            {
              parseInt(props.match.params.sellerId) !== parseInt(props.userInfo.person.userId) ?
              <p className='gray-text product-price'>X {props.language === 'CA' || props.language === 'ca-ES' ? props.productData.unit.cat.toLowerCase() : props.productData.unit.esp.toLowerCase()}</p>
              : <p className='gray-text product-price product-price-space'>X {props.language === 'CA' || props.language === 'ca-ES' ? props.productData.unit.cat.toLowerCase() : props.productData.unit.esp.toLowerCase()}</p>
            }
           
            
            </div>
        </Link>

        {parseInt(props.match.params.sellerId) !== parseInt(props.userInfo.person.userId) &&
        <div>
        {
          props.userOrders.length > 0 &&
          props.userOrders.forEach(order => 
            order.orderItem.forEach(item =>
              orderItemId.push(item.productId)
            )
          )
        }
        {
          orderItemId.find(orderId => orderId === props.id)
          ? <div className='container-add-to-cart'><CheckCircleTwoTone twoToneColor="#52c41a" /></div>
          :
          props.userInfo.store && props.storeId === props.userInfo.store.id ?
          <div className="">
          <Link to={`/producto/${props.id}/${props.slug}/0`}>
            <Button loading={loadings[0]} 
                className='btn-first round-button center-block btn-add-to-cart button-small' 
                type='primary'
            >
             {t("view", {a: ' '})}
            </Button>
            </Link>
          </div>
          :
          <div>
            <div className="btn-cart-movil">
              <Button loading={loadings[0]} 
                    className='btn-first round-button center-block btn-add-to-cart button-small' 
                    type='primary' icon={<ShoppingCartOutlined/>} 
                    onClick={addToCart.bind(this)} id="btn-add-to-cart">
                    
              </Button>
            </div>
            <div className="btn-cart-desktop">
              <Button loading={loadings[0]} 
                    className='btn-first round-button center-block btn-add-to-cart button-small' 
                    type='primary' icon={<ShoppingCartOutlined/>} 
                    onClick={addToCart.bind(this)} id="btn-add-to-cart">
                   
                   {t("pedir", {a: ' '})}
              </Button>
            </div>
          </div>
        }
        </div>
        }
      
      </Card>
      </div>
    )
}
const mapStateToProps = state => ({
  userInfo: state.userReducer.userInfo,
  userOrders: state.ordersReducer.userOrders,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps, {})(ProductBox))
