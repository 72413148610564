import React, {useState, useEffect} from 'react';
import SellerBoxProfile from '../../Molecules/SellerBoxProfile/SellerBoxProfile'
import {connect} from 'react-redux';
import Loader from '../../Atoms/Loader/Loader';
// import MenuVertical from '../../Organisms/MenuVertical/MenuVertical';
import './Favorites.scss';
// import EmptyCart from '../../Molecules/EmptyCart/EmptyCart';
import logoFavoritos from '../../../assets/P1-perfil-fav-no-fav-imagen.png';
import {useTranslation} from 'react-i18next';
const defaultPicture = 'https://res.cloudinary.com/dhit2vukk/image/upload/v1593815715/default/propt1-avatar_ukxxuw.png'

function Favorites(props) {
  const { t } = useTranslation();
    const [favorites, setFavorites] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [reload, setReload] = useState(false)

    const getFavoriteSellers = () => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/favorites/${props.userInfo.id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res=>res.json())
        .then(res=>{
            if (res.message==='favorites by user') {
                setFavorites(res.findFavorites)
                setIsLoading(false);
            } else {
                setIsLoading(false);
                //showerror
            }  
        })
        .catch(err=>console.log('error es: ', err))
    }

    const verifyFavoriteDelete = (arrayFavorites) => {
      let result = false
      arrayFavorites.map(favorite => {
        if(favorite.store === null) {
          result = true
        }
      })
    }

    useEffect(() => {
      document.body.scrollIntoView({block:'start'})
      getFavoriteSellers();
    }, [reload])
    return (
        <div className="profile-container">
          <div className="wrapped-profile-container">
              {isLoading ? <Loader /> :
              favorites.length < 1 
              ?  
              <div className="main-empty box box-favorites-empty">
                <h2>{t("no-favorites", {a: ' '})}</h2>
                <img src={logoFavoritos} alt={t("no-favorites", {a: ' '})}/>
              </div> 
              :
              <div className="box container-favorites" >
                <h2 className='center-text s-100 title-form title-favorites'>{t("favorites", {a: ' '})}</h2>
                <div className="container-favorite">
    
                  {favorites.map(favorito => (
                    <SellerBoxProfile 
                      idFav={favorito.id}
                      idStore={favorito.storeId} 
                      nameStore={favorito.storeName}
                      imgStore={favorito.store ? favorito.store.profilePicture.url : defaultPicture} 
                      reload={reload} 
                      setReload={setReload} 
                      setIsLoading={setIsLoading} 
                      // location='Tarragona, Catalunya. A 20kms de ti'
                      // category={favorito.store.mainCategory.map(catObj=>catObj.name).join(' / ')}
                      // delivery={favorito.store.deliveryTypeId[0]===1?'Entrega a domicilio y recogida':'Solo recogida'}
                      ratingStore={favorito.storeTotalReputation}
                      key={favorito.id}
                    />
                  ))}
                </div>
            </div>}
          </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(Favorites)
