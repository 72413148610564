import React from 'react';
import './Category.scss';
import {withRouter} from 'react-router-dom';

function Category(props) {
    return (
        <div className='category-box' onClick={props.searchByCarrousel}>
            <div className='category-icon'>
                <img src={props.catIcon} alt='Categoría' className='category-img' />
            </div>
            <p className='center-text category-name'>{props.catName}</p>
        </div>
    )
}

export default withRouter(Category);