import React, {useEffect, useState} from 'react';
import {Button} from 'antd';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

function StripeAccount(props) {
	const { t } = useTranslation();
	const [success, setSuccess] = useState(false);

	useEffect(()=>{
		// console.log(props);
		if (props.location.state.plan) {
			setSuccess(true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			{success && 
				<div className='box center-text success-box'>
					<p>{t("subscribed-plan", {x: props.location.state.plan, a: ' '})}</p>
					<p>{t("stripe-benefit", {a: ' '})}</p>
					<div>
						<a href={props.location.state.url} target="_blank" without rel="noopener noreferrer"><Button type='primary' className='btn-first round-btn'>{t("go-stripe", {a: ' '})}</Button></a>
						<Link to='/dashboard/tienda'><Button className='btn-second round-btn'>{t('later', {a: ' '})}</Button></Link>
					</div>
				</div>
			}
		</>
	)
}

export default StripeAccount;
