import React, {useState, useEffect} from "react";
import {Form, Input, Button, Alert} from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
//import {validateNotEmpty} from '../../validation/validations';
import { withRouter } from "react-router-dom";
import { SAVE_LOGIN_USER } from "../../../redux/actions";
// import MenuVertical from '../../Organisms/MenuVertical/MenuVertical';
import Logo from '../../Atoms/Logo/Logo'
import alerta from '../../../utilityFunctions/alerta';
import {useTranslation} from 'react-i18next';

const EditProfileConsumer = (props) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])
  const [currentUserData, setCurrentUserData] = useState({
    fName: props.userInfo.person.firstName,
    lName: props.userInfo.person.lastName,
  })
  const [errors, setErrors] = useState({
   /* fNameError: '',
    lNameError: '',
    contactError: '',*/
    formError: ''
  })

  useEffect(()=>{
    document.body.scrollIntoView({block:'start'})
  }, [])
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  const handleChange = (e) => {
    setCurrentUserData({
      ...currentUserData,
      [e.target.name] : e.target.value
    })
  }

  const handleFormSubmitConsumer = (e) => {
    setErrors({
     /* fNameError: '',
      lNameError: '',*/
      formError: ''
    })
    /*const validFirstName = validateNotEmpty(currentUserData.fName);
    const validLastName = validateNotEmpty(currentUserData.lName);
    if (validFirstName === '' && validLastName === '' ) {*/
      enterLoading(0);
      fetch(`${process.env.REACT_APP_API_URL}/person/${props.userInfo.person.userId}`, { 
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
        body: JSON.stringify({
            "firstName": currentUserData.fName,
            "lastName" : currentUserData.lName
        })
      })
      .then(res => res.json())
      .then(res => {

        if(res.message === 'Person updated') {
          props.dispatch({
            type: SAVE_LOGIN_USER,
            data: res.findUser
          })
          alerta(t("saved-info", {a: ' '}))
          props.history.push('/dashboard/perfil')
        } else {
            const newLoadings = [...loadings]
            newLoadings[0] = false;
            setLoadings(newLoadings)
            setErrors(prevState => ({
              ...prevState,
              formError: t('oops', {a: ' '})
            }))
          }
      })
      .catch(err => {
        const newLoadings = [...loadings]
        newLoadings[0] = false;
        setLoadings(newLoadings)
        setErrors(prevState => ({
          ...prevState,
          formError: t('oops', {a: ' '})
        }))
      })
    /*} else {
        setErrors({
          fNameError: validFirstName,
          lNameError: validLastName,
        })
    }*/
    
  }
  return (
    <>
    <div className="profile-container">
    <div className="wrapped-profile-container">
      {/* <MenuVertical/> */}
    <Form
        name="user-info-form"
        onFinish={handleFormSubmitConsumer}
        className="form EditProfile"
        initialValues={{
          "fName": props.userInfo.person.firstName,
          "lName": props.userInfo.person.lastName,
        }}
      >
      <Logo />
      <Form.Item name="fName" label={t('nombre', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
        <Input placeholder={t('nombre', {a: ' '})} name="fName" className="text-input" onChange={handleChange.bind(this)} /*defaultValue={props.userInfo.person.firstName}*/ />
      </Form.Item>
      {/*errors.fNameError && <Alert message={errors.fNameError} type='error' className='error-msg' />*/}
      <Form.Item name='lName' label={t('apellido', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
        <Input placeholder={t('apellido', {a: ' '})} name='lName' className='text-input' onChange={handleChange.bind(this)} /*defaultValue={props.userInfo.person.lastName}*/ />
      </Form.Item>
      {/*errors.lNameError && <Alert message={errors.lNameError} type='error' className='error-msg' />*/}
          <Button
            type="primary"
            htmlType="submit"
            className="btn mb-1 btn-first"
            loading={loadings[0]}
          >
            {t('save', {a: ' '})}
            <ArrowRightOutlined />
          </Button>
          <Button danger onClick={props.history.goBack} className="btn mb-1">{t('cancel', {a: ' '})}</Button>
      {errors.formError && <Alert message={errors.formError} type='error' className='error-msg' />}
    </Form>
    </div>
    </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(EditProfileConsumer))
