import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import espanol from './locales/es.json'
import catalan from './locales/ca.json'

const resources = {
  ES: {
    translation: espanol
  },
  'es-ES': {
    translation: espanol
  },
  CA: {
    translation: catalan
  },
  'ca-ES': {
    translation: catalan
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ES',
    debug: true,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;