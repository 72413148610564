import React, {useState, useEffect} from 'react'
// import Profile from '../Profile/Profile'
// import './Dashboad.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {Form, Input, Button, Alert} from 'antd';
// import { ArrowRightOutlined} from '@ant-design/icons';
import Logo from '../../Atoms/Logo/Logo'
// import MenuProfile from '../../Organisms/MenuProfile/MenuProfile';
// import MenuVertical from '../../Organisms/MenuVertical/MenuVertical';
// import { connect } from 'react-redux';
//import {validateNotEmpty} from '../../validation/validations';
import { Link} from 'react-router-dom';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
// import Loader from '../../Atoms/Loader/Loader';
// import Swal from 'sweetalert2';
import alerta from '../../../utilityFunctions/alerta';
import './CreateAddress.scss'
import {useTranslation, Trans} from 'react-i18next';
import errorStore from '../../../utilityFunctions/noStore';

const CreateAddress = (props) => {
  const { t } = useTranslation();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const submitAddress = ev => {
    setButtonLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/location/${props.userInfo.store.location[0].id}/${props.userInfo.id}`, { 
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
        body: JSON.stringify({
          additionalInformation: ev.additionalInformation,
          address: ev.geoAddress
        })  
      })
      .then(res => res.json())
      .then(res => {
       
          setButtonLoading(false);
          if (res.message === 'store location updated') {
            props.dispatch({
                type: SAVE_LOGIN_USER,
                data: res.findUser
            });
            alerta(t("location-edited", {a: ' '}))
            props.history.push('/dashboard/tienda')
          } else {
              setFormError(t('oops', {a: ' '}))
          }
      })
      .catch(err => {
          setButtonLoading(false);
          setFormError(t('oops', {a: ' '}))
      })
  }
  
  useEffect(()=>{
    document.body.scrollIntoView({block:'start'})
    if (props.userInfo.store.blocked) {
      errorStore();
      props.history.push('/dashboard/tienda');
    }
  }, [])

  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
        <div className="box create-address-container">
        {props.userInfo.store.location.length > 0 ?
          <Form name='address-form' className="form form-address-form" onFinish={submitAddress} initialValues={{"geoAddress": props.userInfo.store.location[0].address, "additionalInformation": props.userInfo.store.location[0].additionalInformation}}>
          <Logo />
        <h1 className='center-text title-form'>{t("edit-address", {a: ' '})}</h1>
        <Form.Item name='geoAddress' label={t('address', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
            <Input.TextArea maxLength={200} rows={4} placeholder={t('address', {a: ' '})} className='text-input'/>
        </Form.Item>
        <Form.Item name='additionalInformation' label={t('additional-info', {a: ' '})} hasFeedback rules={[{whitespace: true, message: t('not-empty', {a: ' '})}]}>
            <Input.TextArea maxLength={200} rows={2} placeholder={t("reference-location", {a: ' '})} className='text-input'/>
        </Form.Item>
        <Form.Item>
        {!props.userInfo.store.blocked &&
          <>    
            <Button type='primary' htmlType='submit' className='btn btn-first mb-5' loading={buttonLoading}>{t('save', {a: ' '})}</Button>
            <Link to={'/editar-tienda/mapa'}><Button className="btn btn-second mb-5">{t("edit-map", {a: ' '})}</Button></Link>
          </>
        }  
        <Button danger onClick={props.history.goBack} className="btn mb-1 btn-edit-cancel">{t('cancel', {a: ' '})}</Button>
        </Form.Item>
        {formError && <Alert message={formError} type='error' className='error-msg' />}        
        </Form> : 
          <h1 className='center-text title-form-information '>
            <Trans i18nKey="no-location-warning" values={{a: ' '}}>
              No registraste la dirección de tu tienda. <Link to="/registro/vender/ubicar-tienda">Por favor hazlo ahora.</Link>
            </Trans>
          </h1>}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(CreateAddress)) 
