import React from 'react';
import './RecoverPassword.scss'
//import {validateEmail} from '../../validation/validations';
import {Form, Input, Button, Alert} from 'antd';
//import {MailOutlined, ArrowRightOutlined} from '@ant-design/icons';
import Logo from '../../Atoms/Logo/Logo';
import {Link} from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
class RecoverPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            //emailError: '', 
            formError: '',
            successMsg: '',
            loadings: []
        }
        this.handleFormSubmit=this.handleFormSubmit.bind(this);
    }
    enterLoading = index => {
        const newLoadings = [...this.state.loadings];
        newLoadings[index] = true;
        this.setState({
            loadings: newLoadings,
        });
    };
    handleFormSubmit(ev) {
        const { t } = this.props;
        this.setState({
            //emailError: '',
            formError: '',
            successMsg: ''
        })
        //const validEmail=validateEmail(ev.email);
        /*if (validEmail === '') {*/
            this.enterLoading(0);     
            fetch(`${process.env.REACT_APP_API_URL}/forgot-password`, { 
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "email": ev.email.trim(),   
                })
            })
            .then(res => res.json())
            .then(res=> {
                const newLoadings = [...this.state.loadings];
                newLoadings[0] = false;
                if (res.message==='email has been sent') {
                    this.setState({
                        successMsg: t("recover-msg", {a: ' '}),
                        loadings: newLoadings
                    })
                } else {
                    this.setState({
                        formError: t("email-not-found", {a: ' '}),
                        loadings: newLoadings
                    })
                }
                
            })
            .catch(err=>{
                const newLoadings = [...this.state.loadings];
                newLoadings[0] = false;
                this.setState({
                    formError: t('oops', {a: ' '}),
                    loadings: newLoadings
                })
            })
        /*} else {
            this.setState({
                emailError: validEmail
            })
        }*/
    }
    render() {
        const { t } = this.props;
        return (
            <Form name='recover-password-form abs-center' onFinish={this.handleFormSubmit} className='form max-with-form'>
                <Logo />
                <h1 className='center-text title-form'>{t("recover-title", {a: ' '})}</h1>
                <Form.Item name='email' label={t("correo", {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}, {type: 'email', message: t("invalid-email", {a: ' '})}]}>
                    <Input placeholder={t("correo", {a: ' '})} className='text-input' />
                </Form.Item>
                {/*this.state.emailError && <Alert message={this.state.emailError} type='error' className='error-msg' />*/}
                <Form.Item>
                    <Button type='primary' htmlType='submit' className='btn btn-first' loading={this.state.loadings[0]} >
                        {t("recover", {a: ' '})}
                        {/*<ArrowRightOutlined />*/}
                    </Button>
                </Form.Item>
                {this.state.formError && <Alert message={this.state.formError} type='error' className='error-msg' />}
                {this.state.successMsg && <Alert message={this.state.successMsg} type='success' className='error-msg' />}
                <p className="form-info">
                    <Trans i18nKey="recover-login" values={{a: ' '}}>
                        ¿Recordaste la contraseña? <Link to='login'>Inicia sesión</Link>
                    </Trans>
                </p>
            </Form>
        )
        
    }
}

export default withTranslation()(RecoverPassword);
