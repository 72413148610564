import React, {useEffect} from 'react';
import { Link, withRouter } from 'react-router-dom';
import './Notification.scss';
import { 
  DeleteOutlined
} from '@ant-design/icons';
import { getNotifications } from '../../../redux/actionCreators';
import store from '../../../redux/store';
import { connect } from 'react-redux';
import NotificationHoursCA from './hours/NotificationHoursCA';
import NotificationHoursES from './hours/NotificationHoursES';


const Notification = ({userInfo, notification, deleteNotification, language, setVisibleNotification, reloadNotification}) => {
  useEffect(() => {
    // console.log('language', language)
  }, [language])

  // Funcion para colocar la notifiacion como leida
  const readNotification = async (notificationId) => {
    setVisibleNotification(false)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/notification/${notificationId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    const data = await response.json()
    reloadNotification()
    store.dispatch(getNotifications(userInfo.person.userId))
  }

  return (
    <>
    <div className="icon-delete-notification" onClick={()=> deleteNotification(notification.id)}>
      <DeleteOutlined />
    </div>
    <Link to={`${notification.itemUrl}`} key={notification.id} >
      <div className="body-container-drawel-noti" onClick={()=> readNotification(notification.id)}>
        <div className="img-notification-container">
          <img src={notification.pictureUrl} alt="Logo" className="img-notification"/>
        </div>
        <div className="msg-notification-container">
          { 
            notification.notificationTypeId === 1 &&
            <p className="msg-notification">{`${language === 'CA' || language === 'ca-ES' ? 'Tens una nova comanda a nom de' : notification.info}`} <span className="msg-notification-name">{`${notification.buyerName}`}</span></p>
          }
          { 
            notification.notificationTypeId === 2 &&
            <p className="msg-notification"><span className="msg-notification-name">{`${notification.buyerName !== null ? notification.buyerName : notification.sellerName}`}</span> {`${language === 'CA' || language === 'ca-ES' ? 'ha cancel·lat la comanda.' : notification.info}`}</p>
          }
          { 
            notification.notificationTypeId === 3 &&  notification.info.includes('delivery') &&
            <p className="msg-notification"><span className="msg-notification-name">{`${notification.sellerName}`}</span> {`${language === 'CA' || language === 'ca-ES' ? `ha confirmat la teva comanda. Se't enviarà per delivery.` : notification.info}`} </p>
          }
          { 
            notification.notificationTypeId === 3 &&  !notification.info.includes('delivery') && 
             <p className="msg-notification"><span className="msg-notification-name">{`${notification.sellerName}`}</span> {`${language === 'CA' || language === 'ca-ES' ? 'ha establert temps de recés per la teva comanda.' : notification.info}`} </p>
          }
          {
            language === 'CA' || language === 'ca-ES' ?
            <NotificationHoursCA hours={notification.createdAt} />
            :
            <NotificationHoursES hours={notification.createdAt} />
          }
          
        </div>
      </div>
    </Link>
    </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  userNotification: state.notificationsReducer.userNotifications,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps, {})(Notification))
