import React, {useState, useEffect, useRef} from 'react'
import {  Button } from 'antd'
import './MyProductCard2.scss'
import { Link, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import alerta from '../../../utilityFunctions/alerta';
import {useTranslation} from 'react-i18next';
import logoMovil from '../../../assets/logo.png'

const MyProductCard2 = ({product, setReload, reload, history, active, inactive}) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])
  const [loadings2, setLoadings2] = useState([])
  const ref1 = useRef()
  const ref2 = useRef()

  useEffect(() => {
    setReload(false)
  },[product, reload]);

  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  const enterLoading2 = index => {
    const newLoadings = [...loadings2]
    newLoadings[index] = true
    setLoadings2(newLoadings)
  }

  let carousel = React.createRef();

  // Eliminar producto
  const deleteProduct = (id, name) => {
    ref1.current.setAttribute("disabled", "")
    enterLoading2(0);
    Swal.fire({
      title: `¿${t("confirm-delete-product", {a: ' '})} ${name}?`,
      text: t("delete-cat-warning-3", {a: ' '}),
      icon: "warning",
      showCancelButton :true,
      confirmButtonText: t("confirm-delete", {a: ' '}),
      cancelButtonText: t("keep", {a: ' '})
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        let url = `${process.env.REACT_APP_API_URL}/delete-product/${id}`;
        const response = await fetch(url, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
          body: JSON.stringify({
            "status": 'Eliminado'
          }) 
        })
        if(response.status === 200) {
          setReload(true)
          history.push('/dashboard/tienda/productos-activos')
          history.push('/dashboard/tienda/productos-inactivos')
          Swal.fire(t("deleted-product", {a: ' '}), {
            icon: "success",
          });
        }
        } else {
          ref1.current.removeAttribute("disabled")
          enterLoading2(1);
        }
    });
  }

  // Habilitar/Desabilitar producto
  const changeStatusProduct = (id,status) => {
    if(status) {
      ref2.current.setAttribute("disabled", "")
    }
    enterLoading(0);
    fetch(`${process.env.REACT_APP_API_URL}/product/${id}`, { 
      method: 'PATCH',
      headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      body: JSON.stringify({
        "status": status ? 'Disponible' :'No disponible',
      })  
    })
    .then(res => res.json())
    .then(res => {
        console.log(res); 
        if(res.message === 'process done') {
          if(active) {
            history.push('/dashboard/tienda/productos-inactivos')
            history.push('/dashboard/tienda/productos-activos')
          }
          if(inactive) {
            history.push('/dashboard/tienda/productos-activos')
            history.push('/dashboard/tienda/productos-inactivos')
          }
          enterLoading(1);
          alerta(`${status ? t("activated-product", {a: ' '}) : t("deactivated-product", {a: ' '})}`)
          setReload(true)
        } else{
          const newLoadings = [...loadings];
          newLoadings[0] = false; 
          Swal.fire({
            icon: 'error',
            title: t("oops", {a: ' '}),
            text: '',
          })
          setLoadings(newLoadings)
        }
    })
    .catch(err=>{
      console.log(err)
      const newLoadings = [...loadings];
      newLoadings[0] = false; 
      Swal.fire({
        icon: 'error',
        title: t("oops", {a: ' '}),
        text: '',
      })
      setLoadings(newLoadings)
    })
  }

  return (
    <div className="box main-card-product-item" key={product.id}>
          <div className="container-img-data">
            <div className="main-card-product-item-container-img">
              <Link to={`/producto/${product.id}/${product.slug}/1`}>
                <img src={product.productImage.length > 0 ? product.productImage[0].url : 'https://res.cloudinary.com/propt1/image/upload/v1608131263/default/logo_x4qbw4_vx0ugc.png'} alt={product.name}/>
              </Link>
            </div>
            {/* Container data */}
            <div className="product-item-container-data">
              <Link to={`/producto/${product.id}/${product.slug}/1`}>
                <p className='product-item-container-data-title text-data'>{product.name}</p>
              </Link>  
              {
                product.status === 'Disponible' ?
                <p className="product-item-container-data-status-available">{t("status", {a: ' '})}: <span>{product.status}</span></p>
                : <p className="product-item-container-data-status-not-available">{t("status", {a: ' '})}: <span>{product.status}</span></p>
              }
              <p className="product-item-container-data-price">{`${product.price} €`}</p>
            </div>
          </div>
           {/* container botones */}
           <div className="main-card-product-item-container-btns">
            {active && <Link to={`/dashboard/tienda/editar-producto/${product.id}`}>
              <Button className="round-btn btn-first" ref={ref1} type="primary">{t("edit", {a: ' '})}</Button>
            </Link>}  
            {active && <Button loading={loadings[0]} className="round-btn " type="primary" danger onClick={() => changeStatusProduct(product.id,false)}>
              {t("deactivate", {a: ' '})}  
            </Button>}
            {inactive && <Button ref={ref1} loading={loadings[0]} className="round-btn btn-first " type="primary" onClick={() => changeStatusProduct(product.id,true)}>
              {t("activate", {a: ' '})} 
            </Button>}
            {inactive && <Button ref={ref2} loading={loadings2[0]} className="round-btn" type="primary" danger onClick={() => deleteProduct(product.id,product.name)}>
              {t("delete", {a: ' '})}
            </Button>}
          </div>
    </div>
  )
}

export default withRouter(MyProductCard2)
