import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
// import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import "./i18next.js";
import Loader from './components/Atoms/Loader/Loader';

const root = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}> 
    <Router>
      <Suspense fallback={<Loader />}>
          <App />
      </Suspense>
    </Router>
  </Provider>, root
)

// serviceWorker.register();
