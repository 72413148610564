import React, {useState, useEffect} from 'react';
import './SellerStore.scss'
import ProductCatalog from '../../Organisms/ProductCatalog/ProductCatalog';
import SellerInfo from '../../Organisms/SellerInfo/SellerInfo';
import SellerInfo3 from '../../Organisms/SellerInfo/SellerInfo3';
import Loader from '../../Atoms/Loader/Loader';
import notProduct from '../../../assets/P1-Busqueda-no-encontrada.png';
import {useTranslation} from 'react-i18next';
import { useProducts } from '../../../customHooks/useProducts';
const defaultLimit=20;

function SellerStore(props) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true)
    const [storeInfo, setStoreInfo] = useState({});
    const [storeDataInfo, setStoreDataInfo] = useState([])
    const [distanceToUser, setDistanceToUser] = useState(null)
    const [offset, setOffset] = useState(0);
    const [storeNotFound, setStoreNotFound] = useState(false);

    const { loadingProducts, products, productsCount, setPage } = useProducts({storeId: props.match.params.sellerId, status: 1, switchBloqued: 0})

    const getStore = () => {
        fetch(`${process.env.REACT_APP_API_URL}/store/${props.match.params.sellerId}/${storeInfo.id?storeInfo.id:0}/${defaultLimit}/${offset}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res=>res.json())
        .then(res=>{
            if (res.message==='Store found') {
                !storeInfo.id && setStoreInfo(res.findStore);
                if (res.products.count) {
                    setStoreDataInfo(res.findStore);
                    setOffset(offset+defaultLimit);
                }
                if (!storeInfo.id && res.findStore.location.length > 0) {
                    const storeLat = Number(res.findStore.location[0].latitude);
                    const storeLong = Number(res.findStore.location[0].longitude);
                    let pos;
                    const calculateDistance = userLoc => {
                        const p = 0.017453292519943295;
                        const c = Math.cos;
                        const a = 0.5 - c((storeLat - userLoc.lat) * p) / 2 + (c(userLoc.lat * p) * c(storeLat * p) * (1 - c((storeLong - userLoc.lng) * p))) / 2;
                        const distance = 12742 * Math.asin(Math.sqrt(a)); //esto da en kilometros
                        setDistanceToUser(distance.toFixed(1));
                        setIsLoading(false);
                    }
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(position=>{
                        pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        calculateDistance(pos)
                        setIsLoading(false);
                        }, () => {
                          pos = {lat: 41.2318958, lng: 1.8016172}
                          calculateDistance(pos)
                          // console.log('No fue posible detectar su localización.')
                        })
                    } else {
                          pos = {lat: 41.2318958, lng: 1.8016172}
                          calculateDistance(pos)
                          setIsLoading(false);
                          // console.log('El navegador no permite geolocalizacion.')
                    }
                }
                
            }
            if(res.message === 'store not found') {
              setStoreNotFound(true)
              setIsLoading(false);
            } 
            else {
              // console.log('error es: ', res)
            }  
        })
        .catch(err=>console.log('error es: ', err))
    }
    useEffect(() => {
      document.body.scrollIntoView({block:'start'})
      getStore()
    }, [])

    
    return (
        <>
            {isLoading? <Loader /> :
            storeNotFound ?
            <div className="box main-empty-store-product not-found-store">
              <div className="main-empty">
                <h2 className="title-main-empty-product">{t('store-not-found', {a: ' '})}</h2>
                <img src={notProduct} alt={t('store-not-found', {a: ' '})}/>
              </div> 
            </div> 
            : 
            <div className="seller-store-container">
                {storeInfo.id && <SellerInfo3 distanceToUser={distanceToUser} sellerInfo={storeInfo} setIsLoading={setIsLoading} />}
                {/* {storeInfo.id && <SellerInfo distanceToUser={distanceToUser} sellerInfo={storeInfo} setIsLoading={setIsLoading}/>} */}
                {storeInfo.id && <ProductCatalog getMoreProducts={() => setPage(prevPage => prevPage + 1)} totalProduct={productsCount} loadingProducts={loadingProducts} storeDataInfo={storeDataInfo} productList={products} search={false} sellerStore={true}/>}
            </div>}
        </>  
    )
}

export default SellerStore;
