import React, { useState, useEffect } from 'react';
import './ContactForm.scss'
import {Form, Input, Button} from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Logo from '../../Atoms/Logo/Logo';
import Swal from 'sweetalert2';
import {useTranslation} from 'react-i18next';

const ContactForm = (props) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])
  const [reload, setReload] = useState(false)

  // Loader del boton 
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  useEffect(() => {
    setReload(false)
    document.body.scrollIntoView({block:'start'})
  }, [reload])
  
  const handleFormSubmit = async (e) => {
    enterLoading(0);

    const config = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({
        name : `${props.userInfo.person.firstName} ${props.userInfo.person.lastName} ` ,
        email: props.userInfo.email,
        topic: e.asunto,
        message: e.message,
        esp: localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
      })
    }
    try {
   
     const response = await fetch(`${process.env.REACT_APP_API_URL}/contact`, config)
     const data = await response.json();
    
     if(response.status === 200) {
      enterLoading(1);
      Swal.fire({
        icon: 'success',
        title: t("sent-correct", {a: ' '}),
        text: t("wait-propt1", {a: ' '}),
      })
      setReload(true);
     } else {
      enterLoading(1);
      Swal.fire({
        icon: 'error',
        title: t("oops", {a: ' '}),
        text: '',
      })
      
     }
    } catch (error) {
      enterLoading(1);
      
    }
   
  }

  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
        <Form name='user-info-form' onFinish={(e) => handleFormSubmit(e)} className='form' >
          <Logo />
          <h1 className='center-text title-form'>{t("how-help", {a: ' '})}</h1>
          <Form.Item name='asunto' label={t("subject", {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t("required", {a: ' '})}]}>
            <Input placeholder={t("subject", {a: ' '})} className='text-input' />
          </Form.Item>
          <Form.Item name='message' label={t("additional-info", {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t("required", {a: ' '})}]}>
            <Input.TextArea maxLength={500} placeholder={t("comment-help", {a: ' '})} className='text-input' />
          </Form.Item>
          <Form.Item>  
            <Button htmlType='submit' loading={loadings[0]} type='primary' className='btn btn-first mb-5'>
            {t("send", {a: ' '})}
            </Button>
            <Button type='secondary' className='btn btn-back-store' onClick={props.history.goBack}>
            {t("cancel", {a: ' '})}
            </Button>
          </Form.Item>
        </Form>       
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(withRouter(ContactForm));
