import React, {useState, useEffect} from 'react';
import {Button} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import {ArrowRightOutlined} from '@ant-design/icons';
import './Welcome.scss';
import logotipo from '../../../assets/logotipo.png'
import mapaP1 from '../../../assets/mapaP1.jpg'
import RegisterGoogle from '../../Molecules/RegisterGoogle/RegisterGoogle';
import InstallPWA from '../../Atoms/InstallPWA/InstallPWA';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import { connect } from 'react-redux';
import Loader from '../../Atoms/Loader/Loader';
import {useTranslation, Trans} from 'react-i18next';

const userLogged = localStorage.getItem('token');

const Welcome = (props) => {
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setLoader(false)
    document.body.scrollIntoView({block:'start'})
    if(userLogged) {
      setLoader(true)
      fetch(`${process.env.REACT_APP_API_URL}/check-login-token`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
      })
      .then(res=>res.json())
      .then(res=> {
        if(res.findUser) {
          props.dispatch({
            type: SAVE_LOGIN_USER,
            data: res.findUser
          });
          // console.log('res.findUser reload', res.findUser)
          if(res.findUser.verified) {
            props.history.push('/tiendas/mapa')
          } else {
            props.history.push('/verificar-email')
          }
         }else {
          setLoader(false)
         } 
      })
    }
  }, [])

    return (
        loader ? <Loader />
        :
        <div className="main-welcome">
            <div className="main-welcome--content"> 
              <div>
                <img className="main-welcome-logo" id="logo" src={logotipo} alt='Propt1' />
                <div className="main-welcome-btn">
                  <div className="container-btn-google-login"><RegisterGoogle home/></div>
                  <div className="main-welcome--ctaRegister">
                    <Link to='/registro'>
                      <Button className="cta-register btn-second">
                        {t("register", {a: ' '})}
                          <ArrowRightOutlined />
                      </Button>
                    </Link>
                  </div>
                  <div className="main-welcome--ctaLogin">
                    <Trans i18nKey='reg-login' values={{a: ' '}}>
                        ¿Ya tienes una cuenta? <Link to='login'>Inicia sesión</Link>
                    </Trans>
                  </div>
                  <div id="install" className="main-cta-pwa">
                    <InstallPWA />
                  </div>
                  <p className="main-welcome--ctaLogin faq"><a href="https://propt1.cat/faq-i-videos-dajuda/" target='_blank' rel="noopener noreferrer">{t("faq", {a: ' '})}</a></p>
                  {/* <div className="privacy-google privacy-google-welcome">
                    Sitio protegido por reCAPTCHA, la <a href='https://policies.google.com/privacy' rel='noopener noreferrer' target="_blank">Política de Privacidad </a> y los <a href='https://policies.google.com/terms' rel='noopener noreferrer' target="_blank"> Términos del Servicio </a> de Google aplican.
                  </div> */}
                </div>
              </div>
            </div>
            <img className="main-welcome-map" src={mapaP1} alt='Mapa' />
      </div>
    )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
})

export default connect(mapStateToProps)(withRouter(Welcome))
