import React, {useState} from 'react'
import OrderItemBuyer from '../OrderItemBuyer/OrderItemBuyer'
import { Button, Input, Form, Divider } from 'antd'
import { connect } from 'react-redux';
import { FieldTimeOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import alerta from '../../../utilityFunctions/alerta';
import moment from 'moment';
import 'moment/locale/es';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import 'sweetalert2/src/sweetalert2.scss'
import CancelOrderSeller from '../CancelOrderSeller/CancelOrderSeller';
import {useTranslation, Trans} from 'react-i18next';

const MySwal = withReactContent(Swal);
const OrderCardBuyer = ({orderSeller, setReload, confirm, userInfo, history, match, language}) => {
  const { t } = useTranslation();
  // const [withdrawalTime, setWithdrawalTime] = useState('')
  const [loadings, setLoadings] = useState([])
  const [loadings2, setLoadings2] = useState([])
  const [additionalInformationConfirm, setAdditionalInformationConfirm] = useState(null)
  // const [showModal, setShowModal] = useState(false)
  // const [orderConfirm, setOrderConfirm] = useState(false)
  // Loader del boton 
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  // Loader del boton 2
  const enterLoading2 = index => {
    const newLoadings2 = [...loadings2]
    newLoadings2[index] = true
    setLoadings2(newLoadings2)
  }

  // Guardo el tiempo de entrega
  // const changewithdrawalTime = (e) => {
  //   setWithdrawalTime(e.target.value)
  // }

  // Envio la peticion para enviar el tiempo de entrega
  const sendwithdrawalTime = (orderId, personId, buyerName, email,e) => {
    // console.log(e);
    enterLoading(0);
    fetch(`${process.env.REACT_APP_API_URL}/set-withdrawal-time/${orderId}/${personId}`, { 
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({
          "personId": userInfo.person.id,
          "withdrawalTime": e.withDrawelTime,
          "myUserId": personId,
          "buyerEmail": email,
          "buyerName": buyerName,
          "sellerName": userInfo.store.storeName,
          "deliveryConfirmed" : false,
          "additionalInformation": e.additionalInformation,
          esp: localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
      })
    })
    .then(res => res.json())
    .then(res=> {
      setReload(true)
      if (res.message==="withdrawal time set") {
        // enterLoading(1)
        history.push('/dashboard/tienda/ventas-activas')
        alerta(`${t("withdrawal-time-sent", {a: ' '})} ${orderSeller.buyerName}`)    
      }else {
        enterLoading(1);
        // console.log(res)
        Swal.fire({
          icon: 'error',
          title: t('oops', {a: ' '}),
          text: '',
        })
      }
    })
  }

  // Entregar orden
  const deliverOrder = (orderId) => {
    enterLoading2(0)
    Swal.fire({
      title: t('confirm-delivery2', {a: ' '}),
      // text: "Luego de cancelar no podra revertir esta acción ",
      icon: "warning",
      showCancelButton :true,
      confirmButtonText: t('yes', {a: ' '}),
      cancelButtonText: t('no', {a: ' '})
    })
    .then( async (result) => {
      // console.log(result)
      if (result.isConfirmed) {
        let url = `${process.env.REACT_APP_API_URL}/deliver-order/${orderId}`;
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "status": "Entregado",
                "personId": userInfo.person.id,
                "deliveryConfirmed": true
            })
        })
        if(response.status === 200) {
          // setReload(true)
          history.push('/dashboard/tienda/ventas-activas')
          // history.push(`/dashboard/tienda/ventas-activas/ord-${match.params.id}`)
          Swal.fire({
            icon: 'success',
            title: `${t('delivered-to', {a: ' '})} ${orderSeller.buyerName}`,
            text: t('historial-order', {a: ' '}),
          })
          // alerta(`Pedido entregado con exito a ${orderSeller.buyerName}`)
          // console.log(response)
        } else {
          enterLoading2(1)
          Swal.fire({
            icon: 'error',
            title: t('oops', {a: ' '}),
            text: '',
          })
        }
      } else {
        enterLoading2(1);
      }
    });
  }

  // Confirmar orden
  const confirmOrder = (orderId, personId, buyerName,email) => {
    // console.log(`Sotre name: ${userInfo.store.storeName}`);
    // console.log(`buller email: ${null}`);
    // console.log(`buyerName: ${buyerName}`);
    // console.log(`additionalInformation: ${additionalInformationConfirm}`);
    enterLoading2(0);
    fetch(`${process.env.REACT_APP_API_URL}/confirm-order/${orderId}/${personId}`, { 
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({
        "sellerName": userInfo.store.storeName,
        "storeName": userInfo.store.storeName,
        "personId": personId,
        "buyerEmail": email,
        "buyerName": buyerName,
        "myUserId": personId,
        "additionalInformation": additionalInformationConfirm,
        esp: localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
      })
    })
    .then(res => res.json())
    .then(res=>{
      if (res.message==="order confirmed") {
        setReload(true)
        // setOrderConfirm(true)
        enterLoading2(1)
        // history.push('/dashboard/tienda/ventas-activas')
        history.push(`/dashboard/tienda/ventas-activas/ord-${match.params.id}`)
        alerta(t("order-confirmed", {a:' '}))
      } else {
        enterLoading2(1)
        // console.log(res)
        Swal.fire({
          icon: 'error',
          title: t('oops', {a: ' '}),
          text: '',
        })
      }
    })
  }

  // Obtengo la inforamacion adicional para enviarla en el confirm order
  const handleAdditionalInformationConfirm = (e) => {
    setAdditionalInformationConfirm(e.target.value);
  }

  // Cancelando orden
  const cancelOrder = (orderId, buyerId, sellerPhoneNumber, buyerEmail) => {
    MySwal.fire({
      title: t("reason-2", {a: ' '}),
      html: <CancelOrderSeller setReload={setReload} orderId={orderId} buyerId={buyerId} sellerPhoneNumber={sellerPhoneNumber} buyerName={`${userInfo.person.firstName} ${userInfo.person.lastName !== null && userInfo.person.lastName}`} personId={userInfo.person.id} sellerName={userInfo.store.storeName} buyerEmail={buyerEmail}/>,
      icon: "info",
      showCancelButton :true,
      showConfirmButton :false,
      // confirmButtonText: 'Valorar tienda',
      cancelButtonText: t("no-cancel", {a: ' '})
    })
    .then( async (result) => {
      // console.log(result)
      if (result.isConfirmed) {
        Swal.fire(t("cancelled-order-2", {a: ' '}))
        // setShowModal(false)
      } else {
      
      } //Fin de else
    });
  }

  const languages = {
    'Reservado': 'Reservat',
    'En espera': 'En espera',
    'Entregado': 'Lliurat',
    'Cancelado': 'Cancel·lat'
  }

  return (
    // orderSeller.status === 'Reservado' && //Me muestra solo las ordenes Reservadas.
    <div key={orderSeller.id} className="card-product-buyer-container">
      <div className="card-product-buyer">
        <div className="cart-seller-info">
          <h1 className="cart-seller-info--name">{`${t("buyer", {a: ' '})}: ${orderSeller.buyerName}`}</h1>
          <h6>{t("order-number2", {a: ' '})} #{orderSeller.id}</h6>
          <h6 className="mt-5">{t("ordered-on", {a: ' '})}: {moment(orderSeller.createdAt).format('L')}</h6>
          <h4 className="cart-seller-info--status">{`${t("order", {a: ' '})}: ${language !== 'CA' && language !== 'ca-ES' ? orderSeller.status : languages[orderSeller.status]}`}</h4>
          <h6 className="cart-seller-info-data">{t("total", {a: ' '})}: <span>{`${orderSeller.totalPrice}`}</span></h6>
          {
            confirm &&
            <div><h6>{t("client", {a: ' '})} <span className="cart-seller-info--withDrawelTime-undefined">{`${orderSeller.buyerName}`}</span> {t("no-qualify-yet", {a: ' '})}</h6></div>
          }
          {
            userInfo.store &&
            userInfo.store.deliveryTypeId[0] === 1
            ? //Si hace delivery
            <>

            {
              orderSeller.deliveryAddress !== null && !confirm &&
              <>
                <p className="cart-seller-info-data">{`${t("delivery-address2", {a: ' '})}: ${orderSeller.deliveryAddress}`}</p>
                {orderSeller.additionalInformation !== null && <p className="cart-seller-info-data">{`${t("message-sent", {a: ' '})}: ${orderSeller.additionalInformation}`}</p>}
                <p className="cart-seller-info-data">{`${t("client-phone", {a: ' '})}: ${orderSeller.buyerPhoneNumber}`}</p>
                {/* {orderSeller.status !== 'En espera' && <a className="enlace-whatsapp" href={`tel:${orderSeller.buyerPhoneNumber}`} rel="noopener noreferrer">Escribir a <span className="whatsapp-name-user">{` ${orderSeller.buyerName} ${orderSeller.person.lastName}`}</span> via <span className="whatsapp-name"> WhatsApp <WhatsAppOutlined /></span></a>} */}
                {orderSeller.status !== 'En espera' && <a className="enlace-whatsapp" href={`https://api.whatsapp.com/send?phone=${orderSeller.buyerPhoneNumber}&text=Hola%20${orderSeller.buyerName},%20`} target="_blank" rel="noopener noreferrer">{t("message-to", {a: ' '})} <span className="whatsapp-name-user">{` ${orderSeller.buyerName}`}</span> via <span className="whatsapp-name"> WhatsApp <WhatsAppOutlined /></span></a>}
              </>
            }
            {
              orderSeller.deliveryAddress !== null && !confirm && orderSeller.additionalInformation === null && orderSeller.status === 'En espera' &&
              <Form name='additionalInformationConfirmForm'>
                  <Form.Item name='additionalInformationConfirm'>
                      <Input.TextArea onChange={(e) => handleAdditionalInformationConfirm(e)} maxLength={200} placeholder={t("additional-optional", {a: ' '})} className='text-input' />
                  </Form.Item>
                  {/* <Button loading={loadings[0]} className="round-btn btn-first" htmlType='submit' type="primary">Enviar información adicianal</Button> */}
              </Form>
            }
            {
              orderSeller.deliveryAddress === null && orderSeller.withdrawalTime === null &&
              <>
                <p className="cart-seller-info-data no-requered-delivered">{t("no-delivery-user", {a: ' '})}</p>
                <h6 className="message-withDrawelTime">{t("no-delivery-user-msg", {a: ' '})}</h6>
                <div className="container-withDrawelTime">
                  <Form name='new-product-withDrawelTime' onFinish={(e) => sendwithdrawalTime(orderSeller.id, orderSeller.personId, orderSeller.buyerName, orderSeller.buyerEmail, e)}>
                    <Form.Item name='withDrawelTime' hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
                      <Input className="text-input" placeholder={t("withdrawal-time-msg", {a: ' '})} prefix={<FieldTimeOutlined />} />
                    </Form.Item>
                    <Form.Item name='additionalInformation' hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
                        <Input.TextArea maxLength={200} placeholder={t("additional-required", {a: ' '})} className='text-input' />
                    </Form.Item>
                    <Button loading={loadings[0]} className="round-btn btn-first" htmlType='submit' type="primary">{t("send-info", {a: ' '})}</Button>
                  </Form>
                </div>
              </>
            }
            {
              orderSeller.deliveryAddress === null && orderSeller.withdrawalTime !== null && !confirm &&
              <>
                <p className="cart-seller-info-data">{t("no-delivery-user", {a: ' '})}</p>
                <p className="cart-seller-info-data">{`${t("withdrawal-time-2", {a: ' '})}: ${orderSeller.withdrawalTime}`}</p>
                {orderSeller.additionalInformation !== null && <p className="cart-seller-info-data">{`${t("message-sent", {a: ' '})}: ${orderSeller.additionalInformation}`}</p>}
                <p className="cart-seller-info-data">{`${t("client-phone", {a: ' '})}: ${orderSeller.buyerPhoneNumber}`}</p>
                {/* {orderSeller.status !== 'En espera' && <a className="enlace-whatsapp" href={`tel:${orderSeller.buyerPhoneNumber}`} rel="noopener noreferrer">Escribir a <span className="whatsapp-name-user">{` ${orderSeller.buyerName} ${orderSeller.person.lastName}`}</span> via <span className="whatsapp-name"> WhatsApp <WhatsAppOutlined /></span></a>} */}
                {orderSeller.status !== 'En espera' && <a className="enlace-whatsapp" href={`https://api.whatsapp.com/send?phone=${orderSeller.buyerPhoneNumber}&text=Hola%20${orderSeller.buyerName},%20`} target="_blank" rel="noopener noreferrer">{t("message-to", {a: ' '})} <span className="whatsapp-name-user">{` ${orderSeller.buyerName}`}</span> via <span className="whatsapp-name"> WhatsApp <WhatsAppOutlined /></span></a>}
              </>
            }
            </>
            : //Si no hace delivery
            <>
            {
              orderSeller.withdrawalTime === null &&
              <div className="container-withDrawelTime">
                <h6 className="message-withDrawelTime mt-15">{t("no-delivery-user-msg", {a: ' '})}</h6>
                <Form name='new-product-withDrawelTime' onFinish={(e) => sendwithdrawalTime(orderSeller.id,orderSeller.personId, orderSeller.buyerName,orderSeller.buyerEmail, e)}>
                  <Form.Item name='withDrawelTime' hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
                    <Input className="text-input" placeholder={t("withdrawal-time-msg", {a: ' '})} prefix={<FieldTimeOutlined />} />
                  </Form.Item>
                  <Form.Item name='additionalInformation' hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
                    <Input.TextArea maxLength={100} placeholder={t('additional-info', {a: ' '})} className='text-input' />
                  </Form.Item>
                  <Button className="round-btn btn-first" loading={loadings[0]}  htmlType='submit' type="primary">{t("save-time", {a: ' '})}</Button>
                </Form>
              </div>
            }
            {
              orderSeller.withdrawalTime !== null && !confirm &&
              <>
                <p className="cart-seller-info-data">{`${t("withdrawal-time-2", {a: ' '})}: ${orderSeller.withdrawalTime}`}</p>
                <p className="cart-seller-info-data">{`${t("client-phone", {a: ' '})}: ${orderSeller.buyerPhoneNumber}`}</p>
                {orderSeller.status !== 'En espera' && <a className="enlace-whatsapp" href={`https://api.whatsapp.com/send?phone=${orderSeller.buyerPhoneNumber}&text=Hola%20${orderSeller.buyerName},%20`} target="_blank" rel="noopener noreferrer">{t("message-to", {a: ' '})} <span className="whatsapp-name-user">{` ${orderSeller.buyerName}`}</span> via <span className="whatsapp-name"> WhatsApp <WhatsAppOutlined /></span></a>}
              </>
            }
            </>
          }
        </div>
        <Divider />
        <div className="cart-seller-product-container">
          {orderSeller.orderItem.map(orderSellerItem =>
            <OrderItemBuyer key={orderSellerItem.id}
              orderSellerItem={orderSellerItem}
              orderSeller={orderSeller}
            />
          )}
          </div>
        {/* Si no ofrezco o no quieren mi delivery, al yo enviar el withdrawalTime la orden pasa a resevado desde el backend*/}
        <div className="container-btn-cart">
          {
            orderSeller.deliveryAddress === null && orderSeller.withdrawalTime !== null && orderSeller.status === 'Reservado'  &&
            <Button className="round-btn btn-first" loading={loadings2[0]} type="primary" onClick={() => deliverOrder(orderSeller.id)}>
              {t("give-order", {a: ' '})}
            </Button>
          }
          {/* Si ofrezco */}
          {
            orderSeller.deliveryAddress !== null && orderSeller.withdrawalTime === null && orderSeller.status === 'Reservado'  &&
            <Button className="round-btn btn-first btn-cart-buyer" loading={loadings2[0]} type="primary" onClick={() => deliverOrder(orderSeller.id)}>
              {t("give-order", {a: ' '})}
            </Button>
          }
          {/* Si ofrezco pero aun esta en Espera.*/}
          {
            orderSeller.deliveryAddress !== null && orderSeller.withdrawalTime === null && orderSeller.status === 'En espera' &&
            <Button className="round-btn btn-first btn-cart-buyer"  loading={loadings2[0]} type="primary" onClick={() => confirmOrder(orderSeller.id, orderSeller.personId, orderSeller.buyerName, orderSeller.buyerEmail)}>
              {t("confirm-order", {a: ' '})}
            </Button>
          }
          {/* cancelar una orden*/}
          {
            orderSeller.cancellationReason === null && orderSeller.status === 'En espera' &&  
            /* <Button className="round-btn btn-cart-buyer" type="primary" danger onClick={() => cancelOrder(orderSeller.id, orderSeller.person.userId)}>
              Cancelar pedido
            </Button> */
            <p className="btn-delete-order">
              <Trans i18nKey='cancel-order2' values={{a: ' '}}>
                ¿Deseas cancelar el pedido? Haz <span onClick={() => cancelOrder(orderSeller.id, orderSeller.personId, orderSeller.buyerPhoneNumber, orderSeller.buyerEmail)} className="cta-delete-order">click aquí</span> 
              </Trans>
            </p>
          }
        </div>
      </div>
    </div> 
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps,{})(OrderCardBuyer))
