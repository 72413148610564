import React, {useState, useEffect} from 'react'
// import './Orders.scss';
import { connect } from 'react-redux';
// import { Collapse } from 'antd';
// import MenuVertical from '../../Organisms/MenuVertical/MenuVertical';
// import OrderCardDelivered from '../../Molecules/OrderCardDelivered/OrderCardDelivered';
import OrderCardRequested from '../../Molecules/OrderCardRequested/OrderCardRequested';
// import OrderCardCanceled from '../../Molecules/OrderCardCanceled/OrderCardCanceled';
// import EmptyCart from '../../Molecules/EmptyCart/EmptyCart';
import logoHistorial from '../../../assets/P1-perfil-historial-no-historial-compras-imagen.png';
import Loader from '../../Atoms/Loader/Loader';
// import OrderCardBuyer from '../../Molecules/OrderCardBuyer/OrderCardBuyer';
// import { ReloadOutlined } from '@ant-design/icons';
// const { Panel } = Collapse;
import {useTranslation} from 'react-i18next';
import OrderCardDescriptionHistory from '../../Molecules/OrderCardDescription/OrderCardDescriptionHistory';

const MySalesHistory= (props) => {
    const { t } = useTranslation();
    // const [allOrder, setAllOrder] = useState([]) //Para guardar las ordenes
    const [allOrderSeller, setAllOrderSeller] = useState([]) //Para guardar lo que me han comprado
    const [reload, setReload] = useState(false)
    const [reReload, setReReload] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    
    // Valida que exista una orden con tal status
    const validOrder = (array, message) => {
      let result = false
      array &&
      array.forEach(o=> {
        if(o.status === message) {
          result = true
        }
      })
      return result
    }
    // const validOrder = (array, message) => {
    //   let result = false
    //   array &&
    //   array.forEach(o=> {
    //     if(o.status === message && !o.deliveryConfirmed) {
    //       result = true
    //     }
    //   })
    //   return result
    // }
    // Valida que una orden tenga el deliveryConfirm
    // const validdeliveryConfirm = (array, message) => {
    //   let result = false
    //   array &&
    //   array.forEach(o=> {
    //     if(o.status === message && o.deliveryConfirmed) {
    //       result = true
    //     }
    //   })
    //   return result
    // }

    // let ordersPlaced = validOrder(allOrderSeller, "Reservado")
    // let ordersInWait = validOrder(allOrderSeller, "En espera")
    let requestedOrders = validOrder(allOrderSeller, "Entregado")
    // let canceledOrders = validOrder(allOrderSeller, "Cancelado")
    // let orderConfirmDelivery = validdeliveryConfirm(allOrderSeller, 'Entregado')
    // let valid2 
    
    // if(ordersInWait === true || ordersPlaced === true || orderConfirmDelivery) valid2 = true

    useEffect(() => {
      // document.body.scrollIntoView({block:'start'})
      setReload(false)
      setReReload(true)
      if(props.userInfo.userRoleId === 2) {
        let ordersSeller = []
        fetch(`${process.env.REACT_APP_API_URL}/orders-by-seller/${props.userInfo.person.userId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(resp => resp.json())
        .then(resp => {
          // console.log(resp)
          resp.findOrders.map(order => (
            ordersSeller.push(order)
          ))
          setAllOrderSeller(ordersSeller)
          setIsLoading(false);
        })
      }
      // const reloadPage = setInterval(() => {
      //   setReload(true)
      // }, 10000)
      // // la función se frena al desmontar el componente
      // return () => {
      //   clearInterval(reloadPage)
      // }
    }, [props.userInfo.person.userId, props.userInfo.userRoleId, reload, reReload])

  return (
      <>
      <div className="profile-container container-orders">
        <div className="wrapped-profile-container">
        {/* {
          reload 
          ? <ReloadOutlined spin className="icon-load" />
          : <ReloadOutlined className="icon-load" onClick={() => setReload(true)}/>
        } */}
        {isLoading? <Loader /> :
          <div className='box container-orders-reserved'>
          {requestedOrders && <h2 className='center-text title-form'>{t("sale-history", {a: ' '})}</h2>}
                {
                  allOrderSeller.map(orderSeller => (
                  orderSeller.status === 'Entregado' && orderSeller.deliveryConfirmed && //Me muestra solo las ordenes Entregado sin calificar el deliveryConfirmed esta en true aun.
                    <OrderCardDescriptionHistory key={orderSeller.id}
                      orderSeller={orderSeller}
                      deliveryConfirmed={true} 
                      setReload={setReload}
                    />
                  ))
                }
                {
                  // allOrderSeller.map(orderSeller => (
                  // orderSeller.status === 'Entregado' && !orderSeller.deliveryConfirmed && //Me muestra solo las ordenes Entregado.
                  //   <OrderCardRequested key={orderSeller.id}
                  //     orderSeller={orderSeller} 
                  //   />
                  // ))
                  allOrderSeller.map(orderSeller => (
                  orderSeller.status === 'Entregado' && !orderSeller.deliveryConfirmed && //Me muestra solo las ordenes Entregado ya cafilificadas el deliveryConfirmed esta el false.
                    <OrderCardDescriptionHistory key={orderSeller.id}
                      orderSeller={orderSeller} 
                      setReload={setReload}
                    />
                  ))
                }
                {
                  !requestedOrders &&
                  <div className="main-empty">
                    <h2>{t("empty-history", {a: ' '})}</h2>
                    <img src={logoHistorial} alt={t("empty-history", {a: ' '})}/>
                  </div>
                }
          </div>}
      </div>
      </div>
      </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(MySalesHistory)
