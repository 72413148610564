import React, {useState} from 'react'
import { Input, Form, Button } from 'antd';
import Swal from 'sweetalert2';
// import { BrowserRouter as withRouter } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const CancelOrderSeller = (props) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])
  const [objetoReason, setObjetoReason] = useState({
    cancellationReason: null,
    sellerName: props.sellerName,
    personId: props.personId,
    buyerName: props.buyerName,
    sellerPhoneNumber: props.sellerPhoneNumber,
    buyerEmail: props.buyerEmail,
    esp: localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
  })


  // Loader del boton 
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  // Actualizo el comment
  const sendReason = (e) => {
    setObjetoReason({
      ...objetoReason,
      cancellationReason: e.target.value
    })
  }


  // Cancelar orden 
  const handleCancelOrder = (e) => {
    enterLoading(0)
    fetch(`${process.env.REACT_APP_API_URL}/cancel-order-by-seller/${props.orderId}/${props.buyerId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(objetoReason)
    })
    .then(resp => resp.json())
    .then(resp=> {
      // console.log(resp)
      if(resp.message === 'Order canceled') {
        props.setReload(true)
        Swal.fire({
          icon: 'success',
          title: t("cancelled-order", {a: ' '}),
          text: t("info-sent", {a: ' '}),
        })
        // props.history.push('/dashboard/tienda/ventas-activas')
      }else {
        enterLoading(1)
        Swal.fire({
          icon: 'error',
          title: t('oops', {a: ' '}),
          text: '',
        })
      }
    })
  }

  return (
    <div className="quialifed-container">
      <Form name='cancellationReasonForm' onFinish={(e) => handleCancelOrder(e)}>
        <Form.Item name='cancellationReason' hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
          <Input className="text-input" maxLength={80} onChange={(e) => sendReason(e)} placeholder={t('reason', {a: ' '})} />
        </Form.Item>
        <Button className="round-btn btn-first btn-quialifed-modal" loading={loadings[0]} htmlType='submit' type="primary">{t("cancel-order", {a: ' '})}</Button>
      </Form>
    </div>
  )
}

export default CancelOrderSeller


