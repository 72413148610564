import React, {Fragment} from 'react';
import {validateEmail, validatePassword} from '../../validation/validations';
import {Link} from 'react-router-dom';
import {Form, Input, Button, Alert, Checkbox} from 'antd';
//import {  ArrowRightOutlined } from '@ant-design/icons';
import Logo from '../../Atoms/Logo/Logo';
import { connect } from 'react-redux'
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import './Login.scss';
import { Trans, withTranslation } from 'react-i18next';
import RegisterGoogle from '../../Molecules/RegisterGoogle/RegisterGoogle';
// import RegisterGoogle from '../../Molecules/RegisterGoogle/RegisterGoogle';


class Login extends React.Component {
  
    constructor(props) {
        super(props);
        this.state={
            emailError: '',
            passwordError: '',
            loginError: '',
            showFormSteps: false,
            loadings: [],
            rememberCheck: true
        };  
        this.handleFormSubmit=this.handleFormSubmit.bind(this);
    }
    enterLoading = index => {
        const newLoadings = [...this.state.loadings];
        newLoadings[index] = true;
        this.setState({
          loadings: newLoadings,
        });
    };
    
    handleFormSubmit(ev) {
        const { t } = this.props;
        this.setState({
            emailError: '',
            passwordError: '',
            loginError: ''
        })
        const validEmail=validateEmail(ev.email);
        const validPassword=validatePassword(ev.password);
        if (validEmail === '' && validPassword === '') {
            this.enterLoading(0);
            fetch(`${process.env.REACT_APP_API_URL}/login`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "email": ev.email.trim().toLowerCase(),
                    "password": ev.password.trim(),  
                })
            })
            .then(res => res.json())
            .then(res => {
                const newLoadings = [...this.state.loadings];
                newLoadings[0] = false;
                if (res.message === "Auth Successful" || res.message === 'not allowed') {
                    // Guardo la data en redux
                    this.props.dispatch({
                      type: SAVE_LOGIN_USER,
                      data: res.findUser
                    })           

                    if (res.token) {
                      if (this.state.rememberCheck) {
                          localStorage.setItem('remember', 'true')
                      } else {
                          localStorage.setItem('remember', 'false')
                      }
                      localStorage.setItem('token', res.token);
                    } else {
                        localStorage.setItem('remember', 'false')
                    }
                    // Funcion para convertir la subcripcion del usuario
                    function urlBase64ToUint8Array(base64String) {
                      const padding = '='.repeat((4 - base64String.length % 4) % 4);
                      const base64 = (base64String + padding)
                        .replace(/-/g, '+')
                        .replace(/_/g, '/');

                      const rawData = window.atob(base64);
                      const outputArray = new Uint8Array(rawData.length);

                      for (let i = 0; i < rawData.length; ++i) {
                        outputArray[i] = rawData.charCodeAt(i);
                      }
                      return outputArray;
                    }
                    const WEB_PUSH_PUBLIC_KEY = 'BBUmYtq4HOOc-DCO7giM1WIVrvxa7elnqRr2kZsOVM7Lleenkjodqe35-ZtO6r5jL5-ceON0VvaRjcDGmix8Oxo';
                    // Funcion que registra al usario para las notificaciones
                    function registerPush() {
                       
                        navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/serviceworker.js`);
                        navigator.serviceWorker.ready.then(function(registration) {
                            return registration.pushManager.getSubscription()
                                .then(function(subscription) {
                                    if (subscription) {
                                        return subscription;
                                    }
                                  
                                    return registration.pushManager.subscribe({
                                        userVisibleOnly: true,
                                        applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_PUBLIC_KEY)
                                    });
                                }) 
                                .then(function(subscription) {
                              
                                    return fetch(`${process.env.REACT_APP_API_URL}/subscription`, {
                                        method: 'post',
                                        headers: {
                                          "Content-type": "application/json",
                                          'Authorization': `Bearer ${localStorage.getItem('token')}`
                                        },
                                        body: JSON.stringify({
                                          subscription,
                                          "userId": res.findUser.person.userId
                                        })
                                    })
                                    .then(resp => resp.json())
                                    .then(resp => {
                                      console.log(resp)
                                      if(resp.message === "process done") {
                                        // console.log('Subscrito correctamente')
                                      }else {
                                        // console.log('No se pudo hacer la subscripcion')
                                      }
                                    })
                                })
                                .catch(err=>console.log(err));
                        });
                    }
                    registerPush();

                    if (res.findUser.verified) { 
                        return this.props.history.push('/tiendas/mapa');
                    } else {
                        return this.props.history.push('/verificar-email')
                    }
                }
                else {
                    return this.setState({
                        loginError: res.customMessage ? res.message : t("login-invalid", {a: ' '}),
                        loadings: newLoadings
                    })
                }
            })
            .catch(err => {
                const newLoadings = [...this.state.loadings];
                newLoadings[0] = false;
                this.setState({
                    loginError: t('oops', {a: ' '}),
                    loadings: newLoadings
                })
            })
        } else {
            this.setState({
                emailError: validEmail,
                passwordError: validPassword
            })
        }
    }
    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <Form onFinish={this.handleFormSubmit} name='login-form' className='form max-with-form'>
                    <Link to='/'><Logo /></Link>
                    <h1 className='center-text s-100 title-form'>{t("login", {a: ' '})}</h1>
                    <div className="main-container-btn-google-login"><RegisterGoogle /></div>
                    {/* <h6 className='center-text'>Entra con</h6>
                    <div className="container-btn-google-login"><RegisterGoogle /></div>
                    <h6 className='center-text google-margin'>O utiliza tu correo</h6> */}
                    <Form.Item name='email' label={t('correo', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}, {type: 'email', message: t("invalid-email", {a: ' '})}]}>
                        <Input placeholder={t('correo', {a: ' '})} className='text-input'/>
                    </Form.Item>
                    {/*this.state.emailError && <Alert message={this.state.emailError} type='error' className='error-msg' />*/}
                    <Form.Item name='password' label={t('contraseña', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}/*, {validator: (_, value) => value === 'hola' ? Promise.resolve() : Promise.reject('formato malo, poner regex aqui')}*/]}>
                        <Input.Password placeholder={t('contraseña', {a: ' '})} className='text-input'/>
                    </Form.Item>
                    {/*this.state.passwordError && <Alert message={this.state.passwordError} type='error' className='error-msg' />*/}
                    <Form.Item name='remember'>
                        <Checkbox className="font-size-body" checked={this.state.rememberCheck} onChange={()=>this.setState({rememberCheck: !this.state.rememberCheck})}>{t("remember", {a: ' '})}</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' className='btn btn-first' loading={this.state.loadings[0]}>
                            {t("login-enviar", {a: ' '})}
                            {/*<ArrowRightOutlined />*/}
                        </Button>
                    </Form.Item>
                    
                    {this.state.loginError && <Alert message={this.state.loginError} type='error' className='error-msg' />}
                    <p className="font-size-body center-text mb-5">
                        <Trans i18nKey={"forgotten-password"} values={{a: ' '}}>
                            ¿Olvidaste tu contraseña?, <Link to='/recuperar-contraseña'> haz clic aquí</Link>
                        </Trans>
                    </p>
                    <p className="font-size-body center-text mb-5">
                        <Trans i18nKey={"not-registered"} values={{a: ' '}}>
                            ¿No estas registrado? <Link to='/registro' className='center-text link-register'> Regístrate ahora</Link>
                        </Trans>
                    </p>
                  
                    <div className="privacy-google font-size-body" >
                        <Trans i18nKey={"login-msg"} values={{a: ' '}}>
                            Sitio protegido por reCAPTCHA, la <a href='https://policies.google.com/privacy' rel='noopener noreferrer' target="_blank">Política de Privacidad </a> y los <a href='https://policies.google.com/terms' rel='noopener noreferrer' target="_blank"> Términos del Servicio </a> de Google aplican.
                        </Trans>
                    </div>
                </Form>    
            </Fragment>   
        )
    }
}

export default connect()(withTranslation()(Login))
