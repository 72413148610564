import React, {useState} from 'react'
import { Input, Form, Button } from 'antd';
import Swal from 'sweetalert2';
// import { BrowserRouter as withRouter } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
// import { withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';

const CancelOrderBuyer = (props) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState([])
  const [objetoReason, setObjetoReason] = useState({
    "cancellationReason": null, 
    "personId": props.personId,
    "buyerName": props.buyerName,
    "sellerEmail": props.sellerEmail,
    "buyerEmail": props.buyerEmail,
    "buyerPhoneNumber": props.buyerPhoneNumber,
    "sellerId": props.sellerId,
    "mySellerId": props.mySellerId,
    "esp": localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
  })


  // Loader del boton 
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  // Actualizo el comment
  const sendReason = (e) => {
    setObjetoReason({
      ...objetoReason,
      cancellationReason: e.target.value
    })
  }


  // Cancelar orden 
  const handleCancelOrder = (e) => {
    enterLoading(0)
    fetch(`${process.env.REACT_APP_API_URL}/cancel-order/${props.orderId}/${props.sellerId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(objetoReason)
    })
    .then(resp => resp.json())
    .then(resp=> {
      console.log('resp', resp)
      if(resp.message === 'Order canceled') {
        props.setReload(true)
        // props.history.push('/dashboard/perfil/compras-activas')
        Swal.fire(t("cancelled-order", {a: ' '}), {
            icon: "success",
        });
      }else {
        enterLoading(1)
        Swal.fire({
          icon: 'error',
          title: t('oops', {a: ' '}),
          text: '',
        })
      }
    })
  }

  return (
    <div className="quialifed-container">
      <Form name='cancellationReasonForm' onFinish={(e) => handleCancelOrder(e)}>
        <Form.Item name='cancellationReason' hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
          <Input className="text-input" maxLength={80} onChange={(e) => sendReason(e)} placeholder={t('reason', {a: ' '})} />
        </Form.Item>
        <Button className="round-btn btn-first btn-quialifed-modal" loading={loadings[0]} htmlType='submit' type="primary">{t("cancel-order", {a: ' '})}</Button>
      </Form>
    </div>
  )
}

export default CancelOrderBuyer;

