import { useElements, useStripe, LinkAuthenticationElement, PaymentElement } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';


export const CheckoutForm = ({order, history}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `http://localhost:3000/dashboard/test-stripe/confirmation`,
      },
      redirect: 'if_required',
    });

    if(paymentIntent){
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/make-order/notification/${order.id}`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            esp: localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
          })
        });
  
        await Swal.fire({
          icon: 'success',
          title: t('order-made', {a: ' '})+order?.sellerName,
          text: t('order-success', {a: ' '}),
        })

        history.push('/carrito')

        return;
        
      } catch (error) {
        console.error('Error sending notifications');

        await Swal.fire({
          icon: 'error',
          title: t('error-occured', {a: ' '})+order?.sellerName,
          text: t('error-paying', {a: ' '}),
        })

        history.push('/carrito')

        return

      }
    }
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      await Swal.fire({
        icon: 'error',
        title: t('error-occured', {a: ' '}),
        text: error.message,
      })

    } else {
      setMessage("An unexpected error occurred.");
      await Swal.fire({
        icon: 'error',
        title: t('error-occured', {a: ' '}),
        text: 'Ha ocurrido un error inesperado',
      })
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" className="checkout-app-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.value.email)}
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button className="checkout-app-button" disabled={isLoading || !stripe || !elements} id="submit" >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default withRouter(CheckoutForm)
