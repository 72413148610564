import React from 'react';
import {Link} from 'react-router-dom';
import {Card, Rate} from 'antd';
import './SellerBox.scss';

function SellerBox(props) {
    return (
      <>
        {/* <Link to={`/vendedor/${props.id}/${props.name.toLowerCase().replace(/ /g, '-')}`}> */}
        <Link to={`/vendedor/${props.id}`}>
            <Card className='card-seller'>
                <img src={props.img} alt={props.name} className='seller-img'/>
                <div className="container-seller-description">
                <h5 className='blue-text card-seller-name'>{props.name}</h5>
                <div className="container-rate"><Rate allowHalf disabled defaultValue={props.rating} /></div>
                  <p className='main-text seller-box-location'>{props.location}</p>
                  <p className='gray-text seller-box-category'>{props.category}</p>
                </div>
            </Card>
        </Link>
      </>
    )
}

export default SellerBox;
