import React, {useState,useEffect} from 'react'
import './ViewOrderDetail.scss'
import { withRouter, Link, /*Link*/} from 'react-router-dom'
import Loader from '../../Atoms/Loader/Loader'
import { Button, Divider } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import {useTranslation} from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';
import logoVentas from '../../../assets/P1-perfil-compras-no-compras-activas-imagen.png';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import alerta from '../../../utilityFunctions/alerta';

const ViewOrderDetailStore = (props) => {
  const { t } = useTranslation();
  const [orderDetail, setOrderDetail] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [orderNotFound, setOrderNotFound] = useState(false)
  const [loadings, setLoadings] = useState([])

  // Loader del boton 
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/order/${props.match.params.id}/${null}/${props.userInfo.person.userId}`, {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
  })
    .then(resp => resp.json())
    .then(resp => {
      console.log(resp)
      if(resp.message === 'order found') {
        setOrderDetail(resp.findOrder)
        setIsLoading(false);
        setOrderNotFound(false)
      } 
      if (resp.message === 'order not found'){
        console.log('Orden no encontrada')
        setOrderNotFound(true)
        setIsLoading(false);
      } 
      if(resp.message === 'not allowed') {
        if(props.location.pathname.includes('/perfil/detalle')) {
          console.log('debe ir a historial de compras')
          props.history.push('/dashboard/perfil/historial-de-compras')
        }
        if(props.location.pathname.includes('/tienda/detalle')){
          console.log('debe ir a historial de ventas')
          props.history.push('/dashboard/tienda/historial-de-ventas')
        }
        console.log(props.location.pathname)
      }
    })
  }, [props.match.params.id])

   // Eliminar venta
  const confirmCancelOrden = (orderId) => {
    enterLoading(0);
    Swal.fire({
      title: t('confirm-delete-sell', {a: ' '}),
      text: t('delete-cat-warning-2', {a: ' '}),
      icon: "warning",
      showCancelButton :true,
      confirmButtonText: t('confirm-delete', {a: ' '}),
      cancelButtonText: t('keep', {a: ' '})
    })
    .then( async (result) => {
      console.log(result)
      if (result.isConfirmed) {
        let url = `${process.env.REACT_APP_API_URL}/confirm-cancelation/${orderId}/${props.userInfo.person.userId}`
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
        })
        if(response.status === 200) {
          console.log(response)
          props.history.push('/dashboard/tienda/historial-de-ventas')
          alerta(t('deleted-sell', {a: ' '}))
        }
      } else {
        // Swal.fire("Acción cancelada");
        enterLoading(1);
      }
    });
  }
  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
          {
            isLoading ? <Loader /> :
            <div className="order-details-container box">
                {orderNotFound ?  
                <div className="main-empty">
                  <h2>{t('order-not-found', {a: ' '})}</h2>
                  <img src={logoVentas} alt={t('order-not-found', {a: ' '})}/>
                </div> 
                :
              <>
                <div className='container-orders-reserved-arrow'>
                  <Link to="/dashboard/tienda/historial-de-ventas"><ArrowLeftOutlined /></Link>
                </div>
                <div className="order-details-header">
                  <h2>{t('sale', {a: ' '})} #{orderDetail.id} - {moment(orderDetail.createdAt).format('L')}</h2>
                </div>
                <div className="order-details-summary">
                  <div className="order-details-summary-header">
                    <p>{t('sold-to', {a: ' '})} {orderDetail.buyerName}</p>
                    <p className="order-details-summary-price">{t('total-sold', {a: ' '})}: <span>{`${orderDetail.totalPrice}€`}</span></p>
                    {/* <p className="order-details-summary-status" >{orderDetail.status}</p> */}
                    <p>{t('delivered-store', {a: ' '})}</p>
                  </div>
                  <Divider />
                  <div className="order-details-summary-body">
                    {
                      orderDetail.orderItem.map(od => (
                        <div className="order-details-summary-order-item" key={od.id}>
                          <div>
                            <img className="order-details-summary-img" src={od.productImageUrl} alt={od.productName}/>
                          </div>
                          <div className="order-details-summary-content">
                            <p>{t('product-name', {a: ' '})}: <Link to={`/producto/${od.productId}/${od.productName.trim().toLowerCase().replace(/\s+/g, '-')}/1`}><span className="order-details-summary-name">{od.productName}</span></Link> </p>
                            <p>{t('product-description', {a: ' '})}: <span className="order-details-summary-description">{od.productDescription !== '' ? od.productDescription : 'Sin descripción'}</span> </p>
                            <p>{t('quantity', {a: ' '})}: <span className="order-details-summary-description">{od.quantity}</span> </p>
                            <p>{t('price', {a: ' '})}: <span className="order-details-summary-description">{od.productPrice}</span> </p>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                {/* <div className="order-details-summary-footer">
                  <p className="order-details-summary-storeName">Vendedor <span><Link to={`/vendedor/${orderDetail.orderItem[0].product.store.userId}/${orderDetail.orderItem[0].product.store.storeName.trim().toLowerCase().replace(/\s+/g, '-')}`}>{orderDetail.orderItem[0].product.store.storeName}</Link></span> </p>
                  <Link to={`/producto/${orderDetail.orderItem[0].product.id}/${orderDetail.orderItem[0].product.slug}`}>Ver producto</Link>
                </div> */}
                {console.log(orderDetail)}
              </>
              }
              <Button className="round-btn btn-first delete-btn" loading={loadings[0]} danger onClick={() => confirmCancelOrden(orderDetail.id)} htmlType='submit' type="primary">{t('delete-sell', {a: ' '})}</Button>
            </div>
          }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(ViewOrderDetailStore))
