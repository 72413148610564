import React from 'react';
import { withRouter } from "react-router-dom";
import './Register.scss';
/*import {validateEmail, validatePassword, validateConfirmedPassword, /*validateNotEmpty} from '../../validation/validations';*/
import {Form, Input, Button, Alert, Checkbox} from 'antd';
//import { ArrowRightOutlined } from '@ant-design/icons';
import Logo from '../../Atoms/Logo/Logo';
import { connect } from 'react-redux';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import { Link } from 'react-router-dom';
// import RegisterGoogle from '../../Molecules/RegisterGoogle/RegisterGoogle';
import { withTranslation, Trans } from 'react-i18next';
class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            /*emailError: '',
            passwordError: '',*/
            confirmedPasswordError: '',
            registerError: '',
            loadings: [],
            termsCheck: false,
            termsCheckError: ''
        } 
        this.handleFormSubmit=this.handleFormSubmit.bind(this);
        const { t } = this.props;
    }
    enterLoading = index => {
        const newLoadings = [...this.state.loadings];
        newLoadings[index] = true;
        this.setState({
          loadings: newLoadings,
        });
    };
    handleFormSubmit(ev) {
        const { t } = this.props;
        
        let objUser = { 
          "email": ev.email.trim().toLowerCase(),
          "password": ev.password.trim(),
          "totalReputation": 0,
          "newsletter": false,
          "verified": false,
          "reconfirmed": true,
          "blocked": false,
          "userRoleId": 1,
          "trial": false,
          "totalSessions": 0,
          "googleRegister": false,
          "modifiedEmail": null,
          "reconfirmed": true
        }
        let objPerson = {
          "firstName": ev.fName.trim(),
          "lastName": ev.lName.trim(),
          "userId": null
        }
        let response = []
        response.push(objUser)
        response.push(objPerson)

        this.setState({
            registerError: '',
        })

        /*const validFirstName = validateNotEmpty(ev.fName);
        const validLastName = validateNotEmpty(ev.lName);
        //const validConfirmedPassword=validateConfirmedPassword(ev.password,ev['confirmed-password']);
        //const validTermsCheck=this.state.termsCheck ? '' : 'Debe aceptar los Términos y condiciones de propT1.'
        /*if (/*validEmail === '' && validPassword === '' && validConfirmedPassword === '' /*&& validTermsCheck === '') {*/
            this.enterLoading(0);
            console.log('response', response)
            console.log('JSON.stringify(response)', JSON.stringify(response))
            localStorage.removeItem('token');
            localStorage.removeItem('remember');
            fetch(`${process.env.REACT_APP_API_URL}/signup`, { 
              headers: {
                'Content-Type': 'application/json',
              },
              method: 'POST',
              body: JSON.stringify(response)
            })
            .then(res => res.json())
            .then(res => {
              console.log(res)
              if (res.message === "User created") {
                this.props.dispatch({
                  type: SAVE_LOGIN_USER,
                  data: res.createUser
                })
                this.props.history.push('/registro/verificar-email')
              } else if(res.message === "Email already exist") {
                    const newLoadings = [...this.state.loadings];
                    newLoadings[0] = false;
                    return this.setState({
                        registerError: t("reg-email-exist", {a: ' '}),
                        loadings: newLoadings
                    })
                } else {
                    const newLoadings = [...this.state.loadings];
                    newLoadings[0] = false;
                    return this.setState({
                        registerError: t("oops", {a: ' '}),
                        loadings: newLoadings
                    })
                }
              
            }).catch(err => {
                const newLoadings = [...this.state.loadings];
                newLoadings[0] = false;
                this.setState({
                    registerError: t("oops", {a: ' '}),
                    loadings: newLoadings
                })
            })
    
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <Form name='register-form' onFinish={this.handleFormSubmit} className='form max-with-form'>
                    <Link to='/'><Logo /></Link>
                    <h1 className='center-text title-form'>{t('reg-titulo', {a: ' '})}</h1>
                    {/* <h6 className='center-text'>Entra con</h6> */}
                    {/* <Button type='primary' className='btn-first center-block google-register-btn'>Google</Button> */}
                    {/* <div className="container-btn-google-login"><RegisterGoogle /></div> */}
                    {/* <h6 className='center-text google-margin'>O ingresando tus datos</h6> */}
                    <Form.Item name='fName' label={t('nombre', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
                        <Input placeholder={t('nombre', {a: ' '})} className='text-input' />
                    </Form.Item> 
                    <Form.Item name='lName' label={t('apellido', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
                        <Input placeholder={t('apellido', {a: ' '})} className='text-input' />
                    </Form.Item>
                    <Form.Item name='email' label={t('correo', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}, {type: 'email', message: t('invalid-email', {a: ' '})}]}>
                        <Input placeholder={t('correo', {a: ' '})} className='text-input' />
                    </Form.Item>
                    {/* <Alert className='register-email-alert mb-10 mt-5' type='info' message='NOTA: Se recomienda usar correos gmail para facilitar la recepción de correos. El uso de correos hotmail u otros no se recomienda.' /> */}
                    <Alert className='register-email-alert mb-10 mt-5' type='info' message={t('post-data', {a: ' '})} />
                    {/*this.state.emailError && <Alert message={this.state.emailError} type='error' className='error-msg' />*/}
                    <Form.Item name='password' label={t('contraseña', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}, {validator: (_, value) => /\S{8,}/.test(value) ? Promise.resolve() : Promise.reject(t("error-password", {a: ' '}))}]}>
                        <Input.Password placeholder={t('contraseña', {a: ' '})} className='text-input' />
                    </Form.Item>
                    <Form.Item name='confirmed-password' label={t('confirm-contraseña', {a: ' '})} hasFeedback dependencies={['password']} rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}, ({getFieldValue}) => ({validator(_,value) {if (!value || !getFieldValue('password') || getFieldValue('password').trim() === value.trim()) {return Promise.resolve();} return Promise.reject(t('pass-not-match', {a: ' '}))}})/*, {validator: (_, value) => value === 'hola' ? Promise.resolve() : Promise.reject('formato malo, validar formato con regex aqui')}*/]}>
                        <Input.Password placeholder={t('confirm-contraseña', {a: ' '})} className='text-input'/>
                    </Form.Item>
                    <Form.Item name='terms-conditions' valuePropName='checked' hasFeedback rules={[{validator: (_, value)=> value? Promise.resolve():Promise.reject(t("reg-checkbox-error", {a: ' '}))}]}>
                        <Checkbox>
                            <Trans i18nKey='reg-checkbox' values={{a: ' '}}>
                                Declaro que soy mayor de edad y acepto los <Link to='/terminos-y-condiciones' rel='noopener noreferrer' target="_blank">Términos y condiciones</Link> de propt1.
                            </Trans>    
                        </Checkbox>
                    </Form.Item>
                    <Form.Item name='submit-btn'>
                        <Button type='primary' htmlType='submit' className='btn btn-first' loading={this.state.loadings[0]}>
                            {t('register', {a: ' '})}
                        </Button>
                    </Form.Item>
                    {this.state.registerError && <Alert message={this.state.registerError} type='error' className='error-msg' />}
                    <p className="form-info font-size-body">
                        <Trans i18nKey='reg-login' values={{a: ' '}}>
                            ¿Ya tienes una cuenta? <Link to='login'>Inicia sesión</Link>
                        </Trans> 
                    </p> 
                </Form>
            </>   
        )      
    }
}

export default withRouter(connect()(withTranslation()(Register)))
