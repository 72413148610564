import React from 'react';
import {Link} from 'react-router-dom';
import {Card, Button, Rate} from 'antd';
// import RateTotal from '../../Atoms/RateTotal/RateTotal';
import './SellerBoxProfile.scss';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import {useTranslation} from 'react-i18next';

const SellerBox = ({idFav, idStore, nameStore, reload, setReload, setIsLoading, location,category,delivery,ratingStore, imgStore}) => {
  const { t } = useTranslation();
  const removeFavorite = (idFav, nameStore) => {
    Swal.fire({
      title: `${t("confirm-delete-favorite", {x: nameStore, a: ' '})}`,
      text: t("delete-cat-warning-2", {a: ' '}),
      icon: "warning",
      showCancelButton :true,
      confirmButtonText: t("confirm-delete", {a: ' '}),
      cancelButtonText: t('keep', {a: ' '})
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        let url = `${process.env.REACT_APP_API_URL}/favorite/${idFav}`
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        })
        if(response.status === 200) {
          console.log(response)
          Swal.fire(`${nameStore} ${t("deleted-fav", {a: ' '})}`, {
            icon: "success",
          });
          setReload(!reload)
        }
      } else {
        Swal.fire(t("cancelled-action", {a: ' '}));
      }
    });
  };
    return (
      <Card className='card-seller-favorites'>
        {/* <Link to={`/vendedor/${idStore}/${nameStore.toLowerCase().replace(/ /g, '-')}`}> */}
        <Link to={`/vendedor/${idStore}`}>
          <img src={imgStore} alt={nameStore} className='seller-img-favorites'/>
        </Link> 
        <div className='card-seller-favorites-description'>
          <h5 className='blue-text card-seller-name'>{nameStore}</h5>
          {/* <RateTotal rating={props.rating}/> */}
          <Rate allowHalf disabled defaultValue={ratingStore} className="container-rate-favorites"/>
            {/* <p className='main-text'>{location}</p> */}
            {/* <p className='gray-text'>{category}</p> */}
            {/* <p className='blue-text'>{delivery}</p> */}
          <Button danger onClick={() => removeFavorite(idFav, nameStore)} className='center-block btn-removeFavorite-profile round-button button-small'>{t('quit', {a: ' '})}</Button>
        </div>
      </Card>
    )
}

export default SellerBox;
