import React from 'react';
import { Button } from 'antd'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import {useTranslation} from 'react-i18next';


const OrderCardDescriptionPurchase = ({order ,history, language}) => {
  const { t } = useTranslation();
  const goToDescription = (id) => {
    history.push(`/dashboard/perfil/compras-activas/ord-${id}`)
  }

  console.log('order:::::::::::::', order)
  // Sumar el total 
  // const getTotal = orderArr => {
  //   let total=0;
  //   orderArr.forEach(product=>{
  //     total += product.quantity * product.product.price
  //   })
  //   return total;
  // }

  const languages = {
    'Reservado': 'Reservat',
    'En espera': 'En espera',
    'Entregado': 'Lliurat',
    'Cancelado': 'Cancel·lat'
  }

  return (
    <div className="card-product" key={order?.id}>
      <div className="cart-seller-info">
        {/* <h1 className="cart-seller-info--name">{`${t("seller", {a: ' '})} ${order?.orderItem[0]?.product?.store ? order?.orderItem[0]?.product?.store?.storeName: 'N/A'}`}</h1> */}
        <h1 className="cart-seller-info--name">{`${t("seller", {a: ' '})} ${order?.sellerName}`}</h1>
        <h6>{t("order-number", {x:order.id, a: ' '})} {moment(order.createdAt).format('L')}</h6>
        <h4 className="cart-seller-info--status">{`${t("order", {a: ' '})}: ${language !== 'CA' && language !== 'ca-ES' ? order?.status : languages[order?.status]}`}</h4>
        <Button className="round-btn btn-first"  onClick={() => goToDescription(order.id)} htmlType='submit' type="primary">{t("order-detail", {a: ' '})}</Button>
      </div> 
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps,{})(OrderCardDescriptionPurchase))
