import React, { useRef, useState } from 'react';
import './OrderCardDescription.scss'
import { Button } from 'antd'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import alerta from '../../../utilityFunctions/alerta';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import {useTranslation} from 'react-i18next';

const OrderCardDescriptionHistory = ({orderSeller, userInfo, history, deliveryConfirmed, setReload, language}) => {
  const { t } = useTranslation();
  const [loaderSpiner, setLoaderSpiner] = useState(false)
  const [loadings, setLoadings] = useState([])
  const containerRemember = useRef()

  // Loader del boton 
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }
  
  const goToDescription = (id) => {
    history.push(`/dashboard/tienda/detalle/ord-${id}`)
  }

  const rememberRate = (orderId, email, buyerName) => {
    containerRemember.current.style.pointerEvents = "none";
    setLoaderSpiner(true)
    // console.log(orderId)
    // console.log(email)
    // console.log(buyerName)
    fetch(`${process.env.REACT_APP_API_URL}/reputation-reminder`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({
        "buyerName": buyerName,
        "email" : email,
        "orderId": orderId,
        esp: localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res.message === 'process done') {
        setReload(true)
        setLoaderSpiner(false)
        alerta(`Recordatorio enviado a ${orderSeller.buyerName}`)
      }
      // console.log(res)
    })
  }


   // Eliminar venta
   const confirmCancelOrden = (orderId) => {
    enterLoading(0);
    Swal.fire({
      title:t("confirm-delete-sell", {a: ' '}),
      text: t("delete-cat-warning-2", {a: ' '}),
      icon: "warning",
      showCancelButton :true,
      confirmButtonText: t("confirm-delete", {a: ' '}),
      cancelButtonText: t("keep", {a: ' '}),
    })
    .then( async (result) => {
      // console.log(result)
      if (result.isConfirmed) {
        let url = `${process.env.REACT_APP_API_URL}/confirm-cancelation/${orderId}/${userInfo.person.userId}`
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        })
        if(response.status === 200) {
          // console.log(response)
          // setReload(true)
          alerta(t("deleted-sell", {a: ' '}))
        }
      } else {
        // Swal.fire("Acción cancelada");
        enterLoading(1);
      }
    });
  }

  const languages = {
    'Reservado': 'Reservat',
    'En espera': 'En espera',
    'Entregado': 'Lliurat',
    'Cancelado': 'Cancel·lat'
  }

  return (
    <div key={orderSeller.id}>
      {console.log('ViewOrderDetailStore', orderSeller)}
      <div className="card-product">
        <div className="cart-seller-info">
          <h1 className="cart-seller-info--name">{`${t("buyer", {a: ' '})}: ${orderSeller.buyerName}`}</h1>
          <h6>{t("order-number", {a: ' ', x: orderSeller.id})} {moment(orderSeller.createdAt).format('L')}</h6>
          <h4 className="cart-seller-info--status">{`${t("order", {a: ' '})}: ${language !== 'CA' && language !== 'ca-ES' ? orderSeller.status : languages[orderSeller.status]}`} </h4>
          <h6>{t("order-qualified", {a: ' '})}: <span className="cart-seller-info--status">{deliveryConfirmed ? t("no-qualified", {a: ' '}) : t("yes-qualified", {a: ' '})}</span></h6>
          {orderSeller.remembered && deliveryConfirmed && <h6 className="mb-5">{t("reminder-sent2", {a: ' '})}</h6>}
          {
            orderSeller.person !== null && !orderSeller.remembered && 
            <div  className="container-remember-to-rate">
              {deliveryConfirmed && <h6 ref={containerRemember} onClick={() => rememberRate(orderSeller.id, orderSeller.buyerEmail, orderSeller.buyerName)} className="remember-to-rate">{t("remind-client", {a: ' '})}<span>*</span> </h6>}
              {loaderSpiner && <LoadingOutlined />}
            </div>
          }
        { /*<h6 className="cart-seller-info-data">Total de la compra: <span>{`${orderSeller.totalPrice}`}</span></h6>*/}
          <div className="cart-seller-info-data-btns">
            <Button className="round-btn btn-first"  onClick={() => goToDescription(orderSeller.id)} htmlType='submit' type="primary">{t("order-detail", {a: ' '})}</Button>
            {/* <Button className="round-btn btn-first delete-btn" loading={loadings[0]} danger onClick={() => confirmCancelOrden(orderSeller.id)} htmlType='submit' type="primary">Eliminar venta</Button> */}
          </div>
        </div>
      </div>
    </div> 
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps,{})(OrderCardDescriptionHistory))
