import React, { useState, useEffect }from 'react';
import './SellerInfo3.scss';
import {Drawer, Collapse, Rate, Button} from 'antd';
import {StarFilled, WhatsAppOutlined, ArrowRightOutlined,PhoneOutlined,EnvironmentFilled,CarOutlined,FieldTimeOutlined,ShopOutlined, FileProtectOutlined,CheckCircleOutlined, EuroCircleOutlined} from '@ant-design/icons';
import {withRouter, Link} from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import alerta from '../../../utilityFunctions/alerta';
import {useTranslation} from 'react-i18next';

const SellerInfo3 = (props) => {
    const { t } = useTranslation();
    const [favorite, setFavorite] = useState(false);
    const [favId, setFavId] = useState(undefined);
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
      setVisible(true);
    };
    const onClose = () => {
      setVisible(false);
    };

    const addToFavorite = () => {
        props.setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/favorite`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
            body: JSON.stringify({
              "personId": props.userInfo.id, 
              "storeId": props.sellerInfo.id 
            })
        })
        .then(res => res.json())
        .then(res=>{
            // console.log(res)
            if (res.message === 'process done') {
                setFavorite(true)
                props.setIsLoading(false);
                alerta(t('added-to-fav', {a: ' '}))
            } else {
              props.setIsLoading(false);
              Swal.fire({
                icon: 'error',
                title: t('oops', {a: ' '}),
                text: '',
              })
            }
        })
        .catch(erro => {
          Swal.fire({
            icon: 'error',
            title: t('oops', {a: ' '}),
            text: '',
          })
        })
    };

    const removeFavorite = (storeName) => {
      Swal.fire({
        title: `${storeName} ${t('already-fav', {a: ' '})}`,
        // text: "Luego de cancelar no podra revertir esta acción ",
        icon: "warning",
        showCancelButton :true,
        confirmButtonText: t('yes', {a: ' '}),
        cancelButtonText: t('no', {a: ' '})
      })
      .then( async (result) => {
        if (result.isConfirmed) {
          props.setIsLoading(true);
          let url = `${process.env.REACT_APP_API_URL}/favorite/${favId}`;
          const response = await fetch(url, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
          if(response.status === 200) {
            // setReload(true)
            // history.push('/dashboard/tienda/ventas-activas')
            // history.push(`/dashboard/tienda/ventas-activas/ord-${match.params.id}`)
            props.setIsLoading(false);
            alerta(t('deleted2', {a: ' '}))
            // alerta(`Pedido entregado con exito a ${orderSeller.buyerName}`)
            console.log(response)
          } else {
            // enterLoading2(1)
            Swal.fire({
              icon: 'error',
              title: t('oops', {a: ' '}),
              text: '',
            })
          }
        } else {
          props.setIsLoading(false);
        }
      });
    };

    // const removeFavorite = (storeName) => {
    //     props.setIsLoading(true);
    //     console.log(favId)
    //     fetch(`${process.env.REACT_APP_API_URL}/favorite/${favId}`, {
    //         method: 'DELETE',
    //         headers: {
    //           'Authorization': `Bearer ${localStorage.getItem('token')}`
    //         }
    //     })
    //     .then(res => res.json())
    //     .then(res=>{
    //         if (res.message==='Favorite deleted') {
    //             setFavorite(false)
    //             props.setIsLoading(false);
    //         }
    //     })
    // };

    const getFavorites = () => {
        if (props.sellerInfo.userId !== props.userInfo.id) {
            fetch(`${process.env.REACT_APP_API_URL}/favorites/${props.userInfo.id}`, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(res=>{
                if (res.message==='favorites by user') {  
                    res.findFavorites.forEach(favObj=>{
                        if (favObj.store.userId === props.sellerInfo.userId) {
                            setFavorite(true);
                            setFavId(favObj.id);
                        }
                    }); 
                }
            })
        }
    };

    const goToStoreLocation = () => {
        if (props.sellerInfo.location.length > 0) {
            props.history.push(`/tienda/mapa/${props.sellerInfo.userId}`)
        } else {
            Swal.fire({
              icon: 'error',
              title: t("location-not-found-title", {a: ' '}),
              text: t("location-not-found-msg", {a: ' '}),
            })
        }
    }
    useEffect(getFavorites, []);

  return (
    <div className="main-container-store-info box">
      <div className="container-store-info">
        <div className="container-store-portrait-picture">
          <img src={props.sellerInfo.portraitPicture.url} alt={props.sellerInfo.storeName}/>
        </div>

        <div className="container-store-container-icon-movil">
          <div className="p2-desktop-container-location">
            <div className="container-store-container-icon">
              {
                props.sellerInfo.userId === props.userInfo.id ?
                <Link to='/dashboard/tienda'>
                  <div className="container-store-container-icon-1">
                    <ShopOutlined twoToneColor="#161B4B" />
                  </div>
                </Link>
                :

                favorite?
                <div className="container-store-container-icon-2" onClick={() => removeFavorite(props.sellerInfo.storeName)}>
                  <StarFilled twoToneColor="#161B4B" /> 
                </div>
                :
                <div className="container-store-container-icon-2" onClick={addToFavorite} >
                  <StarFilled twoToneColor="#161B4B" /> 
                </div>
              }
          <div>
          <EnvironmentFilled className="container-store-container-icon-3" onClick={goToStoreLocation} twoToneColor="#161B4B"/>
          </div>
        </div>
        </div>
        </div>

        <div className="container-store-profile-picture">
          <img src={props.sellerInfo.profilePicture.url} alt={props.sellerInfo.storeName}/>
        </div>
      </div>

      {/* contenido */}
      <div className="container-store-description">
        <div className="s-50"> {/*Container de las nombre categoria y estrealla */}
          <h1 className="blue-text container-store-description-name">{props.sellerInfo.storeName} {props.sellerInfo.userId === props.userInfo.id && <span>({t("your-store", {a: ' '})})</span>}</h1>
          {props.distanceToUser && props.sellerInfo.userId !== props.userInfo.id && <p className='gray-text container-store-description-location movil'>{t("search-distance-2", {x: props.distanceToUser, a: ' '})}</p>}
          <p className='container-store-description-category'>{t("categories", {a: ' '})}: {props.sellerInfo.mainCategory.map(catObj=> props.language === 'CA' || props.language === 'ca-ES' ? catObj.cat : catObj.esp).join(' / ')}</p>
          <Rate clasName="rate-store" allowHalf disabled defaultValue={props.sellerInfo.totalReputation} />
        </div>
        <div > {/*Container de botones y location */}
          <div className="p2-movil">
            <div className="container-btns-movil">
              {props.sellerInfo.userId !== props.userInfo.id &&
                <a href={`https://api.whatsapp.com/send?phone=${props.sellerInfo.mainPhoneNumber}&text=Hola%20${props.sellerInfo.storeName},%20`} target="_blank" rel="noopener noreferrer">
                  <Button className="btn btn-WhatsApp button-small">
                    WhatsApp
                    <WhatsAppOutlined />
                  </Button>
                </a>
              }
              {
                props.sellerInfo.userId === props.userInfo.id ?
                <Button className="btn btn-description button-small" onClick={showDrawer}>
                 {t("description", {a: ' '})}
                  <ArrowRightOutlined />
                </Button>
                :
                <Button className="btn btn-description button-small" onClick={showDrawer}>
                  {t("description", {a: ' '})}
                  <ArrowRightOutlined />
                </Button>
              }
              </div>
          </div>
          <div className="p2-desktop">
            <div className="p2-desktop-container-location">
              {props.distanceToUser && props.sellerInfo.userId !== props.userInfo.id && <p className='gray-text center-text container-store-description-location'>{t("search-distance-2", {x: props.distanceToUser, a: ' '})}</p>}
              <div className="container-store-container-icon">
                {
                  props.sellerInfo.userId === props.userInfo.id ?
                  <Link to='/dashboard/tienda'>
                    <div className="container-store-container-icon-1">
                      <ShopOutlined twoToneColor="#161B4B" />
                    </div>
                  </Link>
                  :

                  favorite?
                  <div className="container-store-container-icon-2" onClick={() => removeFavorite(props.sellerInfo.storeName)}>
                    <StarFilled twoToneColor="#161B4B" /> 
                  </div>
                  :
                  <div className="container-store-container-icon-2" onClick={addToFavorite} >
                    <StarFilled twoToneColor="#161B4B" /> 
                  </div>
                }
              <div>
              <EnvironmentFilled className="container-store-container-icon-3" onClick={goToStoreLocation} twoToneColor="#161B4B"/>
              </div>
            </div>
            </div>
            <div className="p2-desktop-container-btns">
              {props.sellerInfo.userId !== props.userInfo.id &&
                <div>
                  <a href={`https://api.whatsapp.com/send?phone=${props.sellerInfo.mainPhoneNumber}&text=Hola%20${props.sellerInfo.storeName},%20`} target="_blank" rel="noopener noreferrer">
                    <Button className="btn btn-WhatsApp button-small">
                      WhatsApp
                      <WhatsAppOutlined />
                    </Button>
                  </a>
                </div>
              }
              {
                props.sellerInfo.userId === props.userInfo.id ?
                <Button className="btn btn-description button-small" onClick={showDrawer}>
                  {t("description", {a: ' '})}
                  <ArrowRightOutlined />
                </Button>
                :
                <Button className="btn btn-description button-small" onClick={showDrawer}>
                  {t("description", {a: ' '})}
                  <ArrowRightOutlined />
                </Button>
              }
            </div>
          </div>
        </div>
      </div>
       {/* menu */}
      <Drawer
          title={props.sellerInfo.storeName}
          placement="right"
          closable={true}
          onClose={onClose}
          visible={visible}
          width={300}
          className="drawer-store"
        >
          <div className="drawer-info-store">
            <p className='more-info-panel-text'><FileProtectOutlined /><span className='more-info-bold'>{t("description", {a: ' '})}:</span> {props.sellerInfo.description}</p>
            <p className='more-info-panel-text'><PhoneOutlined /><span className='more-info-bold'>{t("first-phone", {a: ' '})}:</span> {props.sellerInfo.mainPhoneNumber}</p>
            {props.sellerInfo.secondaryPhoneNumber && <p className='more-info-panel-text'><PhoneOutlined /><span className='more-info-bold'>{t("second-phone", {a: ' '})}:</span> {props.sellerInfo.secondaryPhoneNumber}</p>}
            <p className='more-info-panel-text'><EuroCircleOutlined /><span className='more-info-bold'>{t("payment-method", {a: ' '})}:</span> {props.sellerInfo.paymentMethods.map(method => props.language === 'CA' || props.language === 'ca-ES' ? method.cat : method.esp).join(' / ')}</p>
            {props.sellerInfo.schedules.length>0 && <p className='more-info-panel-text'><FieldTimeOutlined /><span className='more-info-bold'>{t("schedule", {a: ' '})}:</span> 
              {t("schedule-result", {
                  x: props.sellerInfo.schedules[0].openingDay,
                  y: props.sellerInfo.schedules[0].closingDay,
                  z: props.sellerInfo.schedules[0].openingTime,
                  v: props.sellerInfo.schedules[0].closingTime,
                  a: ' '
              })}
            </p>}
            {props.sellerInfo.schedules.length>0 && props.sellerInfo.schedules[0].scheduleExtended !== null && <p className='more-info-panel-text'><FieldTimeOutlined /><span className='more-info-bold'>{t("special-schedule", {a: ' '})}:</span> {props.sellerInfo.schedules[0].scheduleExtended}</p>}
            <p className='more-info-panel-text'><CarOutlined /><span className='more-info-bold'>{props.sellerInfo.deliveryTypeId[0]===1?t("delivery", {a: ' '}):t("no-delivery", {a: ' '})}</span></p>
            {props.sellerInfo.deliveryTypeId[0] === 1 && props.sellerInfo.minimumDelivery && <p className='more-info-panel-text'><EuroCircleOutlined /><span className='more-info-bold'>{t("minimum-delivery", {a: ' '})}:</span> {props.sellerInfo.minimumDelivery} €</p>}
          </div>
        </Drawer>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})

export default connect(mapStateToProps)(withRouter(SellerInfo3));
