import React, {useEffect} from 'react'
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux'
//import { Button } from 'antd'
import Logo from '../../Atoms/Logo/Logo';
import './EmailVerification.css'
import logoEmailVerification from '../../../assets/P1-correo-veririficacion-imagen.png';
import {useTranslation, Trans} from 'react-i18next';

function EmailVerification(props) {
  const { t } = useTranslation();

    useEffect(() => {
      document.body.scrollIntoView({block:'start'})
    }, [])
    return (
      <div className='box abs-center'>
        <Logo />
        <h1 className='center-text title-form'>{t("email-welcome-title", {a: ' '})}</h1>
        <p className='center-text'>
          <Trans i18nKey="verify-identity" values={{x: props.userInfo.email, a: ' '}} components={{ bold: <strong />}}/>
        </p>
        <div className='container-logo-verify'><img src={logoEmailVerification} alt="Verificar Identidad"/></div>
        <p className='center-text'>
          <Trans i18nKey="verify-identity-warning" values={{a: ' '}}>
            Si no verificas tu email no podrás utilizar la aplicación. Si no te ha llegado el email de verificación, intenta esperar unos minutos y/o revisar tu carpeta de spam. Si aún no lo has recibido, <Link to='/verificar-email'>presiona aquí</Link>
          </Trans>
        </p>
        {/*<Button type='primary' className='center-block round-btn btn-first' onClick={()=> props.history.push('/inicio')}>Verificar luego</Button>*/}
      </div>
    )
}
const mapStateToProps = state => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps, {})(EmailVerification))
