import React, {useState, useEffect} from 'react';
import {Form, Button, Input,Alert, InputNumber} from 'antd';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import { connect } from 'react-redux';
import alerta from '../../../utilityFunctions/alerta';
import {withRouter} from 'react-router-dom';
import './AddressForm.scss';
import Logo from '../../Atoms/Logo/Logo';
import {useTranslation} from 'react-i18next';


function AddressForm(props) {
    const { t } = useTranslation();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [formError, setFormError] = useState('')

    useEffect(() => {
      document.body.scrollIntoView({block:'start'})
    }, [])
    
    const submitForm = e => {
        setButtonLoading(true);
        setFormError('');
        // console.log(e)
        fetch(`${process.env.REACT_APP_API_URL}/create-location/${props.userInfo.id}`, { 
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
            body: JSON.stringify([{
                //address: `${e.street}, ${e.city} ${e.postalCode}, ${e.province}`,
                address: [e.street, e.postalCode, e.city, e.province].join('+++'),
                personId: props.userInfo.person.id,
                additionalInformation: e.additional,
                phoneNumber: '+34'+e.phone
            }])
        })
        .then(res => res.json())
        .then(res => {
            setButtonLoading(false);
            if (res.message === 'location created') {
                props.dispatch({
                  type: SAVE_LOGIN_USER,
                  data: res.findUser
                });
                alerta(t("added-address", {a: ' '}))
                props.history.push('/dashboard/perfil')
              } else {
                setFormError(t("oops", {a: ' '}))
              }
        })
        .catch(err => {
            setButtonLoading(false);
            setFormError(t("oops", {a: ' '}))
            console.log(err);
        })
    }
    const editForm = e => {
        setButtonLoading(true);
        setFormError('');
        fetch(`${process.env.REACT_APP_API_URL}/location/${props.userInfo.person.location[0].id}/${props.userInfo.id}`, { 
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
            body: JSON.stringify({
                //address: `${e.street}, ${e.city} ${e.postalCode}, ${e.province}`,
                address: [e.street, e.postalCode, e.city, e.province].join('+++'),
                additionalInformation: e.additional,
                phoneNumber: '+34'+e.phone
            })
        })
        .then(res => res.json())
        .then(res => {
            setButtonLoading(false);
            if (res.message === 'store location updated') {
                props.dispatch({
                  type: SAVE_LOGIN_USER,
                  data: res.findUser
                });
                alerta(t("location-edited", {a: ' '}))
                props.history.push('/dashboard/perfil')
              } else {
                setFormError(t("oops", {a: ' '}))
              }
        })
        .catch(err => {
            setButtonLoading(false);
            setFormError(t("oops", {a: ' '}))
            console.log(err);
        })
    }
    
    let initialValues = null;
    if (props.userInfo.person.location.length>0) {
        const splittedString = props.userInfo.person.location[0].address.split('+++');
        initialValues = {
            street: splittedString[0],
            postalCode: splittedString[1],
            city: splittedString[2],
            province: splittedString[3],
            phone: props.userInfo.person.location[0].phoneNumber.slice(3),
            additional: props.userInfo.person.location[0].additionalInformation
        }
    } 

    return (
      <>
        <div className="profile-container">
          <div className="wrapped-profile-container ">
          <div className="address-form box">
            <Form name='user-address-form' onFinish={initialValues?editForm:submitForm} initialValues={initialValues}>
                <Logo />
                <h1 className='center-text title-form'>{t("delivery-address", {a: ' '})}</h1>
                <Form.Item name='street' label={t("complete-address", {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
                    <Input placeholder={t("complete-address", {a: ' '})} className='text-input' />
                </Form.Item>
                <Form.Item name='postalCode' label={t("postal-code", {a: ' '})} hasFeedback rules={[{validator:(_, value)=> value?typeof Number(value) === 'number' ? String(value).length === 5 ? Promise.resolve():Promise.reject(t('postal-valid', {a: ' '})):Promise.reject(t('numeric', {a: ' '})):Promise.reject(t('required', {a: ' '}))},{required: true, message: ' '}]}>
                    <Input placeholder={t("postal-code", {a: ' '})} maxLength={5} className='text-input' />
                </Form.Item>
                <Form.Item name='city' label={t("city", {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
                    <Input placeholder={t("city", {a: ' '})} className='text-input' />
                </Form.Item>
                <Form.Item name='province' label={t('province', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}]}>
                    <Input placeholder={t('province', {a: ' '})} className='text-input' />
                </Form.Item>
                {/* <Form.Item name='phone' label='Teléfono de contacto' hasFeedback rules={[{whitespace: true, required: true, message: 'Este campo no debe ser vacío'}]}>
                    <Input addonBefore="+34" maxLength={9}  placeholder='Teléfono de contacto' className='text-input' />
                </Form.Item> */}
                <Form.Item name='phone' label={t('phone', {a: ' '})} rules={[{validator:(_, value)=> value?value.length === 9 ? !isNaN(value)?Promise.resolve():Promise.reject(t('numeric', {a: ' '})):Promise.reject(t('phone-valid', {a: ' '})):Promise.reject(t('required', {a: ' '}))},{required: true, message: ' '}]}>
                  {/*<InputNumber
                      className='text-input input-phone'
                      maxLength={11}
                      formatter={value => `${value}`.replace(/(\d{3})(?=\d)/g, "$1-")}
                      parser={value => `${value.replace(/\$\s?|(-*)/g, '')}`}
                      placeholder='Ej: (+34) xxx-xxx-xxx'
                  />*/}
                    <Input addonBefore="+34" maxLength={9} placeholder={t('phone', {a: ' '})} className='text-input' size='large'/>
                </Form.Item>
                <Form.Item name='additional' label={t('additional-info', {a: ' '})} hasFeedback rules={[{whitespace: true, message: t("not-empty", {a: ' '})}]}>
                    <Input placeholder={t('additional-info', {a: ' '})} className='text-input' />
                </Form.Item>
                    <Button htmlType='submit' type='primary' className='btn btn-first mb-5' loading={buttonLoading}>{t('save-location', {a: ' '})}</Button>
                    <Button danger className='btn round-button' onClick={props.history.goBack}>{t('cancel', {a: ' '})}</Button>
                {formError && <Alert message={formError} type='error' className='error-msg' />}
            </Form>
            </div>
            </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    userInfo: state.userReducer.userInfo
})

export default connect(mapStateToProps)(withRouter(AddressForm));
