import React from 'react'
import './InstallPWA.scss'
import pwa from '../../../assets/P1-phone.png';
import pwaIcon from '../../../assets/pwa.svg';
import Swal from 'sweetalert2';
import {useTranslation} from 'react-i18next';

const InstallPWA = () => {
  const { t } = useTranslation();
  // Alerta para IOS
  const installIos = () => {
    Swal.fire({
      title: t('install-ios', {a: ' '}),
      text: t('install-ios-instructions', {a: ' '}),
      imageUrl: pwa,
      imageWidth: 270,
      imageHeight: 520,
      imageAlt: 'Install-pwa-ios',
    })
  }

  const validateOs = navigator.userAgent.indexOf('Mac OS X') !== -1

  return (
      validateOs === true ?
      <img src={pwaIcon} alt="Instala nuestra pwa" className="img-logo-pwa" id="install-pwa-ios" onClick={installIos}/>
      :
      <img src={pwaIcon} alt="Instala nuestra pwa" className="img-logo-pwa" id="buttonAdd"/>
      
  )
}

export default InstallPWA
