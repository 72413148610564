import React from 'react';
import {Button} from 'antd';
import {MailOutlined} from '@ant-design/icons';
import Logo from '../../Atoms/Logo/Logo';
import './PricingTableModal.scss';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const PricingTableModal = props => {
  const { t } = useTranslation();
  return (
    <div >
      <Logo />
      <div className="center-text mb-4">
        <h1 className="title-form">{t("plan-title", {a: ' '})}</h1>
      </div>
      <div className="row mb-5 justify-content-center">
        <div className="col-lg-4 main-card-body">
          <div className="card">
            <div className="card-body card-body-pricing ">
              <div className="pt-2 pb-2 pl-2 pr-2 text-center flex-grow-1 card-body-content">
                <MailOutlined className='plan-icon' />
                <div className="font-weight-bold plan-heading">{t("plan-month", {a: ' '})}</div>
                <div className="font-weight-bold mb-3 plan-price">
                {t("plan-price", {a: ' '})}
                <span> ({t("impostos-message", {a: ' '})})</span>
                </div>
                <ul className="list-unstyled">
                  <li>{t("plan-free", {a: ' '})}</li>
                  <li>{t("plan-benefit", {a: ' '})}</li>
                </ul>
                {!props.hideButtons && <Link to='/dashboard/adquirir-membresia/1'><Button type='primary' className='round-button btn-first'>{t("select", {a: ' '})}</Button></Link>}
              </div>
            </div>
          </div>
        </div>
        {/*<div className="col-lg-4 main-card-body">
          <div className="card bg-primary text-white card-body-two">
            <div className="card-body ">
              <div className="pt-5 pb-5 pl-5 pr-5 text-center flex-grow-1 card-body-content">
                <CrownOutlined className='plan-icon' />
                <div className="font-weight-bold plan-heading">Anual</div>
                <div className="font-weight-bold mb-3 plan-price">
                  60€ <sup>/año</sup>
                </div>
                <ul className="list-unstyled mb-5">
                  <li>Primeros 3 meses gratuitos</li>
                  <li>Publica y ofrece tus productos sin límites</li>
                  <li>Recibe en total 15 meses</li>
                </ul>
                {!props.hideButtons && <Link to='/adquirir-membresia/2'><Button className='round-button'>Seleccionar</Button></Link>}
              </div>
            </div>
          </div>
        </div>*/}
      </div>
      {props.hideButtons && <Button type='primary' size='large' className='round-btn back-btn btn-close-pricing' onClick={props.closeModal}>
        {t('close', {a: ' '})}
      </Button>}
    </div>
  )
}

export default PricingTableModal
