import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import Logo from '../../Atoms/Logo/Logo';
import { connect } from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import CardSection from './CardElement/CardSection.jsx';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Loader from '../../Atoms/Loader/Loader';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import Swal from 'sweetalert2';
import {useTranslation, Trans} from 'react-i18next';
import './StripeConsumerForm.scss';

function StripeConsumerForm(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState('');
    const [planInfo, setPlanInfo] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [customerName, setCustomerName] = useState(null);
    const [paymentMethodDetails, setPaymentMethodDetails] = useState(null);
    const [payWithNewCard, setPayWithNewCard] = useState(null);
    const [sameCardLoading, setSameCardLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const handleFormSubmit = ev => {
        setFormError('');
        setLoading(true);
        if (!props.userInfo.stCustomerId) {
            fetch(`${process.env.REACT_APP_API_URL}/create-customer`, { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    "userId": props.userInfo.id,
                    "name": ev.name,
                    "email": props.userInfo.email
                })
            })
            .then(res => res.json())
            .then(res => {
                // console.log(res);
                if (res.message === 'customer created' || res.message === 'customer found') {
                    createPayment(res.customerData, ev.name)
                } else {
                    throw new Error('Algo mal')
                }
            })
            .catch(err => {
                setFormError(t('oops', {a: ' '}))
                setLoading(false);
            })
        } else {
            createPayment(props.userInfo.stCustomerId, ev.name)
        }
    }
    
    const createPayment = async (customerId, name) => {
        if (!stripe || !elements) {
          return;
        }
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
        if (error) {
          setFormError(error.message);
          setLoading(false);
        } else {
            createSubscription(paymentMethod, customerId, name);
        }
    };

    const createSubscription = (paymentMethod, customerId, name) => {
        // console.log(paymentMethod, customerId, name, paymentMethodDetails, payWithNewCard);
        if (paymentMethodDetails) {
            !payWithNewCard && setSameCardLoading(true);
        } 
        
        fetch(`${process.env.REACT_APP_API_URL}/create-subscription`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                customerId: customerId,
                paymentMethodId: paymentMethod.id,
                priceId: planInfo.stPriceId,
                productSubscriptionId: planInfo.stProductId,
                userId: props.userInfo.id,
                itemName: planInfo.name,
                cardBrand: paymentMethod.card.brand, 
                lastFourNumbers: paymentMethod.card.last4,
                email: props.userInfo.email,
                name: name, 
                totalPrice: planInfo.price,
                currentSubscriptionId: props.userInfo.planUsage ? props.userInfo.planUsage.planSubscriptionId : null
            })
        })
        .then(res => res.json())
        .then(res => {
            // console.log(res);
            if (res.message === 'subscription created') {
                fetch(`${process.env.REACT_APP_API_URL}/plan-usage`, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        userId: props.userInfo.id,
                        planId: planInfo.id,
                        planSubscriptionId: res.createSubscription.id,
                        name: planInfo.name, 
                        quantity: planInfo.quantity,
                        canceled: false,
                        paymentFailed: false,
                        paymentFailedDate: null
                    })
                })
                .then(res => res.json())
                .then(res=>{
                    if (res.message === 'Plan usage created' || res.message === 'Plan usage updated') {
                        props.dispatch({
                            type: SAVE_LOGIN_USER,
                            data: res.findUser
                        });
                        Swal.fire({
                            icon: 'success',
                            title: t("subscription-success", {a: ' '}),
                            text: t("subscription-success-msg", {x: planInfo.name, a: ' '})
                        })
                        /*fetch(`${process.env.REACT_APP_API_URL}/create-stripe-connect`, {
                            method: 'POST',
                            headers: {
                                'Content-type': 'application/json',
                            },
                            body: JSON.stringify({
                               email: props.userInfo.email
                            })
                        })
                        .then(res => res.json())
                        .then(res=> {
                            console.log(res);
                            if (res.message === 'process done') {
                                //setRedirect(true);
                                props.history.push({
                                    pathname: '/dashboard/membresía/exito',
                                    state: {
                                        plan: planInfo.name,
                                        url: res.accountLinkURL
                                    }
                                })
                            } 
                        })*/
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: t("error-occured", {a: ' '}),
                            text: t("error-associating", {a: ' '})
                        })
                    }
                    if (paymentMethodDetails) {
                        !payWithNewCard && detachPayment();
                    }
                    setSameCardLoading(false);
                    setLoading(false);
                })
                .catch(err=>{
                    // console.log(err)
                    setSameCardLoading(false);
                    setLoading(false);
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: t("error-occured", {a: ' '}),
                    text: t("error-paying", {a: ' '})
                })
            }
        })
        .catch(err => {
            // console.log(err)
        })
    }
    const getPlanInfo = () => {
        if (props.userInfo.planUsage && props.userInfo.planUsage.planId === Number(props.match.params.planId)) {
            props.history.push('/dashboard/planes')
            Swal.fire({
                icon: 'error',
                title: t("already-subscribed", {a: ' '}),
                text: t("subscribed", {a: ' '}) + ' '+props.userInfo.planUsage.name
            })
        } else {
            fetch(`${process.env.REACT_APP_API_URL}/plans`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res=>res.json())
            .then(res=>{
                // console.log(res)
                res.getPlans.forEach(plan=>{
                    if (plan.id === Number(props.match.params.planId)) {
                        setPlanInfo(plan);
                        if (props.userInfo.stCustomerId) {
                            fetch(`${process.env.REACT_APP_API_URL}/get-customer/${props.userInfo.stCustomerId}`, {
                                headers: {
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                }
                            })
                            .then(res=>res.json())
                            .then(res=>{
                                if (res.paymentMethod) {
                                    setPaymentMethodDetails({
                                        card: {
                                            brand: res.paymentMethod.card.brand,
                                            last4: res.paymentMethod.card.last4
                                        },
                                        id: res.paymentMethod.id
                                    })
                                } else {
                                    setPayWithNewCard(true);
                                }
                                setCustomerName(res.customer.name)
                                setPageLoading(false);
                            })
                            .catch(err=>{
                                // console.log(err)
                            })
                        } else {
                            setPayWithNewCard(true);
                            setPageLoading(false);
                        }
                    }
                })
            })
            .catch(err => {
                // console.log(err)
            })
        }   
    }
    const detachPayment = () => {
        fetch(`${process.env.REACT_APP_API_URL}/detach-payment-method`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
              currentPaymentMethodId: paymentMethodDetails.id
            })
        })
        .then(res => res.json())
        .then(res => {
            // console.log(res)
        })
        .catch(err=>console.log(err))
    }

    useEffect(getPlanInfo, [])

    return (
        <div className="profile-container">
            <div className="wrapped-profile-container">
                <div className="box container-PricingTable container-StripeConsumerForm">
            {!pageLoading ?
                <>  
                    {/*redirect && <Redirect to={'/dashboard/membresía/exito'} target='_blank' />*/}
                    <Logo />
                    <div>
                        <h1 className='center-text title-form title-StripeConsumerForm'>Estas suscribiéndote al {planInfo.name}</h1>
                        <h5 className='center-text subtitle-StripeConsumerForm'>Podrás publicar y ofrecer tus productos sin límites</h5>
                        <p className='center-text price-StripeConsumerForm'>Costo: <strong>{planInfo.price} € mensual</strong></p>
                    </div>
                    <Form name='billing-form' onFinish={handleFormSubmit} className='max-with-form' initialValues={{'name': customerName}}>
                        <h1 className='center-text title-form'>Datos de facturación</h1>
                        {paymentMethodDetails && 
                        <div className='center-block'>
                            <p className='center-text'>
                                <Trans 
                                    components={{bold: <strong />}}
                                    i18nKey="card-confirmation" 
                                    values={{x: paymentMethodDetails.card.brand.slice(0,1).toUpperCase().concat(paymentMethodDetails.card.brand.slice(1)), y: paymentMethodDetails.card.last4}} 
                                />
                            </p>
                            <div className='center-text'>
                                <Button type='primary' onClick={()=>{setPayWithNewCard(false); setTimeout(createSubscription(paymentMethodDetails, props.userInfo.stCustomerId, customerName), 0)}} loading={sameCardLoading} className='round-button btn-first'>{t("confirm-card")}</Button>
                                <Button className='round-button' onClick={()=>setPayWithNewCard(true)}>{t("not-confirm-card", {a: ' '})}</Button>
                            </div>
                        </div>}
                        {payWithNewCard && 
                        <div className='center-block'>
                            <Form.Item name='name' label={t("nombre-apellido", {a: ' '})} hasFeedback rules={[{required: true, message: t('required', {a: ' '})}]}>
                                <Input placeholder={t("nombre-apellido", {a: ' '})} className='text-input' />
                            </Form.Item>
                            <CardSection />
                            <Button type='primary' htmlType='submit' className='btn btn-first' loading={loading}>
                                {t("pay-monthly", {x: planInfo.price, a: ' '})}
                            </Button>
                        </div>}
                        {formError && <Alert message={formError} type='error' className='card-error-msg' />}
                    </Form>
                </> : <Loader />
            }
        </div> 
        </div> 
        </div> 
    )
}

const mapStateToProps = (state) => ({
    userInfo: state.userReducer.userInfo
})

export default connect(mapStateToProps)(withRouter(StripeConsumerForm));
