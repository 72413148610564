import React, {useState, useEffect} from 'react'
import SellerBox from '../../Molecules/SellerBox/SellerBox';
import SellerBoxProfile from '../../Molecules/SellerBoxProfile/SellerBoxProfile';
import './SellerCatalog.scss';
import {Button} from 'antd';
import {withRouter} from 'react-router-dom';
import logoTiendas from '../../../assets/P1-home-no-vendedores-imagen.png';
import {useTranslation} from 'react-i18next';
import { connect } from 'react-redux';

const SellerCatalog = props => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const getMore = () => {
    setLoading(true);
    props.getMoreStores();
  }
  useEffect(()=>setLoading(false), [props.sellerList])
  return (
      <>
        {props.sellerList.length > 0 ?
          <>
            <div className="product-container">
              {props.sellerList.map(seller => {
                return (
                  <div key={props.match.params.categories ? seller.storeData.store.id : seller.store.id} >
                    {props.favorite ?
                    <SellerBoxProfile setIsLoading={props.setIsLoading} setReload={props.setReload} reload={props.reload} idFav={seller.id} id={seller.store.userId} name={seller.store.storeName} location='Tarragona, Catalunya. A 20kms de ti' category={seller.store.mainCategory.map(catObj=>catObj.name).join(' / ')} delivery={seller.store.deliveryTypeId[0]===1?t("delivery-type", {a: ' '}):t("delivery-type-2", {a: ' '})} img={seller.store.profilePicture.url} rating={seller.store.totalReputation} />
                    :
                    <SellerBox id={seller.storeData.store.id} name={seller.storeData.store.storeName} location={props.lat ? t("search-distance", {x: seller.distanceToUser.toFixed(1)}):t("search-distance-2", {x: seller.distanceToUser.toFixed(1), a: ' '})} category={seller.storeData.store.mainCategory.slice(0,2).map(catObj => props.language === 'CA' || props.language === 'ca-ES' ? catObj.cat : catObj.esp).join(' / ')} delivery={seller.storeData.store.deliveryTypeId[0]===1?t("delivery-type", {a: ' '}):t("delivery-type-2", {a: ' '})} img={seller.storeData.store.profilePicture.url} rating={seller.storeData.store.totalReputation} />
                    }
                  </div>
                )
              })}
            </div>
            {/*<Button loading={loading} type='primary' className='round-button btn-first pagination-button' onClick={getMore}>Cargar más</Button>*/}
          </> 
            :<div className="main-empty box main-empty-home">
              <h2>{t("no-stores", {a: ' '})}</h2>
              <img src={logoTiendas} alt={t("no-stores", {a: ' '})}/>
            </div>
            }
      </>
  )
}

const mapStateToProps = (state) => ({
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps)(SellerCatalog))
