import React from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getOrders } from '../../../redux/actionCreators';
import store from '../../../redux/store';
import {useTranslation} from 'react-i18next';

const OrderItem = ({userInfo, orderItem, setReload, setAllOrder}) => {
  // const [editItem, setEditItem] = useState(true)
  const { t } = useTranslation();
  // EDITAR CANTIDAD DEL PRODUCTO
  const editQuantity = (previousQuantity, addition, orderItemId, maxQuantity) => {
    if (previousQuantity === 1 && !addition) {
      //ver si mostrar mensaje o algo de eliminar producto
    } else if (previousQuantity===maxQuantity) {
        //ver si mostrar mensaje o algo de que no hay mas disponibilidad
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/edit-order-item/${orderItemId}/${userInfo.person.userId}`, { 
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
            "quantity": addition ? previousQuantity + 1 : previousQuantity - 1,
            "addition": addition
        })
      })
      .then(res => res.json())
      .then(res=> {
        if (res.message==='item modified') {
          setAllOrder(res.findOrders)
        } else {
          //mostrar error o algo
        }
      })
    }  
  }

  // Eliminar Item de una orden
  const handleDeleteItem = (id, name) => {
    Swal.fire({
      title: t("confirm-delete-cart", {x: name, a: ' '}),
      text: t("delete-cat-warning-3", {a: ' '}),
      icon: "warning",
      showCancelButton :true,
      confirmButtonText: t("confirm-delete", {a: ' '}),
      cancelButtonText: t('keep', {a: ' '})
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        let url = `${process.env.REACT_APP_API_URL}/delete-order-item/${id}/${userInfo.person.userId}`;
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        if(response.status === 200) {
          setReload(true)
          store.dispatch(getOrders(userInfo.person.userId)) //Cuando agrego al Carrito me actualiza el store
          Swal.fire(t("deleted-product", {a: ' '}), {
            icon: "success",
          });
        }
      } else {
        Swal.fire(t('cancelled-action', {a: ' '}));
      }
    });
  }

  return (
    <div key={orderItem.id}>
      <div className="cart-seller-product-cart">
        <img className="cart-seller-img" src={orderItem.productImageUrl} alt={orderItem.productName}/>
        <div className="cart-seller-description-Incart">
        <div className="cart-description">
          <div className="title-container">
            <Link to={`/producto/${orderItem.product.id}/${orderItem.product.slug}/1`}><p className="cart-seller-description-title">{orderItem.productName}</p></Link>
          </div>
          <p>{t('price', {a: ' '})} <span>{`${orderItem.productPrice}€`}</span> </p>
          <div className="container-editar">
          <p>{t('quantity', {a: ' '})} <span>{`${orderItem.quantity}`}</span> </p>
          {
            <Button.Group className="container-editQuiantity">
              <Button onClick={()=>editQuantity(orderItem.quantity, false, orderItem.id, orderItem.product.quantity)}>
                <MinusOutlined />
              </Button>
              <Button onClick={()=>editQuantity(orderItem.quantity, true, orderItem.id, orderItem.product.quantity)}>
                <PlusOutlined />
              </Button>
            </Button.Group>
          }
          </div>
          <div className="proando">
          <p className="subtotoal-inCart" >{t('subtotal', {a: ' '})} <span>{`${orderItem.quantity * orderItem.product.price}€`}</span></p>
          <div className="cart-seller-btn-actions">
            {/* {
              !editItem &&
              <p className="btn-edit" onClick={() => setEditItem(true)}>Editar</p>
            }
            {
              editItem &&
              <p className="btn-edit" onClick={() => setEditItem(false)}>Guardar</p>
            } */}
            <p className="btn-cancel" onClick={() => handleDeleteItem(orderItem.id, orderItem.product.name)}>{t("delete-product", {a: ' '})}</p>
          </div>
          </div>
        </div> 
          {/* <div className="cart-seller-btn-actions">
            {
              !editItem &&
              <p className="btn-edit" onClick={() => setEditItem(true)}>Editar</p>
            }
            {
              editItem &&
              <p className="btn-edit" onClick={() => setEditItem(false)}>Guardar</p>
            }
            <p className="btn-cancel" onClick={() => handleDeleteItem(orderItem.id, orderItem.product.name)}>Cancelar</p>
          </div> */}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps,{})(OrderItem)
