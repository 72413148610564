import React, {useState, useEffect} from 'react'
import './StoreReputation.scss'
import { Rate } from 'antd';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Loader from '../../Atoms/Loader/Loader'
import logoVentas from '../../../assets/P1-perfil-compras-no-compras-activas-imagen.png';
import moment from 'moment';
import 'moment/locale/es';
import {useTranslation} from 'react-i18next';

const StoreReputation = ({userInfo, language}) => {
  const { t } = useTranslation();
  const [reputations, setReputations] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
    setIsLoading(true)
    const getReputation = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/store-reputations/${userInfo.store.id}/20/0`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      const data = await response.json()
      if(data.message === 'reputations by user') setReputations(data.findReputations.rows)
      // console.log(data)
      setIsLoading(false)
    }
    getReputation()
  },[userInfo.store.id])

  const languages = {
    'Malo': 'Malo',
    'Podrían Mejorar': 'Podrien Millorar',
    'Correcto': 'Correcte',
    'Bueno': 'Bé',
    'Muy bueno': 'Molt bo',
  }
  
  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
        {
          isLoading ? <Loader /> 
          :
          reputations.length < 1 
          ? <div className="main-empty box not-calification">
              <h2>{t("rep-empty", {a: ' '})}</h2>
              <img src={logoVentas} alt={t("rep-empty", {a: ' '})}/>
            </div>
          :
          <div className="container-reputations box">
            <h2 className='center-text title-form title-reputations'>{t("my-reps", {a: ' '})}</h2>
            <div className="main-reputations-container">
              {
                reputations.map(reputation => (
                  <div className="main-reputation-item" key={reputation.id}>
                  {console.log(reputation)}
                    <div className="main-reputation-container-img">
                      <img className="main-reputation-img" src={reputation.user.profilePicture.url} alt="cliente"/>
                    </div>
                    <div className="main-reputation-content">
                      <p className="main-reputation-name">{`${reputation.user.person.firstName} ${reputation.user.person.lastName}`}</p>
                      <p className="main-reputation-date">{t("rated-date", {a: ' '})} {moment(reputation.createdAt).format('L')}</p>
                      <Rate className="main-reputation-date-star" allowHalf disabled defaultValue={reputation.givenReputation} />
                      <p className="main-reputation-comment"><span>{reputation.user.person.firstName}</span> {`${t('said', {a: ' '})}: ${language === 'CA' || language === 'ca-ES' ? languages[reputation.comment] : reputation.comment}`}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps)(StoreReputation)) 
