import React from 'react';
//import {validatePassword, validateConfirmedPassword} from '../../validation/validations';
import {Form, Input, Button, Alert, Modal} from 'antd';
import {LockOutlined,/* ArrowRightOutlined,*/ LeftOutlined} from '@ant-design/icons';
import Logo from '../../Atoms/Logo/Logo';
import {withRouter} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2';

class NewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            /*passwordError: '',
            confirmedPasswordError: '',*/
            formError: '',
            formSuccess: '',
            loadings: []
        }
        this.handleFormSubmit=this.handleFormSubmit.bind(this);
    }
    enterLoading = index => {
        const newLoadings = [...this.state.loadings];
        newLoadings[index] = true;
        this.setState({
            loadings: newLoadings,
        });
    };
    handleFormSubmit(ev) {
        const { t } = this.props;
        this.setState({
            passwordError: '',
            confirmedPasswordError: '', 
            formError: ''
        })
        /*const validPassword=validatePassword(ev.password);
        const validConfirmedPassword=validateConfirmedPassword(ev.password, ev['confirmed-password']);
        if (validPassword === '' && validConfirmedPassword === '') {*/
            this.enterLoading(0); 
            fetch(`${process.env.REACT_APP_API_URL}/recover-password`, { 
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.props.match.params.token}`
                },
                body: JSON.stringify({
                    "password": ev.password.trim(),
                    "currentDate": moment.utc().format(),
                    "esp": localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
                })
            })
            .then(res=>res.json())
            .then(res=>{
                const newLoadings = [...this.state.loadings];
                newLoadings[0] = false;
                this.setState({
                    loadings: newLoadings
                })
                if (res.message === "process done") {
                    let secondsToGo = 5;
                    const modal = Modal.success({
                        title: t("pass-updated", {a: ' '}),
                        content: t("login-again", {a: ' '}),
                    });
                    const timer = setInterval(() => {
                        secondsToGo -= 1;
                    }, 1000);
                    setTimeout(() => {
                        clearInterval(timer);
                        modal.destroy();
                    }, secondsToGo * 1000);
                    this.props.history.push('/login');         
                } else if (res.message === "Auth failed") {
                    let secondsToGo = 5;
                    const modal = Modal.error({
                        title: t("error", {a: ' '}),
                        content: t("expired-link", {a: ' '}),
                    });
                    const timer = setInterval(() => {
                        secondsToGo -= 1;
                    }, 1000);
                    setTimeout(() => {
                        clearInterval(timer);
                        modal.destroy();
                    }, secondsToGo * 1000);
                    this.props.history.push('/login');
                } else {
                    this.setState({
                        formError: t('oops', {a: ' '})
                    })
                }
            })
            .catch(err=>{
                const newLoadings = [...this.state.loadings];
                newLoadings[0] = false;
                this.setState({
                    formError: t('oops', {a: ' '}),
                    loadings: newLoadings
                })
            })
       /* } else {
            this.setState({
                passwordError: validPassword,
                confirmedPasswordError: validConfirmedPassword
            })
        }*/
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <Logo />
                <h1 className='center-text'>{t("enter-new-pass", {a: ' '})}</h1>
                <Form name='new-password-form' onFinish={this.handleFormSubmit} className='form  max-with-form'>
                    <Form.Item name='password' label={t('contraseña', {a: ' '})} hasFeedback rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}/*, {validator: (_, value) => value === 'hola' ? Promise.resolve() : Promise.reject('formato malo, poner regex aqui')}*/]}>
                        <Input.Password placeholder={t('contraseña', {a: ' '})} prefix={<LockOutlined />} className='text-input' />
                    </Form.Item>
                    {/*this.state.passwordError && <Alert message={this.state.passwordError} type='error' className='error-msg' />*/}
                    <Form.Item name='confirmed-password' label={t("confirm-contraseña", {a: ' '})} hasFeedback dependencies={['password']} rules={[{whitespace: true, required: true, message: t('required', {a: ' '})}, ({getFieldValue}) => ({validator(_,value) {if (!value || !getFieldValue('password') || getFieldValue('password').trim() === value.trim()) {return Promise.resolve();} return Promise.reject(t("pass-not-match", {a: ' '}))}})/*, {validator: (_, value) => value === 'hola' ? Promise.resolve() : Promise.reject('formato malo, validar formato con regex aqui')}*/]}>
                        <Input.Password placeholder={t("confirm-contraseña", {a: ' '})} prefix={<LockOutlined />} className='text-input' />
                    </Form.Item>
                    {/*this.state.confirmedPasswordError && <Alert message={this.state.confirmedPasswordError} type='error' className='error-msg' />*/}
                    <Form.Item>
                        <Button type='primary' htmlType='submit'className='btn' loading={this.state.loadings[0]} >
                            {t("update", {a: ' '})}
                            {/*<ArrowRightOutlined />*/}
                        </Button> 
                    </Form.Item>
                    {this.state.formError && <Alert message={this.state.formError} type='error' className='error-msg' />}
                </Form>
            </>
        )
        
    }
}

export default withRouter(withTranslation()(NewPassword));
