import React, { useState,useEffect} from 'react';
import './ProductDescriptionII.scss';
import {ShoppingCartOutlined, ArrowLeftOutlined, ArrowUpOutlined, EuroCircleOutlined, EditFilled, ZoomInOutlined, ZoomOutOutlined, FullscreenExitOutlined} from '@ant-design/icons';
import {Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../Atoms/Loader/Loader';
import ReactSlick from 'react-slick';
import { Button, InputNumber, Rate } from 'antd';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import alerta from '../../../utilityFunctions/alerta';
import { getOrders } from '../../../redux/actionCreators';
import store from '../../../redux/store';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import notProduct from '../../../assets/P1-Busqueda-no-encontrada.png';
import logoMovil from '../../../assets/logo.png'

const ProductDescriptionII = ({rimProps, match, rsProps, userInfo, userOrders, history, language}) =>  {
    const { t } = useTranslation();
    const [productInfo, setProductInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [quantityValue, setQuantityValue] = useState(1);
    let orderItemId = []
    const [reload, setReload] = useState(false)
    const [loadings, setLoadings] = useState([])
    const [showTop, setshowTop] = useState(false)
    const [productNull, setProductNull] = useState(false) // cuando la tienda esta desactivada y por alguna razon llegas a la vista del producto
    console.log('.match.params', match.params)
    
    const getProduct = () => {
      console.log('.match.params', match.params)
        fetch(`${process.env.REACT_APP_API_URL}/product/${match.params.productId}/${match.params.mine}`, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res=>res.json())
        .then(res=>{
          // console.log('respuesta de /product',res)
            if(res.product === null) {
              setProductNull(true)
            }
            if (res.message==='Product not found') {
              // console.log('error es Product not found: ', res)
            } else {
              setProductInfo(res.product);
              setIsLoading(false);
            }  
        })
        .catch(err=>console.log('error es catch: ', err))
    }

    
    function myFunction() {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        setshowTop(true)
      } else {
        setshowTop(false)
      }
    }
    window.onscroll = function() {myFunction()};
    useEffect(() => {
      document.body.scrollIntoView({block:'start'})
      getProduct()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.person.userId, reload])

  // Loader del boton 
  const enterLoading = index => {
    const newLoadings = [...loadings]
    newLoadings[index] = true
    setLoadings(newLoadings)
  }

  // Añadir al carrito 
  const addToCart = (e) => {
    // if (props.userInfo.verified) {
      // console.log('productInfo', productInfo)
      // console.log(productInfo.name)
      // console.log(productInfo.price)
      // console.log(productInfo.productImage[0].url)
      // console.log(productInfo.store.storeName)
      // console.log(productInfo.store.id)
      enterLoading(0);
      fetch(`${process.env.REACT_APP_API_URL}/filling-shopping-cart`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
        body: JSON.stringify({
          "storeId": productInfo.store.id,
          "productName": productInfo.name,
          "sellerEmail": productInfo.store.user.email,
          "productUnitName": language === 'CA' || language === 'ca-ES' ? productInfo.unit.cat : productInfo.unit.esp ,
          "productDescription": productInfo.description,
          "productPrice": productInfo.price,
          "productImageUrl": productInfo.productImage.length > 0 ? productInfo.productImage[0].url : 'https://res.cloudinary.com/propt1/image/upload/v1608131263/default/logo_x4qbw4_vx0ugc.png',
          "sellerName": productInfo.store.storeName,
          "storePhoneNumber": productInfo.store.mainPhoneNumber,
          "personId": userInfo.person.id, // id del comprador
          "sellerId": productInfo.store.userId , // id del vendedor --> productInfo.store.userId 
          "productId": productInfo.id, // id del producto que se metio al carrito -->  productInfo.id
          "quantity": quantityValue, // cantidad del producto agregado, por defecto es 1, se podrá modifica en el carrito  --> quantityValue
          "deliveryConfirmed": false,
        })
      })
      .then(res => res.json())
      .then(res => {
        // console.log(res)
        if(res.message === 'Order and/or item created') {
          store.dispatch(getOrders(userInfo.person.userId)) //Cuando agrego al Carrito me actualiza el store
          alerta(t("added-cart", {a: ' '}))
          setReload(true)
        }
        else if(res.message === 'not allowed - blocked') {
            enterLoading(1);
            Swal.fire({
              title: t("error", {a: ' '}),
              text: t("cant-buy", {a: ' '})
            })
        }
        else {
          enterLoading(1);
          // console.log(res)
          Swal.fire({
            icon: 'error',
            title: t('oops', {a: ' '}),
            text: '',
          })
        }
      // })
      // .catch(err=>{
      //   enterLoading(1);
      //   console.log(err);
      //   Swal.fire({
      //     title: 'Error',
      //     text: 'Ha ocurrido un error desactivando su tienda'
      //   })
      })
    }
    
        return (
          isLoading? <Loader /> : 
          productNull ?
          <div className="box main-empty-store-product not-found-store">
            <div className="main-empty">
              <h2 className="title-main-empty-product">{t('product-not-exist', {a: ' '})}</h2>
              <img src={notProduct} alt="Tienda no encontrada"/>
            </div> 
          </div> 
          :
          <div className="main-container-product-description box">
            {showTop && <div className="icon-scroll" onClick={() => document.body.scrollIntoView({block:'start', behavior:'smooth'})}><ArrowUpOutlined /></div>}
            
            <div className="container-product-description ">
              <div className="container-img">
              <ReactSlick
                {...{
                  dots: false,
                  infinite: false,
                  speed: 500,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  touchMove: false 
                }}>
                {
                  productInfo.productImage.length > 0 ? productInfo.productImage.map((src, index) => (
                  <div key={index} className="container-zoom-pan">
                      <TransformWrapper 
                        wheel={false}
                      >
                        {({ zoomIn, zoomOut, resetTransform, /*...rest*/ }) => (
                          <>
                            <section className="tools-image-product">
                              <div className="tools-image-product-btn" onClick={zoomIn}><ZoomInOutlined /></div>
                              <div className="tools-image-product-btn" onClick={zoomOut}><ZoomOutOutlined /></div>
                              <div className="tools-image-product-btn" onClick={resetTransform}><FullscreenExitOutlined /></div>
                            </section>
                            <TransformComponent>
                              <img src={src.url} className="img-zoom-product" alt={productInfo.name} />
                            </TransformComponent>
                          </>
                        )}
                      </TransformWrapper>
                  </div>  
                ))
                : 
                <div  className="container-zoom-pan">
                  <img src="https://res.cloudinary.com/propt1/image/upload/v1608131263/default/logo_x4qbw4_vx0ugc.png " className="img-zoom-product" alt="Default product" />
                </div>
                }
              </ReactSlick>
            </div>
            {
              userOrders.length > 0 &&
              userOrders.forEach(order => 
                order.orderItem.forEach(item =>
                  orderItemId.push(item.productId)
                )
              )
            }
            <div className="container-description" >
              <div className='product-info-container'>
                <div>
                  <div className='product-info-store-description'>
                    <div className='product-info-store-img-container'>
                      <img src={productInfo.store.profilePicture.url} alt={productInfo.store.storeName} /> 
                    </div>
                    <div className='product-info-store-data-container'>
                      {/* <Link to={`/vendedor/${productInfo.store.id}/${productInfo.store.storeName.replace(/ /g, '-').toLowerCase()}`}><p className='product-info-store-data-container-storeName'>{productInfo.store.storeName}</p></Link> */}
                      <Link to={`/vendedor/${productInfo.store.id}`}><p className='product-info-store-data-container-storeName'>{productInfo.store.storeName}</p></Link>
                      <p className='product-info-store-data-container-category'>{productInfo.store.mainCategory.map(catObj => language === 'CA' || language === 'ca-ES' ? catObj.cat : catObj.esp).join(' / ')}</p>
                      <Rate allowHalf disabled defaultValue={productInfo.store.totalReputation} />
                    </div>
                  </div>
                  <h3 className='product-info-productName'>{productInfo.name}</h3>
                  {
                    productInfo.description !== '' ?
                    <p className='gray-text product-info-description-data'><span>{t('product-description', {a: ' '})}: </span> {productInfo.description}</p>:
                    <p className='gray-text product-info-description-data'><span>{t('product-description', {a: ' '})}: </span> {t('no-description', {a: ' '})}</p>
                  }
                 <div className='container-product-info-product-price'> <p className='product-info-product-price'><EuroCircleOutlined /> {productInfo.price}{t('price-each', {a: ' '})} {productInfo.unitValue} {language === 'CA' ? productInfo.unit.cat.toLowerCase() : productInfo.unit.esp.toLowerCase() }</p></div>
                  {/*<p className='gray-text center-text'>Cantidad disponible: {productInfo.quantity} {productInfo.unit.name.toLowerCase()}{productInfo.quantity>1 ? productInfo.unit.name[productInfo.unit.name.length-1].match(/[aeiou]/)? 's' : 'es' : null}</p>*/}
                  {
                  orderItemId.find(orderId => orderId === productInfo.id) ? null 
                  :
                  productInfo.store.userId !== userInfo.person.userId &&
                  <div className="product-info-quantity-container">
                    <div><p className='gray-text product-info-quantity-container-text'>{t('quantity-to-buy', {a: ' '})}: </p></div>
                    <div className='product-quantity-container'>
                      <div className='product-quantity-action action-one' onClick={()=>{if (quantityValue>1) {setQuantityValue(quantityValue-1)}}}>-</div>
                      <div><InputNumber size='medium' min={1} defaultValue={1} className='product-quantity-input' value={quantityValue} onChange={val=>setQuantityValue(val)}/></div>
                      <div className='product-quantity-action action-two' onClick={()=>setQuantityValue(quantityValue+1)}>+</div>
                    </div>
                  </div>
                  }
                <div>
                  {
                    
                    orderItemId.find(orderId => orderId === productInfo.id)
                    ?  
                    <div className='container-button-products-description'>  
                      <Button className='round-button btn-medium product-info-btn in-to-cart' icon={<ShoppingCartOutlined/>} onClick={() => history.push('/carrito')}>
                        {t('view-cart', {a: ' '})}
                      </Button>
                      {/* <Link to={`/vendedor/${productInfo.store.id}/${productInfo.store.storeName.replace(/ /g, '-').toLowerCase()}`}> */}
                      <Link to={`/vendedor/${productInfo.store.id}`}>
                      <Button className='round-button btn-medium product-info-btn in-to-cart' icon={<ArrowLeftOutlined />}>
                      {t('back-store', {a: ' '})}
                      </Button>
                      </Link>
                    </div>
                    :
                    <>
                    {productInfo.store.userId === userInfo.person.userId ?
                    <div className='container-button-products-description'>
                      <Link to={`/dashboard/tienda/productos-activos`}>
                        <Button className='round-button btn-medium product-info-btn-person'>
                        {t('go-products-active', {a: ' '})}
                        </Button>
                      </Link>
                      <Link to={`/dashboard/tienda/editar-producto/${productInfo.id}`}>
                        <Button className='round-button  btn-medium product-info-btn-person mb-5' icon={<EditFilled />}>
                        {t('edit-product', {a: ' '})}
                        </Button>
                      </Link>
                    </div>
                    :
                    <Button loading={loadings[0]} className='btn-first  btn-medium round-button product-info-btn' type='primary' icon={<ShoppingCartOutlined/>} onClick={addToCart.bind(this)} >
                      {t('add-to-cart', {a: ' '})}
                    </Button> }
                    </>
                    }
                </div>
                </div>
              </div>
            </div>
          </div> 
        </div>           
    );
}


const mapStateToProps = state => ({
  userInfo: state.userReducer.userInfo,
  userOrders: state.ordersReducer.userOrders,
  language: state.languageReducer.language
})
export default withRouter(connect(mapStateToProps, {})(ProductDescriptionII))
