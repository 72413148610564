export function validateEmail(email) {
    const emailRegex=/./;
    if (email === undefined || email.trim() === '') {
        return 'Este campo es obligatorio'
    } else if (!emailRegex.test(email)) {
        return 'Correo electrónico inválido'
    } else { 
        return '';
    }
}
export function validatePassword(password) {
    const passwordRegex=/./;
    if (password === undefined || password.trim() === '') {
        return 'Este campo es obligatorio'
    } else if (!passwordRegex.test(password)) {
        return 'Contraseña inválida'
    } else {
        return '';
    }   
}
export function validateConfirmedPassword(password, confirmedPassword) {
    if (confirmedPassword === undefined || confirmedPassword.trim() === '') {
        return 'Este campo es obligatorio'
    } else if (password === confirmedPassword) {
        return ''
    } else {
        return 'Las contraseñas no coinciden'
    }
}
export function validateNotEmpty(input) {
    if (input === null) {
        return 'Este campo es obligatorio'
    } else if (typeof input === 'object') { //para validar inputs en forma de array
        if (input.length > 0) {
            return ''
        } else {
            return 'Este campo es obligatorio'
        }
    } else if (typeof input === 'number') { //para validar inputs en forma de numeros
        return ''
    } else if (input === undefined || input.trim() === '') { //para validar inputs en forma de strings
        return 'Este campo es obligatorio'
    } else {
        return ''
    }
}
export function validateFileSize(file) {
    if (file.size / 1024 / 1024 > process.env.REACT_APP_MAX_FILE_SIZE) { 
        return `La foto no puede exceder ${process.env.REACT_APP_MAX_FILE_SIZE}mb de tamaño. Por favor intente con otra imagen.`
    } else {
        return ''
    }
}
export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}