import React, {useEffect} from 'react'
import Profile from '../Profile/Profile'
import './Dashboad.scss'
import { withRouter } from 'react-router-dom'

const Dashboad = () => {
  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
  }, [])

  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
        <Profile />
      </div>
    </div>
  )
}

export default withRouter(Dashboad)
