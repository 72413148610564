import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import './CardSection.scss'
import {useTranslation} from 'react-i18next';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: '"Nunito", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "14.5px",
      color: "rgba(0,0,0,0.65)",
      "::placeholder": {
        color: "rgba(0,0,0,0.3)",
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {
  const { t } = useTranslation();
  return (
    <div className='card-input-container'>
      <span className='card-label-color'><span className='card-label-asterisk'>* </span>{t("card-data", {a: ' '})}: </span>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  );
};

export default CardSection;