import React, { useState, useEffect } from 'react';
import './PageHeader.scss'
import { Input, Avatar,  Drawer, Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import audioNotification from '../../../assets/notification.mp3'
import logoMovil from '../../../assets/logo.png'
import logoDesktop from '../../../assets/propt1-logowepapp.svg'
import {DownOutlined, CaretDownOutlined} from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import DrawerMenu from '../DrawerMenu/DrawerMenu';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import Loader from '../../Atoms/Loader/Loader';
import Notification from '../../Molecules/Notification/Notification';
import IconNotification from '../../Atoms/IconNotification/IconNotification';
import { getLanguage, getNotifications } from '../../../redux/actionCreators';
import store from '../../../redux/store';
import { useTranslation } from "react-i18next";

const defaultLimit = 10;

const PageHeader = (props) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false)
    const [visibleNotification, setVisibleNotification] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [notificationsEmpty, setNotificationsEmpty] = useState(false)
    const [countNotification, setCountNotification] = useState(0)
    const [offset, setOffset] = useState(0)
    const { i18n } = useTranslation();

    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
    };

    const menu = (
      <Menu>
        <Menu.Item>
          <span className="flag spain" onClick={e => changeLanguage('ES')}>
            Esp
          </span>
        </Menu.Item>
        <Menu.Item>
          <span className="flag cat" onClick={e => changeLanguage('CA')}>
            Cat
          </span>
        </Menu.Item>
      </Menu>
    );
    // const newArr2 = [...new Set(notifications)]
    const [reload, setReload] = useState(false)

    useEffect(() => {
      setReload(false);
    }, [reload])


    // OBTENER LAS NOTIFICACIONES
    const getNotificationsUser = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications/${props.userInfo.person.userId}/${defaultLimit}/${offset}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      const data = await response.json()
      if(data.message === 'notifications by user') {
        // console.log('data', data);
        setNotifications(notifications.concat(data.findNotifications.rows));
        setOffset(offset+defaultLimit);
        setCountNotification(data.findNotifications.count)
        // setNotifications([]);
        if(data.findNotifications.count < 1 ) {
          setNotificationsEmpty(true)
        }else {
          setNotificationsEmpty(false)
        }
      }else {
        return 
      }
      // console.log(data)
    }

    const moreNotification = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications/${props.userInfo.person.userId}/${defaultLimit}/${offset}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      const data = await response.json()
      setNotifications(notifications.concat(data.findNotifications.rows));
      setOffset(offset+defaultLimit);
      // console.log(data.findNotifications.rows)   
      // console.log(notifications)
    }
    
    // drawer notificaciones
    const showDrawerNotification = async () => {
      await getNotificationsUser(); 
      store.dispatch(getNotifications(props.userInfo.person.userId))
      setVisibleNotification(true);
      reloadNotification();
      // console.log('Se abrio el drawer de notificaciones')
    }

    const onCloseNotification = () => {
      setVisibleNotification(false)
      // Cuando cierro el drawer reinicio las notificaciones
      setNotifications([])
      setOffset(0);
    }

    // drawer menu
    const showDrawer = () => {
      setVisible(true)
    };
    
    const onClose = () => {
      setVisible(false)
    }

    // Eliminar notificacion 
    const deleteNotification = async (id) => {
      Swal.fire({
        title: t("confirm-delete-notification", {a: ' '}),
        text: t("delete-cat-warning-2", {a: ' '}),
        icon: "warning",
        showCancelButton :true,
        confirmButtonText: t("confirm-delete", {a: ' '}),
        cancelButtonText: t('keep', {a: ' '})
      })
      .then( async (result) => {
        if (result.isConfirmed) {
          setReload(true);
          let url = `${process.env.REACT_APP_API_URL}/delete-notification/${id}`;
          const response = await fetch(url, {
            method: 'DELETE',
            headers:{'Content-Type': 'application/json','Authorization': `Bearer ${localStorage.getItem('token')}`}
          })
          if(response.status === 200) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications/${props.userInfo.person.userId}/${defaultLimit}/${0}`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            })
            const data = await response.json()
            if(data.findNotifications.count < 1 ) {
              setNotificationsEmpty(true)
            }else {
              setNotificationsEmpty(false)
            }
            setCountNotification(data.findNotifications.count)
            setNotifications(data.findNotifications.rows)
            store.dispatch(getNotifications(props.userInfo.person.userId))
            Swal.fire(t("deleted", {a: ' '}), {
              icon: "success",
            });
            setReload(false);
          }else {
            Swal.fire({
              icon: 'error',
              title: t('oops', {a: ' '}),
              text: '',
            })
          }
        } else {
          return null
        }
      });
    }

    const reloadNotification = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications/${props.userInfo.person.userId}/${defaultLimit}/${0}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      const data = await response.json()
      if(data.findNotifications.rows) setNotifications(data.findNotifications.rows)
      setCountNotification(data.findNotifications.count)
      store.dispatch(getNotifications(props.userInfo.person.userId))
      // console.log('Se recargo las notifiaciones')
    }


    const searchProducts = word => {
      if(word !== '') {
        props.history.push(`/productos/busqueda/${word}`);
      } else {
        return 
      }
    }

   
     
      if(props.userInfo.person) {
        setInterval(function(){
          store.dispatch(getNotifications(props.userInfo.person.userId));
          // console.log('Notificacion ejecutada')
        }, 120000);
      }
      
      // const iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
      // const iOS = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
      const iOS = navigator.userAgent.includes("Mac"); 

      if(!iOS) {
        // alert('No estas en Ios');
        // Creo el canal y espero que en SW me llegue el Canal de transmisión y espero el evento message :)
        const channel = new BroadcastChannel('sw-messages');
        channel.addEventListener('message', event => {
          store.dispatch(getNotifications(props.userInfo.person.userId));
          document.getElementById('audio').play()
          // channel.close();
        });
      }else {
        // alert(navigator.userAgent);
        // console.log('Estas en IOS');
      }


    return  (
      !/verify\/[\S]+\/[\S]+@[\S]+\.[\S]+/.test(props.location.pathname) &&
      !/password\/[\S]+\/[\S]+@[\S]+\.[\S]+/.test(props.location.pathname) &&
      //!/tiendas\/mapa\/[\S]+\/[\S]+/.test(props.location.pathname) &&
      //!/tiendas\/lista\/categorias\/[\S]+\/radio\/[\S]+/.test(props.location.pathname) &&
      props.location.pathname !== '/verificar-email' &&
      //props.location.pathname !== '/tiendas/mapa' &&
      props.location.pathname !== '/' && 
      props.location.pathname !== '/login' && 
      props.location.pathname !== '/registro' && 
      props.location.pathname !== '/recuperar-contraseña' &&  
      props.location.pathname !== '/terminos-y-condiciones' && 
      props.location.pathname !== '/registro/verificar-email' &&
      <>
      <div className='margin-after'>
        <div className='margin-header-container'>
        <header className="main-header">
        <audio id="audio"><source src={audioNotification} type="audio/mp3"></source></audio>
          <Link to='/tiendas/lista/categorias/1-2-3-4-5-6/radio/30'>
            <div className="main-header-p1">
              <div className="img1">
                <img className="main-header--logoM" src={logoMovil} alt="logo"/> 
              </div>
              <div className="img2">
                <img className="main-header--logoD" src={logoDesktop} alt="logo"/> 
              </div>
            </div>
          </Link>
          <div className="main-header-p2">
            <div className="main-header-p2-item">
              <Input.Search 
                  placeholder={t('search', {a: ' '})} 
                  onSearch={value => searchProducts(value)} 
                  className="main-header-input-search"
                />
            </div>
            
            <div className="main-container-icon-language">
              <Dropdown overlay={menu} trigger={['click']} className="container-icon-language flag" onChange={store.dispatch(getLanguage())}>
                <a onClick={e => e.preventDefault()} className="icon-language">
                  {i18n.language !== 'ES' && i18n.language !== 'CA' ? '' : ''} <DownOutlined />
                </a>
              </Dropdown>
              {/* <CaretDownOutlined className="main-header-down"/> */}
            </div>

            <IconNotification showDrawerNotification={showDrawerNotification} />

            {/* El avatar que esta en el perfil para movil*/}
            <div className="avatar-show-movil">
              {
                props.location.pathname.includes('/dashboard/perfil') &&
                <div className="main-header-p2-item" onClick={showDrawer}>
                  <Avatar shape="square" size="large" src={props.userInfo.profilePicture.url} />
                </div>
              }
              {
                props.location.pathname.includes('/dashboard/tienda') &&
                <div className="main-header-p2-item" onClick={showDrawer}>
                  <Avatar shape="square" size="large" src={props.userInfo.profilePicture.url} />
                </div>
              }
              {
                props.location.pathname.includes('/dashboard/pedido/') &&
                <div className="main-header-p2-item" onClick={showDrawer}>
                  <Avatar shape="square" size="large" src={props.userInfo.profilePicture.url} />
                </div>
              }
            </div>
            {/* El avatar que esta en el perfil para desktop*/}
            <div className="avatar-show-profile-desktop">
              {
                props.location.pathname.includes('/dashboard/perfil') &&
                <div className="main-header-p2-item">
                  <Avatar shape="square" size="large" src={props.userInfo.profilePicture.url} />
                </div>
              }
              {
                props.location.pathname.includes('/dashboard/tienda') &&
                <div className="main-header-p2-item">
                  <Avatar shape="square" size="large" src={props.userInfo.profilePicture.url} />
                </div>
              }
              {
                props.location.pathname.includes('/dashboard/pedido') &&
                <div className="main-header-p2-item">
                  <Avatar shape="square" size="large" src={props.userInfo.profilePicture.url} />
                </div>
              }
            </div>
            
              {
                !props.location.pathname.includes('/dashboard/perfil') && !props.location.pathname.includes('/dashboard/tienda') && !props.location.pathname.includes('/dashboard/pedido') &&
                <div className="main-header-p2-item" onClick={showDrawer}>
                  <Avatar shape="square" size="large" src={props.userInfo.profilePicture.url} />
                </div>
              }
             
            {
              props.location.pathname.includes('/dashboard/perfil') && 
              <DrawerMenu visible={visible} className="drawer-show-profile" onClose={onClose} setVisible={setVisible} showDrawer={showDrawer}/>
            }
            {
              props.location.pathname.includes('/dashboard/tienda') &&
              <DrawerMenu visible={visible} onClose={onClose} className="drawer-show-profile" setVisible={setVisible} showDrawer={showDrawer}/>
            }
            {
              props.location.pathname.includes('/dashboard/pedido') &&
              <DrawerMenu visible={visible} onClose={onClose} className="drawer-show-profile" setVisible={setVisible} showDrawer={showDrawer}/>
            }
            {
              !props.location.pathname.includes('/dashboard/perfil') && !props.location.pathname.includes('/dashboard/tienda') && !props.location.pathname.includes('/dashboard/pedido') &&
              <DrawerMenu visible={visible} onClose={onClose} setVisible={setVisible} showDrawer={showDrawer}/>
            }

            {/* Este drawer se pasara a otro componente, de momento esta aqui, falta refactorizarlo  */}
            <Drawer
              title={t('notifications', {a: ' '})}
              placement='right'
              closable={true}
              onClose={onCloseNotification}
              visible={visibleNotification}
              key='right'
              width={310}
              className="main-container-drawer-notification"
            >
            <div className="container-drawel-notification">
              {
                notificationsEmpty ? <p className="not-notifications">{t("no-notifications", {a: ' '})}</p> :
                notifications.length < 1 ? <Loader />
                : 
                notifications.map(notification => (
                  notification.read ?
                  <div className="container-drawel-noti" key={notification.id}>
                    <Notification reloadNotification={reloadNotification} notification={notification} setVisibleNotification={setVisibleNotification} deleteNotification={deleteNotification}/>
                  </div>
                    :
                  <div className="container-drawel-noti not-read" key={notification.id}>
                    <Notification reloadNotification={reloadNotification} notification={notification} setVisibleNotification={setVisibleNotification} deleteNotification={deleteNotification}/>
                  </div>
                ))
              }
            {notifications.length < countNotification && !notificationsEmpty && <p className="btn-notification-more" onClick={moreNotification}>{t("load-more", {a: ' '})}</p>}
            </div>
            </Drawer>
          </div>
        </header>
        </div>
      </div>  
      </>
    )
}
const mapStateToProps = state => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps, {})(PageHeader))
