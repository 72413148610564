import React, {useState, useEffect} from 'react'
import './MyOrdersActives.scss';
import { connect } from 'react-redux';
// import { Tabs } from 'antd';
// import MenuVertical from '../../Organisms/MenuVertical/MenuVertical';
// import OrderCardBuyer from '../../Molecules/OrderCardBuyer/OrderCardBuyer';
// import OrderCardReserved from '../../Molecules/OrderCardReserved/OrderCardReserved';
// import OrderCardDelivered from '../../Molecules/OrderCardDelivered/OrderCardDelivered';
// import EmptyCart from '../../Molecules/EmptyCart/EmptyCart';
import logoCompras from '../../../assets/P1-perfil-compras-no-compras-activas-imagen.png';
import Loader from '../../Atoms/Loader/Loader';
import OrderCardDescriptionPurchase from '../../Molecules/OrderCardDescription/OrderCardDescriptionPurchase';
// import { ReloadOutlined } from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

const MyOrdersActives = (props) => {
    const { t } = useTranslation();
    // const [allOrderSeller, setAllOrderSeller] = useState([]) //Para guardar lo que me han comprado
    const [reload, setReload] = useState(false)
    const [reReload, setReReload] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [allOrder, setAllOrder] = useState([]) //Para guardar las ordenes

    // Valida que exista una orden con tal status
    const validOrder = (array, message) => {
      let result = false
      array &&
      array.forEach(o=> {
        if(o.status === message) {
          result = true
        }
      })
      return result
    }
    // Valida que una orden tenga el deliveryConfirm
    const validdeliveryConfirm = (array, message) => {
      let result = false
      array &&
      array.forEach(o=> {
        if(o.status === message && o.deliveryConfirmed) {
          result = true
        }
      })
      return result
    }
    let requestedOrders = validOrder(allOrder, "Reservado")
    let ordersInWait = validOrder(allOrder, "En espera")
    // let reservedOrders = validOrder(allOrder, "Entregado")
    let orderConfirmDelivery = validdeliveryConfirm(allOrder, 'Entregado')
    let valid2 
    
    if(ordersInWait === true || requestedOrders === true || orderConfirmDelivery) valid2 = true

    useEffect(() => {
      // document.body.scrollIntoView({block:'start'})
      setReload(false)
      setReReload(false)
      let orders = []
      fetch(`${process.env.REACT_APP_API_URL}/orders-by-buyer/${props.userInfo.person.userId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(resp => resp.json())
      .then(resp => {
        // console.log(resp)
        resp.findOrders.map(order => (
          orders.push(order)
        ))
        setAllOrder(orders)
        setIsLoading(false);
      })
      const reloadPage = setInterval(() => {
        setReload(true)
      }, 10000)
      // la función se frena al desmontar el componente
      return () => {
        clearInterval(reloadPage)
      }
    }, [props.userInfo.person.userId, props.userInfo.userRoleId, reload, reReload])

  return (
      <>
      
      <div className="profile-container container-orders">
      <div className="wrapped-profile-container">
        {isLoading? <Loader /> :
          <div className='box container-orders-reserved'>
          {console.log('compras activas 2022: ', allOrder)}
            {valid2 && <h2 className='center-text title-form'>{t("active-order", {a: ' '})}</h2>}
                {
                  allOrder.map(orderSeller => (
                    orderSeller.status === 'En espera' && //Me muestra solo las ordenes En espera.
                    <OrderCardDescriptionPurchase key={orderSeller.id}
                      order={orderSeller} 
                      setReload={setReload}
                    />
                  ))
                }
                {
                  allOrder.map(orderSeller => (
                    orderSeller.status === 'Reservado' && //Me muestra solo las ordenes Reservadas.
                    <OrderCardDescriptionPurchase key={orderSeller.id}
                      order={orderSeller} 
                      setReload={setReload}
                    />
                  ))
                }
                {
                  allOrder.map(orderSeller => (
                    orderSeller.status === 'Entregado' && orderSeller.deliveryConfirmed && //Me muestra solo las ordenes que están sin calificar
                    <OrderCardDescriptionPurchase key={orderSeller.id}
                      order={orderSeller} 
                      deliveryConfirmed={true}
                      setReload={setReload}
                    />
                  ))
                }
                {
                  !valid2 &&
                  <div className="main-empty">
                    <h2>{t("no-active-order", {a: ' '})}</h2>
                    <img src={logoCompras} alt={t("no-active-order", {a: ' '})}/>
                  </div>
                }
          </div>
          }
      </div>
      </div>
      </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps)(MyOrdersActives)
