import React, {useState,useEffect} from 'react'
// import './ViewOrderDetail.scss'
import { withRouter, Link} from 'react-router-dom'
import Loader from '../../Atoms/Loader/Loader'
// import EmptyCart from '../../Molecules/EmptyCart/EmptyCart'
import logoVentas from '../../../assets/P1-perfil-compras-no-compras-activas-imagen.png';
import 'moment/locale/es';
// import OrderItemBuyer from '../../Molecules/OrderItemBuyer/OrderItemBuyer'
import OrderCardBuyer from '../../Molecules/OrderCardBuyer/OrderCardBuyer';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import { connect } from 'react-redux';

const OrderDescription = (props) => {
  const { t } = useTranslation();
  const [orderDetail, setOrderDetail] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [orderNotFound, setOrderNotFound] = useState(false)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
    setReload(false)
    // setIsLoading(true);
    // console.log('recargando')
    // console.log(orderDetail.status)
    fetch(`${process.env.REACT_APP_API_URL}/order/${props.match.params.id}/${null}/${props.userInfo.person.userId}`, {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    .then(resp => resp.json())
    .then(resp => {
      // console.log(resp)
      if(resp.message === 'order found') {
        setOrderDetail(resp.findOrder)
        setIsLoading(false);
        setOrderNotFound(false)
      }else if (resp.message === 'order not found'){
        // console.log('Orden no encontrada')
        setOrderNotFound(true)
        setIsLoading(false);
      } 
      if(resp.message === 'not allowed') {
        if(props.location.pathname.includes('/compras')) {
          // console.log('debe ir a compras activas')
          props.history.push('/dashboard/perfil/compras-activas')
        }
        if(props.location.pathname.includes('/ventas')){
          // console.log('debe ir a ventas activas')
          props.history.push('/dashboard/tienda/ventas-activas')
        }
        // console.log(props.location.pathname)
      }
    })
    .catch(error => {
      // console.log('Error:', error)
    })

    const reloadPage = setInterval(() => {
      setReload(true)
      setIsLoading(false);
    }, 10000)
    // la función se frena al desmontar el componente
    return () => {
      clearInterval(reloadPage)
    }
  }, [orderDetail.status, props.match.params.id, reload])

  return (
    <div className="profile-container">
      <div className="wrapped-profile-container">
          {
              isLoading ? <Loader /> :
              <>
              <div className='box container-orders-reserved'>
                <>
                <div className='container-orders-reserved-arrow'>
                  <Link to="/dashboard/tienda/ventas-activas"><ArrowLeftOutlined /></Link>
                </div>
                {
                  /* orderNotFound || orderDetail.status === 'Cancelado' || (orderDetail.status === 'Entregado' && !orderDetail.deliveryConfirmed ) */
                  orderNotFound || orderDetail.status === 'Cancelado' || (orderDetail.status === 'Entregado')
                  ? 
                    <div className="main-empty">
                      <h2>{t("no-order-found", {a: ' '})}</h2>
                      <img src={logoVentas} alt="Pedido no encontrado"/>
                    </div> 
                  :
                  <>
                    {/* {orderDetail.status === 'Entregado' && orderDetail.deliveryConfirmed && //Me muestra solo las ordenes que están sin calificar
                    <OrderCardBuyer key={orderDetail.id}
                      orderSeller={orderDetail}
                      setReload={setReload}
                      confirm={true}
                    />} */}

                    {orderDetail.status === 'En espera' &&//Me muestra solo las ordenes En espera.
                    <OrderCardBuyer key={orderDetail.id}
                      orderSeller={orderDetail}
                      setReload={setReload}
                    />}

                    {orderDetail.status === 'Reservado' &&//Me muestra solo las ordenes Reservadas.
                    <OrderCardBuyer key={orderDetail.id}
                      orderSeller={orderDetail}
                      setReload={setReload}
                    />}
                  </>
                }
                </>
              </div>
              </>
          }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(OrderDescription))
