import React, {useState, useEffect} from 'react';
import ProductCatalog from '../../Organisms/ProductCatalog/ProductCatalog';
import Loader from '../../Atoms/Loader/Loader';
import Banner from '../../Molecules/Banner/Banner';
import {useTranslation} from 'react-i18next';

function SearchProducts(props) {
	const { t } = useTranslation();
	const [productList, setProductList] = useState([])
	const [offset, setOffset] = useState(0);
	const [loading, setLoading] = useState(true);

	const search = () => {
		setLoading(true);
		fetch(`${process.env.REACT_APP_API_URL}/search/10/${offset}`, { 
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				"name": props.match.params.query
			})
		})
		.then(res => res.json())
		.then(res=>{
			// console.log(res);
			setProductList(res.findProducts.rows)
			setLoading(false)
		})
	}
	const getMoreProducts = () => {
		setOffset(offset+10)
	}

  useEffect(() => {
    document.body.scrollIntoView({block:'start'})
    search()
  }, [props.match.params.query, offset])

	return (
		<>
			<Banner title={t("search-results", {a: ' '})} />
			<p className='center-text 9'>{t("searched", {a: ' '})}: "{props.match.params.query}"</p>
			{loading ? <Loader /> : <ProductCatalog productList={productList} getMoreProducts={getMoreProducts} search={true} />}
		</>
	)
}

export default SearchProducts;
