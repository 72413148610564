import React, {useState, useEffect} from 'react'
import './Cart.scss'
import Banner from '../../Molecules/Banner/Banner';
import { connect } from 'react-redux';
import OrderCard from '../../Molecules/OrderCard/OrderCard';
import OrderCard2 from '../../Molecules/OrderCard/OrderCard2';

import cartempty from '../../../assets/P1-icono-carrito-vacio.png';
import Loader from '../../Atoms/Loader/Loader';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import {useTranslation} from 'react-i18next';

const Cart = (props) => {
    const { t } = useTranslation();
    const [allOrder, setAllOrder] = useState([]) //Para guardar las ordenes
    // const [allOrderSeller, setAllOrderSeller] = useState([]) //Para guardar lo que me han comprado
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const validOrder = (array, message) => {
      let result = false
      array.forEach(o=> {
        if(o.status === message) {
          result = true
        }
      })
      return result
    }
    let haveOrdersInToCart = validOrder(allOrder, "En carrito")

    useEffect(() => {
      document.body.scrollIntoView({block:'start'})
      setReload(false)
      let orders = []
      fetch(`${process.env.REACT_APP_API_URL}/orders-by-buyer/${props.userInfo.person.userId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(resp => resp.json())
      .then(resp => {
        resp.findOrders.map(order => (
          orders.push(order)
        ))
        setAllOrder(orders)
        setIsLoading(false);
      })
    }, [props.userInfo.person.userId, reload])

    return (
      <div className="cart-container">
          <Banner title={t("your-order", {a: ' '})}/>
          { isLoading ? <Loader /> :
          <div className="container-carrito">
              {/* {
                allOrder.map(order => (
                  order.status === "En carrito" &&
                  <OrderCard key={order.id}
                    order={order} 
                    setReload={setReload}
                    setAllOrder={setAllOrder}
                  />
                ))
              } */}
              {
                allOrder.map(order => (
                  order.status === "En carrito" &&
                  <>
                  <OrderCard2 key={order.id}
                    order={order} 
                    setReload={setReload}
                    setAllOrder={setAllOrder}
                  />
                  </>
                ))
              }
              {
                
                !haveOrdersInToCart && 
                <>
                <div className="main-empty box main-empty-cart">
                  <h2>{t('empty-cart', {a: ' '})}</h2>
                  <img src={cartempty} alt="Carrito vacío"/>
                  <Link to='/tiendas/lista/categorias/1-2-3-4-5-6/radio/30'><Button type='primary' className='round-button btn-first btn-cart-empty'>{t('continue-buying', {a: ' '})}</Button></Link>
                </div>
                </>
              }
          </div>
          }
      </div>
    )
  }
const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default connect(mapStateToProps,{})(Cart)
