import React, { useState, useEffect } from 'react';
import './SellerInfo.scss';
// import RateTotal from '../../Atoms/RateTotal/RateTotal';
import {withRouter, Link} from 'react-router-dom';
import {Button, Collapse, Rate} from 'antd';
import {StarFilled, AimOutlined, CheckCircleOutlined, WhatsAppOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import {useTranslation} from 'react-i18next';

function SellerInfo(props) {
    const { t } = useTranslation();
    const [favorite, setFavorite] = useState(false);
    const [favId, setFavId] = useState(undefined);
    const addToFavorite = () => {
        props.setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/favorite`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
         },
            body: JSON.stringify({
              "personId": props.userInfo.id, 
              "storeId": props.sellerInfo.id 
            })
        })
        .then(res => res.json())
        .then(res=>{
            if (res.message==='Favorite created') {
                setFavorite(true)
                props.setIsLoading(false);
            }
        })
    };
    const removeFavorite = () => {
        props.setIsLoading(true);
        console.log(favId)
        fetch(`${process.env.REACT_APP_API_URL}/favorite/${favId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
        })
        .then(res => res.json())
        .then(res=>{
            if (res.message==='Favorite deleted') {
                setFavorite(false)
                props.setIsLoading(false);
            }
        })
    };
    const getFavorites = () => {
        if (props.sellerInfo.userId !== props.userInfo.id) {
            fetch(`${process.env.REACT_APP_API_URL}/favorites/${props.userInfo.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
              })
            .then(res => res.json())
            .then(res=>{
                if (res.message==='favorites by user') {  
                    res.findFavorites.forEach(favObj=>{
                        if (favObj.store.userId === props.sellerInfo.userId) {
                            setFavorite(true);
                            setFavId(favObj.id);
                        }
                    }); 
                }
            })
        }
    };
    const goToStoreLocation = () => {
        if (props.sellerInfo.location.length > 0) {
            props.history.push(`/tienda/mapa/${props.sellerInfo.userId}`)
        } else {
            Swal.fire({
                icon: 'error',
                title: t("location-not-found-title", {a: ' '}),
                text: t("location-not-found-msg", {a: ' '}),
            })
        }
    }
    useEffect(getFavorites, []);
    return (
        <div>
            <div className='info-container box'>
                <div className='seller-img-container'>
                    <img src={props.sellerInfo.portraitPicture.url} alt={props.sellerInfo.storeName} className='seller-info-portrait-img' />
                    <img src={props.sellerInfo.profilePicture.url} alt={props.sellerInfo.storeName} className='seller-info-img' />
                </div>
                <h2 className='blue-text center-text seller-profile-info'>{props.sellerInfo.storeName}</h2>
                {props.sellerInfo.userId !== props.userInfo.id &&<a className="enlace-whatsapp center-text" href={`https://api.whatsapp.com/send?phone=${props.sellerInfo.mainPhoneNumber}&text=Hola%20${props.sellerInfo.storeName},%20`} target="_blank" rel="noopener noreferrer">Contacta a <span className="whatsapp-name-user">{`${props.sellerInfo.storeName}`}</span> via <span className="whatsapp-name"> WhatsApp <WhatsAppOutlined /></span></a>}
                {props.distanceToUser && <p className='gray-text center-text seller-profile-info'>{t("search-distance-2", {x: props.distanceToUser, a: ' '})}</p>}
                <p className='blue-text center-text seller-profile-info'>{props.sellerInfo.mainCategory.map(catObj=>catObj.name).join(' / ')}</p>
                {/* <RateTotal rating={props.sellerInfo.totalReputation}/> */}
                <Rate allowHalf disabled defaultValue={props.sellerInfo.totalReputation} />
                <div className='buttons-container'>
                    {props.sellerInfo.userId === props.userInfo.id ? <Link to='/dashboard/tienda'><Button type='secondary' className='round-button btn-second hover-blue' icon={<StarFilled />}>{t("view-my-profile", {a: ' '})}</Button></Link> : 
                    favorite?
                    <Button danger className='round-button' icon={<StarFilled />} onClick={removeFavorite}>Quitar de favoritos</Button> :
                    <Button type='secondary' className='round-button btn-second hover-blue btn-store' icon={<StarFilled />} onClick={addToFavorite}>Agregar a favoritos</Button>}
                    <Button onClick={goToStoreLocation} type='primary' className='round-button btn-first btn-store' icon={<AimOutlined />}>Ir al lugar</Button>
                </div>
                <Collapse className='more-info round-box'>
                    <Collapse.Panel header={t("more-info", {a: ' '})} key='more-info' className='more-info-panel'>
                      <div className='more-info-panel-container'> 
                        <div className='more-info-panel-p1 '> 
                          <p className='more-info-panel-text'><CheckCircleOutlined /><span className='more-info-bold'>{t('description', {a: ' '})}:</span> {props.sellerInfo.description}</p>
                          <p className='more-info-panel-text'><CheckCircleOutlined /><span className='more-info-bold'>{t("first-phone", {a: ' '})}l:</span> {props.sellerInfo.mainPhoneNumber}</p>
                          {props.sellerInfo.secondaryPhoneNumber && <p className='more-info-panel-text'><CheckCircleOutlined /><span className='more-info-bold'>{t("second-phone", {a: ' '})}:</span> {props.sellerInfo.secondaryPhoneNumber}</p>}
                        </div> 
                        <div className='more-info-panel-p2 '> 
                          <p className='more-info-panel-text'><CheckCircleOutlined /><span className='more-info-bold'>{t("payment-method", {a: ' '})}:</span> {props.sellerInfo.paymentMethods.map(method=>method.name).join(' / ')}</p>
                          {props.sellerInfo.schedules.length>0 &&
                                <p className='more-info-panel-text'><CheckCircleOutlined /><span className='more-info-bold'>{t("schedule", {a: ' '})}:</span>
                                    {t("schedule-result", {
                                        x: props.sellerInfo.schedules[0].openingDay,
                                        y: props.sellerInfo.schedules[0].closingDay,
                                        z: props.sellerInfo.schedules[0].openingTime,
                                        v: props.sellerInfo.schedules[0].closingTime,
                                        a: ' '
                                    })}
                                </p>
                            }
                          <p className='more-info-panel-text'><CheckCircleOutlined /><span className='more-info-bold'>{props.sellerInfo.deliveryTypeId[0]===1?t("delivery", {a: ' '}):t("no-delivery", {a: ' '})}</span></p>
                          {props.sellerInfo.deliveryTypeId[0] === 1 && props.sellerInfo.minimumDelivery && <p className='more-info-panel-text'><CheckCircleOutlined /><span className='more-info-bold'>{t("minimum-delivery", {a: ' '})}:</span> {props.sellerInfo.minimumDelivery} €</p>}
                        </div> 
                      </div> 
                    </Collapse.Panel>
                </Collapse>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userInfo: state.userReducer.userInfo
  })

export default connect(mapStateToProps)(withRouter(SellerInfo));
