import React, {useState} from 'react';
import Logo from '../../Atoms/Logo/Logo';
import { connect } from 'react-redux';
import {Form, Button,Alert} from 'antd';
import alerta from '../../../utilityFunctions/alerta';
import Swal from 'sweetalert2';
import { SAVE_LOGIN_USER } from '../../../redux/actions';
import {useTranslation} from 'react-i18next';

function CloseStore(props) {
    const { t } = useTranslation();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [formError, setFormError] = useState('');

    const submitForm = e => {
        Swal.fire({
            title: t("confirm-baja", {a: ' '}),
            text: t("confirm-baja-2", {a: ' '}),
            icon: "warning",
            showCancelButton :true,
            confirmButtonText: t("yes", {a: ' '}),
            cancelButtonText: t("keep", {a: ' '})
        }).then(result => {

            if (result.isConfirmed) {
                setButtonLoading(true);
                setFormError('');
                
                fetch(`${process.env.REACT_APP_API_URL}/close-store/${props.userInfo.store.id}`, { 
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        esp: localStorage.getItem('i18nextLng') === 'CA' || localStorage.getItem('i18nextLng') === 'ca-ES' ? false : true
                    })
                })
                .then(res => res.json())
                .then(res => {
                  
                    if (res.message === 'process done') {
                        props.dispatch({
                            type: SAVE_LOGIN_USER,
                            data: res.findUser
                        })
                        props.history.push('/dashboard/tienda');
                        alerta(t("deactivated-store", {a: ' '}));
                    } else if (res.message === 'not allowed') {
                        Swal.fire({
                            title: t("error", {a: ' '}),
                            text: t("deactivated-error", {a: ' '})
                        })
                        setButtonLoading(false);
                    } else {
                        setButtonLoading(false);
                        Swal.fire({
                            title: t('error', {a: ' '}),
                            text: t('oops', {a: ' '})
                        })
                    }
                })
                .catch(err=>{
                    setButtonLoading(false);
                    setFormError(t('oops', {a: ' '}))
                })
            }
        })
    }

    return (
        <div className="profile-container">
            <div className="wrapped-profile-container">
                <Form name='close-account-form' className='form' onFinish={submitForm}>
                    <Logo />
                    <h1 className='center-text title-form'>{t("deactivate-store", {a: ' '})}</h1>
                    <p className='center-text'>{t("deactivate-store-warning", {a: ' '})}</p>
                    <p className='center-text'>{t("deactivate-store-valid", {a: ' '})}</p>
                    <Button htmlType='submit' type='primary' className='btn btn-first center-btn' loading={buttonLoading}>{t("deactivate-store", {a: ' '})}</Button>
                    {formError && <Alert message={formError} type='error' className='error-msg' />}
                </Form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userInfo: state.userReducer.userInfo
})

export default connect(mapStateToProps)(CloseStore);
