import React, {useState, createRef} from 'react'
import './MenuVertical.scss'
import { Button, Menu, Divider } from 'antd';
import {
  UserOutlined,
  LogoutOutlined,
  SkinOutlined,
  EuroCircleOutlined,
  ShopOutlined,
  ShoppingOutlined,
  MailOutlined,
  SolutionOutlined, 
  BankOutlined,
  PlusCircleFilled
} from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { LOGOUT_USER, SAVE_LOGIN_USER } from '../../../redux/actions';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert2';

const { SubMenu } = Menu;

const menu = createRef()

const MenuVertical = (props) => {
  const { t } = useTranslation();
  const [openKeys, setOpenKeys] = useState([])
  let rootSubmenuKeys = ['sub1', 'sub5', 'sub8'];

  const onOpenChange = openKey => {
    const latestOpenKey = openKey.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKey);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('remember');
    props.dispatch({
      type: LOGOUT_USER,
      data: {}
    })
    props.history.push('/');
  }
  const handleCloseAccount = () => {
    Swal.fire({
      title: t("confirm-delete-account", {a: ' '}),
      text: t("confirm-delete-account2", {a: ' '}),
      icon: "warning",
      showCancelButton :true,
      confirmButtonText: t("confirm-delete", {a: ' '}),
      cancelButtonText: t("keep", {a: ' '})
    })
    .then(result => {
        if (result.isConfirmed) {
          props.history.push('/dashboard/perfil/darse-baja')
        }
    })
  }

  return (
  <div className="father-container-menu-vertical">
    <div className="container-menu-vertical">
      <div className="menu-vertical" ref={menu}>
        <div className="container-drawel_header">
          <div className="container-drawel_imageContainer">
            <img src={props.userInfo.profilePicture.url} alt="Foto de perfil"/> 
          </div>
          <div className="container-drawel_description">
            <h6>{`${t('hi', {a: ' '})} ${props.userInfo.person.firstName}`}</h6>
            <p>{props.userInfo.email}</p>
          </div>
      </div>
      <Menu mode="inline" selectedKeys={[props.location.pathname]} defaultOpenKeys={[props.location.pathname]} openKeys={openKeys} onOpenChange={onOpenChange.bind(this)}>
        <SubMenu key="sub1" icon={<UserOutlined />} title={t("my-propt", {a: ' '})}>
          <SubMenu key="sub2" icon={<SolutionOutlined />} title={t("my-profile", {a: ' '})} className="submenu-item">
            <Menu.Item key='/dashboard/perfil'><Link to='/dashboard/perfil'>{t("personal-data", {a: ' '})}</Link></Menu.Item>
            {/*<Menu.Item key='/dashboard/perfil/apariencia'><Link to='/dashboard/perfil/apariencia'>Apariencia</Link></Menu.Item>
            <Menu.Item key='/dashboard/perfil/direccion-de-envio'><Link to='/dashboard/perfil/direccion-de-envio'>Dirección de envío</Link></Menu.Item>*/}
            {!props.userInfo.googleRegister && <Menu.Item key='/dashboard/perfil/cambiar-contraseña'><Link to='/dashboard/perfil/cambiar-contraseña'>{t("edit-pass", {a: ' '})}</Link></Menu.Item>}
            {!props.userInfo.googleRegister && <Menu.Item key='/dashboard/perfil/cambiar-correo'><Link to='/dashboard/perfil/cambiar-correo'>{t("edit-email", {a: ' '})}</Link></Menu.Item>}
          </SubMenu>

          <SubMenu key="sub3" icon={<ShoppingOutlined />} title={t("my-orders", {a: ' '})} className="submenu-item">
            <Menu.Item key='/dashboard/perfil/compras-activas'><Link to='/dashboard/perfil/compras-activas'>{t("active-order", {a: ' '})}</Link></Menu.Item>
            <Menu.Item key='/dashboard/perfil/historial-de-compras'><Link to='/dashboard/perfil/historial-de-compras'>{t("order-history", {a: ' '})}</Link></Menu.Item>
            <Menu.Item key='/dashboard/perfil/favoritos'><Link to='/dashboard/perfil/favoritos'>{t("favorites", {a: ' '})}</Link></Menu.Item>
            {/* <Menu.Item key="9"><Link to='/dashboard/perfil/calificar-vendedor'>Calificar Vendedores</Link></Menu.Item> */}
            {/* <Menu.Item key='/dashboard/construccion'><Link to='/dashboard/construccion'>Calificar Vendedores</Link></Menu.Item> */}
          </SubMenu>
          
          <SubMenu key="sub4" icon={<MailOutlined />} title={t("help", {a: ' '})} className="submenu-item"> 
            {/* <Menu.Item key='/dashboard/perfil/politica-de-privacidad'><Link to='/dashboard/perfil/politica-de-privacidad'>{t("privacy-policty", {a: ' '})}</Link></Menu.Item> */}
            <Menu.Item key='/dashboard/perfil/politica-de-privacidad'><a href="https://propt1.cat/privacy-policy/" target='_blank' rel="noopener noreferrer">{t("privacy-policty", {a: ' '})}</a></Menu.Item>
            <Menu.Item key='/dashboard/perfil/darse-baja' onClick={handleCloseAccount}>{t("delete-account", {a: ' '})}</Menu.Item>
            <Menu.Item key='/dashboard/perfil/contacta'><Link to='/dashboard/perfil/contacta'>{t("contact2", {a: ' '})}</Link></Menu.Item>
            <Menu.Item key='/faq'><a href="https://propt1.cat/faq-i-videos-dajuda/" target='_blank' rel="noopener noreferrer">{t("faq", {a: ' '})}</a></Menu.Item>
            {/* <Menu.Item key='/faq'><Link to='/faq' target='_blank' rel='noopener noferrer'>{t("faq", {a: ' '})}</Link></Menu.Item> */}
          </SubMenu>
        </SubMenu>

        {props.userInfo.userRoleId === 1 &&
          <Menu.Item key='/dashboard/tienda/crear-tienda' className="sub-rol-item" icon={<ShopOutlined />}>
            <Link to='/dashboard/tienda/crear-tienda'>{t('create-store', {a: ' '})}</Link>
          </Menu.Item>}

        {props.userInfo.userRoleId === 2 &&
        <SubMenu key="sub5" icon={<ShopOutlined />} title={t("my-store", {a: ' '})}>
          <SubMenu key="sub2-1" icon={<BankOutlined />} title={t("my-store-title", {a: ' '})} className="submenu-item">
            <Menu.Item key='/dashboard/tienda'><Link to='/dashboard/tienda'>{t("store-data", {a: ' '})}</Link></Menu.Item>
            {/*<Menu.Item key='/dashboard/tienda/apariencia'><Link to='/dashboard/tienda/apariencia'>Apariencia</Link></Menu.Item>
            <Menu.Item key='/dashboard/tienda/crear-direccion'><Link to='/dashboard/tienda/crear-direccion'>Dirección</Link></Menu.Item>*/}
          </SubMenu>

          <SubMenu key="sub6" icon={<SkinOutlined />} title={t("my-products", {a: ' '})} className="submenu-item">
            <Menu.Item key='/dashboard/tienda/crear-producto'><Link to='/dashboard/tienda/crear-producto'>{t("new-product", {a: ' '})}</Link></Menu.Item>
            <Menu.Item key='/dashboard/tienda/productos-activos'><Link to='/dashboard/tienda/productos-activos'>{t("active-products", {a: ' '})}</Link></Menu.Item>
            <Menu.Item key='/dashboard/tienda/productos-inactivos'><Link to='/dashboard/tienda/productos-inactivos'>{t("inactive-products", {a: ' '})}</Link></Menu.Item>
          </SubMenu>

          <SubMenu key="sub7" icon={<ShoppingOutlined />} title={t("my-sales", {a: ' '})} className="submenu-item">
            <Menu.Item key='/dashboard/tienda/ventas-activas'><Link to='/dashboard/tienda/ventas-activas'>{t("active-sales", {a: ' '})}</Link></Menu.Item>
            <Menu.Item key='/dashboard/tienda/historial-de-ventas'><Link to='/dashboard/tienda/historial-de-ventas'>{t("sale-history", {a: ' '})}</Link></Menu.Item>
            {/* <Menu.Item key='/dashboard/tienda/ordenes-canceladas'><Link to='/dashboard/tienda/ordenes-canceladas'>{t("cancelled-orders", {a: ' '})}</Link></Menu.Item> */}
            <Menu.Item key='/dashboard/tienda/mis-calificaciones'><Link to='/dashboard/tienda/mis-calificaciones'>{t("my-reps", {a: ' '})}</Link></Menu.Item>
            {/* <Menu.Item key="16"><Link to='/dashboard/tienda/mis-calificaciones'>Mis Calificaciones</Link></Menu.Item> */}
          </SubMenu>
        </SubMenu>
        }

        <SubMenu key="sub8" icon={<EuroCircleOutlined />} title={t("billing", {a: ' '})}>
          {/* <Menu.Item key="20"><Link to='/dashboard/tienda/afiliar-plan'>Afiliarse a un Plan</Link></Menu.Item> */}
          <Menu.Item key='/dashboard/planes'><Link to='/dashboard/planes'>{t("get-plan", {a: ' '})}</Link></Menu.Item>
          {/* <Menu.Item key="21"><Link to='/dashboard/tienda/metodos-de-pago'>Métodos de Pago</Link></Menu.Item> */}
        </SubMenu>

        <Menu.Item key="22" icon={<LogoutOutlined />} className="sub-rol-item" onClick={logout}>
        {t("logout", {a: ' '})}
        </Menu.Item>
        
        <Divider />
        {props.userInfo.userRoleId === 2 &&
        <Link to='/dashboard/tienda/crear-producto'>
          <Button className="btn-second center-block btn-drawer mb-15">
            <PlusCircleFilled />
            {t("create-product2", {a: ' '})}
          </Button>
        </Link>}

      </Menu>
      </div>
    </div>
  </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userInfo
})
export default withRouter(connect(mapStateToProps)(MenuVertical))
