import React from 'react'
import {useTranslation} from 'react-i18next';

const OrderItemCanceled = ({orderSellerItem}) => {
  const { t } = useTranslation();
  return (
    <div className="cart-seller-product" key={orderSellerItem.productId}>
      <img className="cart-seller-img" src={orderSellerItem.productImageUrl} alt={orderSellerItem.productName}/>
      <div className="cart-seller-description">
      <div>
        <div className="title-container">
          <h4 className="cart-seller-description-title">{`${t("cancelled-order-3", {a: ' '})}: ${orderSellerItem.productName}`}</h4>
        </div>
        <p>{`${t('price', {a: ' '})}: ${orderSellerItem.productPrice}€`}</p>
        <p>{`${t('quantity', {a: ' '})}: ${orderSellerItem.quantity}`}</p>
      </div>
      </div>
    </div>
  )
}

export default OrderItemCanceled
